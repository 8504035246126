import React from 'react'
import TextField from '@mui/material/TextField';

function EditMemo(p: any) {

    return(
        <TextField 
            label={p.label} 
            name={p.fieldName}
            disabled={p.disabled}
            multiline
            minRows={2}
            autoFocus={p.autoFocus}
            fullWidth
            onKeyDown={p.onKeyDown}
            // margin="dense" 
            margin={p.margin ? p.margin : "normal"}
            value={p.value}
            onChange={event => p.onChangeField(p.fieldName, event.target.value)}
            variant="outlined" 
            size="small" 
            helperText={p.helperText}
        />
    )
}

export default React.memo(EditMemo)