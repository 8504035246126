import React from 'react'
import { locale } from '../../../../ui/locale'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import { SettingsTableHeadCell } from '../../../../modules/settingsTableHeadCell'
import { SettingsTableRow } from '../../../../modules/settingsTableRow'
import { SettingsTableCell } from '../../../../modules/settingsTableCell'


export default function Items(p: any) {

    return(
        p.filters.length === 0
        ? null
        :<TableContainer>
            <Table sx={{opacity: p.loading || p.edited ? '0.5' : undefined }}>
        {/* <table className={clsx("cm-table", {'active' : !p.edited})}> */}
            <TableHead>
                <TableRow>
                    <SettingsTableHeadCell>Поле</SettingsTableHeadCell>
                    <SettingsTableHeadCell>Значение</SettingsTableHeadCell>
                    <SettingsTableHeadCell></SettingsTableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {p.filters
                .filter((filter: any) => filter.fieldName)
                .map((filter: any, index: number) => (
                <SettingsTableRow 
                    key={index} 
                    selected={p.editIndex === index }
                >
                <SettingsTableCell 
                    selected={p.editIndex === index}
                    // width="40%"
                    onClick={() => p.onEditClick(index)}
                >
                    {p.collectionFields.findIndex((el:any) => el.value === filter.fieldName) >= 0
                        ? p.collectionFields[p.collectionFields.findIndex((el:any) => el.value === filter.fieldName)].description
                        : locale('common.notSpecified')
                    }
                </SettingsTableCell>
                <SettingsTableCell 
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)}
                >
                    {filter.fieldListName !== '-' ? filter.fieldListName : ''}
                </SettingsTableCell>
                
                {p.editIndex === index && !p.edited
                ?<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                    width="70px"
                    selected={p.editIndex === index}
                >  
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={index === 0 || p.edited}
                        onClick={event => p.onMoveFilter(event, index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={index === p.filters.length - 1 || p.edited}
                        onClick={event => p.onMoveFilter(event, index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </SettingsTableCell>
                :<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                    width="70px"
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)}>
                </SettingsTableCell>
                }
                </SettingsTableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}