import ViewStreamIcon from '@mui/icons-material/ViewStream'
import ViewListIcon from '@mui/icons-material/ViewList'
import { IconButton } from '../../modules/icon-button';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

export const ViewButtons = (p: any) => (
    <div>
        <IconButton icon={ViewListIcon} checked={p.view === 0} onClick={() => p.onViewChange(0)} />
        <IconButton icon={ViewModuleIcon} checked={p.view === 1} onClick={() => p.onViewChange(1)} />
        <IconButton icon={ViewStreamIcon} checked={p.view === 2} onClick={() => p.onViewChange(2)} />
    </div>
);
