import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import SettingsIcon from '@mui/icons-material/Settings'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import EditAttributesIcon from '@mui/icons-material/EditAttributes'
import PageviewIcon from '@mui/icons-material/Pageview'
import FilterListIcon from '@mui/icons-material/FilterList'
import SortIcon from '@mui/icons-material/Sort'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ListIcon from '@mui/icons-material/List';
import { locale } from '../../../ui/locale'
import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import FloatMenu from './floatMenu'
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';

// export const MENU_COVER = 0
export const MENU_PROPS = 0
export const MENU_CATALOG = 1
export const MENU_COMPLECT = 2
export const MENU_ACCESS = 3
export const MENU_FIELDS = 4
export const MENU_VIEW = 5
export const MENU_FILTERS = 6
export const MENU_SORT = 7
export const MENU_FORM = 8
export const MENU_LIST = 9

// const itemTextSx = {display: {sm: 'none', md: 'block' }};
// const itemIconSx = {minWidth: {sm: 0, md: '56px' }};
// const itemTextSx = {display: {xs: 'none', sm: 'none', md: 'none', lg: 'block' }};
// const itemIconSx = {minWidth: {xs: 0, sm: 0, md: 0, lg: '56px' }};



export default function Menu(p: any){
    // const theme = useTheme();
    const listMode = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    return (
        listMode
        ? <div>
            <Box sx={{backgroundColor: 'action.hover'}}>
            {/* <List sx={{width: {xs: '4rem', sm: '4rem', md: '4rem', lg: '17rem'}, padding: 0 }}> */}
            <List sx={{width: '17rem', padding: 0 }}>
               {menuItems.map((item, index: number) => (
                    <ListItem 
                        key={index} 
                        button 
                        selected={p.editTabIndex === item.index} 
                        onClick={(event) => p.onMenuClick(item.index)} 
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={locale(item.name)} />
                    </ListItem>
               ))
               } 
            </List>
        </Box>
        </div>
        // <Box sx={{backgroundColor: 'action.hover'}}>
        //     <List sx={{width: {xs: '4rem', sm: '4rem', md: '4rem', lg: '17rem'}, padding: 0 }}>
        //     <ListItem button selected={p.editTabIndex === MENU_COVER} onClick={(event) => p.onMenuClick(MENU_COVER)} >
        //         <ListItemIcon sx={itemIconSx}><PhotoCameraIcon /></ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.cover')} />
        //     </ListItem>
        //     <ListItem button selected={p.editTabIndex === MENU_PROPS} onClick={(event) => p.onMenuClick(MENU_PROPS)} >
        //         <ListItemIcon sx={itemIconSx}> <SettingsIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.props')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_CATALOG} onClick={(event) => p.onMenuClick(MENU_CATALOG)} >
        //         <ListItemIcon sx={itemIconSx}> <LibraryBooksIcon /> </ListItemIcon>
        //     <ListItemText sx={itemTextSx} primary={locale('collections.settings.catalog')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_FIELDS} onClick={(event) => p.onMenuClick(MENU_FIELDS)} >
        //         <ListItemIcon sx={itemIconSx}> <EditAttributesIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.fields')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_VIEW} onClick={(event) => p.onMenuClick(MENU_VIEW)} >
        //         <ListItemIcon sx={itemIconSx}> <PageviewIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.view')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_FILTERS} onClick={(event) => p.onMenuClick(MENU_FILTERS)} >
        //         <ListItemIcon sx={itemIconSx}> <FilterListIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.filters')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_SORT} onClick={(event) => p.onMenuClick(MENU_SORT)} >
        //         <ListItemIcon sx={itemIconSx}> <SortIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.sort')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_FORM} onClick={(event) => p.onMenuClick(MENU_FORM)} >
        //         <ListItemIcon sx={itemIconSx}> <ListAltIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.form')} />
        //     </ListItem>

        //     <ListItem button selected={p.editTabIndex === MENU_LIST} onClick={(event) => p.onMenuClick(MENU_LIST)} >
        //         <ListItemIcon sx={itemIconSx}> <ListIcon /> </ListItemIcon>
        //         <ListItemText sx={itemTextSx} primary={locale('collections.settings.list')} />
        //     </ListItem>

        //     </List>
        // </Box>
        
        : <FloatMenu onMenuClick={p.onMenuClick} editTabIndex={p.editTabIndex}/>
    )
}

export const menuItems = [
    // {
    //     name: 'collections.settings.cover',
    //     index: MENU_COVER,
    //     icon: <PhotoCameraIcon />
    // },
    {
        name: 'collections.settings.props',
        index: MENU_PROPS,
        icon: <SettingsIcon />
    },
    {
        name: 'collections.settings.catalog',
        index: MENU_CATALOG,
        icon: <LibraryBooksIcon />
    },
    {
        name: 'collections.settings.complect',
        index: MENU_COMPLECT,
        icon: <InventoryIcon />
    },
    {
        name: 'collections.settings.access',
        index: MENU_ACCESS,
        icon: <PeopleIcon />
    },
    {
        name: 'collections.settings.fields',
        index: MENU_FIELDS,
        icon: <EditAttributesIcon />
    },
    {
        name: 'collections.settings.view',
        index: MENU_VIEW,
        icon: <PageviewIcon />
    },
    {
        name: 'collections.settings.filters',
        index: MENU_FILTERS,
        icon: <FilterListIcon />
    },
    {
        name: 'collections.settings.sort',
        index: MENU_SORT,
        icon: <SortIcon />
    },
    {
        name: 'collections.settings.form',
        index: MENU_FORM,
        icon: <ListAltIcon />
    },
    {
        name: 'collections.settings.list',
        index: MENU_LIST,
        icon: <ListIcon />
    },
]