import React, { useRef } from 'react'
import { locale } from '../../../../ui/locale'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import EditFieldListRow from './EditFieldListRow'

// import {CatalogOnDrawName} from './CatalogOnDrawName'


export default function EditFieldList(p: any) {

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, p.onOutsideClick)


    return(
        <div>
        {p.list.length === 0
        ? <Box sx={{padding: 1, color: 'text.disabled'}}>
                {locale('collections.sort.fieldsListValuesEmpty')}
        </Box>
        : <TableContainer>
            <Table>
                <TableBody>
                    {p.list
                    .map((item: any, index: number) => (
                    <React.Fragment key={index}>
                        {p.toEditListIndex === index
                        ? <EditFieldListRow
                            key={index}
                            item={item}
                            index={index}
                            onOutsideClick={p.onOutsideClick}
                            collectionFields={p.collectionFields}
                            onSaveListItem={p.onSaveListItem}
                            onCancelEditListItem={p.onCancelEditListItem}
                            upDisabled={index === 0}
                            downDisabled={index === p.list.length - 1}
                            onMoveItem={p.onMoveItem}
                            onDeleteListItem={p.onDeleteListItem}

                            onSaveListItemName={p.onSaveListItemName}
                            onSaveListItemLength={p.onSaveListItemLength}

                        />
                        : <TableRow 
                            hover={p.toEditListIndex !== index}
                            key={index} 
                            // ref={p.toEditListIndex === index ? wrapperRef : null}
                        >
                            <TableCell sx={{cursor: 'pointer', padding: 1, height: '45px'}} onClick={event => p.onEditListItem(index)} >            
                                {item.name}
                            </TableCell>
                            
                            <TableCell width='70px'>
                                {item.length} из 12
                            </TableCell>

                        </TableRow>
                        }
                    </React.Fragment>

                        // <tr 
                        //     key={index} 
                        //     // onClick={event => p.onEditClick(index)}
                        //     ref={p.toEditListIndex === index && useWrapper ? wrapperRef : null}
                        // >
                        // <td>
                        //     {p.toEditListIndex === index
                        //     ?<EditFieldListItemName 
                        //         value={item.name}
                        //         collectionFields={p.collectionFields}
                        //         onCancelEditListItem={p.onCancelEditListItem}
                        //         onSaveListItemName={p.onSaveListItemName}
                        //         onSaveListItemLength={p.onSaveListItemLength}
                        //         onOpen={onOpen}
                        //         onClose={onClose}
                        //     />
                        //     :<span onClick={event => p.onEditListItem(index)} style={{cursor: 'pointer'}} >
                        //         {item.name}
                        //     </span>                 
                        //     }
                        // </td>
                        // <td width='90px'>
                        //     {p.toEditListIndex === index
                        //     ?<EditFieldListItemLength 
                        //         value={item.length}
                        //         collectionFields={p.collectionFields}
                        //         onCancelEditListItem={p.onCancelEditListItem}
                        //         onSaveListItemName={p.onSaveListItemName}
                        //         onSaveListItemLength={p.onSaveListItemLength}
                        //         onOpen={onOpen}
                        //         onClose={onClose}
                        //     />
                        //     :<span onClick={event => p.onEditListItem(index)} style={{cursor: 'pointer'}} >
                        //         {item.length}
                        //     </span>                 
                        //     }
                        // </td>

                        // <td width='130px'>
                        //     {p.toEditListIndex === index
                        //     ?<>
                        //         <IconButton 
                        //             size="small" 
                        //             color="primary"
                        //             disabled={index === 0}
                        //             onClick={event => p.onMoveItem(index, false)}
                        //         >
                        //             <ArrowUpwardIcon />
                        //         </IconButton>
                        //         <IconButton 
                        //             size="small" 
                        //             color="primary"
                        //             disabled={index === p.list.length - 1}
                        //             onClick={event => p.onMoveItem(index, true)}
                        //         >
                        //             <ArrowDownwardIcon />
                        //         </IconButton>
                        //         <IconButton 
                        //             size="small" 
                        //             // disabled={formState.loading}
                        //             color="secondary"
                        //             onClick={event => p.onDeleteListItem(index)}
                        //         >
                        //             <DeleteOutlineIcon />
                        //         </IconButton>                    
                        //     </>    
                        //     : null
                        //     }            
                        // </td>
                        
                        // </tr>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        }
        <Button 
            id="addListItem" 
            size="medium"
            startIcon={<AddIcon />}
            color="primary"
            onClick={p.addListItem}
            disabled={p.toEditListIndex >= 0}
        >
            {locale('collections.sort.fieldsListAdd')}
        </Button>
        </div>
    )
}