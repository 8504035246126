import { useMemo, useRef, useState }  from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { locale } from '../../../ui/locale'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { Box } from '@mui/material'

export default function CopyFrom(p: any) {
    const [useWrapper, setUseWrapper] = useState( true )
    const wrapperRef = useRef(null)

    useOutsideClick(wrapperRef, p.closeCopyFrom)

    const onOpen = () => {
        setUseWrapper(false)
    }
    const onClose = () => {
        setUseWrapper(true)
        p.closeCopyFrom()
    }

    const onChange = (event: object, value: any, reason: string) => {
        if (value && value.item) {
            p.onCopyFields(value.item)
        }
    }
    
    const renderOption = (props: any, option: any, state: any) => {
        return (
            <li {...props} key={option.CollectionItemId}>
                <Box sx={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    fontSize: '0.875rem',
                    padding: '0.1rem 0.5rem',
                    marginRight: '0.4rem',
                    borderRadius: '0.25rem',
                }}>
                    {option.itemToFind ? locale('itemEdit.itemToFind') : option.number.trim()}
                </Box>
                {option.description}
            </li>
        )
    }

    const options = useMemo(() => p.options.filter((option: any) => (
        p.all ? true : option?.item?.source?.description
    )), [p.options, p.all]);

    return(
        <Box ref={useWrapper ? wrapperRef : null} sx={{
            width: '100%',
            position: 'absolute',
            top: '8px', 
            left: 0,
            backgroundColor: 'background.paper'
        }}>
        <Autocomplete
            onOpen={onOpen}
            onClose={onClose}
            // sx={{position: 'absolute', top: 0, left: 0}}
            
            openOnFocus
            fullWidth
            size="small"
            noOptionsText={locale('itemEdit.copyFromNoOptions')}
            options={options}
            groupBy={(option: any) => option.group}
            getOptionLabel={(option: any) => option.description}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} autoFocus color="secondary" label={locale('itemEdit.copyFromLabel')} variant="outlined" />}  
            renderOption={renderOption}
            filterOptions={(options, params) => {
                if (params.inputValue !== '') {
                    return options.filter(option => (
                        option.number.includes(params.inputValue) ||
                        option.description.toUpperCase().includes(params.inputValue.toUpperCase())
                    )) 
                }
                return options
            }}
      />
      </Box>

    )
}