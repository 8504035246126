import { Box, Input, TextField, Toolbar, Typography } from '@mui/material';
import { locale } from '../../ui/locale';
import SearchIcon from '@mui/icons-material/Search';



function HeaderOther(p: any){
    return (
        <Toolbar disableGutters> 
            <Typography variant='h1'>{locale('collections.titleOther')}</Typography>
            <div className='grow'></div>
            {p.mobileMode
                ? null
                :<Input 
                    sx={{marginTop: '1rem'}}
                    placeholder="Поиск"
                    value={p.filterString}
                    onChange={event => p.onChangeFilterString(event.target.value)}
                    endAdornment={<SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />}
                />
            }
        </Toolbar>
    )
}

export default HeaderOther