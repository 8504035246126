import React from 'react'
import * as I from '../../../store/interfaces'
import EditField from '../../../ui/editors/editField'
import EditDropDown from '../../../ui/editors/editDropDown'
import EditValues from './editValues'
import EditChips from './editChips'
import EditChipsAndValue from './editChipsAndValue'

export default function EditFormField(p: any) {

    const onChange = (fieldName: string, newValue: string) => {
        if (p.toEdit.value !== newValue) {
            p.onChangeField(fieldName, newValue)
        }
    };

    if (!p.field) return null;

    return(
        p.field.type === I.FIELD_TEXT
        ?<EditField
            key={p.field.name}
            // className={p.width ? "cm-edit-width-" + p.width : null}
            span={p.width}
            freeSolo={true}
            value={p.toEdit.value || ''}
            fieldName={p.field.name}
            onChangeField={onChange}
            list={[]}
            label={p.field.name}
            disabled={p.loading}
        />
        : p.field.type === I.FIELD_AUTOCOMPLETE
        ? <EditField
            key={p.field.name}
            // className={p.width ? "cm-edit-width-" + p.width : null}
            span={p.width}
            freeSolo={true}
            value={p.toEdit.value || ''}
            fieldName={p.field.name}
            onChangeField={onChange}
            list={p.valuesMap.get(p.field.name)}
            label={p.field.name}
            disabled={p.loading}
        />
        : p.field.type === I.FIELD_DROPDOWNTEXT
        ? <EditField
            key={p.field.name}
            // className={p.width ? "cm-edit-width-" + p.width : null}
            span={p.width}
            freeSolo={true}
            value={p.toEdit.value || ''}
            fieldName={p.field.name}
            onChangeField={onChange}
            list={p.field.list}
            label={p.field.name}
            disabled={p.loading}
        />
        : p.field.type === I.FIELD_DROPDOWN
        ? <EditDropDown
            key={p.field.name}
            span={p.width}
            value={p.toEdit.value || ''}
            fieldName={p.field.name}
            onChangeField={onChange}
            label={p.field.name}
            disabled={p.loading}
            options={p.field.list.map((item: string) => ({value: item, description: item}))}
        />
        : p.field.type === I.FIELD_VALUES
        ? <EditValues
            key={p.field.name}
            toEdit={p.toEdit}
            onChangeField={p.onChangeValuesField}
            label={p.field.name}
            disabled={p.loading}
            field={p.field}
        />
        : p.field.type === I.FIELD_CHIPS
        ? <EditChips
            key={p.field.name}
            toEdit={p.toEdit}
            onChangeField={p.onChangeValuesField}
            onDeleteField={p.onDeleteValuesField}
            label={p.field.name}
            disabled={p.loading}
            field={p.field}
        />
        : p.field.type === I.FIELD_CHIPSANDVALUE
        ? <EditChipsAndValue
            key={p.field.name}
            toEdit={p.toEdit}
            onChangeField={p.onChangeValuesField}
            onDeleteField={p.onDeleteValuesField}
            label={p.field.name}
            disabled={p.loading}
            field={p.field}
        />
        : null


    )
}