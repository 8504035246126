import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import AddIcon from '@mui/icons-material/Add';
import Edit from './Edit';
import Items from './Items'
import { locale } from '../../../../ui/locale'
import { Box } from '@mui/material'
import { twoPanelsStyle } from '../../../../style/styles'

export default function Fields(p: any) {
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        loading: false, 
        editIndex: -1, 
        edited: false,
        error: null
    });
    const [toEdit, setToEdit] = useState(p.collection.fields)
    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(p.collection.fields) 
    }, [p.collection])

    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }

    const addField = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {name: '', type: '', listSorting: false, list: []}])
        setPageState({...pageState, editIndex: index, edited: true})
    }
    const onEditClick = (index: number) => {
        if (!pageState.edited) {
            setPageState({...pageState, editIndex: index})
        }
    }

    const saveFields = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        formData.fields = formData.fields.filter((field: any) => field.name)
        setToEdit(formData.fields)
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = async (itemData: any) => {
        const formData = {
            CollectionId: CollectionId,
            fields: [...toEdit.slice(0, pageState.editIndex), itemData, ...toEdit.slice(pageState.editIndex + 1)]
        }
        saveFields(formData)
    }
    const onDeleteClick = () => {
        const formData = {
            CollectionId: CollectionId,
            fields: [...toEdit.slice(0, pageState.editIndex), ...toEdit.slice(pageState.editIndex + 1)]
        }        
        saveFields(formData)
        setPageState({...pageState, editIndex: -1, edited: false})
    }
    const onCancelClick = () => {
        setToEdit([...toEdit.filter((field: any) => field.name) ])
        setPageState({...pageState, edited: false})
    }
    const onMoveField = (event: any, index: number, up: boolean) => {
        const formData = {
            CollectionId: CollectionId,
            fields: up
                ?[
                    ...toEdit.slice(0, index), 
                    toEdit[index + 1], 
                    toEdit[index], 
                    ...toEdit.slice(index + 2)]
                :[
                    ...toEdit.slice(0, index - 1), 
                    toEdit[index], 
                    toEdit[index - 1], 
                    ...toEdit.slice(index + 1)]
        }        
        saveFields(formData)
        setPageState((prev) => ({...prev, editIndex: up ? index + 1 : index - 1 }))
    }

    return(
    <Box sx={twoPanelsStyle}>
        <div>
            <div>
                <Button 
                    id="add" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => addField()}
                    startIcon={<AddIcon />}                    
                    disabled={pageState.loading || pageState.edited}
                >
                    {locale('collections.fields.addField') }
                </Button>
            </div>
            {/* <LineLoader active={pageState.loading && !pageState.edited} /> */}
            <Items 
                fields={toEdit}
                onEditClick={onEditClick}
                editIndex={pageState.editIndex}
                edited={pageState.edited}
                loading={pageState.loading}
                onMoveField={onMoveField}
            />
        </div>
            <Edit 
                field={pageState.editIndex >=0 ? toEdit[pageState.editIndex] : null}
                onSaveClick={onSaveClick}
                onDeleteClick={onDeleteClick}
                onCancelClick={onCancelClick}
                setEdited={setEdited}
                edited={pageState.edited}
                loading={pageState.loading}
            />

    </Box>
    )
}