import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import * as http from '../../modules/http'

import { Box, Button } from '@mui/material';
import { locale } from '../../ui/locale'
import { FormError } from '../../modules/form-error';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useNavigate } from 'react-router-dom';


export default function Reset(p: any) {
    const auth = useSelector((state: RootState) => state.auth.auth);
    const token = new URLSearchParams(p.location.search).get('token')
    const navigate = useNavigate();

    if (!token) {
        navigate('/')
    }

    useEffect(() => {
        if (auth) {
            navigate('/')
        }
    }, [auth, navigate]);
    
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formState, setFormState] = useState({loading: false, ready: false, error: null})

    async function resetClick(event: any) {
        event.preventDefault()
        if (!formState.loading && ! formState.ready) {
            setFormState((prev) =>  ({...prev, loading: true, error: null}))
            const res = await http.httpPost('/auth/reset', {token: token, password: password}, false)
            if (res.success) {
                setFormState((prev) =>  ({...prev, loading: false, error: null, ready: true}))
            } else {
                setFormState((prev) => ({...prev, loading: false, error: res.data.message}))
            }
        }
    }

    return (
        <Box sx={{
                padding: 6, 
                paddingTop: 6, 
                maxWidth:  '25rem', 
                marginLeft: 'auto', 
                marginRight: 'auto'
        }}>
            <h4>{locale('auth.passwordChangeTitle')}</h4>
            {formState.ready
            ? <div>   
                <>&nbsp;</>             
                <p>{locale('auth.passwordChanged')}</p>
            </div>
            :<>
            <FormError message={formState.error} />    
            <TextField
                disabled={formState.loading}
                variant="outlined"
                margin="normal"
                required
                size='small'
                fullWidth
                name="password"
                label={locale('auth.newPassword')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                autoComplete="new-password"
                InputProps={
                { endAdornment: (
                        <InputAdornment position="end" style={{cursor:"pointer"}}>
                            <IconButton
                                size="small"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                    </InputAdornment>
                    ) }
                }
            />          

            <Button
                type="submit"
                disabled={formState.loading || password==='' }
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => resetClick(event)}
            >
                {locale('auth.changePassword')}
            </Button>
            </>
            }
        </Box>
    )
}
