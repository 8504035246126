import { PaletteMode } from '@mui/material'

const theme = {
    palette: {
        mode: 'light' as PaletteMode, 
        primary: {
            // main: '#645DD7', //'#1A73E8',
            main: '#3c69e1', //'#1A73E8',
        },
        secondary: {
            main: '#E57C04',
        },    
        // background: {
        //     default: '#fafafa',
        //     paper: '#fff'
        // },
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: 'inherit',
                // borderColor: 'divider' 
            },
            // styleOverrides: {
            //     root: {
            //       borderColor: 'rgba(0, 0, 0, 0.26)',
            //       '&:hover': { borderColor: 'rgba(0, 0, 0, 0.87)' },
            //     },
            // },
        },
    },
} as const;

export default theme;
