
const Chip = (props: any) => {
    
return (
    props.title
    ? 
    <div className={"chip " + (props.border ? "chip-border" : "")}>
        {props.title}
    </div>
    : null
)
};

export default function ViewChip(p: any) {

    return(
        
        <div className="chips">
            <Chip title={p.item.itemNumber + (p.item.itemSubNumber ? '-' + p.item.itemSubNumber : '')}/>
            <Chip  title={
                p.item.source && p.item.source.storage && p.provenance
                ? p.item.source.storage
                : null
            }
                border
            />
        </div>
    )
}