import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import ToFindFilter from './ToFindFilter'
import DroppedFilter from './DroppedFilter'
import { FilterSwitch } from '../../../modules/filter-switch'


export default function Switches(p: any) {
    const dispatch = useDispatch();

    if (
        !( ['owner', 'fullAccess', 'fullView'].includes(p.cll.role) )
        &&
        !(p.cll.noPhotoFilter && p.cll.viewItemsQty.noImage > 0)
        &&
        !(p.cll.viewItemsQty.toFind)
        &&
        !(p.cll.viewItemsQty.dropped)
        &&
        !(p.cll.useQuantity && p.cll.viewItemsQty.zeroQty > 0)
        &&
        !p.cll.useChildItems
    ) { return null}
            
    return (
        <div className="filter-switch-box">
        {['owner', 'fullAccess', 'fullView'].includes(p.cll.role)
        ? 
            <FilterSwitch 
                label='itemView.provenance'
                checked={p.cll.viewProps.viewProvenance}
                onChange={() =>  dispatch(collectionActions
                    .changeViewProps({viewProvenance: !p.cll.viewProps.viewProvenance}) )}
            />
        : null
        }
        {p.cll.noPhotoFilter && p.cll.viewItemsQty.noImage > 0
        ? 
            <FilterSwitch 
                label='filters.noPhoto.switch'
                checked={p.cll.viewProps.viewNoImages}
                qty={p.cll.viewItemsQty.noImage}
                onChange={() =>  dispatch(collectionActions
                    .changeViewProps({viewNoImages: !p.cll.viewProps.viewNoImages}) )}
            />
        : null
        }
        {p.cll.viewItemsQty.toFind || p.cll.viewProps.viewOnlyToFind
        ? <>
            <FilterSwitch 
                label='filters.toFind.switch'
                checked={p.cll.viewProps.viewToFind}
                qty={p.cll.viewItemsQty.toFind}
                onChange={() =>  dispatch(collectionActions
                    .changeViewProps({viewToFind: !p.cll.viewProps.viewToFind}) )}
            />

            {p.cll.viewProps.viewToFind
            ? <ToFindFilter cll={p.cll}/> 
            : null
            }
        </>
        : null
        }

        {p.cll.viewItemsQty.dropped || p.cll.viewProps.viewOnlyDropped
        ? <>
            <FilterSwitch 
                label='filters.dropped.switch'
                checked={p.cll.viewProps.viewDropped}
                qty={p.cll.viewItemsQty.dropped}
                onChange={() =>  dispatch(collectionActions
                    .changeViewProps({viewDropped: !p.cll.viewProps.viewDropped}) )}
            />

            {p.cll.viewProps.viewDropped
            ? <DroppedFilter cll={p.cll}/> 
            : null
            }
        </>
        : null
        }

        {p.cll.useQuantity && p.cll.viewItemsQty.zeroQty > 0
        ? <FilterSwitch 
            label='filters.qty.switch'
            checked={p.cll.viewProps.viewZeroItems}
            qty={p.cll.viewItemsQty.zeroQty}
            onChange={() =>  dispatch(collectionActions
                .changeViewProps({viewZeroItems: !p.cll.viewProps.viewZeroItems}) )}
        />
        : null
        }
        {p.cll.useChildItems
        ? <FilterSwitch 
            label='filters.child.switch'
            checked={p.cll.viewProps.ignoreChildItems}
            onChange={() =>  {
                dispatch(collectionActions.changeViewProps({ignoreChildItems: !p.cll.viewProps.ignoreChildItems}) )
                dispatch(collectionActions.collectionChanged())
            }}
        />
        : null
        }
        </div>
    )       
}
    