import React, {useState, useRef} from 'react'
import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useOutsideClick from '../../../hooks/useOutsideClick'

import IconButton from '@mui/material/IconButton';

export default function ViewQty(p: any) {
    const dispatch = useDispatch()
    const [editItemsId, setEditItemsId] = useState('')
//    const [editQty, setEditQty] = useState(p.item.qty)

    const wrapperRef = useRef(null)

//    const saveQty = async () => {
//        console.log(editQty)
//        dispatch(collectionActions.updateItemQty(p.item.CollectionItemId, editQty))
//    }

    const handleClickAway = () => {
        setEditItemsId('')
    }

    useOutsideClick(wrapperRef, handleClickAway)

    const onShowQtyEdit = (itemId: string) => {
        setEditItemsId(itemId)
    }

    const onClickPlus = (qty: number) => {
        dispatch(collectionActions.updateItemQty(p.CollectionId, p.CollectionItemId, ++qty))
    }

    const onClickMinus = (qty: number) => {
        dispatch(collectionActions.updateItemQty(p.CollectionId, p.CollectionItemId, --qty))
    }

    return(
        <>
        <span 
            className="cm-qty-view" 
            onClick={(event: any) => onShowQtyEdit(p.CollectionItemId)}
        >
            <span>{p.qtyUsed}</span>
            <span>{p.qty}</span>
        </span>
        {editItemsId === p.CollectionItemId
        ?<span className="cm-qty-edit" >
            <div ref={wrapperRef}>
            <IconButton 
                    disabled={p.readOnly}
                    onClick={event => onClickMinus(p.qty)}
                    >
                <RemoveIcon />
            </IconButton>

            <span>{p.qty}</span>
            
            <IconButton 
                    disabled={p.readOnly}
                    onClick={event => onClickPlus(p.qty)}
            >
                <AddIcon />
            </IconButton>
            </div>
        </span>
        : null
        }
        </>
)
}