import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as I from '../../src/store/interfaces'
import { locale } from './locale';
import { Menu, Stack } from '@mui/material';

export default function Paginator(p: any)  {

    const [anchorPaginator, setAnchorPaginator] = useState<null | HTMLElement>(null);

    const handlePaginator = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorPaginator(event.currentTarget);
    }

    const handlePaginatorClose = () => {
        setAnchorPaginator(null);
    }
    
    const onPaginatorChange = (num: number) => {
        setAnchorPaginator(null)
        p.onPaginatorChange(num)
    }
    
    const onPageDown = () => {
        p.onPageDown()
    }
    const onPageUp = () => {
        p.onPageUp()
    }
    
    return (
        p.totalItems <= I.minItemsPerPage
        ? null
        : 
        
        <Stack 
            direction='row'
            spacing={1} 
            alignItems={'center' }
            sx={{                
                fontSize: '0.875rem',
                color: 'text.secondary',
            }}
        >
            <span>{locale('paginator.onPage')}:</span>
            <Stack 
                direction='row'
                alignItems='center'
                onClick={handlePaginator}                    
                sx={{
                    cursor: 'pointer',
                    color: 'primary.main',
                    fontWeight: 700
                }}
            >
                {p.totalItems
                ? (p.startItem + 1) + '-'
                : null
                }
                {p.itemsOnPage + p.startItem > p.totalItems ? p.totalItems : p.itemsOnPage + p.startItem} 
                {" из "}{p.totalItems}
                <ExpandMoreIcon />
            </Stack>

                <IconButton 
                    key={'pr1'}
                    size={p.mobileMode ? "small" : "medium"}
                    disabled={p.startItem === 0}
                    onClick={ (event) => onPageDown()}
                >
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton 
                    key={'pr2'}
                    size={p.mobileMode ? "small" : "medium"}
                    disabled={p.startItem + p.itemsOnPage >= p.totalItems}
                    onClick={ (event) => onPageUp()}
                >
                    <ChevronRightIcon/>
                </IconButton>
            
            {/* <Button
                onClick={handlePaginator}
                color="inherit"
                endIcon={<ExpandMoreIcon />}                    
            >            
                <span className='paginator-current'>
                {p.totalItems
                ? (p.startItem + 1) + '-'
                : null
                }
                {p.itemsOnPage + p.startItem > p.totalItems ? p.totalItems : p.itemsOnPage + p.startItem} </span> 
                <span className='paginator-total'>{p.totalItems}</span>
            </Button> */}
            <Menu
                id="menu-paginator"
                anchorEl={anchorPaginator}
                open={Boolean(anchorPaginator)}
                onClose={handlePaginatorClose}
            >
                <MenuItem 
                    key={'p1'} 
                    value={50}
                    selected={p.itemsOnPage===50}
                    onClick={(event) => onPaginatorChange(50)}
                >{locale('paginator.itemsOnPage')}: 50</MenuItem>
                <MenuItem 
                    key={'p2'} 
                    value={100}
                    selected={p.itemsOnPage===100}
                    onClick={(event) => onPaginatorChange(100)}
                >{locale('paginator.itemsOnPage')}: 100</MenuItem>
                <MenuItem 
                    key={'p3'} 
                    value={-1}
                    selected={p.itemsOnPage===-1}
                    onClick={(event) => onPaginatorChange(-1)}
                >{locale('paginator.allItems')}</MenuItem>

            </Menu>
            
        </Stack>
    )
}
