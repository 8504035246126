
import { 
    LOAD_LIST_START, LOAD_LIST_FAIL, LOAD_LIST_SUCCESS, LOAD_LIST_CHANGED,
    LIST_CHANGE_FILTER_STR, LIST_CHANGE_FILTER_INDEX
} from "./listReducer"

import * as http from '../../modules/http'

export const changeFilterStr = (filterString: string) => {
    return { 
        type: LIST_CHANGE_FILTER_STR,
        filterString: filterString
    }
}

export const collectionListChanged = () => {
    return { type: LOAD_LIST_CHANGED}
}

export const loadCollectionList = () => {
    return function (dispatch: any, getState: any)  {
        if (getState().app.offlineMode) return
        if (getState().list.loading) return
        if (getState().list.valid) return

        http.reduxHttpGet (
            dispatch,
            '/cll/list',
            {},
            true,
            LOAD_LIST_START,
            LOAD_LIST_FAIL,
            LOAD_LIST_SUCCESS
        )
    }
}

export const changeFilterIndex = (filterIndex: number) => {
    return { 
        type: LIST_CHANGE_FILTER_INDEX,
        filterIndex: filterIndex
    }
}

