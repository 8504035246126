import * as I from '../interfaces'

export function prepareFilterString (cll: I.Collection, filterString: string)  {

    function isItemFiltered(item: I.CollectionItem, filterString: string): boolean {
        return (
            // (item.description && item.description.toLowerCase().indexOf(filterString.toLowerCase()) >= 0 ) ||
            (item.title && item.title.toLowerCase().indexOf(filterString.toLowerCase()) >= 0 ) ||
            (item.subDescription && item.subDescription.toLowerCase().indexOf(filterString.toLowerCase()) >= 0) ||
            (item.catalog && item.catalog.toLowerCase().indexOf(filterString.toLowerCase()) >= 0 ) ||
            item.itemNumber === filterString
        )
    }

    function itemFilterList(item: I.CollectionItem, filterString: string): string[] {
        let arrResult: string[] = []
        item.items.forEach(subItem => {
            if (isItemFiltered(subItem, filterString)) {
                arrResult.push(subItem.CollectionItemId)
            }
        })
        if (arrResult.length > 0) {
            arrResult.push(item.CollectionItemId)
        } else if (isItemFiltered(item, filterString)) {
            arrResult.push(item.CollectionItemId)
        }
        return arrResult
    }

    //cll.useChildItems
    // console.log()
    if (!filterString) {
        cll.viewProps.filterStringItems = []
        return
    }
    if (!cll.useChildItems) {
        cll.viewProps.filterStringItems = cll.items
            .filter((item: I.CollectionItem) => (
                isItemFiltered(item, filterString) 
            ))
            .map(item => item.CollectionItemId)
    } else {
        let arrResult: string[] = []
        cll.items.forEach(item => {
            arrResult = arrResult.concat(itemFilterList(item, filterString))
        })
        cll.viewProps.filterStringItems = arrResult
    }
}
