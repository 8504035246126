import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';

import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditField from '../../../../ui/editors/editField'
import { LineLoader } from '../../../../modules/line-loader';
import EditFrame from '../editFrame';
import { Box } from '@mui/material';
import { bottomToolbar, gridStyle, topToolbar } from '../../../../style/styles';
import { IOSSwitch } from '../../../../modules/ios-switch';


export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState(p.view)

    useEffect( () => {
        setToEdit(p.view) 
    }, [p.view])

    let listIndex = toEdit ? p.collectionFields.findIndex((list: any) => list.value === toEdit.name) : -1
    let list = listIndex >= 0 ? p.collectionFields[listIndex].list : []

    function onChangeName(newValue: any) {
        setToEdit((prev: any) => ({...prev, name: newValue, listName: '-'}))
        p.setEdited()

        listIndex = toEdit ? p.collectionFields.findIndex((list: any) => list.value === toEdit.name) : -1
        list = listIndex >= 0 ? p.collectionFields[listIndex].list : []
    }

    function onChangePrefix(fieldName: string, newValue: string) {
        if (toEdit.prefix !== newValue && (!!toEdit.prefix || !!newValue)) {
            setToEdit((prev: any) => ({...prev, prefix: newValue}))
            p.setEdited()
        }
    }

    function onChangeSuffix(fieldName: string, newValue: string) {
        if (toEdit.suffix !== newValue && (!!toEdit.suffix || !!newValue)) {
            setToEdit((prev: any) => ({...prev, suffix: newValue}))
            p.setEdited()
        }
    }

    function onChangeFramed(newValue: any) {
        setToEdit((prev: any) => ({...prev, framed: newValue}))
        p.setEdited()
    }

    const onCancelClick = () => {
        setToEdit(p.view) 
        p.onCancelClick()
    }
    const onSaveClick = () => {
        p.onSaveClick({
            ...toEdit, 
            listName: list.length === 0 ? null : toEdit.listName
        })
    }
    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'sticky', top: '5rem'}}>
            <Box sx={topToolbar}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={() => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={p.loading || !p.edited }
                >
                    {locale('common.cancel')}
                </Button>
                <Button 
                    id="save" 
                    size="medium"
                    variant={p.loading || !p.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={() => onSaveClick()}
                    startIcon={<DoneIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit || !toEdit.name }
                >
                    {locale('common.save')}
                </Button>
            </Box>
            <LineLoader active={p.loading && p.edited} />

            {!toEdit
            ? null
            :<Box sx={{backgroundColor: 'action.hover', padding: 2}}>
                <div>
                    <FormControl size="small" variant='outlined' fullWidth >
                        <InputLabel id="label-name">{locale('collections.view.fieldName')}</InputLabel>
                        <Select            
                            labelId="label-name"
                            id="field-select"
                            value={toEdit.name}            
                            label={locale('collections.view.fieldName')}
                            onChange={event => onChangeName(event.target.value)}
                        >
                        {p.collectionFields.map((field: any, index: number) => (
                            <MenuItem key={index} value={field.value}>{field.description}</MenuItem>
                        ))}

                        </Select>
                    </FormControl>
                    {/* <Box sx={gridStyle}>
                        <FormControl size="small" variant='outlined' 
                            sx={{gridColumnStart: list.length > 0 ? "span 6" : "span 12"}}
                        >
                            <InputLabel id="label-name">{locale('collections.view.fieldName')}</InputLabel>
                            <Select            
                                labelId="label-name"
                                id="field-select"
                                value={toEdit.name}            
                                label={locale('collections.view.fieldName')}
                                onChange={event => onChangeName(event.target.value)}
                            >
                            {p.collectionFields.map((field: any, index: number) => (
                                <MenuItem key={index} value={field.value}>{field.description}</MenuItem>
                            ))}

                            </Select>
                        </FormControl>
                        {list.length > 0
                        ? <FormControl size="small" variant='outlined' sx={{gridColumnStart: "span 6" }}>
                            <InputLabel id="label-list-name">{locale('collections.view.listName')}</InputLabel>
                            <Select
                                labelId="label-list-name"
                                id="field-list-select"
                                value={toEdit.listName}
                                label={locale('collections.view.listName')}
                                onChange={event => onChangeListName(event.target.value)}
                            >
                            <MenuItem value='-'>
                                <em>{locale('common.all')}</em>
                            </MenuItem>
                            {list.map((value: string, index: number) => (
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        : null
                        }
                    </Box> */}
                <Box sx={gridStyle}>
                    <EditField
                        key="prefix"
                        span={6}
                        freeSolo={true}
                        value={toEdit.prefix ? toEdit.prefix : ''}
                        fieldName="prefix"
                        onChangeField={onChangePrefix}
                        list={[]}
                        label={locale('collections.view.prefix')}
                    />
                    <EditField
                        key="suffix"
                        span={6}
                        freeSolo={true}
                        value={toEdit.suffix ? toEdit.suffix : ''}
                        fieldName="prefix"
                        onChangeField={onChangeSuffix}
                        list={[]}
                        label={locale('collections.view.suffix')}
                    />
                </Box>
                {p.showFramed
                ?<FormControlLabel
                    sx={{paddingLeft: '1rem', gap: '.5rem'}}
                    control={
                        <IOSSwitch
                            checked={!!toEdit.framed}
                            onChange={() => onChangeFramed(!toEdit.framed)}
                            size="small"
                            name="framed"
                            color="primary"
                        />    
                    }
                    label={locale('collections.view.framed')}
                />
                : null
                }
            </div>
            <Box sx={bottomToolbar}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => p.onDeleteClick(p.editSetIndex, p.editIndex)}
                    disabled={p.loading}
                >
                    {locale('collections.fields.delete')}
                </Button>
            </Box>
            </Box>
            }
        </Box> 
        </Box> 
    )
}