import React from 'react'
import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import { locale } from '../../../ui/locale';
import { FormControl, FormControlLabel, Radio } from '@mui/material';

export default function ToFindFilter (p: any) {
    const dispatch = useDispatch();

    return (
        <FormControl sx={{paddingLeft: 2}}>
                <FormControlLabel 
                    sx={{
                        fontSize: '.875rem',
                        color: !p.cll.viewProps.viewOnlyToFind ? 'text.primary' : 'action.active',
                        '&:hover': {color: 'text.primary'}
                    }}
                    disableTypography
                    checked={!p.cll.viewProps.viewOnlyToFind} 
                    control={<Radio size='small' sx={{padding: '4px'}} />} 
                    label={locale('filters.toFind.allItems')} 
                    onClick={event =>  dispatch(collectionActions
                        .changeViewProps({viewOnlyToFind: false}) )}
                />
                <FormControlLabel 
                    sx={{
                        fontSize: '.875rem',
                        color: p.cll.viewProps.viewOnlyToFind ? 'text.primary' : 'action.active',
                        '&:hover': {color: 'text.primary'}
                    }}
                    disableTypography
                    checked={p.cll.viewProps.viewOnlyToFind} 
                    control={<Radio size='small' sx={{padding: '4px'}}/>} 
                    label={locale('filters.toFind.toFindItems')} 
                    onClick={event =>  dispatch(collectionActions
                        .changeViewProps({viewOnlyToFind: true}) )}
                />
        </FormControl>

    )
}