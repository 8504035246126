import { locale } from '../ui/locale'
export const FIELD_TEXT = 'text'
export const FIELD_AUTOCOMPLETE = 'autocomplete'
export const FIELD_DROPDOWNTEXT = 'dropDownText'
export const FIELD_DROPDOWN = 'dropDown'
export const FIELD_VALUES = 'values'
export const FIELD_CHIPS = 'chips'
export const FIELD_CHIPSANDVALUE = 'chipsAndValue'
export const simpleFields = [FIELD_TEXT, FIELD_AUTOCOMPLETE, FIELD_DROPDOWNTEXT, FIELD_DROPDOWN]

export const imageRatioWide = 2
export const imageRatio = 1
export const coverClassName = 'w2h1'
export const buttonCellStyle = {padding: "0rem 0.2rem", width: "70px"}


export function isEditAccess(role: string): boolean {
    return ['owner', 'fullAccess'].includes(role)
}


export function timeString(date: Date): string {
    var currentDate = new Date()
    currentDate.setDate(currentDate.getDate() )

    var timeDiff = Math.abs(date.getTime() - currentDate.getTime())
    let days = Math.floor((timeDiff % 2073600000) / 3600000 /24)
    let hours = Math.floor((timeDiff % 86400000) / 3600000)
    // let minutes = Math.round(((timeDiff % 86400000) % 3600000) / 60000)
    if (hours === 0) {
        return 'менее часа назад'
    }
    return (
        (days > 1 ? days +  ' дней ' : '') + 
        (days === 1 ? days +  ' день ' : '') + 
        (hours > 0 && days < 2 ? hours +  ' ч. ' : '') 
        // + 
        // (minutes > 0 && days === 0 ? minutes + ' мин.' : '') + 
        // (minutes === 0 && hours === 0 && days === 0? 'менее минуты' : '') 
    )
}

export const fieldValueList = (item: CollectionItem, collectionFields: FieldSet[], field: any) => {

    const fieldIndex = item.fields.findIndex(el => el.name ===field.name)
    if (fieldIndex < 0 ) return []
    const setIndex = collectionFields.findIndex(set => set.name ===field.name)
    const fieldType = setIndex >=0 ? collectionFields[setIndex].type : null
    switch (fieldType) {
        case FIELD_DROPDOWNTEXT :{
            return [item.fields[fieldIndex].value]
        }
        case FIELD_DROPDOWN :{
            return [item.fields[fieldIndex].value]
        }
        case FIELD_VALUES :{
            return item.fields[fieldIndex].valueList.filter(el => el.value).map(el => el.name + ' '+ el.value)
        }
        case FIELD_CHIPS :{
            return item.fields[fieldIndex].valueList.map(el => el.name)
        }
        case FIELD_CHIPSANDVALUE :{
            return item.fields[fieldIndex].valueList.map(el => el.name + el.value ? ' '+ el.value : '')
        }
    }
    return []
}
export const fieldCaptionAndValue = (item: CollectionItem, collectionFields: FieldSet[], field: any) => {
    if (field.name === '_catalog') {
        return [item.catalog, locale('collections.view.fields.catalog')]
    } 
    if (field.name === '_description') {
        return [item.description, locale('collections.view.fields.description')]
    } 
    if (field.name === '_subDescription') {
        return [item.subDescription, locale('collections.view.fields.subDescription')]
    } 
    if (field.name === '_itemToFind') {
        return item.itemToFind 
        ? [locale('collections.view.toFind'), locale('collections.view.fields.itemToFind') ]
        : [null, locale('collections.view.fields.itemToFind')]
    } 
    if (field.name === '_itemDropped') {
        return item.itemDropped 
        ? [locale('collections.view.Dropped'), locale('collections.view.fields.itemDropped') ]
        : [null, locale('collections.view.fields.itemDropped')]
    } 
    if (field.name === '_number') {
        return [item.itemNumber + (item.itemSubNumber ? '-' + item.itemSubNumber : ''), locale('collections.view.fields.number')]
    } 

    const fieldIndex = item.fields.findIndex(el => el.name ===field.name)

    const setIndex = collectionFields.findIndex(set => set.name ===field.name)

    const fieldType = setIndex >=0 ? collectionFields[setIndex].type : null

    if (fieldType && fieldIndex >= 0) {
        if (simpleFields.indexOf(fieldType) >= 0) {
            return !field.listName || field.listName === '-'
                ? [item.fields[fieldIndex].value, field.name]
                : field.listName === item.fields[fieldIndex].value 
                ? [item.fields[fieldIndex].value, field.name]
                : [null, null]
        }
        if (item.fields[fieldIndex].valueList && item.fields[fieldIndex].valueList.length > 0) {
            if (field.listName && field.listName !== '-') {
                const listIndex = item.fields[fieldIndex].valueList.findIndex(el => el.name === field.listName)
                // console.log(listIndex)
                if (listIndex >= 0) {
                    return [
                        item.fields[fieldIndex].valueList[listIndex].value, 
                        item.fields[fieldIndex].valueList[listIndex].name
                    ]
                } else {
                    return [null, null]
                }
            }
            else {
                switch (fieldType) {
                    case FIELD_VALUES :{
                        return [item.fields[fieldIndex].valueList.map(el => el.name + ' '+ el.value)
                        .join(', '), field.name]
                    }
                    case FIELD_CHIPS :{
                        return [item.fields[fieldIndex].valueList.map(el => el.name)
                        .join(', '), field.name]
                    }
                    case FIELD_CHIPSANDVALUE :{
                        return [item.fields[fieldIndex].valueList.map(el => el.name + (el.value ? ' '+ el.value : ''))
                        .join(', '), field.name] 
                    }
                }
            }
        }
    }
    return [null, null]
}
export const fieldValue = (item: CollectionItem, collectionFields: FieldSet[], field: any) => {
    return fieldCaptionAndValue(item, collectionFields, field)[0]
}

export const minItemsPerPage = 50

export const initCollectionFields = [
    {value: '_catalog', description: locale('collections.view.fields.catalog'), list: []}, 
    {value: '_description', description: locale('collections.view.fields.description'), list: []}, 
    {value: '_subDescription', description: locale('collections.view.fields.subDescription'), list: []},
    {value: '_itemToFind', description: locale('collections.view.fields.itemToFind'), list: []},
    {value: '_itemDropped', description: locale('collections.view.fields.itemDropped'), list: []},
    {value: '_number', description: locale('collections.view.fields.number'), list: []}
]

export const initCoinCollectionFields = [
    {value: '_coinWeigth', description: locale('collections.view.fields.coinWeigth'), list: []}
]
export const initVineCollectionFields = [
    {value: '_recomend', description: locale('collections.view.fields.recomend'), list: []}
]
export const initQtyCollectionFields = [
    {value: '_qty', description: locale('collections.view.fields.qty'), list: []}
]

export interface Collections {
    loading: boolean
    valid: boolean,
    notFound: boolean,
    CollectionId: string | null,
    collection: Collection | null,
    collections: Collection[],
}
export interface ViewProps {
    catalogOpen: boolean,
    filterString: string,
    filterStringItems: string[],
    startItem: number,
    itemsOnPage: number,
    view: number,
    sorting: number,
    sortingReverce: boolean,
    viewProvenance: boolean,
    viewToFind: boolean,
    viewOnlyToFind: boolean,
    viewNoImages: boolean,
    viewZeroItems: boolean,
    viewDropped: boolean,
    viewOnlyDropped: boolean,
    catalogItems: string[],
    filterItems: string[],
    catalogCheckedList: string[],
    ignoreChildItems: boolean
}

export interface CollectionList {
    loading: boolean,
    valid: boolean,
    filterIndex: number,
    typeList: CollectionType[],
    filterString: string,
    items: Collection[]
}

export interface Auth {
    auth: boolean | null,
    loading: boolean,

    userId: string | null,
    userName: string | null,
    email: string | null,
    roles: string[],

    passwordChanged: boolean,
    emailVerified: boolean,
    error: string | null,
}

export enum CollectionType {na = 'N/A', Vine = 'vine', Reward = 'reward', Badge = 'badge', Weapon = 'weapon', Coin = 'coin', Other = 'other'}
export const typeArray = ['N/A', 'vine', 'reward', 'badge', 'weapon', 'coin', 'other']

export interface Collection {
    CollectionId: string,
    viewProps: ViewProps,

    type: CollectionType
    name: string
    catalogName: string
    details: string,
    useQuantity: boolean,
    shared: boolean,
    useChildItems: boolean,
    noPhotoFilter: boolean,
    backgroundColor: string,

    imageId: string,
    role: string,
    owner: string,

    itemsQty:{
        total: number,
        toFind:number,
        sharedTotal: number,
        sharedToFind: number,
    },
    viewItemsQty:{
        noImage: number,
        toFind: number,
        zeroQty: number,
        dropped: number
    },
    catalog: CatalogItem[],
    filterGroups: FilterGroup[],
    catalogAutoSort: boolean,
    filters: FilterSet[],
    complect: Complect[],
    fields: FieldSet[],
    users: Users[],
    view: ViewSet[],
    sort: SortSet[],
    form: FormSet[],
    listGroup: [{name: string, prefix: string, suffix: string}],
    listDescription: [{name: string, prefix: string, suffix: string}],
    items: CollectionItem[],
    preparedItems: CollectionItem[],
    viewSet: {
        catalogSet: ViewSetFields[],
        titleSet: ViewSetFields[],
        pictureSet: ViewSetFields[],
        labelSet: ViewSetFields[],
        gridSet: ViewSetFields[],
    }
}
export interface FilterGroup {
    // id: number,
    description: string,
    isOpen: boolean,
    hidden: boolean,
    items: FilterItem[],
}

export interface FilterItem {
    description: string,
    isChecked: boolean,
    itemsList: string[],
}

export interface FormSet {
    isGroup: {type: Boolean, default: false},
    isLabel: {type: Boolean, default: false},
    caption: string,
    field: string,
    fields: {name: string, length: string}[]
}

export interface SortSet {
    setName: string,
    addReverce: boolean,
    fields: {name: string, desc: boolean}[]
}

interface ViewSet {
    setName: string,
    fields: ViewSetFields[]
}

export interface ViewSetFields {
    name: string, 
    listName: string, 
    framed: boolean,
    prefix: string,
    suffix: string
}

interface Users {
    userName: string,
    role: string
}
export interface FieldSet {
    name: string,
    // description: {type: String},
    // text 
    // autocomplete текст с выбором из данных
    // dropDownText текст с выбором из списка
    // dropDown жесткий выбор из списка
    // values Значения
    // chips Фишки
    // chipsAndValue Фишки + значение
    type: string, 
    listSorting: boolean,
    list: string[]
}
interface Complect {
    name: string,
    description: string,
    details: string,
    currency: string,
    date: string,
    price: string,
    info: string,
}
export interface FilterSet {
    fieldName: string,
    fieldListName: string,
    titleNone: string,
    titleOther: string,
    useValuesMap: boolean,
    listSorting: boolean,
    valuesMap: ValuesMap[]
}

export interface ValuesMap {
    filter: string, 
    value: string
}
export interface CatalogItem {
    _id: string,
    description: string
    // itemsQty: number,
    // hiddenItemsQty: number,
    // childItemsQty: number,
    // hiddenChildItemsQty: number,
    isOpen: boolean,
    isChecked: boolean,
    items: CatalogItem[]
    itemsList: string[],
    itemsListGroup: string[]
}

export interface CollectionItem {
    CollectionId: string,
    CollectionItemId: string,
    // bigDetails: string,
    catalog: string,
    catalogOrderNr: string,
    // catalogId: string,
    catalogLevel1: string,
    catalogLevel2: string,
    catalogLevel3: string,

    catalogList?: string[],
    catalogListGroup?: string[],

    subDescription: string,
    description: string,
    title?: string,

    itemNumber: string,
    itemSubNumber: string,
    itemToFind: boolean,
    itemDropped: boolean,
    droppedInfo: string,

    itemText: string,
    
    sharedItem: boolean,

    fields: ItemField[],

    qty: number,
    qtyUsed: number,
    yearFrom?: number,
    yearTo?: number,
    url: string[],
    oneImageOnCover: boolean,
    images: string[],
    source: {
        complect: string,
        description: string,
        details: string,
        currency: string,
        date: string,
        price: string,
        info: string,
        storage: string,
    },

    items: CollectionItem[],
    }

    export interface ItemField{
        name: string,
        value: string, 
        valueList:{ name: string, value: string}[]
    }
    export interface ItemImages {
        CollectionId: number,
        imageId: number,
        CollectionItemId: number,
        OrderNo: number,
        ContentType: string,
        wideImage: boolean,
    }