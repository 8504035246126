
export const ru = {
    common: {
      ok: 'Ok',
      cancel: 'Отмена',
      close: 'Закрыть',
      menu: 'Меню',
      save: 'Сохранить',
      search: 'Искать',
      edit: 'Изменить',
      remove: 'Удалить',
      new: 'Новый',
      yes: 'Да',
      no: 'Нет',
      start: 'Старт',
      end: 'Конец',
      notSpecified: 'Не указано',
      all: 'Все',
      one: "Один",
      two: "Два",
      three: "Три",
      send: "Отправить",
      add: "Добавить"
    },
    pdf: {
        prepare: "Скачать карточку предмета в формате pdf",
        download: "Скачать pdf файл",
        loading: "Идет подготовка файла...",
    },
    create: {
        title: "Создание коллекции",
        create: "Создать коллекцию"
    },
    settings: {
        title: 'Настройки',
        emailTitle: 'Электронная почта',
        passwordTitle: 'Изменить пароль',
        email: 'EMail',
        emailNotVerified: 'Мы ожидаем подтверждения адреса электронной почты',
        sendVerificationEmail: 'Отправить повторное сообщение',
        verificationEmailSent: 'Инструкции для подтверждения адреса отправлены по адресу'
    },
    feedback: {
        title: "Обратная связь",
        email: "Контактные данные (email, телефон)",
        message: "Сообщение",
        text: "Укажите контактные данные, напишите сообщение и нажмите «Отправить»",
        sent: "Ваше сообщение отправлено",
        error: {
            email: 'Укажите контактные данные',
            message: 'Укажите текст сообщения',
        }
    },
    filters: {
        none: 'Не указано',
        child: {
            switch: "Без вложенности"
        },
        source: {
            complect: 'Комплект',
            description: 'Источник происхождения',
            storage: 'Место хранения',
        },
        noPhoto: {
            switch: "Без фотографий",
        },
        access: {
            title: 'Публичный доступ',
            notShared: 'Без доступа',
            shared: 'Доступные'
        },
        switchAllSharedOn: 'Включить все',
        switchAllSharedOff: 'Выключить все',
        description: {
            title: "Наименование",
        },
        qty: {
            switch: "Остаток - ноль",
            title: "Количество",
            zero: "Нет",
            one: "1 штука",
            two: "2 штуки",
            twoFive: "От 2 до 5 штук",
            fiveTen: "От 5 до 10 штук",
            tenTwenty: "От 10 до 20 штук",
            tеwentyMore: "Более 20 штук",
        },
        recomend: {
            title: "Рекомендация",
            keep: "Хранить",
            drink: "Пить",
            expired: "Просрочено",
            notDefined: "Срок не указан",
        },
        toFind: {
            switch: "Ищу в коллекцию",
            allItems: "Все предметы",
            toFindItems: "Только «Ищу»"
        },
        dropped: {
            switch: "Выбыл из коллекции",
            allItems: "Все предметы",
            droppedItems: "Только выбывшие"
        }
    },
    app: {
      title: 'Collection Expo Club',
      titleSmall: 'Collection',
      goOfflineMode: 'Приложение перешло в офлайн режим',
      goOnlineMode: 'Приложение перешло в онлайн режим',
    },
    image: {
        specifyImage: 'Имя изображения не задано'
    },
    upload: {
        error: {
            fileNotFound: 'Файл не найден',
            fileTooLarge: 'Превышен допустимый размер файла',
            wrongFileFormat: 'Формат файла не поддерживается',
            incorrectFileName: "Не коректный формат имени файла",
            itemNotFound: "Предмет не найден",
        }
    },
    list: {
        filter: {
            all: "Все",
            my: "Мои",
            others: "Чужие",
        }
    },
    auth: {
        signInTitle:'Вход в Коллекции',
        signIn:'Войти',
        signOut:'Выйти',
        alreadyRegistered: 'Уже зарегистрированы?',
        editProfile: 'Настройки',
        userName:'Ваше имя',
        userNameOrPassword: 'Ваше имя или email',
        password:'Пароль',
        newPassword:'Новый пароль',
        rememberMe:'Запомнить меня',
        forgotPassword:'Забыли пароль?',
        forgotPasswordTitle:'Забыл пароль',
        forgotPasswordButton:'Запросить инструкцию',
        changePassword:'Изменить пароль',
        createAnAccount:'Регистрация',
        login:'Войти',
        register:'Зарегистрироваться',
        registerTitle: 'Регистрация',
        firstName:'Имя',
        lastName:'Фамилия',
        email:'Email',
        phone:'Телефон',
        resetPassword:'Забыли пароль?',
        resetPasswordText1: "В целях безопасности мы не храним ваш пароль и не высылаем его по электронной почте.", 
        resetPasswordText2: "Вы можете ввести адрес электронной почты, указанный при регистрации, и мы вышлем вам инструкцию по созданию нового пароля.",
        resetPasswordSent: "Инструкция отправлена",
        resetPasswordSentStart: 'Инструкция по созданию нового пароля отправлены на',
        resetPasswordSentEnd: 'Пожалуйста, следуйте инструкциям в электронном письме для создания нового пароля.',
        emailConfirmedStart: 'Ваш адрес электронной почты',
        emailConfirmedEnd: 'подтверждён.',
        passwordChanged: 'Пароль вашей учётной записи успешно изменён.',
        passwordChangeTitle: 'Изменить пароль',
        registered: {
            welcome :'Вы зарегистрированы.',
            welcomeTextStart :'На адрес',
            welcomeTextEnd :', мы выслали письмо с инструкциями и ссылкой для подтверждения электронной почты. Обратите внимание, что не подтвердив адрес почты, вы не сможете, например, восстановить пароль.',
            startWork :'Приступить к работе',
            startWorkStart :'Вы',
            startWorkURL :'Приступить к работе с сайтом',
            // startWorkEnd :'под своим именем.',
        },
        error :{
            register: 'Не корректная регистрационная информация',
            login: 'Не правильный пароль или имя пользователя',
            userName: 'Укажите имя пользователя',
            password: 'Укажите пароль',
            currentPassword: 'Не правильный текущий пароль',
            // changePasswordOld: 'Укажите текущий пароль',
            // changePasswordNew: 'Укажите новый пароль',
            emailAlreadyExists: 'email используется другим пользователем',
            loginAlreadyExists: 'Имя используется другим пользователем',
            confirmEmailTokenNotPresent: 'В запросе нет токена подтверждения адреса',
            confirmEmailTokenNotFound: 'Не найден токен подтверждения адреса. Возможно вы уже подтведили свой адресс электронной почты, или ваша регистрация устарела.',
            confirmEmailError: 'Ошибка подтверждения',
            resetPasswordError: 'Не указан адрес электронной почты',
            resetPasswordTokenNotPresent: 'В запросе нет токена для изменения пароля',
            resetPasswordNotPresent: 'Не указан новый пароль',
            resetPasswordTokenNotFound: 'Не найден активный токен изменения пароля',
            authRequired: 'Требуется авторизация',
        }

    },
    paginator: {
        onPage : 'На странице',
        itemsOnPage: 'Предметов на странице',
        allItems: 'Все предметы'
    },
    toolbar: {
        sorting: 'Сортировать'
    },
    import: {
        error: {
            wrongFileFormat: "Ошибка формата файла. ",
            column: "В колонке ",
            expect: "ожидаем поле",
            inFile: ", получили",
        }
    },
    itemView: {
        provenance: "Провенанс",
        goToCollection: "К списку предметов коллекции",
        deleteItem: "Удалить предмет",
        include: "Входит в состав",
        childItems: "Состав предмета",
        fromComplect: "Из комплекта",
        sourceDescription: "Откуда",
        sourceInfo: "Описание",
        droppedInfo: "Причина выбытия",
        sourcePrice: "Цена",
        sourceDate: "Когда",
        emptyGallary: "Изображения не загружены",
        addChild: "Добавить вложенный предмет",
        addEmptyChild: "Добавить пустой",
    },
    itemEdit: {
        title: 'Предмет коллекции',
        toFind: {
            item: 'Предмет',
            toFind: 'Ищу в коллекцию'
        },
        itemNumber: "Номер",
        itemSubNumber: "Субномер",
        description: "Наименование",
        subDescription: "Дополнительно",
        noURL: "Ссылки не указаны",
        addURL: "Добавить ссылку",
        urlTitle: "Ссылки",
        itemText: "Описание",
        provenance: "Провенанс",
        complect: "Комплект",
        source: "Откуда",
        details: "Номер лота, комментарии",
        price: "Цена",
        currency: "Валюта",
        date: "Когда",
        info: "Описание",
        storage: "Место хранения",
        catalog1: "Каталог - уровень 1",
        catalog2: "Каталог - уровень 2",
        catalog3: "Каталог - уровень 3",
        addField: "Добавить поле",
        fieldListEmpty: "Нет данных",
        noImages: "Изображения не загружены",
        uploadImage: "Загрузить изображение",
        onCover: "На обложке",
        oneImage: "Одна картинка на обложке",
        twoImages: "Две картинки на обложке",
        changeAccess: "Публичный доступ к предмету", 
        qtyTitle: "Количество",
        qty: "Сейчас",
        qtyUsed: "Было",
        recomendTitle: 'Рекомендация',
        yearFrom: 'Год с',
        yearTo: 'Год по',
        itemDropped: "Предмет выбыл из коллекции",
        droppedInfo: "Причины выбытия",
        copyFromLabel: "Выберите предмет для копирования полей", 
        copyFromNoOptions: "Ничего не найдено",
        copyFromOpen: "Копировать поля из другого предмета",
        copySourceFromOpen: "Копировать поля провенанса из другого предмета",
        itemToFind: "Ищу",
        error: {
            description: "Укажите наименование",
            catalog: "Укажите раздел каталога",
            itemNumberExists: "Номер предмета дублируется",
            itemSubNumberExists: "Субномер предмета дублируется",
            itemNumberEmpty: "Укажите номер предмета",
            parentItemNotFound: "Не найден предмет верхнего уровня",
            notFound: "Не найдена коллекция",
            forbidden: "Доступ запрещен"
        }
    },
    collections: {
        title: 'Список коллекций',
        titleMy: 'Мои коллекции',
        titleOther: 'Коллекции',
        addButton: 'Создать коллекцию',
        addItem: 'Добавить предмет',
        access: {
            na: 'Нет доступа',
            fullAccess: 'Изменение',
            fullView: 'Полный просмотр',
            view: 'Просмотр',
        },
        menu: {
            items: "Предметы",
            complect: "Комплекты",
            access: "Доступ пользователей",
            settings: "Настройки коллекции",
            exportImport: "Экспорт-импорт",
        },
        props:{
            name: "Наименование",
            catalogName: "Название каталога",
            catalogLevels: "Количество уровней каталога",
            details: "Описание",
            publicAccess: "Публичный доступ к коллекции",
            publicAccessClose: "Закрыт",
            publicAccessOpen: "Отмеченные предметы видны всем",
            type: "Тип",
            imageSmall: "Формат «узкого» изображения",
            imageLarge: "Формат «широкого» изображения",
            noPhotoFilter: "Использовать фильтр для предметов без изображений",
            userName: "Пользователь - владелец коллекции",
            useChildItems: "Коллекция содержит вложенные предметы",
            backgroundColor: "Цвет фона"
        },
    
        users: {
            th: {
                user: 'Пользователь',
                access: 'Доступ',
                delete: 'Удалить'
            },
            addUser: 'Добавить пользователя',
            userListEmpty: 'Доступ не предоставлен другим пользователям',
            error: {
                notFound: 'Пользователь не найден',
                alreadyExists: 'Пользователь уже добавлен'
            }
        },
        settings: {
            cover: 'Обложка коллекции',
            props: 'Основные',
            catalog: 'Каталог',
            complect: 'Комплекты',
            access: 'Доступ к коллекции',
            fields: 'Поля',
            view: 'Просмотр',
            filters: 'Фильтры',
            sort: 'Сортировка',
            form: 'Редактирование',
            list: 'Список предметов'
        },
        fields: {
            addField: 'Добавить поле',
            name: 'Имя',
            type: 'Тип',
            sortList: 'Сортировать список',
            sortAuto: 'Автоматически',
            sortManualy: 'Вручную',
            valuesList: 'Список значений',
            selectToEdit: 'Выберите поле для редактирования',
            fieldsListEmpty: 'Поля коллекции не заданы', 
            fieldsListValuesEmpty: 'Значения не заданы', 
            fieldsListAdd: 'Добавить значение', 
            delete: 'Удалить поле',
            types: {
                text: 'Текст',
                autocomplete: 'Текст с выбором из введенных значений',
                dropDownText: 'Текст с выбором из списка',
                dropDown: 'Выбор из списка',
                values: 'Несколько текстовых значений',
                chips: 'Мультивыбор из списка',
                chipsAndValue: 'Мультивыбор из списка и ввод текста',
            }
        },
        fieldList:{
            catalog: 'Каталог',
            number: 'Номер',
            description: 'Наименование',
            subDescription: 'Дополнительно',
            itemToFind: 'Статус «Ищу»',
            itemDropped: 'Статус «Выбыл»',
            coinWeigth: 'Номинал монеты'
        },
        view: {
            title: 'Просмотр',
            fieldsListValuesEmpty: 'Значения не заданы',
            fieldName: 'Поле',
            listName: 'Значение из списка',
            addField: 'Добавить поле',
            toFind: 'ПОИСК',
            Dropped: 'ВЫБЫЛ',
            framed: 'Показывать рамку',
            prefix: 'Префикс',
            suffix: 'Суффикс',
            types: {
                catalog: 'Каталог', 
                title: 'Заголовок',
                label: 'Описание',
                grid: 'Таблица',
                picture: 'Метка',
            },
            fields: {
                catalog: 'Каталог',
                number: 'Номер',
                description: 'Наименование',
                subDescription: 'Дополнительно',
                itemToFind: 'Статус «Ищу»',
                itemDropped: 'Статус «Выбыл»',
                coinWeigth: 'Номинал монеты',
                recomend: 'Рекомендация',
                qty: 'Количество'
            }
        },
        filters: {
            fieldsListEmpty: 'Значения не заданы',
            selectToEdit: 'Выберите фильтр для редактирования',
            fieldName: 'Поле',
            listName: 'Значение из списка',
            delete: 'Удалить фильтр',
            addField: 'Добавить фильтр',
            titleNone: 'Пункт "не указано"',
            titleOther: 'Пункт "другое"',
            manualFilterItems: "Задать значения фильтра вручную",
            filterLabel: "Фильтр",
            valueLabel: "Значения",
            valueHelperText: "Каждое значение с новой строки"
        },
        sort: {
            name: 'Наименование сортировки',
            addSort: 'Добавить сортировку',
            sortListEmpty: 'Настройки сортировки не заданы',
            selectToEdit: 'Выберите сортировку для редактирования',
            fieldList: 'Список полей',
            fieldsListValuesEmpty: 'Поля не заданы',
            fieldsListAdd: 'Добавить поле',
            delete: 'Удалить сортировку',
            useReverce: 'Добавлять обратную сортировку'
        },
        catalog: {
            sorting : 'Сортировать каталог',
            sortAuto: 'Автоматически',
            sortManualy: 'Вручную',
            catalogTitle: 'В каталоге еще нет записей',
            catalogEmpty: 'При вводе или импорте предметов, записи в каталог будут добавляться автоматически',
        },
        form: {
            formListEmpty: 'Настройки формы редактирования не заданы',
            caption: "Заголовок",
            addMenu: 'Добавить',
            addField: 'Поле',
            addGroup: 'Группу полей',
            addLabel: 'Заголовок',
            label: 'Заголовок',
            group:'Группа',
            field:'Поле',
            selectToEdit: 'Выберите строку для редактирования',
            fieldName: 'Поле',
            delete: 'Удалить',
            fieldList: 'Список полей',
            fieldLength: 'Размер',
            th: {
                name: 'Поле',
                type: 'Тип'                
            }
        },
        list: {
            addField: 'Добавить поле',
            types: {
                listGroup: "Группировка",
                listDescription: "Наименование"
            }
        },
        complect: {
            selectToEdit: 'Выберите строку для редактирования',
            complectListEmpty: 'Список комплектов пуст',
            addComplect: 'Добавить комплект',
            name: 'Наименование',
            description: 'Откуда',
            details: 'Номер лота, комментарии',
            date: 'Когда',
            currency: 'Валюта',
            price: 'Цена',
            info: 'Описание',
            delete: 'Удалить комплект'


        }
    },
    
    home: {
        menu: 'Главная страница',
    },        

    form: {
        error: {
            required: 'Обязательное поле',
            firstName: 'Укажите имя',
            lastName: 'Укажите фамилию',
            email: 'Проверьте адрес электронной почты',
            phone: 'Укажите корректный номер телефона',
            userName: 'Не корректное имя пользователя',
            password: 'Используйте большие и маленькие буквы, цифры и специмволы',
        }
    },
    admin: {
        title: "Администрирование",
        menu: {
            createCollection: 'Создать коллекцию',
            users:'Пользователи',
            collections: 'Коллекции',
            messages: 'Сообщения',
            export: 'Экспорт данных'
        },
        collections: {
            title: 'Список коллекций',
            menu: {
                title: 'Действия',
                clear: 'Очистить',
                delete: 'Удалить коллекцию'
            },
        },
        users: {
            title: "Пользователи",
            disabled: "заблокирован",
            menu: {
                disable: "Заблокировать",
                enable: "Активировать",
                delete: "Удалить",
            },
        },
        feedback: {
            menu: {
                read: "Прочтено",
                unread: "Не прочтено",
                delete: "Удалить",
            },
        },
        error: {
            adminRequired: "Нет прав на создание коллекции",
            userNameNotFound: "Пользователь не найден",
            collectionNotFound: "Коллекция не найдена",
            name: "Имя коллекции не указано"
        }
    }
    

}

export default ru