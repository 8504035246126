import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';
import EditField from '../../../../ui/editors/editField'
import FormControl from '@mui/material/FormControl';
import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CloseIcon from '@mui/icons-material/Close'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as I from '../../../../store/interfaces'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import EditFieldList from './EditFieldList';
import { LineLoader } from '../../../../modules/line-loader';
import { Box } from '@mui/material';
import { bottomToolbar, gridStyle, topToolbar } from '../../../../style/styles';
import { IOSSwitch } from '../../../../modules/ios-switch';
import EditFrame from '../editFrame';

export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState( p.filter )
    const [toEditListIndex, setToEditListIndex] = useState( -1 )

    useEffect( () => {
        setToEdit(p.filter) 
    }, [p.filter])

    let listIndex = toEdit ? p.collectionFields.findIndex((list: any) => list.value === toEdit.fieldName) : -1
    let list = listIndex >= 0 ? p.collectionFields[listIndex].list : []

    function onChangeField(fieldName: string, newValue: any) {
        if (newValue === toEdit[fieldName]) return
        setToEdit((prev: any) => ({...prev, [fieldName]: newValue}))
        p.setEdited()
    }
    const addListItem = (event: any) => {
        setToEditListIndex(toEdit.valuesMap.length)
        p.setEdited()
        setToEdit({...toEdit, valuesMap: [...toEdit.valuesMap, {filter: '', value: ''}]}) 
    }
    const onEditListItem = (index: number) => {
        setToEditListIndex(index)
    }
    const onCancelEditListItem = () => {
        setToEditListIndex(-1)
        if (toEdit.valuesMap.filter((value: any) => value.filter).length > 0){
            setToEdit({
                ...toEdit, 
                valuesMap: toEdit.valuesMap.filter((value: any) => value.filter)
            }) 
        }
    }
    const onSaveListItem = (value: any) => {
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                valuesMap: [
                    ...toEdit.valuesMap.slice(0, toEditListIndex), 
                    value, 
                    ...toEdit.valuesMap.slice(toEditListIndex + 1)
                ]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                valuesMap: toEdit.valuesMap.filter((value: any) => value.filter)
            }) 
        }
        // setToEditListIndex(-1)
    }
    const onOutsideClick = (toSave: any) => {
        setToEditListIndex(-1)
    }

    const onDeleteListItem = (delIndex: number) => {
        p.setEdited()
        setToEditListIndex(-1)
        setToEdit({
            ...toEdit, 
            valuesMap: toEdit.valuesMap.filter((value: any, index: number) => index !== delIndex)
        }) 
    }
    const onMoveItem = (index: number, up: boolean) => {
        p.setEdited()

        if (up){
            setToEdit({
                ...toEdit, 
                valuesMap: [
                    ...toEdit.valuesMap.slice(0, index), 
                    toEdit.valuesMap[index + 1], 
                    toEdit.valuesMap[index], 
                    ...toEdit.valuesMap.slice(index + 2)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                valuesMap: [
                    ...toEdit.valuesMap.slice(0, index - 1), 
                    toEdit.valuesMap[index], 
                    toEdit.valuesMap[index - 1], 
                    ...toEdit.valuesMap.slice(index + 1)]
            }) 
        }
        setToEditListIndex(up ? index + 1 : index - 1)

    }
    const onCancelClick = () => {
        p.onCancelClick()
        setToEdit(p.filter) 
        setToEditListIndex(-1)
    }

    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'sticky', top: '5rem'}}>
            <Box sx={topToolbar}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit }
                >
                    {locale('common.cancel')}
                </Button>
                <Button 
                    id="save" 
                    size="medium"
                    variant={p.loading || !p.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={event => p.onSaveClick(toEdit)}
                    startIcon={<DoneIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit ||!toEdit.fieldName}
                >
                    {locale('common.save')}
                </Button>
            </Box>
            <LineLoader active={p.loading && p.edited} />
            {!toEdit
            ? null
            : <Box sx={{backgroundColor: 'action.hover', padding: 2}}>
                <div>
                    <Box sx={gridStyle}>
                        <FormControl size="small" variant='outlined' sx={{gridColumnStart: "span 6" }}>
                            <InputLabel id="label-name">{locale('collections.filters.fieldName')}</InputLabel>
                            <Select            
                                labelId="label-name"
                                id="field-select"
                                value={toEdit.fieldName}            
                                label={locale('collections.filters.fieldName')}
                                onChange={event => onChangeField('fieldName', event.target.value)}
                            >
                            {p.collectionFields.map((field: any, index: number) => (
                                <MenuItem key={index} value={field.value}>{field.description}</MenuItem>
                            ))}

                            </Select>
                        </FormControl>
                    
                        <FormControl size="small" variant='outlined' sx={{gridColumnStart: "span 6" }}>
                            <InputLabel id="label-list-name">{locale('collections.filters.listName')}</InputLabel>
                            <Select
                                labelId="label-list-name"
                                id="field-list-select"
                                disabled={listIndex < 0 || I.FIELD_VALUES !== p.collectionFields[listIndex].type}
                                value={toEdit.fieldListName}
                                label={locale('collections.filters.listName')}
                                onChange={event => onChangeField('fieldListName', event.target.value)}
                                >
                            <MenuItem value='-'>
                                <em>{locale('common.all')}</em>
                            </MenuItem>
                            {list.map((value: string, index: number) => (
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                
                    </Box>

                    <Box sx={gridStyle}>
                        <EditField
                            key="titleNone"
                            // className="cm-edit-width-6"
                            span={6}
                            freeSolo={true}
                            value={toEdit.titleNone}
                            fieldName="titleNone"
                            onChangeField={onChangeField}
                            list={['Не указано', 'Пусто', 'Значение не задано']}
                            label={locale('collections.filters.titleNone')}
                        />
                        <EditField
                            key="titleOther"
                            // className="cm-edit-width-6"
                            span={6}
                            freeSolo={true}
                            value={toEdit.titleOther}
                            fieldName="titleOther"
                            disabled={listIndex < 0 || I.FIELD_DROPDOWNTEXT !== p.collectionFields[listIndex].type}
                            onChangeField={onChangeField}
                            list={['Другое', 'Прочее']}
                            label={locale('collections.filters.titleOther')}
                        />
                    </Box>
                {listIndex >= 0 && 
                [I.FIELD_TEXT, I.FIELD_AUTOCOMPLETE, I.FIELD_DROPDOWNTEXT, I.FIELD_DROPDOWN]
                .includes(p.collectionFields[listIndex].type)
                ?
                <FormControlLabel
                    sx={{padding: '1rem', gap: '.5rem'}}
                    control={
                        <IOSSwitch
                            disabled={p.loading}
                            checked={toEdit.useValuesMap}
                            value={!!toEdit.useValuesMap}
                            onChange={event => onChangeField('useValuesMap', !toEdit.useValuesMap)}
                            size="small"
                            name="framed"
                            color="primary"
                        />  
                    } 
                    labelPlacement="end"
                    label={locale('collections.filters.manualFilterItems')}
                />
                : null
                }
                {toEdit.useValuesMap
                ?<div>
                    <FormControl component="fieldset" margin="dense" disabled={p.loading} >
                    <FormLabel component="legend">{locale('collections.fields.sortList')}</FormLabel>
                    <RadioGroup 
                        row
                        value={!!toEdit.listSorting}
                        onChange={event => onChangeField('listSorting', event.target.value==="true")}
                    >
                    <FormControlLabel 
                        color="primary" 
                        value={true}
                        control={<Radio color="primary"/>} 
                        label={locale('collections.fields.sortAuto')}
                    />
                    <FormControlLabel 
                        color="primary" 
                        value={false}
                        control={<Radio color="primary"/>} 
                        label={locale('collections.fields.sortManualy')}
                    />
                    </RadioGroup>
                    </FormControl>
                    <h5>{locale('collections.fields.valuesList')}</h5>
                    <EditFieldList
                        list={toEdit.valuesMap}
                        sorting={toEdit.listSorting}
                        toEditListIndex={toEditListIndex}
                        addListItem={addListItem}
                        onEditListItem={onEditListItem}
                        onCancelEditListItem={onCancelEditListItem}
                        onOutsideClick={onOutsideClick}
                        onSaveListItem={onSaveListItem}
                        onDeleteListItem={onDeleteListItem}
                        onMoveItem={onMoveItem}
                    />

                </div>
                : null
                }

            </div>
            <Box sx={bottomToolbar}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => p.onDeleteClick()}
                    disabled={p.loading}
                >
                    {locale('collections.filters.delete')}
                </Button>
            </Box>

            </Box>
            }
    </Box> 
    </Box> 
    )
}