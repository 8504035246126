import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './store/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import './style/fonts/museo/MuseoSansLight.woff'
import './style/fonts/museo/MuseoSansBold.woff'
import './style/fonts/museo/MuseoSansRegular.woff'


export const BACKENDROOT = '/api'
export const STORAGE_AUTH: string = 'auth'

export const store = createStore(rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)

const root = ReactDOM.createRoot(document.getElementById("root") as Element | DocumentFragment);

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider> 
);
  
// const app = (

//     <Provider store={store}>
//         <React.StrictMode>
//             <App />
//         </React.StrictMode>
//     </Provider> 
// )

// ReactDOM.render(app, document.getElementById('root'));

