import React, { useRef } from 'react'
import { locale } from '../../../../ui/locale'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import EditFieldListItem from './EditFieldListItem';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

// import {CatalogOnDrawName} from './CatalogOnDrawName'


export default function EditFieldList(p: any) {

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, p.onCancelEditListItem)

    const viewList = p.list
    .map((item: string, index: number) => ({value: item, index: index}))
    .sort((a: any, b: any) => {
        if (!p.sorting) return 0
        if (a.value === '') return 1
        if (b.value === '') return -1
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1
        } 
        return 1
    })
    
    return(
        <div>
        {viewList.length === 0
        ? <Box sx={{padding: 2}}>
            {locale('collections.fields.fieldsListValuesEmpty')}
        </Box>
        : <TableContainer>
            <Table>
            <TableBody>

            {viewList
            .map((item: any) => (
            <TableRow 
                hover={p.toEditListIndex !== item.index}
                key={item.index} 
                ref={p.toEditListIndex === item.index ? wrapperRef : null}
            >
            {p.toEditListIndex === item.index
            ? <TableCell sx={{padding: 1}} >
                <EditFieldListItem 
                    value={item.value}
                    onCancelEditListItem={p.onCancelEditListItem}
                    onSaveListItem={p.onSaveListItem}
                />
            </TableCell>
            :<TableCell sx={{cursor: 'pointer'}} onClick={event => p.onEditListItem(item.index)} >            
                {item.value}
            </TableCell>
            }            
            <TableCell width='70px' sx={{padding: 1}}>
                {p.toEditListIndex === item.index && !p.sorting
                ?<>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={item.index === 0}
                        onClick={event => p.onMoveItem(item.index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={item.index === p.list.length - 1}
                        onClick={event => p.onMoveItem(item.index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </>
                : null
                }
            </TableCell>
            

            <TableCell width='30px' sx={{padding: 1}}>
                {p.toEditListIndex === item.index
                ?
                <IconButton 
                    size="small" 
                    // disabled={formState.loading}
                    color="secondary"
                    onClick={event => p.onDeleteListItem(item.index)}
                >
                    <DeleteOutlineIcon />
                </IconButton>
                : null
                }
            </TableCell> 
            
            </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        }
        <Button 
            id="addListItem" 
            size="medium"
            startIcon={<AddIcon />}
            color="primary"
            onClick={p.addListItem}
            disabled={p.toEditListIndex >= 0}
        >
            {locale('collections.fields.fieldsListAdd')}
        </Button>
        </div>
    )
}