import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function EditDropDown(p: any) {

    return(
        <FormControl 
            fullWidth={p.className ? false : true}  
            // fullWidth
            sx={{gridColumnStart: p.span ? 'span ' + p.span : undefined}}
            className={p.className} 
            variant="outlined" 
            size="small" 
            margin="normal" 
        >
        <InputLabel id="label">{p.label}</InputLabel>
            <Select
                id={p.fieldName}
                labelId="label"
                value={p.value}
                // onChange={p.onChange}
                onChange={(event, newValue) => {p.onChangeField(p.fieldName, event.target.value || '') }}
                label={p.label}
                disabled={p.disabled}        
            >
                <MenuItem value="">
                    <em>Не указано</em>
                </MenuItem>
                {p.options
                ? p.options.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.value}>{item.description}</MenuItem>
                ))
                : p.list.map((item: string, index: number) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))
                }
            </Select>
        </FormControl>

    )
}