import React, { useState }  from 'react'
import {useSelector} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import * as http from '../../../modules/http'
import Button from '@mui/material/Button'
import fileDownload from 'js-file-download'
import GetAppIcon from '@mui/icons-material/GetApp'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { LineLoader } from '../../../modules/line-loader'
import { useNavigate } from 'react-router-dom'

export default function Export(p: any) {
    const auth = useSelector( (state: any) => state.auth )
    const navigate = useNavigate();
    const [pageState, setPageState] = useState({loading: false, error: null});

    if (auth.auth) {
        if (!auth.roles.includes('admin')) {
            navigate('/')
        }
    }

    const onExportDataClick = async () => {
        setPageState({...pageState, loading: true})
        const res = await http.httpGetBlob('/admin/exportdata', {}, true)
        if (res.success) {
            const date = (new Date()).toISOString().slice(0, 10)
            fileDownload(res.data, 'collection_backup_' + date + '.zip')
        }
        setPageState({...pageState, loading: false})

    }

    const onExportImageClick = async () => {
        setPageState({...pageState, loading: true})
        const res = await http.httpGetBlob('/admin/exportimages', {}, true)
        if (res.success) {
            const date = (new Date()).toISOString().slice(0, 10)
            fileDownload(res.data, 'collection_images_' + date + '.zip')
        }
        setPageState({...pageState, loading: false})

    }

    return (
        !auth.auth || !auth.roles.includes('admin')
        ? <Loader />
        : <>
        <div className="cm-content">
       
        
        <div>
        <LineLoader active={pageState.loading} />
        </div>
        <div className="cm-single-form">
            <form>
            <span style={{width: '20rem'}}>
                <Button 
                    id="back" 
                    className="btn secondary"
                    fullWidth
                    variant="outlined"
                    startIcon={<GetAppIcon />}
                    disabled={pageState.loading}
                    onClick={event => onExportDataClick()}
                >
                    Скачать данные
                </Button>
            </span>
            <span style={{width: '20rem'}}>
                <Button 
                    id="back" 
                    className="btn secondary"
                    fullWidth
                    variant="outlined"
                    startIcon={<PhotoCameraIcon />}
                    disabled={pageState.loading}
                    onClick={event => onExportImageClick()}
                >
                    Скачать изображения
                </Button>
            </span>
            </form>
        </div>
        </div>

        </>
    )
}
