import React, { useState, useEffect } from 'react'
import { locale } from '../../ui/locale'
import * as http from '../../modules/http'
import { Loader } from '../../modules/Loader';
import { Box } from '@mui/material';
import { FormError } from '../../modules/form-error';
import * as authActions from '../../store/auth/authActions'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Confirm(p: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = new URLSearchParams(p.location.search).get('token')

    
    useEffect(() => {if (!token) navigate('/')}, [token, navigate])

    const [pageState, setPageState] = useState({loading: false, loaded: false, email: null, userName: null, error: null})

    useEffect(  () => {
        if (!pageState.loading && !pageState.loaded && token) {
            setPageState(prev => ({...prev, loading: true}))
            http.httpPost('/auth/confirm', {token: token}, false)
            .then(res  => {
                if (res.success) {
                    setPageState(prev => ({...prev, loading: false, loaded: true, email: res.data.email, userName: res.data.userName}))
                    dispatch(authActions.changeEMailVerified())
                } else {
                    setPageState(prev => ({...prev, loading: false, loaded: true, error: res.data.message || 'auth.error.confirmEmailError'}))
                }

            })
            .catch((err: any)  => {
                setPageState(prev => ({...prev, loading: false, loaded: true, error: err.message || 'auth.error.confirmEmailError'}))
            })
    
        }
    }, [pageState.loading, pageState.loaded, token, dispatch])

    return (
        <Box sx={{
            padding: 6, 
            paddingTop: 6, 
            maxWidth:  '25rem', 
            marginLeft: 'auto', 
            marginRight: 'auto'
        }}>
        {!pageState.loaded
        ?<Loader />
        :<Box>
            {pageState.error
            ?<FormError message={pageState.error} />
            :<>
            <h4>{pageState.userName},</h4>
            <p>
                {locale('auth.emailConfirmedStart')}
                <strong> {pageState.email} </strong>
                {locale('auth.emailConfirmedEnd')}
            </p>
            </>
            }
        </Box>
        }
    </Box>
    )
}