import React, { useState } from 'react'
import EditSingleText from '../../../../ui/editors/editSingleText';
import { locale } from '../../../../ui/locale';
import * as http from '../../../../modules/http'
import { useSelector} from 'react-redux'

export default function AddUser (p: any) {
    const auth = useSelector((state: any) => state.auth )

    const [pageState, setPageState] = useState({
        loading: false, 
        error: false,
        errorMessage: ''
    })

    const onSaveClick = async (userName: string) => {
        if (!userName) {
            setPageState({...pageState, error: true, errorMessage: 'collections.users.error.notFound'})
            return
        }
        if (p.list.findIndex((user: any) => user.userName === userName) >= 0 || auth.userName === userName) {
            setPageState({...pageState, error: true, errorMessage: 'collections.users.error.alreadyExists'})
            return
        }
        
        const res = await http.httpGet('/auth/userExists', {userName: userName}, false)
        if (res.success) {
            p.onAddUser(userName)
        } else {
            setPageState({...pageState, error: true, errorMessage: 'collections.users.error.notFound'})
        }

    }

    return (
        <EditSingleText
            onSaveClick={onSaveClick}
            onCancelClick={p.onAddUserCancel}
            fullWidth={true}
            value=''
            error={pageState.error}
            label='Добавить пользователя'
            helperText={locale(pageState.errorMessage)}
            useOutsideClick={true}
        />
    )
}