import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function EditFieldListItem (p: any) {
    const [toEdit, setToEdit] = useState(p.value)


    const onChange = (event: any) => {
        setToEdit(event.target.value)
    }
    const handleClickSave = (event: any) => {
        p.onSaveListItem(toEdit)
    }
    const onKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleClickSave(event)
        }
        if (event.key === 'Escape') {
            p.onCancelEditListItem()
        }
    }
    return (
        <div>
            <FormControl fullWidth>
            <OutlinedInput
                value={toEdit}
                onChange={onChange}
                autoFocus
                size="small"
                margin='none'
                onKeyDown={onKeyDown}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        size="small"
                        onClick={handleClickSave}
                    >
                        <DoneIcon />
                    </IconButton>
                </InputAdornment>
                }
            />
            </FormControl>
        </div>
    )
}