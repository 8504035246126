import { locale } from '../../ui/locale'


export default function AuthForgotSuccess(p: any) {

    return (
        <div>
            <h4>{locale('auth.resetPasswordSent')}</h4>
                <p>
                {locale('auth.resetPasswordSentStart')}
                &nbsp;<strong>{p.email}.</strong>
                </p>
                <p>
                {locale('auth.resetPasswordSentEnd')}
                </p>
        </div>
    )
}
