import * as I from '../../interfaces'
import { locale } from '../../../ui/locale'

// export function filterDescription(data: I.Collection) {
export function filterDescription(data: I.Collection, valuesMap: I.ValuesMap[], listSorting: boolean, titleOther: string) {

    const index = data.filterGroups.length
    const items = data.preparedItems
    data.filterGroups.push({
        description: locale('filters.description.title'),
        isOpen: false,
        hidden: false,
        items: [],        
    })


    let result: I.FilterItem[] = []



    if (valuesMap.length > 0) {

        if (listSorting) {
            valuesMap.sort((a, b) => {
                if (a.filter.toLowerCase() < b.filter.toLowerCase()) {
                    return -1
                } 
                return 1
            })
        }
    
        result = result.concat(valuesMap.map((el, index: number) => ({       
            description: el.filter,
            isChecked: false,
            itemsList: items
                .filter(item => {
                    if (item.description === el.filter || el.value.split('\n').includes(item.description)) {
                        return true
                    }
                    return false
                })
                .map(item => (item.CollectionItemId))
        })))

        let arrValues: string[] = []
        result.forEach(res => { arrValues = arrValues.concat(res.itemsList) })
    
        result.push({description: titleOther, isChecked: false, itemsList: 
            items
            .filter(item => arrValues.indexOf(item.CollectionItemId) < 0)
            .map(item => (item.CollectionItemId))
        })
    
    } else {

        const f: any[] = Array.from(new Set(
            [...items.map((item) => item.description)]
          ))
        f.sort()
        result = f.map((el: string, index: number) => ({       
            description: el,
            isChecked: false,
            itemsList: items
                .filter(item => item.description === el)
                .map(item => (item.CollectionItemId))
        }))
    
    }

    data.filterGroups[index].items = result

}