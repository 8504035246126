import React, { useState, useEffect, useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as authActions from '../../store/auth/authActions'
import DoneIcon from '@mui/icons-material/Done'
import { locale } from '../../ui/locale'
import MailIcon from '@mui/icons-material/Mail'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import * as http from '../../modules/http'
import useOutsideClick from '../../hooks/useOutsideClick'
import { Box } from '@mui/material'
import { RootState } from '../../store/rootReducer'


export default function Email(p: any) {
    const dispatch = useDispatch();
    const auth = useSelector( (state: RootState) => state.auth )

    const [email, setEmail] = useState( {email: auth.email || '', loading: false, edit: false, error: null, emailSent: false  })

    const onCancelEditEmailClick = () => {
        setEmail({...email, email: auth.email || '', edit: false, loading: false, error: null})
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, onCancelEditEmailClick)

    const onKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            saveEmail()
        }
        if (event.key === 'Escape') {
            onCancelEditEmailClick()
        }
    }

    useEffect( () => {
        setEmail(prev => ({...prev, email: auth.email || ''}))
    }, [auth.email] ) 


    const saveEmail = async () => {
        // console.log(email.email, auth.email)
        if (email.email === auth.email) {
            return setEmail((prev) => ({...prev, loading: false, edit: false, error: null}))    
        }
        setEmail((prev) => ({...prev, loading: true}))
        const res = await http.httpPost('/auth/changeemail', {email: email.email}, true)
        if (res.success) {
            setEmail((prev) => ({...prev, loading: false, edit: false, error: null, emailSent: true}))
            dispatch(authActions.changeEMail(email.email))
        } else {
            setEmail((prev) => ({...prev, loading: false, error: res.data}))
        }
    }

    const sendConfirmClick = async (event: any) => {
        setEmail((prev) => ({...prev, loading: true}))
        const res = await http.httpPost('/auth/sendconfirm', {}, true)
        if (res.success) {
            setEmail((prev) => ({...prev, loading: false, edit: false, error: null, emailSent: true}))
        } else {
            setEmail((prev) => ({...prev, loading: false, error: res.data}))
        }
    }

    const editEmailClick = () => {
        if (email.edit) {
            saveEmail()
        } else {
            setEmail({...email, edit: true})
        }
        
    }

    return(
    <Box sx={{width: '100%', maxWidth: '25rem', minWidth: '15rem'}} >
        <h4>
            {locale('settings.emailTitle')}
        </h4>
        <p>Изменить адрес электронной почты</p>
        <TextField
            sx={{marginTop: 4}}
            ref={email.edit ? wrapperRef : null}
            disabled={!email.edit || email.loading}
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            // disabled={disabled={email.loading}}
            inputRef={input => input && input.focus()}      
            name="email"
            value={email.email || ''}
            // style={{width: '30rem'}}
            onChange={event => setEmail({...email, email: event.target.value})}
            onKeyDown={onKeyDown}
            helperText={
                email.edit || auth.emailVerified
                ? null
                : email.error || locale('settings.emailNotVerified')
            }
            FormHelperTextProps={{error: true}}
            InputProps={
                { endAdornment: (
                            <IconButton
                                disabled={email.loading}
                                size="small"
                                onClick={() => editEmailClick()}
                            >
                                {email.edit ? <DoneIcon /> : <EditIcon />}
                            </IconButton>
                )}
            }

        />  
        <div>&nbsp;</div>
        <div>
            {!auth.emailVerified && !email.emailSent && !email.edit
            ? <Button 
                size="medium"
                variant="outlined"
                disabled={email.loading}
                startIcon={<MailIcon />}
                color="primary"
                onKeyDown={onKeyDown}
                onClick={sendConfirmClick}
            >
                {locale('settings.sendVerificationEmail')}
            </Button>
            : email.emailSent ? locale('settings.verificationEmailSent') + ' ' + email.email : null
            }
            
        </div>
        {/* </form>  */}
    </Box>

    )
}