import { useMemo } from 'react';
import { BACKENDROOT } from '../../index';
import ImageGallery from 'react-image-gallery';
import { Box } from '@mui/material';
import { locale } from '../../ui/locale';
import ItemGallaryView from './ItemGallaryView';

export default function ItemGallary(p: any) {

    const {images, index} = useMemo(() => {
        let array = p.viewItem.images || []

        p.viewItem.items.forEach((subItem: any) => {
            array = array.concat(subItem.images)
        })

        let index = array.findIndex((imageId: number) => imageId === p.startImageId)
        index = index < 0 ? 0 : index
    
        const images = array.map((imageId: number, index: number) => (
            {
                original: BACKENDROOT + '/image/gallary/' + imageId + '/1' + (p.backgroundColor ? '/' + p.backgroundColor : ''),
                fullscreen: BACKENDROOT + '/image/full/' + imageId,
                thumbnail: BACKENDROOT + '/image/small/' + imageId + '/1' + (p.backgroundColor ? '/' + p.backgroundColor : '')
            }
        ))
        return {images, index}
    }, [p.viewItem.images, p.viewItem.items, p.startImageId, p.backgroundColor]);

    return <ItemGallaryView 
        images={images} 
        index={index} 
        backgroundColor={p.backgroundColor}
        mobileMode={p.mobileMode}
    />

    return(    

        <Box sx={{
            marginLeft: p.mobileMode ? '-12px' : undefined,
            marginRight: p.mobileMode ? '-12px' : undefined,
        }}> 
        {
        images.length > 0
        ? 
        <ImageGallery 
            items={images} 
            showPlayButton={false}
            showNav={false}
            startIndex={index}
            // slideOnThumbnailOver={true}
            useBrowserFullscreen={false}
            showThumbnails={p.mobileMode ? false : true}
            showBullets={p.mobileMode ? true : false}
            // thumbnailPosition={'left'}
            thumbnailPosition={p.mobileMode ? 'top' : 'left'}
            showFullscreenButton={!p.mobileMode}
            // additionalClass="root-gallery-white"
            additionalClass={"root-gallery-" + p.backgroundColor}
        />
        : <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px dashed',
            borderColor: 'divider',
            minHeight: '5rem',
            height: '100%',
            // textAlign: 'center',
            color: 'action.active',
            // padding: 4
        }}>
            {locale('itemView.emptyGallary')}
        </Box>
        }            
        </Box>
    )
}
