import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'

import { Box, AppBar, Toolbar } from '@mui/material';
import { locale } from '../../ui/locale'
import { FormError } from '../../modules/form-error';
import AuthLogin from './authLogin';
import AuthRegister from './authRegister';
import AuthForgot from './authForgot';


export default function Auth(p: any) {

    const [mode, setMode] = useState('login' as 'login' | 'register' | 'forgot');
    const [error, setError] = useState('');


    const registerClick = () => {
        setMode('register');
    }
    const forgotClick = () => {
        setMode('forgot');
    }
    const loginClick = () => {
        setMode('login');
    }


    return (
        <Box sx={{maxWidth: '25rem'}}>
            <AppBar position='sticky' sx={{
                color: 'text.primary',
                backgroundColor: 'background.default',
                boxShadow: `
                            0px 2px 4px -1px rgb(0 0 0 / 12%), 
                            0px 1px 64px 0px rgb(0 0 0 / 12%)`,
            }}>
                <Toolbar> 
                    <IconButton 
                        color="inherit"
                        size="medium"
                        onClick={p.close}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{
                padding: 6, 
                paddingTop: 6, 
                // width:  '20rem', 
                marginLeft: 'auto', 
                marginRight: 'auto'
            }}>
                {/* <form noValidate onSubmit={(event) => formClick(event)}> */}
                    <Box sx={{
                        paddingBottom: 2, 
                        display: "flex", 
                        alignItems: "center", 
                        gap: 1,
                        fontFamily: 'MuseoSansBold',
                        fontSize: '1.1rem',
                        lineHeight: '2.5rem',
    
                    }}>
                        <img alt="" src="/images/cm-icon.svg" />                        
                        {mode === 'login'
                        ? locale('auth.signInTitle')
                        : mode === 'register'
                        ? locale('auth.registerTitle')
                        : locale('auth.forgotPasswordTitle')
                        } 
                    </Box>
                    <FormError message={error} />                     
                    {mode === 'login'
                    ? 
                    <AuthLogin
                        forgotClick={forgotClick}
                        registerClick={registerClick}
                        setError={setError}
                    />
                    : mode === 'register'
                    ?
                    <AuthRegister
                        forgotClick={forgotClick}
                        loginClick={loginClick}
                        setError={setError}
                    />
                    : <AuthForgot
                        loginClick={loginClick}
                        setError={setError}
                    />
                    }
            {/* </form> */}
          </Box>
        </Box>
    )
}
