import { TableRow } from '@mui/material'
import React from 'react'

export const SettingsTableRow = (p: any) => {
    return (
        <TableRow 
            hover
            onClick={p.onClick}
            sx={{cursor: p.selected ? undefined : 'pointer'}}
        >
            {p.children}
        </TableRow>
    )
}