import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import * as authActions from  '../../store/auth/authActions'

import { Checkbox, FormControlLabel, Box, Button } from '@mui/material';
import { locale } from '../../ui/locale'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';


export default function AuthLogin(p: any) {
    const {setError} = p;
    const dispatch = useDispatch()

    const auth = useSelector( (state: RootState) => state.auth )

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);

    useEffect(()=>{
        setError(auth.error)
    }, [auth.error, setError]);

    const formClick = async (event: any) => {
        event.preventDefault()
        dispatch<any>(authActions.login({
            userName, 
            password, 
            rememberMe
        }))
    }   

    return (
        <form noValidate onSubmit={(event) => formClick(event)}>
            <TextField
                disabled={auth.loading}
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                id="username"
                label={locale('auth.userNameOrPassword')}
                name="userName"
                value={userName}
                onChange={event => setUserName(event.target.value)}
                autoComplete="username"
                autoFocus
            />
            <TextField
                disabled={auth.loading}
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                name="password"
                label={locale('auth.password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                autoComplete="current-password"
                InputProps={
                    { endAdornment: (
                            <InputAdornment position="end" style={{cursor:"pointer"}}>
                                <IconButton
                                    size="small"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                        </InputAdornment>
                        ) }
                }
            />          
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <FormControlLabel
                    control={
                    <Checkbox
                        id="rememberMe"
                        name="rememberMe"
                        checked={rememberMe}
                        onChange={event => setRememberMe(!rememberMe)}
                        color="primary"
                    />
                    }
                    label={locale('auth.rememberMe')}
                />

                <Link
                    sx={{ marginBottom: 1 }}
                    onClick={p.forgotClick}
                    variant='body1'
                    component="button"
                >
                    {locale('auth.forgotPassword')}
                </Link>
            </Box>

            <Button
                sx={{marginTop: 4}}
                type="submit"
                // sx={{width: '10rem'}}
                fullWidth
                disabled={auth.loading || password==='' || userName === ''}
                variant="contained"
                color="primary"
                onClick={(event) => formClick(event)}
            >
                {locale('auth.signIn')}
            </Button>
            <Box sx={{marginTop: 4, textAlign: 'center'}}>
                <Link
                    sx={{marginTop: 2}}
                    onClick={p.registerClick}
                    variant='body1'
                    component="button"
                >
                    {locale('auth.createAnAccount')}
                </Link>
            </Box>
        </form>
    )
}
