
export const filterBox = {
    backgroundColor: 'rgba(0,0,0,.04)', 
    padding: '0.5rem 1rem'
}

export const gridStyle = {
    position: 'relative',
    margin: 0,
    minHeight: '4rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(12,1fr)',
    gap: '0.5rem',
    gridGap: '0.5rem',
    alignContent: 'center',
    alignItems: 'center'   
}

export const twoPanelsStyle = {
    gridTemplateColumns: {xs: 'auto', sm: '1fr 1fr'},
    display: 'grid',
    gap: '2rem',
    gridGap: '2rem',
    // marginTop: '1.5rem',
    marginTop: 0,
    marginBottom: '2rem',
    position: 'relative' 
}

export const viewPanelStyle = {
    gridTemplateColumns: {xs: 'auto', sm: '1fr 1fr'},
    display: 'grid',
    gap: '2rem',
    gridGap: '2rem',
    marginTop: '1.5rem',
    marginBottom: '2rem',
    position: 'relative' 
}

export const topToolbar = {
    display: 'flex', 
    justifyContent: 'space-between', 
    marginBottom: 2
}

export const bottomToolbar = {
    display: 'flex', 
    justifyContent: 'center', 
    paddingTop: '2rem'
}