import React from 'react'
import {useDispatch} from 'react-redux'
import { Routes, Route } from "react-router-dom";
import * as appActions from '../store/app/appActions'
import { NotFound} from './NotFound'
import { List } from './list/List'
import useEvent from '../hooks/useEvent';
import { MOBILEMODE } from '../store/app/appReducer'
import Confirm from './auth/confirm'
import Reset from './auth/reset'
import Collection from './collection/Collection'
import Create from './admin/create/Create'
import Feedback from './feedback/Feedback'
import Settings from './settings/Settings'
import Admin from './admin/Admin'
import { MarkDown } from './markdown/MarkDown';
import { VinoList } from './vino/VinoList';

const MainRoutes:React.FC = (props: any) =>  {
    const dispatch = useDispatch();
    
    const toggleResize = () => {
        window.innerWidth > MOBILEMODE
        ? dispatch(appActions.mobileModeOff())
        : dispatch(appActions.mobileModeOn()) 
    };    

    useEvent('resize', toggleResize)

    return (
        <>
            {/* <BrowserRouter> */}
            <Routes>
                <Route element={ <List /> } path = '/' />
                <Route element={ <Confirm /> } path = '/confirm' />
                <Route element={ <Reset /> } path = '/reset' />

                <Route element={ <Collection /> } path = '/collection/*' />
                {/* <Route element={ <Collection /> } path = '/collection/:string/view/:string' /> */}
                <Route element={ <Create /> } path = '/create' />
                <Route element={ <Feedback /> } path = '/feedback' />
                <Route element={ <Admin /> } path = '/admin' />
                <Route element={ <Settings /> } path = '/settings' />
                <Route element={ <MarkDown /> } path = '/markdown' />
                
                <Route element={ <VinoList  />} path = '/vino' />

                <Route element={ <NotFound /> } path = '/404' />
                <Route element={ <NotFound  />} path = '/' />

            </Routes>
            {/* </BrowserRouter> */}


            {/* <Switch>

                <Route component={ List } path = '/' exact />
                <Route component={ Confirm } path = '/confirm' exact />
                <Route component={ Reset } path = '/reset' exact />

                <Route component={ Collection } path = '/collection/:string' />
                <Route component={ Create } path = '/create' />
                <Route component={ Feedback } path = '/feedback' />
                <Route component={ Admin } path = '/admin' />
                <Route component={ Settings } path = '/settings' />

                <Route component={ NotFound } path = '/404' />
                <Route component={ NotFound } path = '/' />
            </Switch> */}
        </>
    )
}

export default MainRoutes