import * as I from '../../interfaces'


export function textItems(items: I.CollectionItem[], fieldName: string, titleNone: string): I.FilterItem[] {
    const f: any[] = Array.from(new Set(
        [...items.map((item) => {
            const idx = item.fields.findIndex(f => f.name === fieldName)
            if (idx >=0 ) return item.fields[idx].value
            return ''
        })]
      ))
    f.sort()
    return f.map((el: string, index: number) => ({       
        description: !el ? titleNone : el,
        isChecked: false,
        itemsList: items
            .filter(item => {
                const idx = item.fields.findIndex(f => f.name === fieldName)
                if (idx >= 0 && item.fields[idx].value === el) return true
                return !el && idx < 0 ? true : false
            })
            .map(item => (item.CollectionItemId))
    }))
}