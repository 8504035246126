import * as I from '../interfaces'
import { valuesItems } from './filterWorkers/valuesItems'
import { chipsItems } from './filterWorkers/chipsItems'
import { listItems } from './filterWorkers/listItems'
import { textItems } from './filterWorkers/textItems'
import { filterField } from './filterWorkers/filterField'
import { filterAccess } from './filterWorkers/filterAccess'
import { customItems } from './filterWorkers/customItems'
import { filterQty } from './filterWorkers/filterQty'
import { filterRecomend } from './filterWorkers/filterRecomend'
import { filterDescription } from './filterWorkers/filterDescription'

export function setFiltersProps (prevFilters: I.FilterGroup[], newFilters: I.FilterGroup[]) {
    newFilters.forEach( filterGroup => {
        const prevIndex = prevFilters.findIndex( prevFilterGroup => filterGroup.description === prevFilterGroup.description)
        if (prevIndex >=0) {
            filterGroup.isOpen = prevFilters[prevIndex].isOpen
            filterGroup.items.forEach(filter => {
                const prevItemIndex = prevFilters[prevIndex].items.findIndex(prevFilter => prevFilter.description === filter.description)
                if (prevItemIndex >=0) {
                    filter.isChecked = prevFilters[prevIndex].items[prevItemIndex].isChecked
                }
            })
        } 
    })
}

export function onChangeFilter(filters: I.FilterGroup[], groupIndex: number, itemIndex: number): string[] {

    let arrResult: string[] = []
    let first = true
    filters.forEach((filter, groupIdx) => {
        let arr: string[] = []
        if (groupIdx === groupIndex) {
            filter.isOpen = itemIndex < 0 ? !filter.isOpen : filter.isOpen
        }
        filter.items.forEach((item, itemIdx) => {
            if (groupIdx === groupIndex && itemIndex === itemIdx) {
                item.isChecked = !item.isChecked
            }
            if (filter.isOpen && item.isChecked) {
                arr = Array.from(new Set(arr.concat(item.itemsList)))
            }
        }) 
        if (arr.length > 0) {
            if (first) {
                arrResult = arr
                first = false
            } else {
                arrResult = Array.from(new Set(arrResult)).filter(item => arr.includes(item))
            }
        }
    })
    return arrResult.length === 0 && !first ? ['-'] : arrResult
}


export function createFilters(data: I.Collection) {

    const prevFilterGroups: I.FilterGroup[] | null = data.filterGroups

    data.filterGroups = []

    data.filters.forEach(filter => {
        const index = data.filterGroups.length
        if (filter.fieldName === '_qty'){
            filterQty(data)
        }
        if (filter.fieldName === '_recomend'){
            filterRecomend(data)
        }
        if (filter.fieldName === '_description'){
            filterDescription(
                data, 
                filter.valuesMap, 
                filter.listSorting, 
                filter.titleOther
            )
        }
        const fieldIndex = data.fields.findIndex(field => field.name === filter.fieldName) 
        if (fieldIndex >= 0 ) {

            data.filterGroups.push({
                description: filter.fieldName,
                isOpen: false,
                hidden: false,
                items: [],        
            })
            
            if (filter.useValuesMap && 
                [I.FIELD_TEXT, I.FIELD_AUTOCOMPLETE, I.FIELD_DROPDOWNTEXT, I.FIELD_DROPDOWN]
                .includes(data.fields[fieldIndex].type))
            {
                data.filterGroups[index].items = customItems(
                    data.preparedItems, 
                    // data.items, 
                    filter.valuesMap,
                    filter.listSorting, 
                    filter.fieldName, 
                    filter.titleNone, 
                    filter.titleOther
                )
            } else if ([I.FIELD_TEXT, I.FIELD_AUTOCOMPLETE].includes(data.fields[fieldIndex].type)) {
                data.filterGroups[index].items = textItems(
                    data.preparedItems, 
                    // data.items, 
                    filter.fieldName, 
                    filter.titleNone
                )
            } else if ([I.FIELD_DROPDOWNTEXT, I.FIELD_DROPDOWN].includes(data.fields[fieldIndex].type)) {
                data.filterGroups[index].items = listItems(
                    data.preparedItems,
                    // data.items, 
                    data.fields[fieldIndex].list,
                    data.fields[fieldIndex].listSorting,
                    filter.fieldName,
                    filter.titleNone,
                    filter.titleOther
                )
            } else if ([I.FIELD_CHIPS, I.FIELD_CHIPSANDVALUE].includes(data.fields[fieldIndex].type)){
                data.filterGroups[index].items = chipsItems(
                    data.preparedItems,
                    // data.items, 
                    data.fields[fieldIndex].list,
                    data.fields[fieldIndex].listSorting,
                    filter.fieldName,
                    filter.titleNone,
                    filter.titleOther
                )
            } else if (data.fields[fieldIndex].type === I.FIELD_VALUES){
                data.filterGroups[index].items = valuesItems(
                    data.preparedItems,
                    // data.items, 
                    data.fields[fieldIndex].list,
                    // data.fields[fieldIndex].listSorting,
                    filter.fieldName,
                    filter.fieldListName,
                    filter.titleNone
                )
            }

        }

    })
    //source
    if (['owner', 'fullAccess'].includes(data.role)) {
        filterField(data, 'source.complect')
        filterField(data, 'source.description')
        filterField(data, 'source.storage')
    }
    if (data.role === 'owner' && data.shared) {
        filterAccess(data)
    }
    data.filterGroups.forEach(group => {
        group.hidden = (group.items.length < 2)
    })
    if (prevFilterGroups) {
        setFiltersProps(prevFilterGroups, data.filterGroups)
    }
}
