import { Box } from '@mui/material'
import React from 'react'
import * as I from '../../../store/interfaces'
import EditFormField from './EditFormField'
import { gridStyle } from '../../../style/styles'

// export default function EditFields(p: any) {
function EditFields(p: any) {

    return(
        <>
        {
        p.form//.filter((formItem: any) => formItem.field === 'Год')
        .map((formItem: I.FormSet, index: number) => {
            if (formItem.isLabel){
                // return (<h5 key={index}>{formItem.caption}</h5>)
                return (<h5 key={index}>{formItem.caption}</h5>)
            }
            if (formItem.isGroup){
                return (
                <Box key={index} sx={gridStyle}>
                    
                    {formItem.fields.map((groupItem, idx) => {
                        const fieldIndex = p.collection.fields.findIndex((field: any) => field.name === groupItem.name)
                        const toEditIndex = p.toEditFields.findIndex((field: any) => field.name === groupItem.name)
                        return(
                        <EditFormField
                            key={idx}
                            width={groupItem.length}
                            // formItem={groupItem}
                            field={p.collection.fields[fieldIndex]}
                            toEdit={toEditIndex>=0 ? p.toEditFields[toEditIndex] : null}
                            loading={p.loading}
                            onChangeField={p.onChangeField}
                            valuesMap={p.valuesMap}
                        />
                    )})
                    }
                </Box>
                )
            }
            const fieldIndex = p.collection.fields.findIndex((field: any) => field.name === formItem.field)
            const toEditIndex = p.toEditFields.findIndex((field: any) => field.name === formItem.field)
            return (
                <EditFormField
                    key={index}
                    // formItem={formItem}
                    field={p.collection.fields[fieldIndex]}
                    toEdit={toEditIndex>=0 ? p.toEditFields[toEditIndex] : null}
                    loading={p.loading}
                    onChangeField={p.onChangeField}
                    valuesMap={p.valuesMap}
                    onChangeValuesField={p.onChangeValuesField}
                    onDeleteValuesField={p.onDeleteValuesField}
                />
            )
        })
        }
        </>
    )
}

export default React.memo(EditFields)