import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';
import EditField from '../../../../ui/editors/editField'
import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditFieldList from './EditFieldList';
import CloseIcon from '@mui/icons-material/Close'
import FormControlLabel from '@mui/material/FormControlLabel';
import { LineLoader } from '../../../../modules/line-loader';
import { Box } from '@mui/material';
import EditFrame from '../editFrame';
import { bottomToolbar, topToolbar } from '../../../../style/styles';
import { IOSSwitch } from '../../../../modules/ios-switch';


export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState( p.sort )
    const [toEditListIndex, setToEditListIndex] = useState( -1 )

    useEffect( () => {
        setToEdit(p.sort) 
    }, [p.sort])

    function onChangeField(fieldName: string, newValue: any) {
        if (newValue === toEdit[fieldName]) return
        setToEdit((prev: any) => ({...prev, [fieldName]: newValue}))
        p.setEdited()
    }

    const addListItem = (event: any) => {
        setToEditListIndex(toEdit.fields.length)
        p.setEdited()
        setToEdit({...toEdit, fields: [...toEdit.fields, {name: '', desc: false}]}) 
    }

    const onEditListItem = (index: number) => {
        setToEditListIndex(index)
    }

    const onCancelEditListItem = () => {
        setToEditListIndex(-1)
        if (toEdit.fields.filter((value: any) => value.name).length > 0){
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: any) => value.name)
            }) 
        }
    }

    const onChangeListItem = (value: string) => {
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, toEditListIndex), 
                    {name: value, desc: toEdit.fields[toEditListIndex].desc},
                    ...toEdit.fields.slice(toEditListIndex + 1)
                ]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: any) => value.name)
            }) 
        }
        // setToEditListIndex(-1)
    }

    const onSaveListItem = (value: string, desc: boolean) => {
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, toEditListIndex), 
                    {name: value, desc: desc},
                    ...toEdit.fields.slice(toEditListIndex + 1)
                ]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: any) => value.name)
            }) 
        }
        // setToEditListIndex(-1)
    }

    const onDeleteListItem = (delIndex: number) => {
        p.setEdited()
        setToEditListIndex(-1)
        setToEdit({
            ...toEdit, 
            fields: toEdit.fields.filter((value: any, index: number) => index !== delIndex)
        }) 
    }
    const onChangeSortType = (index: number) => {
        p.setEdited()
        setToEdit({
            ...toEdit, 
            fields: [
                ...toEdit.fields.slice(0, index), 
                {name: toEdit.fields[index].name, desc: !toEdit.fields[index].desc},
                ...toEdit.fields.slice(index + 1)]
        }) 
    }

    const onMoveItem = (index: number, up: boolean) => {
        p.setEdited()

        if (up){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, index), 
                    toEdit.fields[index + 1], 
                    toEdit.fields[index], 
                    ...toEdit.fields.slice(index + 2)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, index - 1), 
                    toEdit.fields[index], 
                    toEdit.fields[index - 1], 
                    ...toEdit.fields.slice(index + 1)]
            }) 
        }
        setToEditListIndex(up ? index + 1 : index - 1)

    } 

    const onCancelClick = () => {
        p.onCancelClick()
        setToEdit(p.sort) 
        setToEditListIndex(-1)
    }

    const onSaveClick = (toSave: any) => {
        p.onSaveClick(toSave)
        setToEditListIndex(-1)
    }

    const onOutsideClick = (toSave: any) => {
        setToEditListIndex(-1)
    }

    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'sticky', top: '5rem'}}>
            <Box sx={topToolbar}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit }
                >
                    {locale('common.cancel')}
                </Button>
                <Button 
                    id="save" 
                    size="medium"
                    variant={p.loading || !p.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={event => onSaveClick(toEdit)}
                    startIcon={<DoneIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit ||!toEdit.setName }
                >
                    {locale('common.save')}
                </Button>
        </Box>
        <LineLoader active={p.loading && p.edited} />
            {!toEdit
            ? null
            : <Box sx={{backgroundColor: 'action.hover', padding: 2}}>
                <div>
                    <EditField
                        key="setName"
                        className="cm-edit-width-12"
                        freeSolo={true}
                        value={toEdit.setName}
                        fieldName="setName"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.sort.name')}
                    />
                </div>
                <div>
                    <FormControlLabel
                        sx={{padding: '0 1rem', gap: '.5rem'}}
                        control={
                            <IOSSwitch
                                disabled={p.loading}
                                checked={toEdit.addReverce}
                                value={!!toEdit.addReverce}
                                onChange={event => onChangeField('addReverce', !toEdit.addReverce)}
                                size="small"
                                name="rev"
                                color="primary"
                            />  
                        } 
                        labelPlacement="end"                        
                        label={locale('collections.sort.useReverce')}
                    />
                </div>
                <div>
                    <h5>{locale('collections.sort.fieldList')}</h5>
                    <EditFieldList
                        list={toEdit.fields}
                        collectionFields={p.collectionFields}
                        toEditListIndex={toEditListIndex}
                        addListItem={addListItem}

                        onEditListItem={onEditListItem}
                        onCancelEditListItem={onCancelEditListItem}
                        onSaveListItem={onSaveListItem}
                        onChangeListItem={onChangeListItem}
                        onDeleteListItem={onDeleteListItem}
                        onMoveItem={onMoveItem}
                        onChangeSortType={onChangeSortType}
                        onOutsideClick={onOutsideClick}
                    />

                </div>
            <Box sx={bottomToolbar}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => p.onDeleteClick()}
                    disabled={p.loading}
                >
                    {locale('collections.sort.delete')}
                </Button>
            </Box>

            </Box>
            }
        </Box> 
        </Box> 
    )
}