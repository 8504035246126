import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as appActions from '../../../store/app/appActions'
import * as collectionActions from '../../../store/collection/collectionActions'

import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Switches from './Switches';
import Catalog from './Catalog';
import FilterGroup from './FilterGroup';
import { locale } from '../../../ui/locale';
import { RootState } from '../../../store/rootReducer';
import useDebounce from '../../../hooks/useDebounce';



export default function Filter(p: any){
    const dispatch = useDispatch();
    const cll  = useSelector( (state: RootState) => state.collection.collection )
    const [searchString, setSearchString] = useState(cll.viewProps.filterString || '');
    const defferSearchString = useDebounce(searchString, 600);

    useEffect( () => {
        dispatch(appActions.drawerPageOn())
    }) 

    useEffect (() => {
        dispatch(collectionActions.changeFlterString(defferSearchString))
    }, [defferSearchString, dispatch])

    const catalogClick = () => {
        dispatch(collectionActions.changeViewProps({catalogOpen: !cll.viewProps.catalogOpen}))
        if (!cll.viewProps.catalogOpen) {
            dispatch(collectionActions.catalogOpen(''))
        }
    }

    return (
        <div className="filter-frame">
            <div className="filter-search-box" >
                    <InputBase
                        className="form-control"
                        placeholder={locale('common.search')}
                        fullWidth
                        // onChange={event =>  dispatch(collectionActions.changeFlterString(event.target.value))}
                        onChange={event =>  setSearchString(event.target.value)}
                        value={searchString}
                        endAdornment={<SearchIcon color='action' />}
                    />
            </div>
            <Switches cll={cll} />

            <div className={"filter-list" + (cll.viewProps.filterString ? " disabled" : "")}>
                <List disablePadding >
                    <ListItem 
                        button 
                        onClick={() =>  catalogClick()}
                    >
                        <ListItemText  primary={cll.catalogName} />
                        <IconButton edge="end" size="small">
                            {cll.viewProps.catalogOpen  ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItem>   
                    <Collapse in={cll.viewProps.catalogOpen} timeout="auto" unmountOnExit>
                        <Catalog 
                            items={cll.catalog} 
                            level={0}                     
                        />
                    </Collapse>
                </List>
            </div>
            <FilterGroup 
                items={cll.filterGroups}  
                disabled={!!cll.viewProps.filterString}
                CollectionId={cll.CollectionId}
                showAccess={cll.role === 'owner' && cll.shared}
            />

        </div>
        
    )
}

