import React, { useState, useRef } from 'react'
import { locale } from '../../../../ui/locale'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Edit from './Edit'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'


export default function Items(p: any) {
    const [editIndex, setEditIndex] = useState(-1)

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, onOutsideClick)

    const list = p.catalog
    // .filter((item: any) => item)
    .map((item: any, index: number) => ({value: item, index: index}))
    .sort((a: any, b: any) => {
        if (!p.catalogAutoSort) return 0
        if (a.value.description === '') return 1
        if (b.value.description === '') return -1
        if (a.value.description.toLowerCase() < b.value.description.toLowerCase()) {
            return -1
        } 
        return 1
    })

    function onOutsideClick(){
        setEditIndex(-1)
    }
    const onSaveItem = (value: string) => {
        p.catalog[editIndex].description = value
        setEditIndex(-1)
        p.setEdited()
    }
    const onMoveField = (event: any, index: number, up: boolean) => {
        if (up){
            [p.catalog[index], p.catalog[index + 1]] = [p.catalog[index + 1], p.catalog[index]]
            setEditIndex(editIndex + 1)
        } else {
            [p.catalog[index-1], p.catalog[index]] = [p.catalog[index], p.catalog[index - 1]]
            setEditIndex(editIndex - 1)
        }           
        p.setEdited()     
    }
    const onDeleteItem = (index: number) => {
        p.catalog.splice(index, 1)
        setEditIndex(-1)
        p.setEdited()
   }
    return(
        <TableContainer>
        {/* <Table sx={{ minWidth: 350, marginTop: 2 }}> */}
        <Table >
            <TableBody>
            {list.length === 0
            ? <TableRow><TableCell>
                <h5>{locale('collections.catalog.catalogTitle')}</h5>
                {locale('collections.catalog.catalogEmpty')}
            </TableCell></TableRow>
            : null
            }   
            {list.map((item: any) => (
            <React.Fragment key={item.index}>
            {editIndex === item.index
            ?<TableRow key={item.index} ref={wrapperRef}>            
                <TableCell>
                    <Edit
                        value={item.value.description}
                        onSaveItem={onSaveItem}
                        onCancelClick={onOutsideClick}
                    />
                    {/* редактируем {field.description} */}
                </TableCell>
                {p.catalogAutoSort
                ?<TableCell width='100px' ></TableCell>
                :<TableCell width='100px' >
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={item.index === 0 || p.edited}
                        onClick={event => onMoveField(event, item.index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={item.index === list.length - 1 || p.edited}
                        onClick={event => onMoveField(event, item.index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </TableCell>
                }
                {!item.value.items || item.value.items.length === 0 //&& p.totalCount === 0 
                ?<TableCell width='50px'>
                    <IconButton 
                        size="small" 
                        // disabled={formState.loading}
                        color="secondary"
                        onClick={event => onDeleteItem(item.index)}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>                        
                </TableCell>
                :<TableCell width='50px'></TableCell>
                }
                
            </TableRow>
            :<TableRow hover key={item.index} onClick={event => setEditIndex(item.index)} sx={{cursor: 'pointer'}}>
                <TableCell sx={{padding: 1}}>
                    {item.value.description}
                </TableCell>
                <TableCell width='100px' sx={{padding: 1}}></TableCell>
                <TableCell width='50px' sx={{padding: 1}}></TableCell>
            </TableRow>
            }
            
            {item.value.items && item.value.items.length > 0
            ?<TableRow>
                <TableCell colSpan={3} sx={{borderBottom: '0px', padding: 1, paddingLeft: 4}}>
                    <Items 
                        catalog={item.value.items} 
                        catalogAutoSort={p.catalogAutoSort}
                        setEdited={p.setEdited}
                    />
                </TableCell>
            </TableRow>
            :null
            }
            </React.Fragment>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}