import React, { useState } from 'react'
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { locale } from '../../../../ui/locale';

export default function Edit (p: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        // setClickRow({CollectionId: CollectionId, userName: userName})
    }
    const handleClose = () => {
        setAnchorEl(null);
        // setClickRow({CollectionId: null, userName: null})
    }
    const onChangeAccess = (index: number, role: string) => {
        setAnchorEl(null)
        p.onChangeAccess(index, role)
    }
    return (
        <div>
            <Chip 
                variant="outlined" 
                label={locale('collections.access.' + p.role)} 
                onClick={event => handleMenu(event)} 
                disabled={p.loading}
                color={p.role==='na' 
                    ? "default"
                    : p.role==='fullAccess'
                    ? "secondary"
                    :"primary"
                } 
            />
                <Popover
                    id="menu-user"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={event => onChangeAccess(p.index, 'na')}>{locale('collections.access.na')}</MenuItem>
                    <MenuItem onClick={event => onChangeAccess(p.index, 'fullAccess')}>{locale('collections.access.fullAccess')}</MenuItem>
                    <MenuItem onClick={event => onChangeAccess(p.index, 'fullView')}>{locale('collections.access.fullView')}</MenuItem>
                    <MenuItem onClick={event => onChangeAccess(p.index, 'view')}>{locale('collections.access.view')}</MenuItem>
                </Popover> 
        </div>
    )
}