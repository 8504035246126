import React from 'react'
import { locale } from '../../../../ui/locale';
import EditMemo from '../../../../ui/editors/editMemo'
import { Stack } from '@mui/material';

export default function EditFieldListItem (p: any) {


    const onChangeValue = (fieldName: string, value: string) => {
        p.onSaveListItem({filter: p.filter, value: value})
    }
    const onChangeFilter = (fieldName: string, value: string) => {
        p.onSaveListItem({filter: value, value: p.value})
    }
    
    return (
        <Stack gap={2}>
            <EditMemo 
                label={locale('collections.filters.filterLabel')}
                name="filter"
                autoFocus
                margin="none"
                value={p.filter}
                onChangeField={onChangeFilter}
            />
            <EditMemo 
                label={locale('collections.filters.valueLabel')}
                name="value"
                margin="none"
                value={p.value}
                onChangeField={onChangeValue}
                helperText={locale('collections.filters.valueHelperText')}
            />            
        </Stack>
    )
}