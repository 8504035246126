import React, {useState} from 'react'
import { IconButton } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';

export const ViewVineURL: React.FC<any> = (p) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const onCellartracker = () => {
        const field1Index = p.item.fields.findIndex((field: any) => field.name === 'Производитель')
        const field2Index = p.item.fields.findIndex((field: any) => field.name === 'Год')
        // details4 производитель
        // details1 год
        const url = 'https://www.cellartracker.com/list.asp?fInStock=0&Table=List&iUserOverride=0&szSearch=' +
            (field1Index >= 0 ? p.item.fields[field1Index].value + ', ' : '') +
            p.item.description + ', ' +
            (field2Index >= 0 ? p.item.fields[field2Index].value + ', ' : '')
        window.open(url, '_blank')
        setAnchorEl(null);
    }
    const onWineSearcher = () => {
        const field1Index = p.item.fields.findIndex((field: any) => field.name === 'Производитель')
        const field2Index = p.item.fields.findIndex((field: any) => field.name === 'Год')

        const url = 'https://www.wine-searcher.com/find/' +
            (field1Index >= 0 ? p.item.fields[field1Index].value + ', ' : '') +
            p.item.description + ', ' +
            (field2Index >= 0 ? p.item.fields[field2Index].value + ', ' : '')
        window.open(url, '_blank')
        setAnchorEl(null);
    }
    return(
        <span>
            <IconButton color="primary"
                size="small"
                onClick={handleMenu}
            >
                <LinkIcon color="primary" /> 
            </IconButton>
            <Popover
                id="menu-user"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={onCellartracker}>cellartracker.com</MenuItem>
                <MenuItem onClick={onWineSearcher} >wine-searcher.com</MenuItem>
            </Popover>
        </span>
    )
}