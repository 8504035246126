import { Box } from '@mui/material'
import React from 'react'

export const NotFound:React.FC = () => {

    return (
        <Box sx={{
            minHeight: '20rem',
            textAlign: 'center',
            display: 'grid',
            alignItems: 'center',
            gridColumnStart: 'span 2'
        }}>
            <h2>404 Страница не найдена :(</h2>
        </Box>
    )
}