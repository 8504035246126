import { Box, CircularProgress, LinearProgress } from '@mui/material'
import React from 'react'

export const CircularLoader = (props: any) => {
    return (
        <Box sx={{
            display: 'flex' 
        }} >
            {props.active
            ?<CircularProgress />
            : null
            }
        </Box>
    )
}