import React from 'react'
import { locale } from '../../../../ui/locale'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import EditFieldListRow from './EditFieldListRow'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

export default function EditFieldList(p: any) {
    // const wrapperRef = useRef(null);
    // useOutsideClick(wrapperRef, p.onOutsideClick)

    return(
        <div>
        {p.list.length === 0
        ? <Box sx={{padding: 1, color: 'text.disabled'}}>
            {locale('collections.sort.fieldsListValuesEmpty')}
        </Box>
        : <TableContainer>
            <Table>
                <TableBody>
                {p.list
                .map((item: any, index: number) => (
                <React.Fragment key={index}>
                    {p.toEditListIndex === index
                    ? <EditFieldListRow
                        key={index}
                        item={item}
                        index={index}
                        onOutsideClick={p.onOutsideClick}
                        collectionFields={p.collectionFields}
                        onSaveListItem={p.onSaveListItem}
                        onCancelEditListItem={p.onCancelEditListItem}
                        upDisabled={index === 0}
                        downDisabled={index === p.list.length - 1}
                        onMoveItem={p.onMoveItem}
                        onDeleteListItem={p.onDeleteListItem}
                    />
                    : 
                    <TableRow 
                        hover={p.toEditListIndex !== index}
                        key={index} 
                        // ref={p.toEditListIndex === index ? wrapperRef : null}
                    >
                        <TableCell sx={{cursor: 'pointer', padding: 1, height: '45px'}} onClick={event => p.onEditListItem(index)} >            
                            {p.collectionFields.findIndex((el:any) => el.value === item.name) >= 0
                                ? p.collectionFields[p.collectionFields.findIndex((el:any) => el.value === item.name)].description
                                : locale('common.notSpecified')
                            }
                        </TableCell>
                        
                        <TableCell width='50px'>
                            {item.desc
                            ? <VerticalAlignTopIcon color="disabled" />
                            : <VerticalAlignBottomIcon color="disabled"/>
                            }
                        </TableCell>
                        

                    </TableRow>
                    }
                </React.Fragment>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
        }
        <Button 
            id="addListItem" 
            size="medium"
            startIcon={<AddIcon />}
            color="primary"
            onClick={p.addListItem}
            disabled={p.toEditListIndex >= 0}
        >
            {locale('collections.sort.fieldsListAdd')}
        </Button>
        </div>
    )
}

// p.toEditListIndex === index
                // ?<EditFieldListRow
                //     key={index}
                //     item={item}
                //     index={index}
                //     onOutsideClick={p.onOutsideClick}
                //     collectionFields={p.collectionFields}
                //     onSaveListItem={p.onSaveListItem}
                //     onCancelEditListItem={p.onCancelEditListItem}
                //     upDisabled={index === 0}
                //     downDisabled={index === p.list.length - 1}
                //     onMoveItem={p.onMoveItem}
                //     onDeleteListItem={p.onDeleteListItem}
                // />
                // :<tr 
                //     key={index} 
                // >
                // <td onClick={event => p.onEditListItem(index)} style={{cursor: 'pointer'}}>
                //     <span 
                //         // onClick={event => p.onEditListItem(index)} 
                //         // style={{cursor: 'pointer'}}
                //     >
                //         {p.collectionFields.findIndex((el:any) => el.value === item.name) >= 0
                //             ? p.collectionFields[p.collectionFields.findIndex((el:any) => el.value === item.name)].description
                //             : locale('common.notSpecified')
                //         }
                //     </span>                 
                // </td>
                // <td width='50px' valign="top">
                //     <IconButton 
                //         size="small" 
                //         // disabled={true}
                //         color={"primary"}
                //         // onClick={event => p.onSaveListItem(item.name, !item.desc)}
                //         onClick={event => p.onChangeSortType(index)}
                //     >
                //         {item.desc
                //         ? <VerticalAlignTopIcon />
                //         : <VerticalAlignBottomIcon />
                //         }
                //     </IconButton>
                // </td>
                // <td width='130px' valign="top" onClick={event => p.onEditListItem(index)} style={{cursor: 'pointer'}}>
                // </td>
                // </tr>
                // ))}