import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../modules/http'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import GetAppIcon from '@mui/icons-material/GetApp'
import PublishIcon from '@mui/icons-material/Publish'
import LinearProgress from '@mui/material/LinearProgress'
import * as collectionActions from '../../../store/collection/collectionActions'
import fileDownload from 'js-file-download'
import { locale } from '../../../ui/locale'
import { LineLoader } from '../../../modules/line-loader'
import { Box, Paper, Typography } from '@mui/material'
import { FormError } from '../../../modules/form-error'


interface errData {
    name: string,
    message: string
}
const initErrData: errData[] = []

export default function ExportImport(p: any) {
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        uploadPhoto: false, 
        uploadData: false,
        loading: false, 
        total: 0, 
        uploaded: 0,
        importError: '',
        exportError: {number: -1, name: '', message: '', nameInFile: ''}
    });
    const [errData, setErrData] = useState(initErrData)

    useEffect( () => {
        // console.log(formState.uploadData)
        if (formState.uploadPhoto) {
            if (formState.total === formState.uploaded) {
                if (errData.length === 0) {
                    window.location.reload()
                } else {
                    setFormState(prev => ({...prev, loading: false }))
                    dispatch(collectionActions.collectionChanged())
                }
           }
        }
    }, [formState.uploadPhoto, formState.uploaded, dispatch, errData.length, formState.total] )

    const onExportClick = async () => {
        setFormState(prev => ({...prev, loading: true}))
        const res = await http.httpGetBlob('/export/data', {CollectionId: p.collection.CollectionId}, true)
        setFormState(prev => ({...prev, loading: false}))
        if (res.success) {
            fileDownload(res.data, p.collection.name + '.xlsx')
        }
    }

    const onExportImageClick = async () => {
        setFormState({...formState, loading: true})
        const res = await http.httpGetBlob('/export/images', {CollectionId: p.collection.CollectionId}, true)
        if (res.success) {
            fileDownload(res.data, p.collection.name + '.zip')
        }
        setFormState({...formState, loading: false})
    }
    
    const onImportClick = async (e: any) => {

        setFormState({...formState, loading: true, uploadData: true, exportError: {number:-1, name:'',  message: '', nameInFile: ''}})
        var formData = new FormData();
        formData.append("excel", e.target.files[0]);
        formData.append("CollectionId", p.collection.CollectionId) 

        const res = await http.httpPost('/import/data', formData, true)

        if (res.data.success) {
            window.location.reload() 
        } else {
            if (res.data.number) {
                setFormState({...formState, uploadData: false, loading: false, exportError: res.data})
            } else {
                setFormState({...formState, loading: false, uploadData: false,
                    exportError: {number:-1, name:'',  message: res.data, nameInFile: ''}
                })
            }
        }

    }

    const onImportImgClick = async (e: any) => {
        setFormState({...formState, uploadPhoto: true, loading: true, total: e.target.files.length})

        for(var x = 0; x < e.target.files.length; x++) {

            var formData = new FormData()
            formData.append('image', e.target.files[x]);
            formData.append("CollectionId", p.collection.CollectionId) 
            const fileName = e.target.files[x].name

            const res = await http.httpPost('/import/images', formData, true)
            if (res.success) {
                setFormState(prev => ({...prev, uploaded: prev.uploaded + 1}))
            } else {
                const err = res.data ? res.data : ''
                setErrData(prev => ([...prev, {name: fileName, message: err}]))
                setFormState(prev => ({...prev, uploaded: prev.uploaded + 1 }))
            }
        }
    }

    return (
        formState.uploadPhoto
        ?
        <Box sx={{paddingTop: 4}}>
            <h4>Загрузка изображений</h4>
            <Box>
                <LinearProgress 
                    sx={{height: '0.5rem', borderRadius: '0.25rem', margin: ".5rem 0"}}
                    variant="determinate" 
                    value={(formState.uploaded / formState.total) * 100} 
                />
                <Box sx={{display: 'flex', justifyContent: 'space-between', color: 'action.active'}}>
                    <span>Загружено {formState.uploaded} из {formState.total}</span>
                    <span>
                        {Math.round((formState.uploaded / formState.total) * 100)} %
                    </span>
                </Box>
            </Box>            
            {errData.length > 0
            ? <Box sx={{paddingTop: 4}}>
                <h5>Ошибки загрузки</h5>
                &nbsp;
                {errData.map((err, index) => (
                    <p key={index}><strong>{err.name}</strong> {locale(err.message)}</p>
                ))
                }
            </Box>        
            : null
            }                    
        </Box>
        :formState.uploadData
        ? <Box sx={{paddingTop: 4}}>
            <div className="cm-import-photo">
            <h4>Загрузка данных</h4>
            <div className="upload-progress">
                <LinearProgress sx={{height: '0.5rem', borderRadius: '0.25rem'}} />
            </div>            
            </div>
        </Box>
        :<div>
            <LineLoader active={formState.loading} />
            <Box sx={{
                gridTemplateColumns: {sm: 'auto', md: '1fr 1fr'},
                display: 'grid',
                gap: '2rem',
                gridGap: '2rem',
                marginTop: '1.5rem',
                marginBottom: '2rem',
                position: 'relative'                 
            }}>

            
            <Paper variant='outlined' sx={{
                padding: '1rem 1rem 4.25rem',
                position: {sm: 'relative', md: undefined},
            }}> 
                <h4>Экспорт</h4>
                <Typography sx={{   
                    fontSize: '.875rem',
                    lineHeight: '1rem',
                    letterSpacing: '.02em'
                }}>
                    Экспорт данных коллекции
                </Typography>
                <Box sx={{
                    margin: '0.5rem',
                    padding: '1rem',
                    backgroundColor: 'rgba(0,0,0,.04)',
                    borderRadius: '0.25rem'
                }}>
                    Данные выгружаются в формате xlsx. <br />
                    Изображения выгружаются в оригинальном формате, имена файлов соответствуют инвентарному 
                    номеру предмета в коллекции + порядковый номер изображения. <br />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: '1rem',
                    display: 'flex',
                    gap: '0.5rem'
                }}>
                    <Button 
                        id="back" 
                        size="medium"
                        variant="outlined"
                        startIcon={<GetAppIcon />}
                        disabled={formState.loading}
                        onClick={event => onExportClick()}
                    >
                        Скачать данные
                    </Button>
                    <Button 
                        id="back" 
                        size="medium"
                        variant="outlined"
                        startIcon={<PhotoCameraIcon />}
                        disabled={formState.loading}
                        onClick={event => onExportImageClick()}
                    >
                        Скачать изображения
                    </Button>
                </Box>
            </Paper>
            <Paper variant='outlined' sx={{
                padding: '1rem 1rem 4.25rem', 
                position: {sm: 'relative', md: undefined},
            }}>
            
                <h4>Импорт</h4>
                <Typography sx={{   
                    fontSize: '.875rem',
                    lineHeight: '1rem',
                    letterSpacing: '.02em'
                }}>
                    Импорт предметов и изображений коллекции
                </Typography>
                <Box sx={{
                    margin: '0.5rem',
                    padding: '1rem',
                    backgroundColor: 'rgba(0,0,0,.04)',
                    borderRadius: '0.25rem'
                }}>
                    Загрузка данных в формате xlsx, набор колонок должен полностью 
                    соответствовать файлу экспорта.<br />
                    Файлы изображений должны иметь название num_sn<br />
                    где:<br />
                    num - инвентарный номер предмета<br />
                    sn - порядковый номер изображения<br />
                    Например: 001_01.jpg                
                </Box>
                {formState.exportError.number > 0 || formState.exportError.message
                ? <p className="form-error">
                    {formState.exportError.number && formState.exportError.name 
                    ? <FormError message={
                        `${locale('import.error.wrongFileFormat')} 
                         ${locale('import.error.column')} "${formState.exportError.number}" 
                         ${locale('import.error.expect')} "${formState.exportError.name}" 
                         ${locale('import.error.inFile')} "${formState.exportError.nameInFile}"
                        `}
                    />
                    : <FormError message={locale(formState.exportError.message)}/>
                    // ? <>
                    //     {locale('import.error.wrongFileFormat')}&nbsp;
                    //     {locale('import.error.column')}&nbsp;"{formState.exportError.number}"&nbsp; 
                    //     {locale('import.error.expect')}&nbsp;"{formState.exportError.name}"&nbsp; 
                    //     {locale('import.error.inFile')}&nbsp;"{formState.exportError.nameInFile}"
                    // </>
                    // : locale(formState.exportError.message)
                    }
                </p> 
                : null}
                <Box sx={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: '1rem',
                    display: 'flex',
                    gap: '0.5rem'
                }}>
                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{display: 'none'}} 
                    id="upload-xlsx"
                    multiple
                    type="file"
                    onChange={onImportClick}
                />
                <label htmlFor="upload-xlsx">
                <Button 
                    id="uploadxlsx" 
                    size="medium"
                    variant="outlined"
                    component="span"
                    disabled={formState.loading}
                    startIcon={<PublishIcon />}   
                >
                    Загрузить данные
                </Button>
                </label>
                <input
                    accept="image/*"
                    style={{display: 'none'}} 
                    id="upload-img"
                    multiple
                    type="file"
                    onChange={onImportImgClick}
                />
                <label htmlFor="upload-img">
                <Button 
                    id="uploadimg" 
                    size="medium"
                    variant="outlined"
                    component="span"
                    disabled={formState.loading}
                    startIcon={<AddAPhotoIcon />}   
                >
                    Загрузить изображения
                </Button>
                </label>
            </Box>
            </Paper>            
        </Box>
        </div>
    )

}