import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { Box, Button } from '@mui/material';
import { locale } from '../../ui/locale';
import * as form from '../../modules/form';
import * as http from '../../modules/http';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../store/auth/authReducer'
import AuthRegisterSuccess from './authRegisterSuccess';


const initialFormData: form.formField[] = [{
    fieldName: 'userName',
    value: '',
    toched: false,
    error: null,
    type: 'userName',
    requiredError: 'form.error.required',
    typeError: 'form.error.userName'
},{
    fieldName: 'email',
    value: '',
    toched: false,
    error: null,
    type: 'email',
    requiredError: 'form.error.required',
    typeError: 'form.error.email'
},{
    fieldName: 'password',
    value: '',
    toched: false,
    error: null,
    type: 'any',//'password',
    requiredError: 'form.error.required',
    typeError: 'form.error.password'
}
]

export default function AuthRegister(p: any) {
    const dispatch = useDispatch()

    const [formState, setFormState] = useState({loading: false, touched: false, error: null})
    const [formData, setFormData] = useState(initialFormData)
    const [showPassword, setShowPassword] = useState(false)
    const [formResult, setFormResult] = useState(null)

    function onChangeField(event: any) {
        form.onChangeField(event.target.name, event.target.value, formData, setFormData)
    }
    function helper(fieldName: string) {
        return form.textFieldHelper(fieldName, formState, formData)
    }    

    async function onRegisterClick(event: any) {
        event.preventDefault()
        setFormState((prev) =>  ({...prev, touched: true}))
        if (form.checkForm(formData)) {
            setFormState((prev) =>  ({...prev, loading: true, error: null}))

            const res = await http.httpPost('/auth/register', formData, false)
    
            if (res.success) {
                setFormState((prev) =>  ({...prev, loading: false, error: null}))
                setFormResult(res.data)
                
            } else {
                setFormState((prev) => ({...prev, loading: false, error: res.data.message}))
                form.setServerErrors(res.data.errors, formData, setFormData)
            }
        }
    }

    const onLoginClick = (event: any) => {
        dispatch( { type: LOGIN_SUCCESS, data: formResult } )
    }

    return (
        formResult
        ? <AuthRegisterSuccess formData={formData} onLoginClick={onLoginClick}/>
        : <form noValidate onSubmit={(event) => onRegisterClick(event)}>
            <Box sx={{marginBottom: 1.5}}>
                <TextField {...helper("userName")} onChange={onChangeField} autoFocus />   
                <TextField {...helper("email")} onChange={onChangeField} />   
                <TextField 
                    {...helper("password")} 
                    onChange={onChangeField}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={
                    { endAdornment: (
                            <InputAdornment position="end" style={{cursor:"pointer"}}>
                                <IconButton
                                    size="small"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                        </InputAdornment>
                        ) }
                    }
                />  
            </Box>
            <Button
                type="submit"
                className="btn primary"
                fullWidth
                disabled={formState.loading || formData[0].value === '' || formData[1].value === '' || formData[2].value === ''}
                variant="contained"
                color="primary"
                onClick={onRegisterClick}
                
            >
                {locale('auth.register')}
            </Button>
            
            <Box sx={{marginTop: 4, textAlign: 'center'}}>
                <Link
                    sx={{marginTop: 2}}
                    onClick={p.loginClick}
                    variant='body1'
                    component="button"
                >
                    {locale('auth.alreadyRegistered')}
                </Link>
            </Box>
        </form>
    )
}
