import React, {useEffect, useMemo, useState} from 'react'
import {useSelector } from 'react-redux'
import {useDispatch} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import Fields from './fields/Fields'
import Props from './props/Props'
import View from './view/View'
import Menu from './Menu'
import * as appActions from '../../../store/app/appActions'
import Filters from './filters/Filters'
import Sort from './sort/Sort'
import Catalog from './catalog/Catalog'
import Form from './form/Form'
import {MENU_PROPS, MENU_COMPLECT, MENU_CATALOG, MENU_ACCESS, MENU_FIELDS, MENU_VIEW, MENU_FILTERS,MENU_SORT, MENU_FORM, MENU_LIST} from './Menu'
import List from './list/List'
import { Box, Paper, useMediaQuery } from '@mui/material'
import { RootState } from '../../../store/rootReducer'
import Complect from './complect/Complect'
import Users from './users/Users'


//import Forms from './forms/forms'
// import { getCollectionToEdit } from '../../../loadData/fetchEditCollection'
// import { Loader } from '../../../components/Loader';
// import Catalog from './Catalog'
// import Users from './Users'
// import Complect from './Complect'
// import ExportImport from './ExportImport'
const listMenuStyle = {
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gap: '1rem',
    marginTop: '1.5rem',
    marginBottom: '2rem',
    position: 'relative'         
}
const floatMenuStyle = {
    display: 'flex',
    flexDirection: 'column',
    // gridTemplateColumns: 'min-content auto',
    gap: 0,
    marginTop: '1.5rem',
    marginBottom: '2rem',
    position: 'relative'         
}

export default function Settings(p: any) {
    const mobileMode  = useSelector( (state: RootState) => state.app.mobileMode )

    const listMode = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const [editTabIndex, setEditTabIndex] = useState( 0 )

    const menuStyle = useMemo(() => listMode ? listMenuStyle : floatMenuStyle, [listMode]);

    useEffect( () => {
        dispatch(appActions.drawerPageOn())
    }) 
    const onMenuClick = (index: number) => {
        setEditTabIndex(index)
        dispatch(appActions.drawerToggle())
    }

    return (
        !p.cll
        ?<Loader />
        :
        <Box sx={menuStyle}>
            <Menu 
                editTabIndex={editTabIndex}
                onMenuClick={onMenuClick}
                mobileMode={mobileMode}
            />
            <Box sx={{
                gridColumnStart: 2,
                // height: 'max-content'
            }}>
                <Paper variant='outlined' sx={{padding: 2, minHeight: 'calc(100% - 32px)'}}>
                {
                editTabIndex === MENU_PROPS   ? <Props collection={p.cll} />
                :editTabIndex === MENU_CATALOG ? <Catalog collection={p.cll} />
                :editTabIndex === MENU_COMPLECT ? <Complect collection={p.cll} />
                :editTabIndex === MENU_ACCESS ? <Users collection={p.cll} />
                :editTabIndex === MENU_FIELDS  ? <Fields collection={p.cll} />
                :editTabIndex === MENU_VIEW    ? <View collection={p.cll} />
                :editTabIndex === MENU_FILTERS ? <Filters collection={p.cll} />                
                :editTabIndex === MENU_SORT    ? <Sort collection={p.cll} />
                :editTabIndex === MENU_FORM    ? <Form collection={p.cll} />
                :editTabIndex === MENU_LIST    ? <List collection={p.cll} />
                :null
                }
                </Paper>
            </Box>
        </Box>

    )
}