import { Box } from '@mui/material';
import React, {useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import * as appActions from '../../store/app/appActions'
import BottomPanel from './BottomPanel';
import TopPanel from './TopPanel'
import { ViewCards } from './ViewCards';
import { ViewVineList } from './ViewVineList';

export const ItemsList = (p: any) => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(appActions.drawerPageOn())
    });

    const cardClass = useMemo(() => {
        if (p.mobileMode) return 'cards' 
        if (p.cll.viewProps.view === 0) return 'lines' 
        if (p.cll.viewProps.view === 1) return 'cards' 
        return 'scale'
    }, [p.mobileMode, p.cll.viewProps.view]);

return (
    <Box sx={{
        paddingTop: 2,
        width: '100%'
    }}>
        <TopPanel
            mobileMode={p.mobileMode}
            view={p.cll.viewProps.view}
            sorting={p.cll.viewProps.sorting}
            sortingReverce={p.cll.viewProps.sortingReverce}
            sortSet={p.cll.sort}
            paginatorItemsOnPage={p.cll.viewProps.itemsOnPage}
            startItem={p.cll.viewProps.startItem}

            itemsOnPage={p.itemsOnPage}
            totalItems={p.totalItems}
            hideViewType={p.cll.type === 'vine'}
            onFilterClick={p.onFilterClick}
        />

        {
        p.viewItems.length===0
        ?<Box sx={{
            minHeight: '20rem',
            textAlign: 'center',
            display: 'grid',
            alignItems: 'center',
            // gridColumnStart: 'span 2'
        }}>
            <h2>Ничего не найдено :(</h2>
        </Box>
        : p.cll.type === 'vine'
        ? <ViewVineList
            viewItems={p.viewItems}
            cll={p.cll}
        />
        : <ViewCards 
            viewItems={p.viewItems}
            cardClass={cardClass} //'scale'//'line'//'cards'
            // {p.mobileMode
            // ? 'cards' 
            // :  p.cll.viewProps.view === 0 
            // ? 'lines' 
            // : p.cll.viewProps.view === 1
            // ? 'cards'
            // : 'scale'
            // }
            cll={p.cll}
        />
        }
        <BottomPanel
            mobileMode={p.mobileMode}
            paginatorItemsOnPage={p.cll.viewProps.itemsOnPage}
            startItem={p.cll.viewProps.startItem}

            itemsOnPage={p.itemsOnPage}
            totalItems={p.totalItems}
        />
    </Box>
)}

