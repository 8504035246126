import { Box } from '@mui/material'
import React from 'react'
import { gridStyle } from '../../../style/styles'
import EditField from '../../../ui/editors/editField'
import { locale } from '../../../ui/locale'

export default function EditRecomend(p: any) {

    return(
        <div>
            <h5>{locale('itemEdit.recomendTitle')}</h5>
            <Box sx={gridStyle}>
                <EditField
                    disabled={p.loading}
                    span={3}
                    freeSolo={true}
                    value={p.toEdit.yearFrom ? String(p.toEdit.yearFrom) : ''}
                    fieldName="yearFrom"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.yearFrom')}
                />
                <EditField                    
                    disabled={p.loading}
                    span={3}
                    freeSolo={true}
                    value={p.toEdit.yearTo ? String(p.toEdit.yearTo) : ''}
                    fieldName="yearTo"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.yearTo')}
                />
            </Box>             
        </div>

    )
}