import React, { useState, useRef } from 'react'
import FormControl from '@mui/material/FormControl';
import { locale } from '../../../../ui/locale'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { Box, Button, Menu, TableCell, TableRow } from '@mui/material';

export default function EditFieldListRow (p: any) {
    // const [toEdit, setToEdit] = useState(p.item)
    const [useWrapper, setUseWrapper] = useState( true )

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, p.onOutsideClick)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setUseWrapper(false)
    };
    const handleClose = (height: number | undefined) => {
        setAnchorEl(null);
        setUseWrapper(true);
        if (height) {
            p.onSaveListItemLength(height);
        }
    };
  
    const onNameOpen = () => {
        setUseWrapper(false)
    }
    const onNameClose = () => {
        setUseWrapper(true)
    }


    return (
        <TableRow 
            key={"edit"} 
            ref={useWrapper ? wrapperRef : null}
        >
            <TableCell colSpan={2} sx={{padding: 1, height: '45px'}}>
                <Box sx={{display: 'flex', gap: 1, flexDirection: 'row'}}>
                    <FormControl size="small" variant='outlined' sx={{width: '100%'}}>
                        <InputLabel id="label-name">{locale('collections.form.fieldName')}</InputLabel>
                        <Select            
                            labelId="label-name"
                            id="field-select"
                            fullWidth
                            value={p.item.name}
                            label={locale('collections.form.fieldName')}
                            onChange={event => p.onSaveListItemName(event.target.value)}
                            onOpen={onNameOpen}
                            onClose={onNameClose}
                        >
                        {p.collectionFields.map((field: any, index: number) => (
                            <MenuItem key={index} value={field.name}>{field.name}</MenuItem>
                        ))}

                        </Select>
                    </FormControl>

                    <Button
                        onClick={handleClick}
                    >
                        {p.item.length}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={() => handleClose(undefined)}
                    >
                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((height) => (
                            <MenuItem onClick={() => handleClose(height)}>{height + ' из 12'}</MenuItem>
                        ))
                        }
                        {/* <MenuItem onClick={() => handleClose(1)}>1 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(2)}>2 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(3)}>3 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(4)}>4 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(5)}>5 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(6)}>6 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(7)}>7 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(8)}>8 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(9)}>9 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(10)}>10 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(11)}>11 из 12</MenuItem>
                        <MenuItem onClick={() => handleClose(12)}>12 из 12</MenuItem> */}
                    </Menu>

                    {/* <IconButton 
                        size="small" 
                        color={"primary"}
                        onClick={event => p.onSaveListItem(p.item.name, !p.item.desc)}
                    >
                        {p.item.desc
                        ? <VerticalAlignTopIcon />
                        : <VerticalAlignBottomIcon />
                        }
                    </IconButton> */}
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={p.upDisabled}
                        onClick={event => p.onMoveItem(p.index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={p.downDisabled}
                        onClick={event => p.onMoveItem(p.index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                        <IconButton 
                        size="small" 
                        color="secondary"
                        onClick={event => p.onDeleteListItem(p.index)}
                    >
                        <DeleteOutlineIcon />
                    </IconButton> 
                </Box>
            </TableCell>
            {/* <td width='50px' valign="top">
                <IconButton 
                    size="small" 
                    color={"primary"}
                    onClick={event => p.onSaveListItem(p.item.name, !p.item.desc)}
                >
                    {p.item.desc
                    ? <VerticalAlignTopIcon />
                    : <VerticalAlignBottomIcon />
                    }
                </IconButton>

            </td>
            <td width='130px' valign="top">
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={p.upDisabled}
                    onClick={event => p.onMoveItem(p.index, false)}
                >
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={p.downDisabled}
                    onClick={event => p.onMoveItem(p.index, true)}
                >
                    <ArrowDownwardIcon />
                </IconButton>
                    <IconButton 
                    size="small" 
                    color="secondary"
                    onClick={event => p.onDeleteListItem(p.index)}
                >
                    <DeleteOutlineIcon />
                </IconButton>                    
            </td> */}

        </TableRow>
    )
}