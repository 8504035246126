export const markDownText = 
`##### Ссылки
***
Просто ссылка - https://collectionexpo.club  
[Ссылка с описанием](https://collectionexpo.club)

##### Заголовки
***
###### Заголовок 6
##### Заголовок 5
#### Заголовок 4
### Заголовок 3
## Заголовок 2
# Заголовок 1

##### Разрыв строки и начало нового параграфа
***
Чтобы сгенерировать разрыв строки вставьте два или более пробелов в конце строки и нажмите клавишу Enter. Например тут  
разрыв

Чтобы сгенерировать начало нового параграфа  нажмите Enter два раза. Например тут

начинается новый параграф

##### Цитаты
***
Можно выделить блок текста в виде цитаты знаком ">":
>Se você precisa de um blockquote contendo diversos parágrafos dentro dele, utilize o sinal de maior que > a frente de cada um desses parágrafos e os separe por uma linha contendo apenas o sinal de maior que.

##### Нумерованные списки
***
1. Первый элемент
2. Второй элемент
3. Третий элемент
    1. Элемент с отступом
    2. Элемент с отступом
4. Четвертый элемент

##### Не нумерованные списки
***
- Первый элемент
- Второй элемент
- Третий элемент
    - Элемент с отступом
    - Элемент с отступом
- Четвертый элемент

##### Вставка картинки по ссылке
***
![Логотип Markdown](https://markdown.net.br/assets/img/basic-syntax/markdown-logo-small.png)

##### Горизонтальные линии
***
Три звездочки подряд ***, или три тире ---, или три символа подчеркивания ___ создают горизонтальную линию. Перед линией необходимо оставлять пустую строку.

***

##### Выделение текста
***
Отдельные слова выделять **жирным** шрифтом, *курсивом* или ***жирным курсивом***
`