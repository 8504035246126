import React, {useState} from 'react'
import Button from '@mui/material/Button'
import {useDispatch} from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import * as http from '../../../modules/http'
import * as collectionActions from '../../../store/collection/collectionActions'
import * as listActions from '../../../store/list/listActions'
import { locale } from '../../../ui/locale'
import { Stack } from '@mui/material'

export default function AccessAll(p: any) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const sharedAllOn = async () => {
        setLoading(true)
        await http.httpPost(
            '/item/sharedallon', 
            {CollectionId: p.CollectionId},
            true
        )
        dispatch(collectionActions.collectionChanged())
        dispatch(listActions.collectionListChanged())
        setLoading(false)
    }

    const sharedAllOff = async () => {
        setLoading(true)
        await http.httpPost(
            '/item/sharedalloff',
            {CollectionId: p.CollectionId},
            true
        )
        dispatch(collectionActions.collectionChanged())
        dispatch(listActions.collectionListChanged())
        setLoading(false)
    }

    return (
        <Stack 
            direction='row'
            sx={{padding: 1, justifyContent: 'space-evenly'}}
        >
            <Button 
                size="small"
                disableRipple
                startIcon={<VisibilityIcon />}
                onClick={sharedAllOn}
                disabled={loading}
            >
                {locale('filters.switchAllSharedOn')}
            </Button>
            <Button 
                size="small"
                startIcon={<VisibilityOffOutlinedIcon />}
                onClick={sharedAllOff}
                disabled={loading}
            >
                {locale('filters.switchAllSharedOff')}
            </Button>             
        </Stack>

    )
}