import React, { useState, useEffect, useMemo } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import AddIcon from '@mui/icons-material/Add';
import Edit from './Edit';
import Items from './Items'
import { locale } from '../../../../ui/locale'
import * as I from '../../../../store/interfaces'
import { Box } from '@mui/material'
import { twoPanelsStyle } from '../../../../style/styles'

export default function Filters(p: any) {
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        loading: false, 
        editIndex: -1, 
        edited: false,
        error: null
    });
    const [toEdit, setToEdit] = useState(p.collection.filters)
    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(p.collection.filters) 
    }, [p.collection])

    const collectionFields = useMemo(() => {
        return (
            p.collection.fields.map((field: any) => ({
                value: field.name, 
                description: field.name,
                list: field.list,
                type: field.type
            }))
            .concat({value: '_description', description: locale('collections.view.fields.description'), list: [], type: I.FIELD_DROPDOWNTEXT})  
            .concat(p.collection.useQuantity ? I.initQtyCollectionFields : [])
            .concat(p.collection.type === 'vine' ? I.initVineCollectionFields : [])
        )
    }, [p.collection])


    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }

    const addField = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {
            fieldName: '', 
            fieldListName: '-',
            titleNone: '', 
            titleOther: '', 
            useValuesMap: false,
            valuesMap: []
        }])
        setPageState({...pageState, editIndex: index, edited: true})
    }
    const onEditClick = (index: number) => {
        if (!pageState.edited) {
            setPageState({...pageState, editIndex: index})
        }
    }

    const saveFlters = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        formData.filters = formData.filters.filter((filter: any) => filter.fieldName)
        setToEdit(formData.filters)
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = async (itemData: any) => {
        const formData = {
            CollectionId: CollectionId,
            filters: [...toEdit.slice(0, pageState.editIndex), itemData, ...toEdit.slice(pageState.editIndex + 1)]
        }
        saveFlters(formData)
    }
    const onDeleteClick = () => {
        const formData = {
            CollectionId: CollectionId,
            filters: [...toEdit.slice(0, pageState.editIndex), ...toEdit.slice(pageState.editIndex + 1)]
        }        
        saveFlters(formData)
        setPageState({...pageState, editIndex: -1, edited: false})
    }
    const onCancelClick = () => {
        setToEdit([...toEdit.filter((field: any) => field.fieldName) ])
        setPageState({...pageState, edited: false})
    }
    const onMoveFilter = (event: any, index: number, up: boolean) => {
        const formData = {
            CollectionId: CollectionId,
            filters: up
                ?[
                    ...toEdit.slice(0, index), 
                    toEdit[index + 1], 
                    toEdit[index], 
                    ...toEdit.slice(index + 2)]
                :[
                    ...toEdit.slice(0, index - 1), 
                    toEdit[index], 
                    toEdit[index - 1], 
                    ...toEdit.slice(index + 1)]
        }        
        saveFlters(formData)
        setPageState((prev) => ({...prev, editIndex: up ? index + 1 : index - 1 }))
    }

    return (
    <Box sx={twoPanelsStyle}>
        <div>
        
            <div>
                <Button 
                    id="add" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => addField()}
                    startIcon={<AddIcon />}                    
                    disabled={pageState.loading || pageState.edited}
                >
                    {locale('collections.filters.addField') }
                </Button>
            </div>
        {/* <LineLoader active={pageState.loading && !pageState.edited} /> */}
            <Items 
                filters={toEdit}
                collectionFields={collectionFields}
                onEditClick={onEditClick}
                editIndex={pageState.editIndex}
                edited={pageState.edited}
                loading={pageState.loading}
                onMoveFilter={onMoveFilter}
            />
        </div>   
        <Edit 
            filter={pageState.editIndex >=0 ? toEdit[pageState.editIndex] : null}
            collectionFields={collectionFields}
            onSaveClick={onSaveClick}
            onDeleteClick={onDeleteClick}
            onCancelClick={onCancelClick}
            setEdited={setEdited}
            edited={pageState.edited}
            loading={pageState.loading}
        />
    </Box>
    )
}