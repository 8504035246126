import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { SettingsTableCell } from '../../../../modules/settingsTableCell';
import { SettingsTableHeadCell } from '../../../../modules/settingsTableHeadCell';
import { SettingsTableRow } from '../../../../modules/settingsTableRow';


export default function Items(p: any) {

    return(
        p.complect.length === 0
        ? null
        : <TableContainer>
            <Table sx={{ opacity: p.loading || p.edited ? '0.5' : undefined }}>
                <TableHead>
                    <TableRow>
                        <SettingsTableHeadCell>Наименование</SettingsTableHeadCell>
                        <SettingsTableHeadCell>Откуда</SettingsTableHeadCell>
                        <SettingsTableHeadCell> Лот</SettingsTableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {p.complect
                .filter((complect: any) => complect.name)
                .map((complect: any, index: number) => (
                    <SettingsTableRow 
                        key={index} 
                        onClick={() => p.onEditClick(index)} 
                        selected={p.editIndex === index}
                    >
                        <SettingsTableCell selected={p.editIndex === index}>
                            {complect.name}
                        </SettingsTableCell>
                        <SettingsTableCell selected={p.editIndex === index}>
                            {complect.description}
                        </SettingsTableCell>
                        <SettingsTableCell selected={p.editIndex === index}>
                            {complect.details}
                        </SettingsTableCell>
                    </SettingsTableRow>

            ))}
                </TableBody>
        </Table>
        </TableContainer>

    )
}