import React from 'react'
import Menu from './Menu'
import {MENU_CLL, MENU_USERS, MENU_MESSAGES, MENU_EXPORT}  from './Menu'
import { locale } from '../../ui/locale'
import Collections from './collections/Collections'
import Users from './Users/Users'
import Feedback from './Feedback/Feedback'
import Export from './Export/Export'


export default function Admin(props: any) {
    
    const [tabIndex, setTabIndex] = React.useState(0);
    const changeTabIndex = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    return (
        <>
        <section>
            <div className="cm-header">
            <div className="cm-toolbar">
            <span>
                <h1>
                    {locale('admin.title')}
                </h1>
            </span>
            </div>
                <Menu tabIndex={tabIndex} changeTabIndex={changeTabIndex} />                
            </div>
        </section>
        <section>
            {tabIndex === MENU_CLL ? <Collections />
            : tabIndex === MENU_USERS ? <Users />
            : tabIndex === MENU_MESSAGES ? <Feedback />
            : tabIndex === MENU_EXPORT ? <Export />
            : null
            }            
        </section> 
        </>
    )
}
