import React, { useState, useEffect }  from 'react'
import {useSelector} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import * as http from '../../../modules/http'
import * as I from '../../../store/interfaces'
import Menu from './Menu'
import { LineLoader } from '../../../modules/line-loader'
import { useNavigate } from 'react-router-dom'


export default function Feedback(p: any) {
    const auth = useSelector( (state: any) => state.auth )
    const navigate = useNavigate();
    
    const [pageState, setPageState] = useState({loaded: false, loading: false, error: null});
    const [list, setList] = useState([])

    if (auth.auth) {
        if (!auth.roles.includes('admin')) {
            navigate('/')
        }
    }

    useEffect( () => {
        if (!pageState.loaded) {
            (async function anyNameFunction() {
                setPageState(prev => ({...prev, loading: true}))
                const res = await http.httpGet('/admin/feedback', {}, true)
                if (res.success) {
                    setList(res.data)
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                } else {
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                }    
            })()
        }    
    }, [pageState.loaded])

    const reloadList = () => {
        setPageState({...pageState, loaded: false})
    }

    const setLoading = (loading: boolean) => {
        setPageState({...pageState, loading: loading})
    }

    return (
        !auth.auth || !auth.roles.includes('admin')
        ? <Loader />
        : <>
        <div className="cm-content">
        
        <div style={{maxWidth:'60rem'}}>
        <LineLoader active={pageState.loading} />
        <table className="cm-table users">
            <tbody>
            {list.map((msg: any, index: number) => (
            <tr key={index}>
                <td>
                    <h4>{msg.userName}</h4>
                    <span>{msg.email}</span>
                </td>
                <td>
                    {msg.message}
                </td>
                <td>
                    {I.timeString(new Date(msg.createdAt))}
                </td>
                <td style={{textAlign:'center'}}>
                    <Menu
                        // userId={user._id}
                        msg={msg}
                        reloadList={reloadList}
                        loading={pageState.loading}
                        setLoading={setLoading}
                    />
                </td>
            </tr>
            ))}
            </tbody>
        </table>        
        </div>
        {/* </div> */}

        </div>
        </>
    )
}
