import ItemView from './ItemView'
import ItemEdit from './ItemEdit'
import Navbar from '../Navbar'
import { Container, Dialog } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as panelActions from '../../store/panel/panelActions'


export default function ItemPanel(props: any) {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(panelActions.closeCollectionItemPanel())
    }

    return (
        !props.show
        ? null
        : 
        props.editMode
        ?<Dialog
            fullScreen
            open={true}
            onClose={close}
            // TransitionComponent={Transition}
        >
            <Navbar />
            <Container 
                maxWidth='xl' 
                // maxWidth='1200px' 
                sx={{minHeight: '80vh'
            }}>

            <ItemEdit 
                CollectionId={props.CollectionId}
                CollectionItemId={props.CollectionItemId}
                CollectionSubItemId={props.CollectionSubItemId}
                noImage={props.noImage}
                add={props.add}
                copy={props.copy}
                subItem={props.subItem}
            />   
            </Container>     
        </Dialog>
        :<Dialog
            fullScreen
            open={true}
            onClose={close}
            // TransitionComponent={Transition}
        >
            <Navbar />
            <Container 
                maxWidth='xl' 
                // maxWidth='1200px' 
                sx={{minHeight: '80vh'
            }}>
            <ItemView 
                CollectionId={props.CollectionId}
                CollectionItemId={props.CollectionItemId}
                CollectionSubItemId={props.CollectionSubItemId}
                startImageId={props.startImageId}
                noImage={props.noImage}
            /> 
            </Container>       
        </Dialog> 

    )
  }