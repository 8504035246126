import { Link } from '@mui/material';
import { locale } from '../../ui/locale'


export default function AuthRegisterSuccess(p: any) {

    return (
        <div>
            <h4>
                {locale('auth.registered.welcome')}
            </h4>
            <p>
                {locale('auth.registered.welcomeTextStart')}
                &nbsp;<strong>{p.formData[1].value}</strong>
                {locale('auth.registered.welcomeTextEnd')}
            </p>
            <p> 
            {/* {locale('auth.registered.startWorkStart')} */}
            &nbsp;
            <Link
                // sx={{marginTop: 2}}
                onClick={p.onLoginClick}
                variant='body1'
                component="button"
            >
                {locale('auth.registered.startWorkURL')}
            </Link>
            
            {/* <Box sx={{cursor: 'pointer'}} onClick={p.onLoginClick}>
                {locale('auth.registered.startWorkURL')}
            </Box> */}
            &nbsp;
            {/* {locale('auth.registered.startWorkEnd')} */}

            </p>
        </div>
    )
}
