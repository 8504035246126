import * as I from '../../interfaces'
import { locale } from '../../../ui/locale'

export function filterQty(data: I.Collection) {

    const index = data.filterGroups.length
    const items = data.preparedItems
    data.filterGroups.push({
        description: locale('filters.qty.title'),
        isOpen: false,
        hidden: false,
        items: [],        
    })
    data.filterGroups[index].items.push({
        description: locale('filters.qty.zero'),
        isChecked: false,
        itemsList: items.filter(item => !item.qty || item.qty === 0).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.one'),
        isChecked: false,
        itemsList: items.filter(item => item.qty === 1).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.two'),
        isChecked: false,
        itemsList: items.filter(item => item.qty === 2).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.twoFive'),
        isChecked: false,
        itemsList: items.filter(item => item.qty > 2 && item.qty <= 5).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.fiveTen'),
        isChecked: false,
        itemsList: items.filter(item => item.qty > 5 && item.qty <= 10).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.tenTwenty'),
        isChecked: false,
        itemsList: items.filter(item => item.qty > 10 && item.qty <= 20).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.qty.tеwentyMore'),
        isChecked: false,
        itemsList: items.filter(item => item.qty > 20).map(item => (item.CollectionItemId))
    })


}