import React, { useRef, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { token } from './store/auth/authActions'
import {BrowserRouter } from 'react-router-dom'
import MainRoutes from './routes/Routes'
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import {createMuiTheme, MuiThemeProvider } from '@mui/material';
import Navbar from './routes/Navbar'
import {Footer} from './routes/Footer';
import ItemPanel from './routes/itemPanel/ItemPanel'
import {ScrollContext} from './modules/context'
import themeSize from './ui/theme/theme-size';
import themePalette from './ui/theme/theme-palette';
import { Box, Container } from '@mui/material';


import './style/css/image-gallery.css';
import './style/css/cm.css'
// import './style/css/content.css';



function App() {
    const dispatch = useDispatch();
    const itemPanel = useSelector( (state: any) => state.itemPanel )
    const scrollRef = useRef(null as (null | HTMLDivElement))

    const theme = createTheme({...themeSize, ...themePalette});
    
    useEffect( () => {
        dispatch<any>(token())
    }, [dispatch])

    return (
        <ThemeProvider theme={theme}>

            <BrowserRouter>
                <Navbar />
                <ItemPanel
                    show={itemPanel.show}
                    CollectionId={itemPanel.CollectionId}
                    CollectionItemId={itemPanel.CollectionItemId}
                    CollectionSubItemId={itemPanel.CollectionSubItemId}
                    startImageId={itemPanel.startImageId}
                    editMode={itemPanel.editMode}
                    noImage={itemPanel.noImage}
                    add={itemPanel.add}
                    copy={itemPanel.copy}
                    subItem={itemPanel.subItem}
                />            
                <Container 
                    maxWidth='xl' 
                    sx={{minHeight: '83vh'
                }}>
                <ScrollContext.Provider value={scrollRef}>
                    <Box ref={scrollRef} >
                        <MainRoutes />
                    </Box>
                </ScrollContext.Provider>
                </Container>
                <Box sx={{
                    width: '100%',
                    marginTop: 2,
                    borderTop: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Footer />
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App;
