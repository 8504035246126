import ImageGallery from 'react-image-gallery';
import { Box } from '@mui/material';
import { locale } from '../../ui/locale';
import useImagePreloader from '../../hooks/useImagePreloader';
import { CircularLoader } from '../../modules/circular-loader';

export default function ItemGallaryView(p: any) {
    const {images, index} = p;
    const { imagesPreloaded } = useImagePreloader([
        ...images.map((image: any) => image.original),
        // ...images.map((image: any) => image.fullscreen),
        ...images.map((image: any) => image.thumbnail),
    ])

    return(        
        <Box sx={{
            marginLeft: p.mobileMode ? '-12px' : undefined,
            marginRight: p.mobileMode ? '-12px' : undefined,
        }}> 
        {
        images.length > 0 && imagesPreloaded 
        ? 
        <ImageGallery 
            items={images} 
            showPlayButton={false}
            showNav={false}
            startIndex={index}
            // slideOnThumbnailOver={true}
            useBrowserFullscreen={false}
            showThumbnails={p.mobileMode ? false : true}
            showBullets={p.mobileMode ? true : false}
            // thumbnailPosition={'left'}
            thumbnailPosition={p.mobileMode ? 'top' : 'left'}
            showFullscreenButton={!p.mobileMode}
            // additionalClass="root-gallery-white"
            additionalClass={"root-gallery-" + p.backgroundColor}
        />
        : <Box sx={{
            border: !imagesPreloaded ? 'unset' : '1px dashed',
            borderColor: 'divider',
            minHeight: '5rem',
            color: 'action.active',
            position: 'relative',
        }}>
            <Box sx={{
                display: 'inline-block',
                position: 'relative',
                width: '100%'
            }}>
                <Box sx={{marginTop: p.mobileMode ? '100%' : '80%'}}></Box>
            </Box>
            <Box sx={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                display: 'flex', 
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div>
                {!imagesPreloaded 
                ? <CircularLoader active={true} />
                : locale('itemView.emptyGallary')}
                </div>
            </Box>
        </Box>
        }            
        </Box>
    )
}
