import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import { locale } from '../../../../ui/locale'
import Items from './Items'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { LineLoader } from '../../../../modules/line-loader'
import { Box } from '@mui/material'

export default function Fields(p: any) {
    const dispatch = useDispatch()

    const [pageState, setPageState] = useState({loading: false, edited: false, error: null})
    const [toEdit, setToEdit] = useState(JSON.parse(JSON.stringify(p.collection.catalog)))
    const [catalogAutoSort, setCatalogAutoSort] = useState(p.collection.catalogAutoSort)

    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(JSON.parse(JSON.stringify(p.collection.catalog)))
    }, [p.collection])


    const onSortingChange = (sorting: boolean) => {
        setPageState({...pageState, edited: true})
        setCatalogAutoSort(sorting)
    }
    const onCancelClick = () => {
        setPageState({...pageState, edited: false})
        setCatalogAutoSort(p.collection.catalogAutoSort)
        setToEdit(JSON.parse(JSON.stringify(p.collection.catalog)))
    }
    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }
    const saveCatalog = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = () => {
        const formData = {
            CollectionId: CollectionId,
            catalogAutoSort: catalogAutoSort,
            catalog: toEdit
        }
        saveCatalog(formData)
    }

    return(

        // <Box sx={{maxWidth:'40rem', }}>
        <div>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2
        }}>
            <Button 
                id="save" 
                size="medium"
                variant="outlined"
                color="primary"
                onClick={event => onCancelClick()}
                startIcon={<CloseIcon />}                    
                disabled={pageState.loading || !pageState.edited }
            >
                {locale('common.cancel')}
            </Button>
            <Button 
                id="save" 
                size="medium"
                variant={pageState.loading || !pageState.edited ? "outlined" : "contained"}
                color="primary"
                onClick={event => onSaveClick()}
                startIcon={<DoneIcon />}
                disabled={pageState.loading || !pageState.edited }
            >
                {locale('common.save')}
            </Button>
        </Box>
        <LineLoader active={pageState.loading} />
        {/* <form> */}
        <FormControl component="fieldset" margin="dense" disabled={p.loading} >
        <FormLabel component="legend">{locale('collections.catalog.sorting')}</FormLabel>
        <RadioGroup 
            row
            value={catalogAutoSort}
            onChange={event => onSortingChange(event.target.value==="true")}
        >
        <FormControlLabel 
            color="primary" 
            value={true}
            control={<Radio color="primary"/>} 
            label={locale('collections.catalog.sortAuto')}
        />
        <FormControlLabel 
            color="primary" 
            value={false}
            control={<Radio color="primary"/>} 
            label={locale('collections.catalog.sortManualy')}
        />
        </RadioGroup>
        </FormControl>

        <Items
            catalog={toEdit}
            catalogAutoSort={catalogAutoSort}
            setEdited={setEdited}
        />
        {/* </form> */}
    </div>
    )
}