export const viewCardStyle = (style: string) => 
    style === 'lines' ? linesStyle : 
    style === 'cards' ? cardsStyle : 
    scaleStyle;

const linesStyle = {
    marginTop: '1.5rem',
    gridAutoFlow: 'row',
    gap: '1rem',
    gridGap: '1rem',
    display: 'grid',
    boxSizing: 'border-box',
    gridTemplateColumns: '',
}
const cardsStyle = {
    marginTop: '1.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill,minmax(15rem,1fr))',
    gap: '1rem',
    gridGap: '1rem'
}
const scaleStyle = {
    marginTop: '1.5rem',
    gridAutoFlow: 'row',
    gap: '1rem',
    gridGap: '1rem',
    display: 'grid',
    boxSizing: 'border-box',
    gridTemplateColumns: '',
}



