import { Box, LinearProgress } from '@mui/material'
import React from 'react'

export const LineLoader = (props: any) => {
    return (
        <Box sx={{
            height: '.125rem',
            marginTop: '.5rem'                  
        }} >
            {props.active
            ?<LinearProgress />
            : null
            }
        </Box>
    )
}