import React, { useState, useRef } from 'react'
import FormControl from '@mui/material/FormControl';
import { locale } from '../../../../ui/locale'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { Box, TableCell, TableRow } from '@mui/material';

export default function EditFieldListRow (p: any) {
    // const [toEdit, setToEdit] = useState(p.item)
    const [useWrapper, setUseWrapper] = useState( true )

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, p.onOutsideClick)

    const onOpen = () => {
        setUseWrapper(false)
    }
    const onClose = () => {
        setUseWrapper(true)
    }

    return (
        <TableRow 
            key={"edit"} 
            ref={useWrapper ? wrapperRef : null}
        >
            <TableCell colSpan={2} sx={{padding: 1, height: '45px'}}>
                <Box sx={{display: 'flex', gap: 1, flexDirection: 'row'}}>
                    <FormControl size="small" variant='outlined' sx={{width: '100%'}}>
                        <InputLabel id="label-name">{locale('collections.filters.fieldName')}</InputLabel>
                        <Select            
                            labelId="label-name"
                            id="field-select"
                            fullWidth
                            value={p.item.name}
                            label={locale('collections.filters.fieldName')}
                            onChange={event => p.onSaveListItem(event.target.value, p.item.desc)}
                            onOpen={onOpen}
                            onClose={onClose}
                        >
                        {p.collectionFields.map((field: any, index: number) => (
                            <MenuItem key={index} value={field.value}>{field.description}</MenuItem>
                        ))}

                        </Select>
                    </FormControl>

                    <IconButton 
                        size="small" 
                        color={"primary"}
                        onClick={event => p.onSaveListItem(p.item.name, !p.item.desc)}
                    >
                        {p.item.desc
                        ? <VerticalAlignTopIcon />
                        : <VerticalAlignBottomIcon />
                        }
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={p.upDisabled}
                        onClick={event => p.onMoveItem(p.index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={p.downDisabled}
                        onClick={event => p.onMoveItem(p.index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                        <IconButton 
                        size="small" 
                        color="secondary"
                        onClick={event => p.onDeleteListItem(p.index)}
                    >
                        <DeleteOutlineIcon />
                    </IconButton> 
                </Box>
            </TableCell>
            {/* <td width='50px' valign="top">
                <IconButton 
                    size="small" 
                    color={"primary"}
                    onClick={event => p.onSaveListItem(p.item.name, !p.item.desc)}
                >
                    {p.item.desc
                    ? <VerticalAlignTopIcon />
                    : <VerticalAlignBottomIcon />
                    }
                </IconButton>

            </td>
            <td width='130px' valign="top">
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={p.upDisabled}
                    onClick={event => p.onMoveItem(p.index, false)}
                >
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={p.downDisabled}
                    onClick={event => p.onMoveItem(p.index, true)}
                >
                    <ArrowDownwardIcon />
                </IconButton>
                    <IconButton 
                    size="small" 
                    color="secondary"
                    onClick={event => p.onDeleteListItem(p.index)}
                >
                    <DeleteOutlineIcon />
                </IconButton>                    
            </td> */}

        </TableRow>
    )
}