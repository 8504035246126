import { ITEM_PANEL_VIEW, ITEM_PANEL_EDIT, 
    ITEM_PANEL_CLOSE, ITEM_PANEL_CLOSE_EDIT, 
    ITEM_PANEL_ADD, ITEM_PANEL_CLOSE_ADD,
    ITEM_PANEL_ADD_CHILD,
    ITEM_PANEL_ADD_COPY
} 
from './panelReducer'


export function viewCollectionItem(CollectionId: string, CollectionItemId: string, startImageId: number, CollectionSubItemId: string): any {
    return {
        CollectionId: CollectionId,
        CollectionItemId: CollectionItemId,
        CollectionSubItemId: CollectionSubItemId,
        startImageId: startImageId,
        type: ITEM_PANEL_VIEW
    }
}

export function addCollectionItem(CollectionId: string): any {
    return {
        CollectionId: CollectionId,
        type: ITEM_PANEL_ADD
    }
}

export function addChildCollectionItem(CollectionId: string, CollectionItemId: string): any {
    return {
        CollectionId: CollectionId,
        CollectionItemId: CollectionItemId,
        type: ITEM_PANEL_ADD_CHILD,
    }
}

export function copyCollectionItem(CollectionId: string, CollectionItemId: string, CollectionSubItemId: string, subItem: boolean): any {
    return {
        CollectionId: CollectionId,
        CollectionItemId: CollectionItemId,
        CollectionSubItemId: CollectionSubItemId,
        subItem: subItem,
        type: ITEM_PANEL_ADD_COPY
    }
}

export function editCollectionItem(CollectionId: string, CollectionItemId: string, CollectionSubItemId: string): any {
    return {
        CollectionId: CollectionId,
        CollectionItemId: CollectionItemId,
        CollectionSubItemId: CollectionSubItemId,
        subItem: false,
        type: ITEM_PANEL_EDIT
    }
}

export function editCollectionItemVine(CollectionId: string, CollectionItemId: string): any {
    return {
        CollectionId: CollectionId,
        CollectionItemId: CollectionItemId,
        noImage: true,
        type: ITEM_PANEL_EDIT
    }
}

export function closeCollectionItemPanel(): any {
    return {
        type: ITEM_PANEL_CLOSE
    }
}

export function closeEditCollectionItem(): any {
    return {
        type: ITEM_PANEL_CLOSE_EDIT
    }
}

export function closeAddCollectionItem(CollectionItemId: string, CollectionSubItemId: string): any {
    return {
        type: ITEM_PANEL_CLOSE_ADD,
        CollectionItemId: CollectionItemId,
        CollectionSubItemId: CollectionSubItemId,
    }
}
