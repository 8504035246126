import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import Image from 'material-ui-image'
import { BACKENDROOT } from '../../index'
import Hightlight from "../collection/view-controls/Hightlight";


export const CardOther = (props: any) => {

    const qty = useMemo(() => props.collection.itemsQty ?
    {
        qtyItems: props.collection.itemsQty.total - props.collection.itemsQty.toFind,
        qtyItemsToFind: props.collection.itemsQty.toFind,
    } : {}, [props.collection])

    const htmlColor = 'rgb(255 255 255 / 80%)';

    const textSx = useMemo(() => ({
        color: htmlColor,
        fontSize: '0.85rem'
    }), []);


    return (
    <Grid item lg={2} md={3} sm={4} xs={12} >
        <Box 
            sx={{cursor: 'pointer'}} 
            onClick={() => props.onCardClick(props.collection.CollectionId)}
        >
        <Box sx={{position: 'relative'}} 
        >
            {props.collection.imageId
            ?<Image
                aspectRatio={2/1}
                src={BACKENDROOT + '/image/cover-picture/' + (props.collection.imageId ? props.collection.imageId : '')} 
            />
            :<Image
                aspectRatio={2/1}
                src='/images/noimage.jpg'
            />
            }
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent)'
                }}>
                <Stack 
                    direction='row' 
                    spacing={1} 
                    sx={{paddingTop: 2, paddingLeft: 1, paddingRight: 1, paddingBottom: 0.5}}
                >
                    <Typography sx={textSx}>{qty.qtyItems}</Typography>
                    {qty.qtyItemsToFind > 0
                    ? <>
                        <Divider color='white' orientation="vertical" flexItem />                        
                        <Typography sx={textSx}>{qty.qtyItemsToFind} ищу</Typography>
                    </>
                    : null
                    }
                    <div className='grow'></div>
                    {/* <Typography sx={{...textSx, color: 'rgb(159, 176, 227)'}}> */}
                    <Box sx={{
                        ...textSx,
                        border: '1px solid',
                        borderRadius: '12px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        // borderColor: 'primary.light',
                        borderColor: 'rgba(0, 0, 0, 1)',
                        color: 'rgba(0, 0, 0, 1)',
                        // color: 'primary.main',
                        // opacity: 0.5,
                        backgroundColor: 'white',
                        opacity: 0.8,
                    }}>
                        {props.collection.owner}
                    </Box>
                </Stack>
            </Box>
        </Box>

        <Stack direction='column'>
            {/* <h3>{props.collection.name}</h3> */}
            <h3>
                <Hightlight filter={props.filterString} str={props.collection.name} />    
            </h3>
            
            <Typography sx={{
                color: 'action.active', 
                fontSize: '12px', 
                lineHeight: '100%'
            }} >
                {/* {props.collection.details} */}
                <Hightlight filter={props.filterString} str={props.collection.details} />    

            </Typography>
        </Stack>
        
        </Box>
    </Grid>
)}
