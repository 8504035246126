import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

// export default function EditField(p: any) {
function EditField(p: any) {

    return(
        <Autocomplete
            // id={p.fieldName}
            value={p.value}
            disabled={p.disabled}
            clearText="Очистить"
            clearOnEscape
            handleHomeEndKeys
            className={p.className}
            sx={{...p.sx, gridColumnStart: p.span ? 'span ' + p.span : undefined}}
            onInputChange={(event, newValue) => {p.onChangeField(p.fieldName, newValue || '') }}
            filterOptions={(options, params) => {
                const filtered = 
                    p.value
                    ? options.filter(el => el.toUpperCase().indexOf(p.value.toUpperCase()) >= 0)
                    : options
                const value = p.value ? p.value : ''
                if (filtered.length === 1 && filtered[0].toUpperCase() === value.toUpperCase()) return []
                return filtered;
            }}
            freeSolo={p.freeSolo}
            options={p.list}
            size="small"
            renderInput={(params) => (
                <TextField {...params}                 
                    label={p.label} 
                    margin="normal" 
                    multiline
                    disabled={p.disabled}
                    variant="outlined" 
                />
            )}
      />
    )
}

export default React.memo(EditField)