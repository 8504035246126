import Hightlight from './Hightlight'


export default function ViewSubTitle(p: any) {
    return(
        p.item.subDescription
        ?
        <div className="subtitle">
            <Hightlight filter={p.filterString} str={p.item.subDescription} />
            {/* {p.item.subDescription} */}
        </div>
        : null        
    )
}