
import { 
    DRAWER_PAGE_ON, DRAWER_PAGE_OFF,
    DRAWER_MODE_ON, DRAWER_MODE_OFF,
    MOBILE_MODE_ON, MOBILE_MODE_OFF,
    DRAWER_TOGGLE,
    OFFLINE_MODE_ON, OFFLINE_MODE_OFF,
    SHOW_ALERT, HIDE_ALERT, SHOW_WARNING, HIDE_WARNING, SHOW_MESSAGE, HIDE_MESSAGE,
    SET_MAIN_REF,
    SET_LOGIN_OPEN_ON,
    SET_LOGIN_OPEN_OFF
} from "./appReducer"

export function drawerPageOn(): any {
    return function (dispatch: any, getState: any)  {
        if (getState().app.drawerPage) return
        dispatch({ type: DRAWER_PAGE_ON })
    }
}
export function drawerPageOff(): any {
    return function (dispatch: any, getState: any)  {
        if (!getState().app.drawerPage) return
        dispatch({ type: DRAWER_PAGE_OFF })
    }
}

export function setMainRef(ref:any): any { return { type: SET_MAIN_REF, ref: ref } }

export function loginOpenOn(): any { return { type: SET_LOGIN_OPEN_ON } }
export function loginOpenOff(): any { return { type: SET_LOGIN_OPEN_OFF } }

export function drawerModeOn(): any { return { type: DRAWER_MODE_ON } }
export function drawerModeOff(): any { return { type: DRAWER_MODE_OFF } }

export function mobileModeOn(): any { return { type: MOBILE_MODE_ON } }
export function mobileModeOff(): any { return { type: MOBILE_MODE_OFF } }

export function drawerToggle(): any { return { type: DRAWER_TOGGLE } }

export function setOfflineModeOn(): any { return { type: OFFLINE_MODE_ON } }
export function setOfflineModeOff(): any { return { type: OFFLINE_MODE_OFF } }

export function showAlert(alert: string): any { return { type: SHOW_ALERT, alert: alert } }
export function hideAlert(): any { return { type: HIDE_ALERT } }

export function showWarning(warning: string): any { return { type: SHOW_WARNING, warning: warning } }
export function hideWarning(): any { return { type: HIDE_WARNING } }

export function showMessage(message: string): any { return { type: SHOW_MESSAGE, message: message } }
export function hideMessage(): any { return { type: HIDE_MESSAGE } }