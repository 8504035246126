import React, {useState} from 'react'
import Image from 'material-ui-image'
import { BACKENDROOT } from '../../../../index'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import * as http from '../../../../modules/http'
import * as listActions from '../../../../store/list/listActions'
import * as collectionActions from '../../../../store/collection/collectionActions'
import { Box } from '@mui/material'
import { FormError } from '../../../../modules/form-error'

// import * as actions from '../../../loadData/fetchEditCollection';
// import { CollectionListChaged } from '../../../loadData/fetchCollectionList'


export default function Cover(p: any) {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({loading: false, error: null});

    async function onFileChange(e: any) {
        setFormState({loading: true, error: null})
        var formData = new FormData();
        formData.append("cover", e.target.files[0]);
        formData.append("CollectionId", p.collection.CollectionId) 

        const res = await http.httpPost('/image/uploadcover', formData, true)
        if (res.success) {
            setFormState({loading: false, error: null})
            dispatch(listActions.collectionListChanged())
            dispatch(collectionActions.collectionChangeImage(res.data))
        } else {
            setFormState({loading: false, error: res.data})
        }
    }

    return(
    <Box sx={{maxWidth:'30rem', }}>
        <FormError message={formState.error} />
        <div>  
            {p.collection.imageId
            ?<Image
                aspectRatio={2/1}
                src={BACKENDROOT + '/image/cover-picture/' + (p.collection.imageId ? p.collection.imageId : '')} 
            />
            :
            <Image     
                aspectRatio={2/1}
                src='/images/noimage.jpg'
            />
            }
        </div>
        <Box sx={{marginTop: 2}}>
                <input
                    accept="image/*"
                    style={{display: 'none'}} 
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="contained-button-file">
                <Button 
                    id="add" 
                    size="medium"
                    variant="outlined"
                    component="span"
                    startIcon={<PhotoCameraIcon />}   
                >
                    Загрузить обложку
                </Button>
                </label>
        </Box>
    </Box>
    )
}