import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {useDispatch} from 'react-redux'
import * as http from '../../../modules/http'
import * as collectionActions from '../../../store/collection/collectionActions'
import * as listActions from '../../../store/list/listActions'
import { locale } from '../../../ui/locale'

export default function SharedButton(p: any) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    async function changeShared () {
        setLoading(true)
        dispatch(collectionActions.collectionSharedChanged(p.item.CollectionItemId, !p.item.sharedItem))
        await http.httpPost(
            '/item/sharedchange', 
            {CollectionId: p.CollectionId, 
            CollectionItemId: p.item.CollectionItemId,
            sharedItem: !p.item.sharedItem
            },
            true
        )
        dispatch(collectionActions.reloadFilters())
        // dispatch(collectionActions.collectionChanged())
        dispatch(listActions.collectionListChanged())
        setLoading(false)
    }

    return(
        <div className="shared-button">
            <IconButton 
                size="small"
                title={locale('itemEdit.changeAccess')} 
                disabled={loading}
                // color={p.item.sharedItem ? 'primary' : undefined}
                // color='inherit'
                onClick={event => changeShared()}
                >
                {p.item.sharedItem ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
            </IconButton>  
        </div>
    )
}
