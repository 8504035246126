import React, {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector } from 'react-redux'
import * as panelActions from '../../store/panel/panelActions'
import ItemForm from './ItemForm'
import { Loader } from '../../modules/Loader'
import * as I from '../../store/interfaces'
import _get from 'lodash/get'
import { RootState } from '../../store/rootReducer'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'


export function prepareItemsForList (items: I.CollectionItem[]): I.CollectionItem[] {
    let res: I.CollectionItem[] = []
    items.forEach(item => {
        res.push(item)
        if (item.items.length > 0) {
            res.push(...item.items.map(i => ({...i, ownerItem:item })))
            // res.concat(item.items.map(i => ({...i, ownerItem:item })))
        }
    })
    return res
}

const listSet = (cll: any, list: any, item: any) => {
    let res = ''
    for (let field of list) {
        const value = I.fieldValue(item, cll.fields, field)
        if (value) {
            res = (res ? res + ', ' : '' )+ I.fieldValue(item, cll.fields, field)
        }
    }
    return res
}

const initData = (CollectionId: string): I.CollectionItem => {
    return ({
        CollectionId: CollectionId,
        CollectionItemId: '',
        // bigDetails: '',
        catalog: '',
        catalogOrderNr: '',
        catalogLevel1: '',
        catalogLevel2: '',
        catalogLevel3: '',

        subDescription: '',
        description: '',
        itemNumber: '',
        itemSubNumber: '',
        itemToFind: false,
        itemDropped: false,
        droppedInfo: '',
    
        itemText: '',
        sharedItem: false,
        fields: [],

        qty: 0,
        qtyUsed: 0,

        url: [],
        oneImageOnCover: false,
        images: [],
        source: {
            complect: '',
            description: '',
            details: '',
            currency: '',
            date: '',
            price: '',
            info: '',
            storage: '',
        },

        items: [],
    })
}

export default function ItemEdit (p:any) {
    const dispatch = useDispatch()
    const collection  = useSelector( (state: RootState) => state.collection )
    const navigate = useNavigate();

    const CollectionId = p.CollectionId
    // const CollectionItemId = p.CollectionSubItemId > 0 ? p.CollectionSubItemId : p.CollectionItemId

    if (!CollectionId || (!p.CollectionItemId && !p.add)) {
        dispatch(panelActions.closeCollectionItemPanel())
        navigate('/404')
    }

    const cll: I.Collection = collection.collection && CollectionId === collection.collection.CollectionId
        ? collection.collection
        : null

    if (!cll) {
        dispatch(panelActions.closeCollectionItemPanel())
        navigate('/404')
    } 

    const itemIndex = cll ? cll.items.findIndex(item => item.CollectionItemId === p.CollectionItemId) : -1
    let subItemIndex = -1
    if (itemIndex >=0 && p.CollectionSubItemId) {
        subItemIndex = cll.items[itemIndex].items.findIndex(item => item.CollectionItemId === p.CollectionSubItemId)
    }
    let data = p.add 
        ? initData(CollectionId)
        : subItemIndex >= 0 ? cll.items[itemIndex].items[subItemIndex] 
        : itemIndex >=0 ? cll.items[itemIndex] 
        : null

    if (p.copy && data) {
        let item = subItemIndex >= 0 
        ? cll.items[itemIndex].items[subItemIndex] 
        : cll.items[itemIndex] 

        if (subItemIndex >=0 ) {
            data.itemNumber = item.itemNumber
            // p.subItem = true
        }
            
        // data.bigDetails = item.bigDetails
        data.catalog = item.catalog
        data.catalogOrderNr = item.catalogOrderNr
        data.catalogLevel1 = item.catalogLevel1
        data.catalogLevel2 = item.catalogLevel2
        data.catalogLevel3 = item.catalogLevel3

        data.subDescription = item.subDescription
        data.description = item.description
    
        data.itemText = item.itemText
        data.sharedItem = item.sharedItem
        data.fields = item.fields
        data.url = item.url
    }


    if (data && p.subItem && itemIndex >=0 ) {
        data.itemNumber = cll.items[itemIndex].itemNumber
    }

    const fieldValues = (key: string):string[] => {
        return Array.from( new Set(cll.items
            .map((item: any) => _get(item, key))
            .filter((item: any) => item) )
        )
    }
    const complectValues = ():string[] => {
        return cll.complect.map(c => c.name)        
    }

    const valuesMap = new Map()
    valuesMap.set('_description', fieldValues('description'))
    valuesMap.set('_subDescription', fieldValues('subDescription'))
    valuesMap.set('_source.complect', complectValues())
    valuesMap.set('_source.description', fieldValues('source.description'))
    valuesMap.set('_source.currency', fieldValues('source.currency'))
    valuesMap.set('_source.storage', fieldValues('source.storage'))
    
    cll.fields.forEach(field => {
        if (field.type === I.FIELD_AUTOCOMPLETE) {
            valuesMap.set(field.name,
                Array.from( new Set(cll.items
                .map(item => {
                    const index = item.fields.findIndex(el => el.name === field.name)
                    if (index >= 0) {
                        return (item.fields[index].value)
                    }
                    return null
                })
                .filter((item: any) => item) 
                ))
            )
        }
        if (data && data.fields.findIndex(value => value.name === field.name) < 0 ) {
            data.fields.push({name: field.name, value: '', valueList: []})
        }
    })

    if (data && !data.CollectionItemId && !data.itemNumber) {
        const arr = cll.items
            .map(item => item.itemNumber)
            .sort((a, b) => a > b ? -1: 1)

        const lastNumber = arr.length > 0 ? arr[0] : ''

        if (lastNumber) {
            const lastInt = parseInt(lastNumber)
            if (lastInt) {
                data.itemNumber = ('000' + String(lastInt + 1) ).slice(-3)
            }
        } else {
            data.itemNumber = '001'
        }
    }

    if (data && p.add && p.subItem ) {
        const index = cll.items.findIndex(item => item.CollectionItemId === p.CollectionItemId)

        if (index >=0 ) {
            const arr = cll.items[index].items
                .map(item => item.itemSubNumber)
                .sort((a, b) => a > b ? -1: 1)

            const lastNumber = arr.length > 0 ? arr[0] : ''
            if (lastNumber) {
                const lastInt = parseInt(lastNumber)
                if (lastInt) {
                    data.itemSubNumber = ('00' + String(lastInt + 1) ).slice(-2)
                }
            } else {
                data.itemSubNumber = '01'
            }

        }
    }


    const itemsList = useMemo(() => {
        return cll && cll.listGroup && cll.listDescription
        ? prepareItemsForList(cll.items).map((item: any) => ({
            CollectionItemId: item.CollectionItemId,
            item: item,
            group: listSet(cll, cll.listGroup, item.ownerItem ? item.ownerItem : item),
            number: item.itemNumber + (item.itemSubNumber ?  '-' + item.itemSubNumber: ' '),
            description: listSet(cll, cll.listDescription, item)
        }))
        .sort((a: any, b: any) => -(b.group + b.number + b.description).localeCompare(a.group + a.number + a.description))
        : []
    }, [cll])

    return (
        !data
        ?<div className="cm-empty-list">
            <Loader />
        </div>
        : <Box sx={{
            padding: 0,
            paddingTop: '1.5rem'
        }}>
            <ItemForm 
                data={data}
                collection={cll}
                noImage={cll.type === 'vine'}
                valuesMap={valuesMap}
                add={p.add}
                copy={p.copy}
                subItem={p.subItem || data.itemSubNumber}
                itemsList={itemsList}
            />
        </Box>
    )
}

