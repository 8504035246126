
import React from 'react'
import { locale } from '../../../ui/locale'
import { Box, Stack, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import { gridStyle } from '../../../style/styles';



export default function EditValues(p: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
  
    const handleClose = () => {
        setAnchorEl(null)
    }

    const addItemClick = (item: string) => {
        p.onChangeField(p.field.name, item, '')
        setAnchorEl(null)
    }

    return(
        <div>
            <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
                <h5>{p.field.name}</h5>
                {p.field.list.length <= 4 ? null
                : <div>
                    <Button 
                        size="small"
                        startIcon={<AddIcon />}
                        color="primary"
                        disabled={p.field.list.length <= p.toEdit.valueList.length}
                        onClick={handleClick}
                    >
                        {/* {locale('itemEdit.addField')} */}
                        {locale('common.add')}
                    </Button>
                    <Menu
                        id="add-tag"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                    {p.field.list
                        .filter((filter: string) =>  p.toEdit.valueList.findIndex((value: any) => value.name === filter) < 0)
                        .map((item: any, index: number) => (
                            <MenuItem 
                                key={index} 
                                onClick={event => addItemClick(item)}
                                value={index}
                            >
                                {item}
                            </MenuItem>
                    ))}
                    </Menu>
                </div>
                }
            </Stack>
            {p.field.list.length <=4
            ? <Box sx={gridStyle}>
                {p.field.list.map((item: string, index: number) => (
                <TextField
                    key={index}
                    variant="outlined"
                    sx={{gridColumnStart: 'span ' + 
                    (p.field.list.length===4 ? 3
                        : p.field.list.length===3 ? 4 
                        : p.field.list.length===2 ? 6
                        : 12)
                    }}
                    // className={p.field.list.length===4 ? "cm-edit-width-3" 
                    //         : p.field.list.length===3 ? "cm-edit-width-4" 
                    //         : p.field.list.length===2 ? "cm-edit-width-6" 
                    //         : "cm-edit-width-12" 
                    //     }
                    size="small"
                    label={item}
                    disabled={p.disabled}
                    value= {p.toEdit.valueList.filter((el: any) => el.name === item)[0]
                        ? p.toEdit.valueList.filter((el: any) => el.name === item)[0].value
                        : ''
                    }
                    onChange={event => p.onChangeField(p.field.name, item, event.target.value)}
                />                
                ))}
            </Box>
            : <div>
                {p.toEdit.valueList.length === 0
                ? <Box sx={{
                    color: 'text.disabled',
                    textAlign: 'center',
                    minHeight: '3rem'
                }}>
                    {locale('itemEdit.fieldListEmpty')}
                </Box>
                : <Box sx={{...gridStyle, minHeight: '3rem'}}>
                    {p.toEdit.valueList.map((item: any, index: number) => (
                    <TextField
                        key={index}
                        variant="outlined"
                        sx={{gridColumnStart: 'span 3'}}
                        // className={"cm-edit-width-3"}
                        size="small"
                        label={item.name}
                        disabled={p.disabled}
                        value={item.value || ''}
                        onChange={event => p.onChangeField(p.field.name, item.name, event.target.value)}
                    />                
                    ))}   
                                
                </Box>
                } 
            
            {/* {p.field.list.length > p.toEdit.valueList.length
            ?<>
            <Button 
                size="small"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleClick}
            >
                {locale('itemEdit.addField')}
            </Button>
            <Menu
                id="add-tag"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            {p.field.list
                .filter((filter: string) =>  p.toEdit.valueList.findIndex((value: any) => value.name === filter) < 0)
                .map((item: any, index: number) => (
                    <MenuItem 
                        key={index} 
                        onClick={event => addItemClick(item)}
                        value={index}
                    >
                        {item}
                    </MenuItem>
            ))}
            </Menu>
            </>
            : null
            } */}
            </div>
            }

        </div>
    )
}