import React from 'react'
import {useDispatch} from 'react-redux'
import * as I from '../../store/interfaces'
import clsx from 'clsx'
import ViewGrid from './view-controls/ViewGrid'
import ViewLabel from './view-controls/ViewLabel'
import ViewTitle from './view-controls/ViewTitle'
import { ViewVineURL } from './view-controls/ViewVineURL'
import ViewQty from './view-controls/ViewQty'
import { ViewSource } from './view-controls/ViewSource'
import * as panelActions from '../../store/panel/panelActions'

const catalogName = (catalog: any, catalogLevel1: string|null, catalogLevel2: string|null, catalogLevel3: string|null) => {
    const index1 = catalog.findIndex((cat: any) => cat._id === catalogLevel1)
    if (!catalogLevel2 || index1 < 0) return index1 >=0 ? catalog[index1].description : null

    const index2 = catalog[index1].items.findIndex((cat: any) => cat._id === catalogLevel2)
    if (!catalogLevel3 || index2 < 0) return index2 >=0 ? catalog[index1].items[index2].description : null

    const index3 = catalog[index1].items[index2].items.findIndex((cat: any) => cat._id === catalogLevel3)
    return index3 >=0 ? catalog[index1].items[index2].items[index3].description : null
}

const Catalog = (p: any) => {
    if (p.prevCatalogLevel1 === p.catalogLevel1 && p.prevCatalogLevel2 === p.catalogLevel2 && p.prevCatalogLevel3 === p.catalogLevel3){
        return null
    }
    return (
        <div className="wine-catalog-title">
        {p.prevCatalogLevel1 !== p.catalogLevel1 && p.catalogLevel1
        ? <div className="level-1" key={1}>{catalogName(p.cll.catalog, p.catalogLevel1, null, null)}</div>
        : null
        }
        {p.prevCatalogLevel2 !== p.catalogLevel2 && p.catalogLevel2
        ? <div className="level-2" key={2}>{catalogName(p.cll.catalog, p.catalogLevel1, p.catalogLevel2, null)}</div>
        : null
        }
        {p.prevCatalogLevel3 !== p.catalogLevel3 && p.catalogLevel3
        ? <div className="level-3" key={3}>{catalogName(p.cll.catalog, p.catalogLevel1, p.catalogLevel2, p.catalogLevel3)}</div>
        : null
        }
        </div>
    )
}

export const ViewVineList: React.FC<any> = (p) => {
    const dispatch = useDispatch()

    // const titleIndex=p.cll.view.findIndex((el: any) => el.setName === 'title')
    // const labelIndex=p.cll.view.findIndex((el: any) => el.setName === 'label')
    // const gridIndex=p.cll.view.findIndex((el: any) => el.setName === 'grid')

    const onEditItemVineClick = (CollectionItemId: string) => {
        dispatch(panelActions.editCollectionItem(p.cll.CollectionId, CollectionItemId, ''))
    }

    return (
        <div className="frame-wine">
        {    
            p.viewItems.map((item: I.CollectionItem, index: number) => {
            const prevItem = index > 0 ? p.viewItems[index - 1] : null
            return (    
                <React.Fragment key={index}>
                    <Catalog
                        cll={p.cll}
                        catalogLevel1={item.catalogLevel1}
                        catalogLevel2={item.catalogLevel2}
                        catalogLevel3={item.catalogLevel3}
                        prevCatalogLevel1={prevItem ? prevItem.catalogLevel1 : null}
                        prevCatalogLevel2={prevItem ? prevItem.catalogLevel2 : null}
                        prevCatalogLevel3={prevItem ? prevItem.catalogLevel3 : null}
                    />
                    <div className="wine-card" key={index}>
                    
                        <div className={clsx("card-content", {"count-zero": item.qty === 0})}
                        >

                            <ViewTitle
                                set={p.cll.viewSet.titleSet}
                                item={item}
                                collectionFields={p.cll.fields}
                                onEditItemVineClick={onEditItemVineClick}
                                readOnly={!I.isEditAccess(p.cll.role)}
                                // filterString={p.cll.viewProps.filterString}
                            />
                            <ViewLabel
                                set={p.cll.viewSet.labelSet}
                                item={item}
                                collectionFields={p.cll.fields}
                            />
                            <div className="label">
                                {item.yearFrom && item.yearFrom
                                ? <span>{item.yearFrom}-{item.yearTo}</span>
                                : null
                                }
                            </div>
                            <div>
                                <ViewVineURL 
                                    item = {item} 
                                />
                            </div>

                            <ViewQty
                                qty={item.qty}
                                qtyUsed={item.qtyUsed}
                                CollectionItemId={item.CollectionItemId}
                                CollectionId={p.cll.CollectionId}
                                readOnly={!I.isEditAccess(p.cll.role)}
                            />
                            <ViewGrid
                                set={p.cll.viewSet.gridSet}
                                item={item}
                                collectionFields={p.cll.fields}
                                hideLabels={true}
                            />
                            {p.cll.viewProps.viewProvenance
                            ?<ViewSource
                                showInfo={false}
                                showCaption={true}
                                item={item}
                                complect={p.cll.complect}
                            />
                            : null
                            }

                        </div>
                </div>
                </React.Fragment>
            )})}
        </div>
    )    
}