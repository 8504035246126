import { useState, useCallback }  from 'react'
import {useDispatch} from 'react-redux'

import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import * as panelActions from '../../store/panel/panelActions'
import * as collectionActions from '../../store/collection/collectionActions'
import * as listActions from '../../store/list/listActions'
import * as http from '../../modules/http'

import DoneIcon from '@mui/icons-material/Done';
import EditToFind from './edit/editToFind'
import EditField from '../../ui/editors/editField'
import { locale } from '../../ui/locale'
import EditURL from './edit/editURL'
import EditMemo from '../../ui/editors/editMemo'
import EditSource from './edit/editSource'
import EditCatalog from './edit/editCatalog'
import EditFields from './edit/editFields'
import ItemImages from './itemImages'
import EditQty from './edit/editQty'
import EditRecomend from './edit/editRecomend'
import EditDropped from './edit/EditDropped'
import CopyFrom from './edit/CopyFrom'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { Box, IconButton, Tooltip } from '@mui/material'
import { FormError } from '../../modules/form-error'

function initData(toEdit: any) {
    const data = JSON.parse(JSON.stringify(toEdit))
    if (data.source && data.source.complect && data.source.price) {
        const pos = data.source.price.indexOf(' / ')
        if (pos >= 0) {
            data.source.price = data.source.price.substring(pos + 3)
        }
    }
    return data
}

function initCatalogData(toEdit: any, catalog: any) {
    if (!toEdit) return []
    const index1 = catalog.findIndex((item: any) => item._id === toEdit.catalogLevel1)
    const index2 = index1 >= 0 
        ? catalog[index1].items.findIndex((item: any) => item._id === toEdit.catalogLevel2) 
        : -1
    const index3 = index2 >= 0 
        ? catalog[index1].items[index2].items.findIndex((item: any) => item._id === toEdit.catalogLevel3)
        : -1
    return [
        {index: index1, description: index1 >= 0 ? catalog[index1].description : ''},
        {index: index2, description: index2 >= 0 ? catalog[index1].items[index2].description : ''},
        {index: index3, description: index3 >= 0 ? catalog[index1].items[index2].items[index3].description : ''}
    ]
}

export default function ItemForm (p:any) {
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({edited: p.copy ? true: false, loading: false, error: '', uploadError: ''});
    const [toEdit, setToEdit] = useState( initData(p.data) )
    const [catalogData, setCatalogData] = useState(initCatalogData(p.data, p.collection.catalog))
    const [copyFromOpen, setCopyFromOpen] = useState(false)
    const [copySourceFromOpen, setCopySourceFromOpen] = useState(false)

    const setEdited = useCallback(() => setFormState((prev) => ({...prev, edited: true})), []);

    const onChangeField = useCallback((fieldName: string, newValue: string) => {
        let edited = true
        setToEdit((prev: any) => {
            edited = prev[fieldName] !== newValue
            return {...prev, [fieldName]: newValue}
        })
        setFormState((prev) => ({...prev, edited: prev.edited ? true : edited}))
    }, [])

    const onChangeProvenanceField = useCallback((fieldName: string, newValue: string) => {
        let edited = true
        setToEdit((prev: any) => {
            edited = prev.source[fieldName] !== newValue
            return {...prev, source: { ...prev.source, [fieldName]: newValue}}
        })
        setFormState((prev) => ({...prev, edited: prev.edited ? true : edited}))
    }, [])

    const onChangeCustomField = useCallback((fieldName: string, newValue: string) => {
        setFormState((prev) => ({...prev, edited: true}))
        setToEdit((prev: any) => {
            const index = prev.fields.findIndex((el: any) => el.name === fieldName)
            return (
                {
                    ...prev,
                    fields: 
                    [
                        ...prev.fields.slice(0, index),
                        {...prev.fields[index], value: newValue},
                        ...prev.fields.slice(index + 1),
                    ]
                }
            )
        })        
    }, [])

    const onChangeValuesField = useCallback((fieldName: string, itemName: string, newValue: string) => {
        setFormState((prev) => ({...prev, edited: true}))
        setToEdit((prev: any) => {
            const index = prev.fields.findIndex((el: any) => el.name === fieldName)
            const itemIndex = prev.fields[index].valueList.findIndex((el: any) => el.name === itemName)
            return ({
                    ...prev,
                    fields: 
                    [
                        ...prev.fields.slice(0, index),
                        {
                            ...prev.fields[index], 
                            valueList: itemIndex>=0 
                                ?[
                                ...prev.fields[index].valueList.slice(0, itemIndex),
                                {...prev.fields[index].valueList[itemIndex], value: newValue},
                                ...prev.fields[index].valueList.slice(itemIndex + 1),
                                ]
                                :[
                                ...prev.fields[index].valueList,
                                {name: itemName, value: newValue},
                                ]
                        },
                        ...prev.fields.slice(index + 1),
                    ]
            })
        })
    }, [])

    const onDeleteValuesField = useCallback((fieldName: string, itemName: string) => {
        setFormState((prev) => ({...prev, edited: true}))
        setToEdit((prev: any) => {
            const index = prev.fields.findIndex((el: any) => el.name === fieldName)
            const itemIndex = prev.fields[index].valueList.findIndex((el: any) => el.name === itemName)
        
            return ({
                ...prev,
                fields: 
                [
                    ...prev.fields.slice(0, index),
                    {
                        ...prev.fields[index], 
                        valueList:
                            [
                            ...prev.fields[index].valueList.slice(0, itemIndex),
                            ...prev.fields[index].valueList.slice(itemIndex + 1),
                            ]
                    },
                    ...prev.fields.slice(index + 1),
                ]
            })
        })
    }, [])

    function changeURL(event: any, index: number) {
        setFormState({...formState, edited: true})
        const value = event.target.value
        setToEdit((prev: any) => {
            return (
            {
                ...prev,
                url: [
                    ...prev.url.slice(0, index),
                    value,
                    ...prev.url.slice(index + 1),
                ]
            })
        })
    }
    
    function deleteURL(index: number) {
        setFormState({...formState, edited: true})
        setToEdit((prev: any) => {
            return (
            {
                ...prev,
                url: [
                    ...prev.url.slice(0, index),
                    ...prev.url.slice(index + 1),
                ]
            })
        })
    }

    function addUrl() {
        setToEdit((prev: any) => {
            return (
                {...prev, url: [...prev.url, '']}
            )
        })
    }

    function changeToFind() {
        setFormState({...formState, edited: true})
        setToEdit((prev: any) => {
            return (
                {...prev, itemToFind: !prev.itemToFind}
            )
        })
    }

    function changeDropped() {
        setFormState({...formState, edited: true})
        setToEdit((prev: any) => {
            return (
                {...prev, itemDropped: !prev.itemDropped}
            )
        })
    }

    async function saveChanges (event:any) {
        if (toEdit.itemToFind) {
            toEdit.itemNumber = ''
            toEdit.itemSubNumber = ''
        }
        setFormState({...formState, loading: true, error: ''})
        const formData = {
            CollectionId: p.collection.CollectionId,
            CollectionItemId: toEdit.CollectionItemId,
            catalog: catalogData.map(catalog => catalog.description),
            itemData: toEdit
        }

        const res = await http.httpPost('/item/save', formData, true)
        if (res.success) {
            setFormState((prev) => ({...prev, edited: false, loading: false, error: ''}))
            dispatch(collectionActions.collectionChanged())
            if (p.collection.type === 'vine') {
                dispatch(panelActions.closeCollectionItemPanel())
            } else if (p.add) {
                if (toEdit.itemSubNumber) {
                    const itemIndex = p.collection.items.findIndex((el: any) => el.itemNumber === toEdit.itemNumber)
                    if (itemIndex >= 0) {
                        // const itemId = p.collection.items[itemIndex].CollectionItemId
                        dispatch(panelActions.closeAddCollectionItem(p.collection.items[itemIndex].CollectionItemId, res.data._id))
                    } else {
                        dispatch(panelActions.closeCollectionItemPanel())
                    }
                } else {
                    dispatch(panelActions.closeAddCollectionItem(res.data._id, ''))
                }
            } else {
                dispatch(panelActions.closeEditCollectionItem())
            }
        } else {
            setFormState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }

    function goBack(event: any){
        dispatch(panelActions.closeEditCollectionItem())
    }

    function goClose(event: any){
        dispatch(panelActions.closeCollectionItemPanel())
    }

    const onAddImage = useCallback((imageId: string, error: any) => {
        if (imageId) {
            setToEdit((prev: any) => {
                return ({...prev, images: [...prev.images, imageId ]} )
            })
            setFormState((prev) => ({...prev, edited: true, uploadError: ''}))
        }
        if (error) {
            setFormState((prev) => ({...prev, uploadError: error}))
        }
    }, [])

    const onDeleteImage = useCallback((index: number) => {
        setToEdit((prev: any) => (
            {
                ...prev,
                images: [
                    ...prev.images.slice(0, index),
                    ...prev.images.slice(index + 1),
                ]
            }
        ))
        setFormState((prev) => ({...prev, edited: true, uploadError: ''}))
    }, [])

    const onMoveImage = useCallback((index: number, isUp: boolean) => {
        setToEdit((prev: any) => (
            {
                ...prev,
                images: isUp
                ?[
                    ...prev.images.slice(0, index - 1), 
                    prev.images[index], 
                    prev.images[index - 1], 
                    ...prev.images.slice(index + 1)]
                :[
                    ...prev.images.slice(0, index), 
                    prev.images[index + 1], 
                    prev.images[index], 
                    ...prev.images.slice(index + 2)]
            }        
        ))
        setFormState((prev) => ({...prev, edited: true, uploadError: ''}))
    }, [])

    const setOneImageOnCover = useCallback(() => {
        setToEdit((prev: any) => ({...prev, oneImageOnCover: !prev.oneImageOnCover} ))
        setFormState((prev) => ({...prev, edited: true, uploadError: ''}))
    }, [])

    const deleteClick = async () => {
        await http.httpPost(
            '/item/delete', 
            {CollectionId: p.collection.CollectionId, CollectionItemId: toEdit.CollectionItemId}, 
            true
        )
        dispatch(collectionActions.collectionChanged())
        dispatch(listActions.collectionListChanged())
        dispatch(panelActions.closeCollectionItemPanel())
    }
    
    const onCopyFields = (item: any) => {
        if (item) {
            setToEdit((prev: any) => {
                const newToEdit = {...prev, 
                    catalog: item.catalog,
                    catalogOrderNr: item.catalogOrderNr,
                    catalogLevel1: item.catalogLevel1,
                    catalogLevel2: item.catalogLevel2,
                    catalogLevel3: item.catalogLevel3,
                    subDescription: item.subDescription,
                    description: item.description,
                    itemText: item.itemText,
                    fields: item.fields,
                    url: item.url,
                }
                setCatalogData(initCatalogData(newToEdit, p.collection.catalog))
                return newToEdit
            })
            setCopyFromOpen(false)
        }
    }

    const onCopySourceFields = (item: any) => {
        if (item) {
            setToEdit((prev: any) => {
                const newToEdit = {...prev, 
                    source: item.source
                }
                setCatalogData(initCatalogData(newToEdit, p.collection.catalog))
                return newToEdit
            })
            setCopyFromOpen(false)
        }
    }


//     onCopyFields={onCopyFields}
//     closeCopyFrom={closeCopyFrom}
//     options={p.itemsList}
// />
// : <Button 
//     size="medium"
//     onClick={event => setCopyFromOpen(true)}
// closeSourceCopyFrom
//onCopySourceFields

    const closeCopyFrom = () => setCopyFromOpen(false);    
    const closeSourceCopyFrom = () => setCopySourceFromOpen(false);    

    if (!toEdit.itemToFind && !p.subItem && !toEdit.source) {
        setToEdit((prev: any) => ({
            ...prev,
            source: {}
        }))
    }
    // console.log(data.source);
 


    // console.log(toEdit)
    return (
        !toEdit
        ? null
        :<Box sx={{display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'}}>
            <Box sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: 0,
                gridGap: 0,
                width: '100%',
                maxWidth: p.collection.type === 'vine' ? '40rem' : undefined
            }}>
                <Button 
                    sx={{justifySelf: 'start'}}
                    id="back" 
                    size="medium"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={p.noImage || !toEdit.CollectionItemId ? goClose : goBack}
                >
                    {locale('common.close')}
                </Button>
            {/* </span> */}

            <Box sx={{justifySelf: 'end'}}>
                <Button 
                    id="save" 
                    size="medium"
                    variant={formState.loading || !formState.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={saveChanges}
                    type="submit"
                    startIcon={<DoneIcon />}                    
                    disabled={formState.loading || !formState.edited}
                >
                    {locale('common.save')}
                </Button>
            </Box>
        </Box>
        <Box sx={{
                gridTemplateColumns: p.collection.type === 'vine' ? 'auto' : '1fr 1fr',
                display: 'grid',
                gap: '2rem',
                gridGap: '2rem',
                marginTop: '1.5rem',
                position: 'relative',
                maxWidth: p.collection.type === 'vine' ? '40rem' : undefined,
                width: '100%'
            }}>
        {p.noImage
        ? null
        : <Box sx={{gridColumnStart: {sm: 'span 2', md: 'unset'}}}>
            <ItemImages
                images={toEdit.images}
                oneImageOnCover={toEdit.oneImageOnCover}
                backgroundColor={p.collection.backgroundColor}
                collection={p.collection}
                CollectionItemId={toEdit.CollectionItemId}
                setEdited={setEdited}
                disabled={formState.loading}
                onAddImage={onAddImage}
                onDeleteImage={onDeleteImage}
                uploadError={formState.uploadError}
                onMoveImage={onMoveImage}
                setOneImageOnCover={setOneImageOnCover}
            /> 
        </Box>
        }
        <Box sx={{gridColumnStart: {sm: 'span 2', md: 'unset'}}}>
            <FormError message={locale(formState.error)} />

            {p.noImage
            ? null
            : <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'space-between'
            }}>
                <EditToFind
                    itemToFind={toEdit.itemToFind}
                    onChangeToFind={changeToFind}
                    disabled={formState.loading}
                    
                />
                {toEdit.itemToFind
                ? null
                : <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                    <EditField
                        key="itemNumber"
                        freeSolo={true}
                        sx={{width: '6rem'}}
                        value={toEdit.itemNumber || ''}
                        fieldName="itemNumber"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('itemEdit.itemNumber')}
                        disabled={formState.loading}
                    />
                    {p.collection.useChildItems  && (p.subItem || p.copy)
                    ?<EditField
                        freeSolo={true}
                        sx={{width: '6rem'}}
                        value={toEdit.itemSubNumber || ''}
                        fieldName="itemSubNumber"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('itemEdit.itemSubNumber')}
                        disabled={formState.loading}
                    />
                    : null
                    }
                    </Box>
                }

            </Box>
            }
            <Box sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    gap: 2,
                    position: 'relative',
                    height: '38px'
                }}>
                    <h5>{locale('itemEdit.title')}</h5>

                    {copyFromOpen
                    ? <CopyFrom
                        onCopyFields={onCopyFields}
                        closeCopyFrom={closeCopyFrom}
                        options={p.itemsList}
                        all
                    />
                    : 
                    <Tooltip title={locale('itemEdit.copyFromOpen')}>
                        <IconButton 
                            sx={{marginTop:1}}
                            size="small"
                            onClick={event => setCopyFromOpen(true)}
                        >
                            <FileCopyOutlinedIcon />
                        </IconButton>  
                    </Tooltip>
                    }
                </Box>
            <EditField
                freeSolo={true}
                value={toEdit.description}
                fieldName="description"
                onChangeField={onChangeField}
                list={p.valuesMap.get('_description')}
                label={locale('itemEdit.description')}
                disabled={formState.loading}
            />
            <EditField
                freeSolo={true}
                value={toEdit.subDescription || ''}
                fieldName="subDescription"
                onChangeField={onChangeField}
                list={p.valuesMap.get('_subDescription')}
                label={locale('itemEdit.subDescription')}
                disabled={formState.loading}
            />
            <EditCatalog
                collection={p.collection}
                catalogData={catalogData}
                setCatalogData={setCatalogData}
                setEdited={setEdited}
                loading={formState.loading}
            />

            <EditFields
                collection={p.collection}
                setEdited={setEdited}
                loading={formState.loading}
                toEditFields={toEdit.fields}
                form={p.collection.form}
                valuesMap={p.valuesMap}
                onChangeField={onChangeCustomField}
                onChangeValuesField={onChangeValuesField}
                onDeleteValuesField={onDeleteValuesField}
            />

            <EditURL
                url={toEdit.url}
                onChangeUrl={changeURL}
                onDeleteURL={deleteURL}
                onAddUrl={addUrl}
                disabled={formState.loading}
            />
            <h5>{locale('itemEdit.itemText')}</h5>
            <EditMemo
                key="itemText"
                margin="dense"
                value={toEdit.itemText ? toEdit.itemText : ''}
                fieldName="itemText"
                onChangeField={onChangeField}
                // label={locale('itemEdit.itemText')}
                disabled={formState.loading}
                helperText={<>Допускается синтаксис <a href="/markdown" target="_blank">markdown</a></> }
            />
            {p.collection.useQuantity
            ? <EditQty
                toEdit={toEdit}
                onChangeField={onChangeField}
                collection={p.collection}
                disabled={formState.loading}
            />
            : null
            }
            {p.collection.type === 'vine'
            ? <EditRecomend
                toEdit={toEdit}
                onChangeField={onChangeField}
                collection={p.collection}
                disabled={formState.loading}
            />
            : null
            }
            {toEdit.itemToFind || p.subItem || !toEdit.source
            ? null
            : <>
                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    gap: 2,
                    position: 'relative',
                    height: '38px'
                }}>
                    <h5>{locale('itemEdit.provenance')}</h5>

                    {copySourceFromOpen
                    ? <CopyFrom
                        onCopyFields={onCopySourceFields}
                        closeCopyFrom={closeSourceCopyFrom}
                        options={p.itemsList}
                    />
                    : 
                    <Tooltip title={locale('itemEdit.copySourceFromOpen')}>
                        <IconButton 
                            sx={{marginTop:1}}
                            size="small"
                            onClick={event => setCopySourceFromOpen(true)}
                        >
                            <FileCopyOutlinedIcon />
                        </IconButton>  
                    </Tooltip>
                    }
                </Box>

                <EditSource
                    toEdit={toEdit}
                    onChangeField={onChangeProvenanceField}
                    collection={p.collection}
                    disabled={formState.loading}
                    valuesMap={p.valuesMap}
                />
            </>
            }
            {toEdit.itemToFind || p.subItem
            ? null
            : <EditDropped
                toEdit={toEdit}
                onChangeField={onChangeField}
                onChangeDropped={changeDropped}
                collection={p.collection}
                disabled={formState.loading}
                valuesMap={p.valuesMap}
            />
            }

        </Box>
        </Box>
        <Box sx={{padding: 4, display: 'flex', justifyContent: 'center'}}>
            <Button 
                id="delete" 
                size="medium"
                color="secondary"
                variant="outlined"
                startIcon={<DeleteOutlineIcon />}
                onClick={event => deleteClick()}
                disabled={formState.loading}
            >
                Удалить предмет
            </Button>
        </Box>
    </Box>
    )
}

