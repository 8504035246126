import Hightlight from './Hightlight'

export default function ViewTitle(p: any) {
    const onClick = () => {
        if (p.onEditItemVineClick && !p.readOnly) {
            p.onEditItemVineClick(p.item.CollectionItemId)
        }
    }
    return(
        p.item.title
        ? 
        // <Box sx={{
        //     display: 'block',
        //     color: 'rgba(0,0,0,.8)',
        //     fontSize: p.subItem ? '1rem' : '1.4rem',
        //     lineHeight: p.subItem ? '1.25rem' : '1.875rem',
        //     textDecoration: 'none',
        //     marginTop: p.subItem ? '0.125rem' : '0.5rem',
        //     cursor: p.onEditItemVineClick && !p.readOnly ? 'pointer' : undefined 
        // }}
        //     onClick={onClick}
        // >
        <div className="title" onClick={onClick}>
            <Hightlight filter={p.filterString} str={p.item.title} />
        </div>
        : null        
    )


}
