import React, {useMemo} from 'react'
import { BACKENDROOT } from '../../index'
import Button from '@mui/material/Button'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { locale } from '../../ui/locale'
import * as http from '../../modules/http'
import { FormError } from '../../modules/form-error';

function ItemImages(p: any) {

    const onImageUpload = async (e: any) => {
        p.setEdited()

        for(var x = 0; x < e.target.files.length; x++) {
            var formData = new FormData();
            formData.append("image", e.target.files[x]);
            formData.append("itemId", p.CollectionItemId) 
            formData.append("CollectionId", p.collection.CollectionId) 

            const res = await http.httpPost('/image/upload', formData, true)
            if (res.success) {
                p.onAddImage(res.data.imageId, null)
            } else {
                p.onAddImage(null, res.data)
            }
    
        }

    }

    const resizeIndex = useMemo(() => p.oneImageOnCover ? 0 : 1, [p.oneImageOnCover])

    return (
        <div>
            <FormError message={locale(p.uploadError)} />
        {/* {p.uploadError ? <p className="form-error">{locale(p.uploadError)}</p> : null} */}


        {/* <form> */}
        {/* <div className="row"> */}
        <FormControl component="fieldset" margin="dense" disabled={p.loading} className="cm-edit-width-12">

            <RadioGroup 
                name="format" 
                row
                value={true} 
                onChange={event => p.setOneImageOnCover()}
            >
                <FormControlLabel 
                    color="primary" 
                    value={p.oneImageOnCover} 
                    control={<Radio color="primary" disabled={p.disabled} />} 
                    label={locale('itemEdit.oneImage')}
                />
                <FormControlLabel 
                    color="primary" 
                    value={!p.oneImageOnCover} 
                    control={<Radio color="primary" disabled={p.disabled}/>} 
                    label={locale('itemEdit.twoImages')}
                />
            </RadioGroup>
        </FormControl>    
        {/* </div> */}
        {/* </form> */}
        <table style={{width: '100%'}}>
            <tbody>
                {p.images.length === 0
                ? <tr>
                    <td>
                        <Box sx={{
                            border: '1px dashed',
                            borderColor: 'divider',
                            margin: '1rem 0',                            
                            color: 'action.active', 
                            minHeight: '5rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {locale('itemEdit.noImages')}
                        </Box>
                    </td>
                </tr>
                : null
                }
                {p.images.map((imageId: string, index: number) => (
                <tr key={index} style={{height: '180px'}}>
                    <td width='180px'>
                        <div> 
                            <img
                                alt=""
                                width='150px'
                                src={resizeIndex >= index
                                    ? BACKENDROOT + '/image/small/' + imageId + 
                                    (index === 0 && p.oneImageOnCover ? '/2' : '/1') +
                                    (p.backgroundColor ? '/' + p.backgroundColor : '')
                                    : BACKENDROOT + '/image/small/' + imageId 
                                }                                
                            />
                        </div>
                    </td>
                    <td>
                    {resizeIndex >= index
                    ? index === 0 && p.oneImageOnCover ? '2 X 1' : '1 X 1' 
                    : null
                    }                    
                    </td>
                    <td>
                    </td>
                    <td>

                        <IconButton 
                            size="small" 
                            title="Переместить вверх" 
                            disabled={index === 0 || p.disabled}
                            onClick={event => p.onMoveImage(index, true)} 
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton 
                            size="small" 
                            title="Переместить вниз" 
                            disabled={index === p.images.length - 1 || p.disabled}
                            onClick={event => p.onMoveImage(index, false)} 
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </td>
                    <td>
                        <IconButton 
                            color="inherit"
                            size="medium"
                            title="Удалить" 
                            onClick={event => p.onDeleteImage(index)}
                            disabled={p.disabled}
                        >
                            <DeleteOutlineIcon /> 
                        </IconButton>
                    </td>

                </tr>
                ))
                }
            </tbody>
        </table>
        <div>
            <span>
                <input
                    accept="image/*"
                    style={{display: 'none'}} 
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onImageUpload}
                />
                <label htmlFor="contained-button-file">
                    <Button 
                        id="add" 
                        size="medium"
                        variant="outlined"
                        component="span"
                        startIcon={<PhotoCameraIcon />} 
                        disabled={p.disabled}
                    >
                        {locale('itemEdit.uploadImage')}
                    </Button>
                </label>
            </span>
        </div>
        </div>
    )
    
}

export default React.memo(ItemImages)
