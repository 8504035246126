import React from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import { SettingsTableHeadCell } from '../../../../modules/settingsTableHeadCell'
import { SettingsTableRow } from '../../../../modules/settingsTableRow'
import { SettingsTableCell } from '../../../../modules/settingsTableCell'

export default function Items(p: any) {

    return(
        p.form.length === 0
        ? null
        :<TableContainer>
        <Table sx={{opacity: p.loading || p.edited ? '0.5' : undefined }}>
            <TableHead>
                <TableRow>
                    <SettingsTableHeadCell>Секции редактирования</SettingsTableHeadCell>
                    <SettingsTableHeadCell></SettingsTableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {p.form
            .filter((form: any) => form.field || form.caption)
            .map((form: any, index: number) => (
            <React.Fragment key={index}>
            <SettingsTableRow 
                key={index} 
                selected={p.editIndex === index }
            >
                <SettingsTableCell 
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)}
                >

                {form.isLabel
                ? <h6>{form.caption}</h6>
                :form.isGroup
                ? <>
                    <small>{form.caption}</small>
                    <div>
                    {/* <strong> */}
                    {form.fields.map((field: any, index: number) => (
                        <React.Fragment key={index}>
                        {field.name}
                        {form.fields.length - 1 === index
                        ? null
                        : ", "
                        } 
                        </React.Fragment>
                    ))
                    }
                    {/* </strong> */}
                    </div>
                </>
                :form.field
                }
            </SettingsTableCell>

            {p.editIndex === index && !p.edited
            ? <SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                width="70px"
                selected={p.editIndex === index}
            >
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={index === 0 || p.edited}
                    onClick={event => p.onMoveField(event, index, false)}
                >
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={index === p.form.length - 1 || p.edited}
                    onClick={event => p.onMoveField(event, index, true)}
                >
                    <ArrowDownwardIcon />
                </IconButton>
            </SettingsTableCell>
            :<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                width="70px"
                selected={p.editIndex === index}
                onClick={() => p.onEditClick(index)}>                            
            </SettingsTableCell> 
            }
            </SettingsTableRow>
            </React.Fragment>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}