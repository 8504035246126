import React, { useState, useEffect, useMemo } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as I from '../../../../store/interfaces'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import { locale } from '../../../../ui/locale'
import Items from './Items'
import AddIcon from '@mui/icons-material/Add'
import Edit from './Edit'
import { Box, Stack } from '@mui/material'
import { twoPanelsStyle } from '../../../../style/styles'

interface list {
    setName: string, 
    fields: {name: string, listName: string | null, prefix: string | null, suffix: string | null}[]
}
const setNames: list[] = [
    {setName: 'listGroup', fields:[]}, 
    {setName: 'listDescription', fields:[]},
]


export default function List(p: any) {
    const dispatch = useDispatch();

    const [toEdit, setToEdit] = useState([
        {setName: 'listGroup', fields: Array.isArray(p.collection.listGroup) ? [...p.collection.listGroup] : []},
        {setName: 'listDescription', fields: Array.isArray(p.collection.listDescription) ? [...p.collection.listDescription] : []},
    ])
    const [pageState, setPageState] = useState({
        ready: false, 
        loading: false, 
        editSetIndex: -1, 
        editIndex: -1, 
        edited: false,
        error: null
    })

    useEffect( () => {
        if (p.collection.view && p.collection.view.length >= setNames.length) {
            setToEdit([
                {setName: 'listGroup', fields: Array.isArray(p.collection.listGroup) ? [...p.collection.listGroup] : []},
                {setName: 'listDescription', fields: Array.isArray(p.collection.listDescription) ? [...p.collection.listDescription] : []},
            ]) 
        } 
    }, [p.collection])

    const collectionFields = useMemo(() => {
        return (
            I.initCollectionFields.concat(p.collection.fields.map((field: any) => ({
                value: field.name, 
                description: field.name,
                list: field.list
            })))     
        )   
    }, [p.collection])

    const CollectionId = p.collection.CollectionId
    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }
    const saveView = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = async (itemData: any) => {
        // console.log(itemData)
        // return
        const formData = {
            CollectionId: CollectionId,
            listGroup: pageState.editSetIndex === 0
                ?[
                    ...toEdit[pageState.editSetIndex].fields.slice(0, pageState.editIndex), 
                    {
                        ...toEdit[pageState.editSetIndex].fields[pageState.editIndex], 
                        ...itemData,
                    },
                    ...toEdit[pageState.editSetIndex].fields.slice(pageState.editIndex + 1), 
                ]
                : toEdit[0].fields,
                listDescription: pageState.editSetIndex === 1
                ?[
                    ...toEdit[pageState.editSetIndex].fields.slice(0, pageState.editIndex), 
                    {
                        ...toEdit[pageState.editSetIndex].fields[pageState.editIndex], 
                        ...itemData,
                    },
                    ...toEdit[pageState.editSetIndex].fields.slice(pageState.editIndex + 1), 
                ]
                : toEdit[1].fields,

        }
// console.log(formData)
// return
        saveView(formData)
    }
    const onEditClick = (editSetIndex: number, editIndex: number) => {
        if (!pageState.edited){
            setPageState({...pageState, editSetIndex: editSetIndex, editIndex: editIndex})
        }
    }
    const addField = (index: number) => {
        setPageState({...pageState, edited: true, editSetIndex: index, editIndex: toEdit[index].fields.length})
        setToEdit([
            ...toEdit.slice(0, index), 
            {
                ...toEdit[index],
                fields: [...toEdit[index].fields, {name: '', prefix: '', suffix: ''}]
            },
            ...toEdit.slice(index + 1)
        ])
    }
    const onDeleteClick = (editSetIndex: number, editIndex: number) => {
        const formData = {
            CollectionId: CollectionId,
            listGroup: editSetIndex === 0
                ? [
                    ...toEdit[editSetIndex].fields.slice(0, editIndex), 
                    ...toEdit[editSetIndex].fields.slice(editIndex + 1), 
                ]
                : toEdit[0].fields,
            listDescription: editSetIndex === 1
                ? [
                    ...toEdit[editSetIndex].fields.slice(0, editIndex), 
                    ...toEdit[editSetIndex].fields.slice(editIndex + 1), 
                ]
                : toEdit[1].fields,
        }
        saveView(formData)
        setPageState(prev => ({...prev, editSetIndex: -1, editIndex: -1}))
    }
    async function onMoveField(setIndex: number, index: number, up: boolean) { 
        const formData = {
            CollectionId: CollectionId,
            listGroup: setIndex === 0
                ? up
                    ?[
                        ...toEdit[setIndex].fields.slice(0, index), 
                        toEdit[setIndex].fields[index + 1],
                        toEdit[setIndex].fields[index],
                        ...toEdit[setIndex].fields.slice(index + 2), 
                    ]
                    :[
                        ...toEdit[setIndex].fields.slice(0, index - 1), 
                        toEdit[setIndex].fields[index],
                        toEdit[setIndex].fields[index - 1],
                        ...toEdit[setIndex].fields.slice(index + 1), 
                    ]
                : toEdit[0].fields,
            listDescription: setIndex === 1
                ? up
                    ?[
                        ...toEdit[setIndex].fields.slice(0, index), 
                        toEdit[setIndex].fields[index + 1],
                        toEdit[setIndex].fields[index],
                        ...toEdit[setIndex].fields.slice(index + 2), 
                    ]
                    :[
                        ...toEdit[setIndex].fields.slice(0, index - 1), 
                        toEdit[setIndex].fields[index],
                        toEdit[setIndex].fields[index - 1],
                        ...toEdit[setIndex].fields.slice(index + 1), 
                    ]
                : toEdit[0].fields,
        }        
        await saveView(formData)
        setPageState((prev) => ({...prev, editIndex: up ? index + 1 : index - 1}))
    }

    const onCloseEdit = () => {
        setPageState(prev => ({...prev, editSetIndex: -1, editIndex: -1}))
    }

    const onCancelClick = () => {
        setToEdit(toEdit.map(set => ({...set, fields: set.fields.filter(field => field.name) })))
        setPageState({...pageState, edited: false})
    }

    return(
        <>
    <Box sx={twoPanelsStyle}>
        <div>
        {/* <LineLoader active={pageState.loading && !pageState.edited} /> */}
        {toEdit.map((viewSet:any, setIndex: number) => (
            <div key={setIndex}>
                <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
                    <h5>
                        {locale('collections.list.types.' + viewSet.setName)}
                    </h5>
                    <Button 
                        id="add" 
                        size="small"
                        // variant="outlined"
                        color="primary"
                        onClick={() => addField(setIndex)}
                        startIcon={<AddIcon />}
                        disabled={pageState.loading}
                    >
                        {locale('collections.list.addField') }
                    </Button>
                </Stack>

                <Items
                    collectionFields={collectionFields}
                    fields={viewSet.fields}
                    setIndex={setIndex}
                    edited={pageState.edited}
                    toEditListIndex={pageState.editSetIndex === setIndex ? pageState.editIndex : -1}
                    addField={addField}
                    onEditClick={onEditClick}
                    onMoveField={onMoveField}
                    onCloseEdit={onCloseEdit}
                />
            </div>
        ))}
        </div>  
            <Edit 
                view={pageState.editSetIndex >=0 && pageState.editIndex >=0
                    ? toEdit[pageState.editSetIndex].fields[pageState.editIndex]
                    : null
                }
                showFramed={pageState.editSetIndex >=0 && pageState.editIndex >=0
                    ? toEdit[pageState.editSetIndex].setName === 'label'
                    : false
                }                
                editSetIndex={pageState.editSetIndex}
                editIndex={pageState.editIndex}
                collectionFields={collectionFields}
                setEdited={setEdited}
                onSaveClick={onSaveClick}
                edited={pageState.edited}
                loading={pageState.loading}
                onDeleteClick={onDeleteClick}
                onCancelClick={onCancelClick}
            />
    </Box>
    </>
    )
}