import Hightlight from './Hightlight'


export default function ViewCatalog(p: any) {
    return(
        p.item.title
        ? 
        // <Box sx={{
        //     display: 'block',
        //     fontSize: '.875rem',
        //     lineHeight: '1.25rem',
        //     fontWeight: 600,
        //     color: '#bf8040',
        //     width: '90%'
        // }}>
        <div className="catalog">
            {/* <span> */}
            < Hightlight filter={p.filterString} str={p.item.catalog} />
            {/* </span> */}
        </div>
        : null        
    )
}
