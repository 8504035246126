import { fieldCaptionAndValue } from '../../../store/interfaces'

export default function ViewGrid(p: any) {
    return(
        <div className="grid">
            {
            p.set.map( (field:any, index: number) => {
            const value = fieldCaptionAndValue(p.item, p.collectionFields, field)                
            return (
                value[0]
                ?<dl key={index}>
                    {p.hideLabels ? null : 
                        <dt>
                            {value[1]}
                        </dt> 
                    }
                    <dd>
                        {field.prefix ? field.prefix : ''} {value[0]}{field.suffix ? ' ' + field.suffix : ''}
                    </dd>
                </dl>
                : null
            )})
            
            }
        </div>
    )

}