import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const LeftDrawer = (props: any) => {
const Component = props.component;
return (
    <Drawer
        open={props.open}
        onClose={props.onClose}
    >
        <AppBar position='sticky' sx={{
            color: 'text.primary',
            backgroundColor: 'background.default',
            boxShadow: `
                        0px 2px 4px -1px rgb(0 0 0 / 12%), 
                        0px 1px 64px 0px rgb(0 0 0 / 12%)`,
        }}>
            <Toolbar> 
            {props.caption}
            <Box
                sx={{color: 'action.active', fontSize: '1rem', marginLeft: 2}}
            >
                {props.subCaption}
            </Box>
            <div className="grow" />
            <IconButton 
                color="inherit"
                size="medium"
                onClick={props.onClose}
            >
                <ArrowBackIosNewIcon />
            </IconButton>
            </Toolbar>
        </AppBar>
        <Box
            sx={{
                margin: 2,
                // width: '100%',                
                width: '300px',                
                maxWidth: '300px',
            }}
        >   
            <Component />
        </Box>
    </Drawer>

)
}
