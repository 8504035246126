import clsx from 'clsx';
import { fieldValue, fieldValueList } from '../../../store/interfaces'


export default function ViewLabel(p: any) {

    return(
        <div className="label-box"> 
        {
        p.set.map( (field:any, index: number) => {
            if (field.listName === null || field.listName !== '-') {
                const value = fieldValue(p.item, p.collectionFields, field)
                return (value 
                    ? <div key={index} className="label-group"> 
                        <div className={clsx("label", {"framed": field.framed }) }  >
                            {field.prefix ? field.prefix : ''} {value}{field.suffix ? ' ' + field.suffix : ''}
                        </div>
                    </div> 
                    : null
                )
            } else {
                return (
                    <div key={index} className="label-group"> 
                        {fieldValueList(p.item, p.collectionFields, field)
                        .map((value: string, index: number) => (
                            <div key={index} className={clsx("label", {"framed": field.framed }) }  >
                                {field.prefix ? field.prefix : ''} {value}{field.suffix ? ' ' + field.suffix : ''}
                            </div>
                        ))
                        }
                    </div>
                )
            }
        })
        }
        </div>
    )
}