import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function ViewItemText(p: any) {

    return(
        p.item.itemText
        ? <div className="item-text">
            {/* <h5>Описание</h5> */}
            <ReactMarkdown
                // allowDangerousHtml
                // plugins={[gfm]}
                remarkPlugins={[remarkGfm]}
                children={p.item.itemText.replace(/<br>/gi, String.fromCharCode(10))}
            />
        </div>
        : null
    )
}