import { Box } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import EditDropDownText from '../../../ui/editors/editDropDownText'
import { locale } from '../../../ui/locale'
import { gridStyle } from '../../../style/styles'


// export default function EditCatalog(p: any) {
function EditCatalog(p: any) {


    const onChangeCatalogField = useCallback((fieldName: string, newValue: string) => {
        p.setEdited()
        if (fieldName === 'catalog1') {
            if (p.catalogData[0].description === newValue) return
            p.setCatalogData([
                {index: p.collection.catalog.findIndex((item: any) => item.description === newValue), 
                description: newValue},
                {index: -1, description: ''},
                {index: -1, description: ''},
                ])
        }
        if (fieldName === 'catalog2') {
            if (p.catalogData[1].description === newValue) return
            p.setCatalogData([
                p.catalogData[0], 
                {index: p.catalogData[0].index >=0
                    ? p.collection.catalog[p.catalogData[0].index].items.findIndex((item: any) => item.description === newValue)
                    : -1, 
                description: newValue},
                {index: -1, description: ''},
                ])
        }
        if (fieldName === 'catalog3') {
            if (p.catalogData[2].description === newValue) return
            p.setCatalogData([
                p.catalogData[0], 
                p.catalogData[1], 
                {index: p.catalogData[1].index >=0
                    ? p.collection
                    .catalog[p.catalogData[0].index]
                    .items[p.catalogData[1].index]
                    .items.findIndex((item: any) => item.description === newValue)
                    : -1, 
                description: newValue},
                ])
        }

    }, [p])

    const span = useMemo(() => p.collection.catalogLevels === 3 
        ? 4
        : p.collection.catalogLevels === 2
        ? 6
        : 12
    , [p.collection.catalogLevels])

    return(
        
        <Box sx={gridStyle}>
            <EditDropDownText
                // className={catWidth}
                span={span}
                value={p.catalogData[0].description}
                fieldName="catalog1"
                onChangeField={onChangeCatalogField}
                options={p.collection.catalog.map((item: any) => ({value: item._id, description: item.description}))}
                label={p.collection.catalogLevels > 1 ? locale('itemEdit.catalog1') : p.collection.catalogName}
                disabled={p.loading}
            />
            {p.collection.catalogLevels > 1
            ? <EditDropDownText
                // className={catWidth}
                span={span}
                value={p.catalogData[1].description}
                fieldName="catalog2"
                onChangeField={onChangeCatalogField}
                options={p.catalogData[0].index >= 0
                    ? p.collection.catalog[p.catalogData[0].index].items.map((item: any) => ({value: item._id, description: item.description}))
                    : []}
                label={locale('itemEdit.catalog2')}
                disabled={p.loading}
            />
            : null
            }
            {p.collection.catalogLevels > 2
            ? <EditDropDownText
                // className={catWidth}
                span={span}
                value={p.catalogData[2].description}
                fieldName="catalog3"
                onChangeField={onChangeCatalogField}
                options={p.catalogData[0].index >= 0 && p.catalogData[1].index >= 0
                    ? p.collection
                        .catalog[p.catalogData[0].index]
                        .items[p.catalogData[1].index].items
                        .map((item: any) => ({value: item._id, description: item.description}))
                    : []}
                label={locale('itemEdit.catalog3')}
                disabled={p.loading}
            />
            : null
            }

        </Box>
    )
}
export default React.memo(EditCatalog)