import * as I from '../interfaces'
import { prepareData, prepareItems } from './prepareData'
import {onChangeCatalog, setCatalogProps, sortCatalog, setCatalogItems} from './prepareCatalog'
import { createFilters, onChangeFilter, setFiltersProps } from './prepareFilters'
import { reorderCollection } from './reorderCollection'
import { prepareFilterString } from './prepareFilterString'
import { LOGIN_SUCCESS } from '../auth/authReducer'

export const LOAD_COLLECTION_START = 'CLL/LOAD_COLLECTION_START'
export const LOAD_COLLECTION_FAIL = 'CLL/LOAD_COLLECTION_FAIL'
export const LOAD_COLLECTION_SUCCESS = 'CLL/LOAD_COLLECTION_SUCCESS'
export const COLLECTION_CHANGED = 'CLL/COLLECTION_CHANGED'
export const COLLECTION_SET = 'CLL/COLLECTION_SET'
export const COLLECTION_CHANGE_PROPS = 'CLL/COLLECTION_CHANGE_PROPS'
export const COLLECTION_CHANGE_IMAGE = 'CLL/COLLECTION_CHANGE_IMAGE'
export const COLLECTION_CLEAR_DATA = 'CLL/COLLECTION_CLEAR_DATA'

export const VIEWPROPS_CHANGE_VIEW = 'CLL/VIEWPROPS_CHANGE_VIEW'
export const VIEWPROPS_CHANGE_SORT = 'CLL/VIEWPROPS_CHANGE_SORT'
export const VIEWPROPS_CHANGE_START_ITEM = 'CLL/VIEWPROPS_CHANGE_START_ITEM'
export const VIEWPROPS_CHANGE_ITEMSONPAGE = 'CLL/VIEWPROPS_CHANGE_ITEMSONPAGE'

export const VIEWPROPS_CHANGE_FILTER_STR = 'CLL/VIEWPROPS_CHANGE_FILTER_STR'
export const VIEWPROPS_CHANGE = 'CLL/VIEWPROPS_CHANGE'

export const CATALOG_CHECK = 'CLL/CATALOG_CHECK'
export const CATALOG_OPEN = 'CLL/CATALOG_OPEN'
export const FILTER_OPEN = 'CLL/FILTER_OPEN'
export const FILTER_CHECK = 'CLL/FILTER_CHECK'
export const SHARED_CHANGE = 'CLL/SHARED_CHANGE'
export const RELOAD_FILTERS = 'CLL/RELOAD_FILTERS'
export const UPDATE_QTY = 'CLL/UPDATE_QTY'

const initialStateCollection: I.Collections = {
    loading: false,
    valid: false,
    notFound: false,
    CollectionId: null,
    collection: null,
    collections: [],
}

function initViewProps(CollectionId: string): I.ViewProps {
    const loaded = sessionStorage.getItem('cll.' + CollectionId)
    let loadedData: any = null

    if (loaded) {
        try { loadedData = JSON.parse(loaded) } catch (e) { loadedData = null }
    }

    return ({
        catalogOpen: loadedData && loadedData.catalogOpen ? loadedData.catalogOpen : false,
        filterString: loadedData && loadedData.filterString ? loadedData.filterString : '',
        filterStringItems: loadedData && loadedData.filterStringItems ? loadedData.filterStringItems : [],
        startItem: loadedData && loadedData.startItem ? loadedData.startItem : 0,
        itemsOnPage: loadedData && loadedData.itemsOnPage ? loadedData.itemsOnPage : I.minItemsPerPage,
        view: loadedData && loadedData.view ? loadedData.view : 0,
        sorting: loadedData && loadedData.sorting ? loadedData.sorting : 0,
        sortingReverce: loadedData && loadedData.sortingReverce ? loadedData.sortingReverce : false,
        viewProvenance: loadedData && loadedData.viewProvenance ? loadedData.viewProvenance : false,
        viewToFind: loadedData && loadedData.viewToFind ? loadedData.viewToFind : false,
        viewOnlyToFind: loadedData && loadedData.viewOnlyToFind ? loadedData.viewOnlyToFind : false,
        viewNoImages: loadedData && loadedData.viewNoImages ? loadedData.viewNoImages : false,
        viewZeroItems: loadedData && loadedData.viewZeroItems ? loadedData.viewZeroItems : false,
        viewDropped: loadedData && loadedData.viewDropped ? loadedData.viewDropped : false,
        viewOnlyDropped: loadedData && loadedData.viewOnlyDropped ? loadedData.viewOnlyDropped : false,
        catalogItems: loadedData && loadedData.catalogItems ? loadedData.catalogItems : [],
        filterItems: loadedData && loadedData.filterItems ? loadedData.filterItems : [],
        catalogCheckedList: loadedData && loadedData.catalogCheckedList ? loadedData.catalogCheckedList : [],
        ignoreChildItems: loadedData && loadedData.ignoreChildItems ? loadedData.ignoreChildItems : false
    })
}

export const collection = (state = initialStateCollection, action: any) => {

    switch (action.type){
        case LOAD_COLLECTION_START:{
            return {...state, loading: true, notFound: false }
        }
        case LOAD_COLLECTION_FAIL:{
            return {
                ...state, 
                loading: false, 
                valid: true, 
                CollectionId: action.request ? action.request.CollectionId : null,
                notFound: true
            }
        }
        case LOAD_COLLECTION_SUCCESS:{
            try {
                if (state.collection && state.CollectionId === action.data.CollectionId) {
                    action.data.viewProps = state.collection.viewProps
                    // action.data.viewProps.filterItems = onChangeFilter(action.data.filterGroups, -1, -1)
                    // action.data.viewProps.catalogCheck = onChangeCatalog(action.data.catalog, '', '').catalogItems
                } else {
                    action.data.viewProps = initViewProps(action.data.CollectionId)
                }
                sortCatalog(action.data)
                
                
                // createFilters(action.data)
                prepareData(action.data)
                reorderCollection(action.data, action.data.viewProps.sorting, action.data.viewProps.sortingReverce)

                createFilters(action.data)
                setCatalogItems(action.data)

                if (state.collection && state.CollectionId === action.data.CollectionId) {
                    setCatalogProps(state.collection.catalog, action.data.catalog)
                    setFiltersProps (state.collection.filterGroups, action.data.filterGroups) 
                    action.data.viewProps.filterItems = onChangeFilter(action.data.filterGroups, -1, -1)
                    action.data.viewProps.catalogItems = onChangeCatalog(action.data.catalog, '', '').catalogItems

                } else {
                    const loaded = sessionStorage.getItem('cll.' + action.data.CollectionId)
                    let loadedData: any = null
                
                    if (loaded) {
                        try { loadedData = JSON.parse(loaded) } catch (e) { loadedData = null }
                    }
                    if (loadedData) {
                        setCatalogProps(loadedData.catalog, action.data.catalog)
                        setFiltersProps(loadedData.filterGroups, action.data.filterGroups) 
                    }
                }

            } catch(err) {
                console.error(err)
            }
            const index = state.collections.findIndex( el => el.CollectionId === (state.collection ? state.collection.CollectionId : -1))
            return {
                ...state,
                loading: false, 
                valid: true, 
                notFound: false,
                CollectionId: action.data ? action.data.CollectionId : state.CollectionId,
                collection: action.data ? action.data : state.collection,
                collections: index >= 0
                    ?[
                    ...state.collections.slice(0, index),
                    state.collection && state.collection.CollectionId === action.data.CollectionId 
                        ? action.data 
                        : state.collection,
                    ...state.collections.slice(index + 1),
                    ]
                    : state.collection
                        ?[
                            ...state.collections,
                            state.collection
                        ]
                        : state.collections
            }
        }
        case COLLECTION_SET:{
            const newIndex = state.collections.findIndex( el => el.CollectionId === action.CollectionId)
            const curIndex = state.collections.findIndex( el => el.CollectionId === (state.collection ? state.collection.CollectionId : -1))
            if (state.collection) {
                return {
                    ...state,
                    loading: false, 
                    valid: true, 
                    CollectionId: state.collections[newIndex].CollectionId,
                    collection: state.collections[newIndex],
                    collections: curIndex >= 0 
                        ?[
                        ...state.collections.slice(0, curIndex),
                        state.collection,
                        ...state.collections.slice(curIndex + 1),
                        ]
                        :[
                        ...state.collections,
                        state.collection,
                        ]    
                }
            } else {
                return {
                    ...state, 
                    loading: true,  
                    valid: true, 
                    CollectionId: state.collections[newIndex].CollectionId,
                    collection: state.collections[newIndex]}
            }        
        }
        case COLLECTION_CHANGED:{
            return {
                ...state,
                valid: false
            }
        }
        case COLLECTION_CHANGE_PROPS:{
            return {
                ...state, 
                valid: false,
                collection: {...state.collection, ...action.props}
            }
        }
        case COLLECTION_CHANGE_IMAGE:{
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    imageId: action.imageId, 
                }
            }
        }
        case VIEWPROPS_CHANGE_VIEW:{
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {...state.collection?.viewProps, view: action.view}
                }
            }
        }
        case VIEWPROPS_CHANGE_SORT:{   
            if (state.collection) {
                reorderCollection(state.collection, action.sorting, action.sortingReverce)
            }
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {
                        ...state.collection?.viewProps, 
                        sorting: action.sorting, 
                        sortingReverce: action.sortingReverce
                    }
                }
            }
        }
        case VIEWPROPS_CHANGE_START_ITEM:{
            if (state.collection) {
                const startItem = state.collection.viewProps.startItem + state.collection.viewProps.itemsOnPage * action.pageSign
            
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        viewProps: {...state.collection?.viewProps, startItem: startItem}
                    }
                }
            }
            return state
        }
        case VIEWPROPS_CHANGE_ITEMSONPAGE:{
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {
                        ...state.collection?.viewProps, 
                        itemsOnPage: action.itemsOnPage,
                        startItem: 0
                    }
                }
            }
        }
        case VIEWPROPS_CHANGE_FILTER_STR:{
            if (state.collection) {
                prepareFilterString(state.collection, action.filterString)
            }
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {
                        ...state.collection?.viewProps, 
                        filterString: action.filterString,
                        startItem: 0
                    }
                }
            }
        }
        case VIEWPROPS_CHANGE:{
            // let catalogItems: string[] | null = null
            let catalogCheck: any
            let filterItems: string[] | null = null

            if (state.collection && (
                "viewToFind" in action.viewProps ||
                "viewOnlyToFind" in action.viewProps ||
                "viewNoImages" in action.viewProps ||
                "viewZeroItems" in action.viewProps ||
                "viewDropped" in action.viewProps ||
                "viewOnlyDropped" in action.viewProps 
            )) {
    
                prepareItems(state.collection, {...state.collection.viewProps, ...action.viewProps})
                reorderCollection(state.collection, state.collection.viewProps.sorting, state.collection.viewProps.sortingReverce)
                createFilters(state.collection)
                setCatalogItems(state.collection)
                catalogCheck = onChangeCatalog(state.collection.catalog, '', '')
                filterItems = onChangeFilter(state.collection.filterGroups, -1, -1)
            }
            if (state.collection && "filterString" in action.viewProps) {
                prepareFilterString(state.collection, action.viewProps.filterString)
            }

            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {
                        ...state.collection?.viewProps, 
                        ...action.viewProps,
                        catalogItems: catalogCheck && catalogCheck.catalogItems
                            ? catalogCheck.catalogItems 
                            : state.collection ? state.collection.viewProps.catalogItems : [],
                        catalogCheckedList: catalogCheck && catalogCheck.catalogCheckedList
                            ? catalogCheck.catalogCheckedList 
                            : state.collection ? state.collection.viewProps.catalogCheckedList : [],
                        filterItems: filterItems 
                            ? filterItems 
                            : state.collection ? state.collection.viewProps.filterItems : [],

                    }
                }
            }
        }
        case CATALOG_OPEN:{
            if (state.collection) {
                const catalog = state.collection.catalog
                const {catalogItems, catalogCheckedList} = onChangeCatalog(catalog, action.id, '')
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        catalog: catalog,
                        viewProps: {
                            ...state.collection?.viewProps, 
                            startItem: catalogItems.length > 0 ? 0 : state.collection?.viewProps.startItem,
                            catalogCheckedList: catalogCheckedList,
                            catalogItems: catalogItems
                        }
                    }
                }
            }  
            return state
        }
        case CATALOG_CHECK:{
            if (state.collection) {
                const catalog = state.collection.catalog
                const {catalogItems, catalogCheckedList} = onChangeCatalog(catalog, '', action.id)
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        catalog: catalog,
                        viewProps: {
                            ...state.collection?.viewProps,                             
                            startItem: 0,
                            catalogItems: catalogItems,
                            catalogCheckedList: catalogCheckedList,
                        }
                    }
                }
            } 
            return state
        }
        case FILTER_OPEN:{
            if (state.collection) {
                const filterItems: string[] = onChangeFilter(state.collection.filterGroups, action.index, -1)
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        viewProps: {
                            ...state.collection?.viewProps, 
                            startItem: filterItems.length > 0 ? 0 : state.collection?.viewProps.startItem,
                            filterItems: filterItems
                        }
                    }
                }
            } 
            return state
        }
        case FILTER_CHECK:{
            if (state.collection) {
                const filterItems: string[] = onChangeFilter(state.collection.filterGroups, action.groupIndex, action.index)
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        viewProps: {
                            ...state.collection?.viewProps, 
                            startItem: 0,
                            filterItems: filterItems
                        }
                    }
                }
            } 
            return state
        }
        case SHARED_CHANGE:{
            if (state.collection) {
                const index = state.collection.items.findIndex(item => item.CollectionItemId === action.CollectionItemId)
                const preparedIndex = state.collection.preparedItems.findIndex(item => item.CollectionItemId === action.CollectionItemId)
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        items: [
                            ...state.collection.items.slice(0, index),
                            {...state.collection.items[index], sharedItem: action.shared},
                            ...state.collection.items.slice(index+1),
                        ],
                        preparedItems: [
                            ...state.collection.preparedItems.slice(0, preparedIndex),
                            {...state.collection.preparedItems[preparedIndex], sharedItem: action.shared},
                            ...state.collection.preparedItems.slice(preparedIndex+1),
                        ]
                    }
                }
            } else {
                return state
            }
        }
        case RELOAD_FILTERS:{
            let catalogCheck: any
            let filterItems: string[] | null = null

            if (state.collection) {
                prepareItems(state.collection, {...state.collection.viewProps, ...action.viewProps})
                reorderCollection(state.collection, state.collection.viewProps.sorting, state.collection.viewProps.sortingReverce)                
                createFilters(state.collection)
                setCatalogItems(state.collection)
                catalogCheck = onChangeCatalog(state.collection.catalog, '', '')

                filterItems = onChangeFilter(state.collection.filterGroups, -1, -1)
            }
            return {
                ...state, 
                collection: {
                    ...state.collection,
                    viewProps: {
                        ...state.collection?.viewProps, 
                        catalogItems: catalogCheck && catalogCheck.catalogItems 
                            ? catalogCheck.catalogItems 
                            : state.collection ? state.collection.viewProps.catalogItems : [],
                        catalogCheckedList: catalogCheck && catalogCheck.catalogCheckedList
                            ? catalogCheck.catalogCheckedList 
                            : state.collection ? state.collection.viewProps.catalogCheckedList : [],
                        filterItems: filterItems 
                            ? filterItems 
                            : state.collection ? state.collection.viewProps.filterItems : [],

                    }
                }
            }
        }
        case COLLECTION_CLEAR_DATA:{
            return initialStateCollection
        }
        case LOGIN_SUCCESS: {
            return initialStateCollection
        }
        case UPDATE_QTY:{
            if (state.collection) {

                const index = state.collection.items.findIndex(item => item.CollectionItemId === action.CollectionItemId)
                const preparedIndex = state.collection.preparedItems.findIndex(item => item.CollectionItemId === action.CollectionItemId)
                return {
                    ...state, 
                    collection: {
                        ...state.collection,
                        items: [
                            ...state.collection.items.slice(0, index),
                            {...state.collection.items[index], qty: action.qty},
                            ...state.collection.items.slice(index+1),
                        ],
                        preparedItems: [
                            ...state.collection.preparedItems.slice(0, preparedIndex),
                            {...state.collection.preparedItems[preparedIndex], qty: action.qty},
                            ...state.collection.preparedItems.slice(preparedIndex+1),
                        ]
                    }
                }
            } else {
                return state
            }
        }
        default: return state
    }
}