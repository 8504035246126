import React, {useCallback, useEffect, useMemo} from 'react'
import {useSelector } from 'react-redux'
import {useDispatch} from 'react-redux'
import { Loader } from '../../modules/Loader'
import * as appActions from '../../store/app/appActions'
import * as listActions from '../../store/list/listActions'
import HeaderMy from './HeaderMy'
import { Button, Grid } from '@mui/material'
import { CardOwn } from './CardOwn'
import { CardOther } from './CardOther'
import HeaderOther from './HeaderOther'
import { EmptyList } from './emptyList'
import { RootState } from '../../store/rootReducer'
import { useNavigate } from 'react-router-dom'
import useDebounce from '../../hooks/useDebounce'


export const List:React.FC = (props: any) => {
    const dispatch = useDispatch();
    // filterString
    const {mobileMode, auth, filterString, loading, items}  = useSelector( (state: RootState) => ({
        mobileMode: state.app.mobileMode,
        auth: state.auth.auth,
        filterString: state.list.filterString,
        loading: state.list.loading,
        items: state.list.items,
    }) )
    const defferSearchString = useDebounce(filterString, 600);

    const navigate = useNavigate()

    useEffect( () => {
        dispatch(appActions.drawerPageOff())
        dispatch<any>(listActions.loadCollectionList())
    }) 

    const onCardClick = useCallback((CollectionId: string) => {
        navigate('/collection/' + CollectionId)
    }, [navigate]);

    const onChangeFilterString = useCallback((filterString: string) => {
        dispatch(listActions.changeFilterStr(filterString))
    }, [dispatch]);

    const otherList = useMemo(() => items.filter(item => 
        item.role !== 'owner' &&
        (
        !defferSearchString ||
            (
                item.name.toUpperCase().indexOf(defferSearchString.toUpperCase()) >=0 
                ||
                item.details.toUpperCase().indexOf(defferSearchString.toUpperCase()) >=0
            )
        )
    ), [items, defferSearchString]);



    return (
        loading && items.length === 0
        ?<Loader />
        : <>
            {auth
            ?<>
            {/* <Button onClick={onClick}>SSS</Button> */}
            <HeaderMy mobileMode={mobileMode} />
            {items.filter((collection: any) => collection.role === 'owner').length === 0
            ? <EmptyList auth/>
            :<Grid container spacing={4} className="list-own">
                {items
                .filter((collection: any) => collection.role === 'owner')
                .map((collection: any, index: number) => (
                    <CardOwn key={index} collection={collection} onCardClick={onCardClick}/>
                ))
                }  
            </Grid>
            }
            </>
            : null
        }
            <HeaderOther 
                mobileMode={mobileMode} 
                onChangeFilterString={onChangeFilterString} 
                filterString={filterString} 
            />
            {otherList.length === 0
            ? <EmptyList />
            :<Grid container spacing={3} className="list-others">
                {otherList
                // .filter((collection: any) => collection.role !== 'owner')
                .map((collection: any, index: number) => (
                    <CardOther 
                        key={index} 
                        collection={collection} 
                        onCardClick={onCardClick}
                        filterString={defferSearchString} 
                    />
                ))
                }                  
            </Grid>
            }        
    </> 
    )
}