import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';

import { Box, Button } from '@mui/material';
import { locale } from '../../ui/locale'
import * as http from '../../modules/http'
import AuthForgotSuccess from './authForgotSuccess';


export default function AuthForgot(p: any) {
    // const auth = useSelector( (state: any) => state.auth )

    const [formState, setFormState] = useState({ready: false, loading: false, error: null});
    const [email, setEmail] = useState('');


    async function onForgotClick(event: any) {
        event.preventDefault()
        setFormState((prev) =>  ({...prev, touched: true}))
        if (email) {
            setFormState((prev) =>  ({...prev, loading: true, error: null}))

            const res = await http.httpPost('/auth/forgot', {email: email}, false)
    
            if (res.success) {
                setFormState((prev) =>  ({...prev, loading: false, ready: true, error: null}))
            } else {
                setFormState((prev) => ({...prev, loading: false, error: res.data.message}))
            }
        }
    }

    return (
        formState.ready
        ? <AuthForgotSuccess email={email}/>
        :<form noValidate onSubmit={(event) => onForgotClick(event)}>
            <p>
                {locale('auth.resetPasswordText1')}
            </p>
            <p>
                {locale('auth.resetPasswordText2')}
            </p>
            <TextField
                disabled={formState.loading}
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                id="email"
                label={locale('auth.email')}
                name="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                autoComplete="email" 
                autoFocus                   
            />        
            

            <Button
                type="submit"
                sx={{marginTop: 2}}

                // sx={{width: '10rem'}}
                fullWidth
                disabled={formState.loading || !email}
                variant="contained"
                color="primary"
                onClick={onForgotClick}
            >
                {locale('auth.forgotPasswordButton')}
            </Button>
            <Box sx={{marginTop: 4, textAlign: 'center'}}>
                <Link
                    sx={{marginTop: 2}}
                    onClick={p.loginClick}
                    variant='body1'
                    component="button"
                >
                    {locale('common.cancel')}
                </Link>
            </Box>
        </form>
    )
}
