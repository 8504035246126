import React from 'react'
import * as I from '../../../store/interfaces'


export default function CollectionList(p: any) {


    return (
        <div>
            {p.list.map((item: I.Collection, index: number) => (
                <p>{item.name}</p>
            ))
            }
        </div>
    )
}
