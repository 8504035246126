import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';
import EditField from '../../../../ui/editors/editField'
import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CloseIcon from '@mui/icons-material/Close'
import EditMemo from '../../../../ui/editors/editMemo';
import { Box } from '@mui/material';
import { bottomToolbar, gridStyle, topToolbar } from '../../../../style/styles';
import EditFrame from '../editFrame';


export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState( p.complect )

    useEffect( () => {
        setToEdit(p.complect) 
    }, [p.complect])

    function onChangeField(fieldName: string, newValue: any) {
        if (newValue === toEdit[fieldName]) return
        setToEdit((prev: any) => ({...prev, [fieldName]: newValue}))
        p.setEdited()
    }

    const onCancelClick = () => {
        p.onCancelClick()
        setToEdit(p.complect) 
    }

    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
            <Box sx={{position: 'sticky', top: '5rem'}}>
                <Box sx={topToolbar}>
                    <Button 
                        id="save" 
                        size="medium"
                        variant="outlined"
                        color="primary"
                        onClick={event => onCancelClick()}
                        startIcon={<CloseIcon />}                    
                        disabled={p.loading || !p.edited || !toEdit }
                    >
                        {locale('common.cancel')}
                    </Button>
                    <Button 
                        id="save" 
                        size="medium"
                        variant={p.loading || !p.edited ? "outlined" : "contained"}
                        color="primary"
                        onClick={event => p.onSaveClick(toEdit)}
                        startIcon={<DoneIcon />}                    
                        disabled={p.loading || !p.edited || !toEdit ||!toEdit.name }
                    >
                        {locale('common.save')}
                    </Button>
                </Box>
            {!toEdit
            ? null
            : <Box sx={{backgroundColor: 'action.hover', padding: 2}}>
                <EditField
                    key="name"
                    className="cm-edit-width-12"
                    freeSolo={true}
                    value={toEdit.name ? toEdit.name : ''}
                    fieldName="name"
                    disabled={!!toEdit._id}
                    onChangeField={onChangeField}
                    list={[]}
                    label={locale('collections.complect.name')}
                />
                <Box sx={gridStyle}>
                    <EditField
                        key="description"
                        span={6}
                        // className="cm-edit-width-6"
                        freeSolo={true}
                        value={toEdit.description ? toEdit.description : ''}
                        fieldName="description"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.complect.description')}
                    />
                    <EditField
                        key="details"
                        span={6}
                        // className="cm-edit-width-6"
                        freeSolo={true}
                        value={toEdit.details ? toEdit.details : ''}
                        fieldName="details"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.complect.details')}
                    />

                </Box>
                <Box sx={gridStyle}>
                    <EditField
                        key="date"
                        span={6}
                        // className="cm-edit-width-6"
                        freeSolo={true}
                        value={toEdit.date ? toEdit.date : ''}
                        fieldName="date"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.complect.date')}
                    />
                    <EditField
                        key="currency"
                        span={2}
                        // className="cm-edit-width-2"
                        freeSolo={true}
                        value={toEdit.currency ? toEdit.currency : ''}
                        fieldName="currency"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.complect.currency')}
                    />
                    <EditField
                        key="price"
                        span={4}
                        // className="cm-edit-width-4"
                        freeSolo={true}
                        value={toEdit.price ? toEdit.price : ''}
                        fieldName="price"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.complect.price')}
                    />
                </Box>
                <EditMemo
                    key="info"
                    value={toEdit.info ? toEdit.info : ''}
                    fieldName="info"
                    onChangeField={onChangeField}
                    label={locale('collections.complect.info')}
                />                    


                <Box sx={bottomToolbar}>
                    <Button 
                        id="delete" 
                        size="medium"
                        color="secondary"
                        variant="outlined"
                        startIcon={<DeleteOutlineIcon />}
                        onClick={event => p.onDeleteClick()}
                        disabled={p.loading}
                    >
                        {locale('collections.complect.delete')}
                    </Button>
                </Box>
            </Box>
            }
        </Box> 
        </Box> 
    )
}