import  { useEffect } from 'react';
import { useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import { AppBar, Box, Container, Dialog, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import * as authActions from '../store/auth/authActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { closeCollectionItemPanel } from '../store/panel/panelActions';
import { locale } from '../ui/locale';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { RootState } from '../store/rootReducer';
import Auth from './auth/auth';
import * as appActions from '../store/app/appActions';
import CloudOffIcon from '@mui/icons-material/CloudOff';

export function Navbar(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // const [loginOpen, setLoginOpen] = useState(false);

    // const auth = useSelector((state: any) => state.auth )
    // const app = useSelector((state: any) => state.app )
    const {auth, itemPanel, mobileMode, loginOpen, offlineMode} = useSelector( (state: RootState) => ({
        auth: state.auth,
        itemPanel: state.itemPanel,
        mobileMode: state.app.mobileMode,
        loginOpen: state.app.loginOpen,
        offlineMode: state.app.offlineMode
    }) )

    useEffect(() => {
        if (auth.auth) {
            dispatch(appActions.loginOpenOff())
            // setLoginOpen(false)
        }
    }, [dispatch, auth.auth])

    function onLogoutClick(event: any) {
        // setAnchorEl(null)
        navigate('/')
        dispatch(closeCollectionItemPanel())
        dispatch(authActions.logout())   
    }
    function onSettingsClick(event: any) {
        dispatch(closeCollectionItemPanel())
        // setAnchorEl(null)
        navigate('/settings')
    }

    const onHomeClick = (event: any) => {
        dispatch(closeCollectionItemPanel())
        navigate('/')
    }
    const onCloseView = (event: any) => {
        dispatch(closeCollectionItemPanel())
    }

    const onAdminClick = (event: any) => {
        dispatch(closeCollectionItemPanel());
        // setAnchorEl(null)
        navigate('/admin');
    }

    const onLoginClick = () => {
        dispatch(closeCollectionItemPanel());
        dispatch(appActions.loginOpenOn())
        // setLoginOpen(true);
    }
    // console.log(loginOpen);

return (
    <>
    
    <AppBar position='sticky' sx={{
                color: 'text.primary',
                backgroundColor: 'background.default',
                boxShadow: `
                            0px 2px 4px -1px rgb(0 0 0 / 12%), 
                            0px 1px 64px 0px rgb(0 0 0 / 12%)`,
    }}>
        <Container maxWidth='xl'> 
        <Toolbar disableGutters> 
            {itemPanel.show && mobileMode
        ? <Box onClick={onCloseView} sx={{display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer'}}> 
                <CloseIcon />
                {locale('common.close')}
            </Box>
            : 
            <Box 
                onClick={onHomeClick}
                sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: 'min-content',
                    gridGap: '.5rem',
                    fontFamily: 'MuseoSansBold',
                    fontSize: '1.25rem',
                    lineHeight: '2.5rem',
                    letterSpacing: '-0.04em',
                    color: 'rgb(0 0 0 / 60%)'
                }}
            >
                <img alt="" src="/images/cm-icon.svg" />
                {locale(mobileMode ? 'app.titleSmall' : 'app.title')}
            </Box>
            }
            <div className="grow" />
            <span>
                {offlineMode
                ? <CloudOffIcon />
                : null
                }
                {!auth.auth
                ? <Button onClick={onLoginClick}>{locale('auth.signIn')}</Button>
                :<Box sx={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridGap: '1rem',
                    alignItems: 'center'
                }}>
                    <Typography variant='subtitle1'>{auth.userName}</Typography>
                    <span>
                        {auth.roles.includes('admin') && !mobileMode
                        ?<IconButton size='small' onClick={onAdminClick}>
                            <AdminPanelSettingsIcon fontSize="small" />
                        </IconButton>
                        : null
                        }
                        <IconButton size='small' onClick={onSettingsClick}>
                            <SettingsIcon fontSize="small" />
                        </IconButton>
                        <IconButton size='small' onClick={onLogoutClick}>
                            <LogoutIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Box>
                }
            </span>            
        </Toolbar>
        </Container>
    </AppBar>
    {mobileMode
    ? <Dialog
        fullScreen
        open={loginOpen}
        onClose={() => dispatch(appActions.loginOpenOff())}
    >
        <Auth close={() => dispatch(appActions.loginOpenOff())}/>
    </Dialog>
    : <Drawer
        anchor='right'
        open={loginOpen}
        onClose={() => dispatch(appActions.loginOpenOff())}
    >
        <Auth close={() => dispatch(appActions.loginOpenOff())}/>
    </Drawer>
    }
    </>    
)
}

export default Navbar
