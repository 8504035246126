import { TableCell } from '@mui/material'
import React from 'react'

export const SettingsTableCell = (p: any) => {
    return (
        <TableCell onClick={p.onClick} width={p.width} sx={{
            padding: 1, 
            paddingLeft: 2,
            backgroundColor: p.selected ? 'action.hover' : undefined,
            ...p.sx,
        }}>
            {p.children}
        </TableCell>
    )
}