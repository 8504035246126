import React, {useState} from 'react';
import { locale } from '../../ui/locale'
import { Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as http from '../../modules/http'

export default function Feedback(p: any) {

    const [formState, setFormState] = useState({ready: false, loading: false, error: null})
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const sendClick = async () => {
        if (email && message) {
            setFormState((prev) =>  ({...prev, loading: true, error: null}))

            const res = await http.httpPost('/feedback/send', {email: email, message: message}, true)
    
            if (res.success) {
                setFormState((prev) =>  ({...prev, loading: false, ready: true, error: null}))
            } else {
                setFormState((prev) => ({...prev, loading: false, error: res.data.message}))
            }
        }
    }
    return (
        <>
            <Box sx={{
                padding: 6, 
                paddingTop: 6, 
                maxWidth:  '40rem', 
                marginLeft: 'auto', 
                marginRight: 'auto'
            }}>
            
            {formState.ready
            ? <div>
                <h4>
                    {locale('feedback.title')}
                </h4>
                <p>&nbsp;</p>
                <p>{locale('feedback.sent')}</p>
            </div>
            :<div>
                <form noValidate onSubmit={(event) => sendClick()} >
                <h4>
                    {locale('feedback.title')}
                </h4>
                <p>
                    {locale('feedback.text')}
                </p>
                {formState.error ? <p className="form-error">{locale(formState.error)}</p> : null}
                <TextField
                    disabled={formState.loading}
                    autoFocus={true}
                    variant="outlined"
                    required
                    size="small"
                    fullWidth
                    margin="normal"
                    name="email"
                    label={locale('feedback.email')}
                    type="text"
                    id="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    autoComplete="email"
                />   
                <TextField 
                    label={locale('feedback.message')}
                    name="message"
                    disabled={formState.loading}
                    multiline
                    rows={5}
                    size="small"
                    fullWidth
                    required
                    margin="normal"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    variant="outlined" 
                />
                <Box sx={{marginTop: 2, display:'flex', justifyContent: 'center'}}>
                    <Button
                        type="submit"
                        sx={{minWidth: '15rem'}}
                        disabled={formState.loading || email === '' || message === '' }
                        className="btn primary"
                        variant="contained"
                        // fullWidth={false}
                        color="primary"
                        onClick={sendClick}
                    >
                        {locale('common.send')}
                    </Button>
                </Box>
            </form>
            </div>
            }
        </Box>
        </>
    )
}