import { Link } from 'react-router-dom';
import { locale } from '../ui/locale';
import { Container } from '@mui/material'

export const Footer = () => {
    return (
        <Container maxWidth='xl' sx={{
            paddingTop: 2, 
            paddingBottom: 2, 
            display: 'flex', 
            justifyContent: 'space-between'
        }}>
            <div>
                {'Copyright © CollectionExpo'}
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </div>
            <div>
                <Link to="/feedback">{locale('feedback.title')}</Link>
            </div>
        </Container>
    )
} 
