import React, { useState, useEffect }  from 'react'
import {useSelector} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import Button from '@mui/material/Button'
import { locale } from '../../../ui/locale'
import * as http from '../../../modules/http'
import Image from 'material-ui-image'
import { BACKENDROOT } from '../../../index'
import Chip from '@mui/material/Chip'
import Menu from './Menu'
import { LineLoader } from '../../../modules/line-loader'
import { useNavigate } from 'react-router-dom'


export default function Collections(p: any) {
    const auth = useSelector( (state: any) => state.auth )
    const navigate = useNavigate();

    const [pageState, setPageState] = useState({loaded: false, loading: false, error: null});
    const [list, setList] = useState([])

    if (auth.auth) {
        if (!auth.roles.includes('admin')) {
            navigate('/')
        }
    }

    useEffect( () => {
        if (!pageState.loaded) {
            (async function anyNameFunction() {
                setPageState(prev => ({...prev, loading: true}))
                const res = await http.httpGet('/admin/collections', {}, true)
                if (res.success) {
                    setList(res.data)
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                } else {
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                }    
            })()
        }    
    }, [pageState.loaded])

    const createCollection = () => {
        navigate('/create')
    }

    const reloadList = () => {
        setPageState({...pageState, loaded: false})
    }

    const setLoading = (loading: boolean) => {
        setPageState({...pageState, loading: loading})
    }

    return (
        !auth.auth || !auth.roles.includes('admin')
        ? <Loader />
        : <>
        <div className="cm-content">
        <div className="cm-toolbar">
            <span>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    onClick={event => createCollection()}
                    color="primary"
                    type="submit"
                >
                    {locale('create.create')}
                </Button>
            </span>
        </div>
        
        <div style={{maxWidth:'60rem'}}>
        <LineLoader active={pageState.loading} />
        <table className="cm-table cll">
            <tbody>
            {list.map((cll: any, index: number) => (
            <tr key={index}>
                <td width='150px'>
                    <div className="cover w3h2" >  
                            {cll.imageId
                            ?<Image
                                aspectRatio={3/2}
                                src={BACKENDROOT + '/image/cover/' + (cll.imageId ? cll.imageId : '')} 
                            />
                            :null
                            }
                        </div>

                </td>
                <td width='50%'>
                    <p className="description">{cll.type}</p>
                    <h4>{cll.name}</h4>
                    <p className="description">{cll.details}</p>
                </td>
                <td style={{textAlign:'center'}}>
                <Chip label={cll.owner ? cll.owner : 'N/A'} variant="outlined" color="primary" size="small" />
                </td>
                <td>
                    {cll.itemsQty.total}
                </td>
                <td style={{textAlign:'center'}}>
                    <Menu
                        CollectionId={cll.CollectionId}
                        reloadList={reloadList}
                        loading={pageState.loading}
                        setLoading={setLoading}
                    />
                </td>
            </tr>
            ))}
            </tbody>
        </table>        
        </div>
        {/* </div> */}

        </div>
        </>
    )
}
