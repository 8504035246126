import { Box } from '@mui/material'
import React from 'react'
import { gridStyle } from '../../../style/styles'
import EditField from '../../../ui/editors/editField'
import { locale } from '../../../ui/locale'

export default function EditQty(p: any) {

    return(
        <div>
            <h5>{locale('itemEdit.qtyTitle')}</h5>
            <Box sx={gridStyle}>
                <EditField
                    disabled={p.loading}
                    span={3}
                    freeSolo={true}
                    value={p.toEdit.qty ? String(p.toEdit.qty) : ''}
                    fieldName="qty"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.qty')}
                />
                <EditField
                    disabled={p.loading}
                    span={3}
                    freeSolo={true}
                    value={p.toEdit.qtyUsed ? String(p.toEdit.qtyUsed) : ''}
                    fieldName="qtyUsed"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.qtyUsed')}
                />
            </Box>             
        </div>

    )
}