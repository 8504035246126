import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import * as http from '../../../../modules/http'
import * as listActions from '../../../../store/list/listActions'
import * as collectionActions from '../../../../store/collection/collectionActions'
import { locale } from '../../../../ui/locale'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import EditDropDown from '../../../../ui/editors/editDropDown'
import { LineLoader } from '../../../../modules/line-loader'
import { Box } from '@mui/material'
import { FormError } from '../../../../modules/form-error'
import { IOSSwitch } from '../../../../modules/ios-switch'
import { twoPanelsStyle } from '../../../../style/styles'
import Cover from '../cover/Cover'

const fields = (collection: any) => {
    return {
        CollectionId: collection.CollectionId,
        name: collection.name,
        type: collection.type,
        catalogName: collection.catalogName ? collection.catalogName : '',
        details: collection.details ? collection.details : '',
        catalogLevels: collection.catalogLevels,
        shared: collection.shared,
        noPhotoFilter: collection.noPhotoFilter,
        useChildItems: collection.useChildItems,
        backgroundColor: collection.backgroundColor
    }
}

export default function Props(p: any) {
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({edited: false, loading: false, error: null});
    const [toEdit, setToEdit] = useState( fields(p.collection)  )

    useEffect( () => {
        setToEdit(fields(p.collection))
        setFormState(prev => ({...prev, edited: false, error: null}))
    }, [p.collection] ) 

    function onChangeField(fieldName: string, value: string) {
        setToEdit({...toEdit, [fieldName]: value})
        setFormState({...formState, edited: true})
    }

    function onChangeShared(event:any) {
        const shared = event.target.value === "1" ? true : false
        setToEdit({...toEdit, shared: shared})
        setFormState({...formState, edited: true})
    }

    function onChangeCataloLevels(event: any) {
        setToEdit({...toEdit, catalogLevels: parseInt(event.target.value)})
        setFormState({...formState, edited: true})
    }

    function onChangeNoPhotoFilter(value: boolean) {
        setToEdit({...toEdit, noPhotoFilter: value})
        setFormState({...formState, edited: true})
    }

    function onChangeUseChildItems(value: boolean) {
        setToEdit({...toEdit, useChildItems: value})
        setFormState({...formState, edited: true})
    }

    async function saveChanges (event:any) {
        event.preventDefault()
        setFormState({edited: true, loading: true, error: null})
        const res = await http.httpPost('/cll/save', toEdit, true)
        if (res.success) {
            setFormState({edited: false, loading: false, error: null})
            dispatch(listActions.collectionListChanged())
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setFormState({edited: true, loading: false, error: res.data})
        }
    }

    const onCancelClick = () => {
        setFormState({...formState, edited: false})
        setToEdit(fields(p.collection))
    }

    return(
    <Box sx={twoPanelsStyle}>
        <Cover collection={p.collection}/>
        <div>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 2
            }}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={formState.loading || !formState.edited }
                >
                    {locale('common.cancel')}
                </Button>
                <Button 
                    id="save" 
                    size="medium"
                    variant={formState.loading || !formState.edited ? "outlined" : "contained"}
                    onClick={saveChanges}
                    color="primary"
                    type="submit"
                    startIcon={<DoneIcon />}
                    disabled={formState.loading || !formState.edited}
                >
                    {locale('common.save')}
                </Button>
            </Box>
            <LineLoader active={formState.loading} />
            <form noValidate onSubmit={(event) => saveChanges(event)} >

                <FormError message={formState.error} />
                <TextField
                    disabled={formState.loading}
                    variant="outlined"
                    size={'small'}  
                    margin="dense" 
                    required
                    fullWidth
                    name="name"
                    label={locale('collections.props.name')}
                    id="name"
                    value={toEdit.name}
                    onChange={event => onChangeField(event.target.name, event.target.value)}
                />  
                <TextField
                    disabled={formState.loading}
                    variant="outlined"
                    size={'small'}  
                    fullWidth
                    margin="dense" 
                    multiline
                    rows={2}
                    name="details"
                    label={locale('collections.props.details')} 
                    id="details"
                    value={toEdit.details}
                    onChange={event => onChangeField(event.target.name, event.target.value)}
                />  

                <TextField
                    disabled={formState.loading}
                    variant="outlined"
                    size={'small'}  
                    fullWidth
                    margin="dense" 
                    required
                    name="catalogName"
                    label={locale('collections.props.catalogName')}
                    id="catalogName"
                    value={toEdit.catalogName}
                    onChange={event => onChangeField(event.target.name, event.target.value)}
                />  

                <FormControl fullWidth component="fieldset" margin="dense" disabled={formState.loading} >
                    <FormLabel component="legend">
                        {locale('collections.props.catalogLevels')}
                    </FormLabel>
                    <RadioGroup 
                        row
                        value={toEdit.catalogLevels}
                        onChange={onChangeCataloLevels}
                    >
                        <FormControlLabel 
                            color="primary" 
                            value={1}
                            control={<Radio color="primary"/>} 
                            label={locale('common.one')}
                        />
                        <FormControlLabel 
                            color="primary" 
                            value={2}
                            control={<Radio color="primary"/>} 
                            label={locale('common.two')}
                        />
                        <FormControlLabel 
                            color="primary" 
                            value={3}
                            control={<Radio color="primary"/>} 
                            label={locale('common.three')}
                        />
                    </RadioGroup>
                </FormControl>
                <EditDropDown
                    value={toEdit.backgroundColor || ''}
                    fieldName={"backgroundColor"}
                    onChangeField={onChangeField}
                    label={locale('collections.props.backgroundColor')}
                    disabled={p.loading}
                    options={[{value: 'white', description: 'белый'}, {value: 'black', description: 'черный'}]}
                />
                <FormControlLabel
                    sx={{padding: '0.25rem 1rem', gap: '.5rem'}}
                    control={
                        <IOSSwitch
                            checked={toEdit.noPhotoFilter}
                            value={toEdit.noPhotoFilter}
                            onChange={event => onChangeNoPhotoFilter(!toEdit.noPhotoFilter)}
                            size="small"
                            name="noPhoto"
                            color="primary"
                        />
                    }
                    labelPlacement="end"
                    label={locale('collections.props.noPhotoFilter')}
                /> 
                <FormControlLabel
                    sx={{padding: '0.25rem 1rem', gap: '.5rem'}}
                    control={
                        <IOSSwitch
                            checked={toEdit.useChildItems}
                            value={toEdit.useChildItems}
                            onChange={event => onChangeUseChildItems(!toEdit.useChildItems)}
                            size="small"
                            name="children"
                            color="primary"
                        />
                    }
                    labelPlacement="end"
                    label={locale('collections.props.useChildItems')}
                /> 
                
        
                {/* <h5>Публичный доступ</h5> */}
                <FormControl component="fieldset" margin="normal" disabled={formState.loading} >
                    <FormLabel component="legend">{locale('collections.props.publicAccess')}</FormLabel>
                    <RadioGroup 
                        row
                        value={toEdit.shared ? 1 : 0}
                        onChange={onChangeShared}
                    >
                    <FormControlLabel 
                        color="primary" 
                        value={0}
                        control={<Radio color="primary"/>} 
                        label={locale('collections.props.publicAccessClose')}
                    />
                    <FormControlLabel 
                        color="primary" 
                        value={1}
                        control={<Radio color="primary"/>} 
                        label={locale('collections.props.publicAccessOpen')}
                    />
                    </RadioGroup>
                </FormControl>
                </form>
        </div>
    </Box>
    )
}