
import { 
    LOAD_COLLECTION_START, LOAD_COLLECTION_FAIL, LOAD_COLLECTION_SUCCESS, COLLECTION_CHANGED, 
    COLLECTION_CLEAR_DATA,
    COLLECTION_SET, COLLECTION_CHANGE_PROPS, COLLECTION_CHANGE_IMAGE, 
    VIEWPROPS_CHANGE_VIEW, VIEWPROPS_CHANGE_SORT,
    VIEWPROPS_CHANGE_START_ITEM, VIEWPROPS_CHANGE_ITEMSONPAGE,
    VIEWPROPS_CHANGE, 
    VIEWPROPS_CHANGE_FILTER_STR,
    CATALOG_OPEN, CATALOG_CHECK,
    FILTER_OPEN, FILTER_CHECK,
    SHARED_CHANGE, RELOAD_FILTERS,
    UPDATE_QTY,

} from "./collectionReducer"

import * as http from '../../modules/http'

export function updateItemQty(CollectionId: string, CollectionItemId: string, qty: number) {
    console.log(CollectionId, CollectionItemId, qty)
    http.httpPost('/item/qty', {CollectionId: CollectionId, CollectionItemId: CollectionItemId, qty: qty}, true)
    return {
        type: UPDATE_QTY,
        CollectionItemId: CollectionItemId,
        qty: qty
    }
}

export function reloadFilters() {
    return {
        type: RELOAD_FILTERS
    }
}

export function collectionSharedChanged(CollectionItemId: string, shared: boolean) {
    return {
        type: SHARED_CHANGE,
        CollectionItemId: CollectionItemId,
        shared: shared
    }
}

export function filterCheck(groupIndex: number, index: number): any {
    return {
        type: FILTER_CHECK,
        index: index,
        groupIndex: groupIndex
    }
}

export function filterOpen(index: number): any {
    return {
        type: FILTER_OPEN,
        index: index
    }
}

export function catalogOpen(id: string): any {
    return {
        type: CATALOG_OPEN,
        id: id
    }
}

export function catalogCheck(id: string): any {
    return {
        type: CATALOG_CHECK,
        id: id
    }
}

export function changeFlterString(filterString: string): any {
    return {
        type: VIEWPROPS_CHANGE_FILTER_STR,
        filterString: filterString
    }
}

export function changeViewProps(viewProps: any): any {
    return {
        type: VIEWPROPS_CHANGE,
        viewProps: viewProps
    }
}

export function changeItemsOnPage(itemsOnPage: number): any {
    return {
        type: VIEWPROPS_CHANGE_ITEMSONPAGE,
        itemsOnPage: itemsOnPage
    }
}
export function changeStartItem(pageSign: number): any {
    return {
        type: VIEWPROPS_CHANGE_START_ITEM,
        pageSign: pageSign
    }
}
export const changeView = (view: number) => {
    return {
        type: VIEWPROPS_CHANGE_VIEW,
        view: view
    }
}
export const changeSorting = (sorting: number, sortingReverce: boolean) => {
    return {
        type: VIEWPROPS_CHANGE_SORT,
        sorting: sorting,
        sortingReverce: sortingReverce
    }
}

export const collectionChanged = () => {
    return { type: COLLECTION_CHANGED}
}

export const loadCollection = (CollectionId: string) => {
    return function (dispatch: any, getState: any)  {
        if (!CollectionId) return
        if (getState().app.offlineMode) return

        const collection = getState().collection
        if (collection.loading) return
        // if (collection.valid) return
        
        if (CollectionId === collection.CollectionId && collection.valid) return
        const index = collection.collections.findIndex((el: any) => el.CollectionId === CollectionId)
        if (index >=0 && CollectionId !== collection.CollectionId) {
            dispatch(collectionSet(CollectionId))
            return
        }

        http.reduxHttpGet (
            dispatch,
            '/cll/collection',
            {CollectionId: CollectionId},
            true,
            LOAD_COLLECTION_START,
            LOAD_COLLECTION_FAIL,
            LOAD_COLLECTION_SUCCESS
        )
    }
}
function collectionSet(CollectionId: string) {
    return { 
        type: COLLECTION_SET, 
        CollectionId: CollectionId 
    }
}

export const clearData = () => {
    return { 
        type: COLLECTION_CLEAR_DATA
    }
}

export const collectionChangeProps = (props: any) => {
    return { 
        type: COLLECTION_CHANGE_PROPS,
        props: props
    }
}
export const collectionChangeImage = (toEdit: any) => {
    return { 
        type: COLLECTION_CHANGE_IMAGE,
        imageId: toEdit.imageId, 
    }
}
