
export const IconButton = (props: any) => {
    const Icon = props.icon;
    return (        
        <Icon
            onClick={ () => props.onClick()}
            sx={{
                margin: 1,
                color: props.checked ? 'primary.main' : 'text.disabled',
                cursor: props.checked ? undefined : 'pointer',
                '&:hover': {
                    color: props.checked ? 'primary.main' : 'text.secondary'
                },
            }}
        />  

    )
}