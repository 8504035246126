import { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Loader } from '../../modules/Loader'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as http from '../../modules/http'
import AddIcon from '@mui/icons-material/Add'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import * as panelActions from '../../store/panel/panelActions'
import * as listActions from '../../store/list/listActions'
import * as collectionActions from '../../store/collection/collectionActions'

import * as I from '../../store/interfaces'
import ItemGallary from './ItemGallary'
import Button from '@mui/material/Button'
import ViewCatalog from '../collection/view-controls/ViewCatalog'
import ViewTitle from '../collection/view-controls/ViewTitle'
import ViewSubTitle from '../collection/view-controls/ViewSubTitle'
import ViewChip from '../collection/view-controls/ViewChip'
import ViewStatus from '../collection/view-controls/ViewStatus'
import ViewLabel from '../collection/view-controls/ViewLabel'
import ViewGrid from '../collection/view-controls/ViewGrid'
import ViewItemText from '../collection/view-controls/ViewItemText'
import { ViewUrl } from '../collection/view-controls/ViewURL'
import { ViewSource } from '../collection/view-controls/ViewSource'
import { locale } from '../../ui/locale'
import { SubItemsDetail } from './SubItemsDetail'
import Card from '../collection/Card'
// import {Item} from '../pdf/item/Item'
// import { pdf } from '@react-pdf/renderer'

// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box } from '@mui/material'
import { IOSSwitch } from '../../modules/ios-switch'
import { RootState } from '../../store/rootReducer'
import { LineLoader } from '../../modules/line-loader'
import { ToolbarView } from './toolbarView'
import { useNavigate } from 'react-router-dom'
//import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SegmentIcon from '@mui/icons-material/Segment';

export default function ItemView(props: any) {
    const dispatch = useDispatch()
    const {collection, app}  = useSelector( (state: RootState) => ({collection: state.collection, app: state.app}) )
    const navigate = useNavigate();
    const [showProvenance, setShowProvenance] = useState(
        collection.collection 
        ? collection.collection.viewProps.viewProvenance
        : false
    )

    let CollectionId = ''
    let CollectionItemId = ''
    let CollectionSubItemId = ''

    if (props.CollectionId && props.CollectionItemId) {
        CollectionId = props.CollectionId
        CollectionItemId = props.CollectionItemId
        CollectionSubItemId = props.CollectionSubItemId
    } else {
        dispatch(panelActions.closeCollectionItemPanel())
    }

    if (CollectionId === collection.CollectionId && collection.notFound){
        dispatch(panelActions.closeCollectionItemPanel())
        navigate('/404')
    }

    const cll: I.Collection = collection.collection && CollectionId === collection.collection.CollectionId
        ? collection.collection
        : null

    useEffect( () => {
        dispatch<any>(collectionActions.loadCollection(CollectionId ? CollectionId : '' ))
    }) 

    var viewItem: I.CollectionItem | null = null
    var viewSubItem: I.CollectionItem | null = null

    if (cll) {
        
        let viewIndex = cll.items.findIndex(item => item.CollectionItemId === CollectionItemId)
        if (viewIndex >=0 ) {
            viewItem = cll.items[viewIndex]

            if (CollectionSubItemId && viewItem.items) {
                viewIndex = viewItem.items.findIndex(item => item.CollectionItemId === CollectionSubItemId)
                if (viewIndex >= 0) {
                    viewSubItem = viewItem.items[viewIndex]
                }
            }
        }
    }

    if ((!cll || !viewItem) && !collection.loading) {
        dispatch(panelActions.closeCollectionItemPanel())
        navigate('/404')
    }

    function goBack(event: any) {
        dispatch(panelActions.closeCollectionItemPanel())
    }

    function goEdit(event: any) {
        dispatch(panelActions.editCollectionItem(CollectionId, CollectionItemId, CollectionSubItemId))
    }
    
    function goCopy(event: any) {
        dispatch(panelActions.copyCollectionItem(CollectionId, CollectionItemId, CollectionSubItemId, !!viewSubItem))
    }

    const onItemClick = (CollectionId: string, CollectionItemId: string, startImageId: number, CollectionSubItemId: string) => {
        dispatch(panelActions.viewCollectionItem(CollectionId, CollectionItemId, startImageId, CollectionSubItemId))
    }

    const deleteClick = async (CollectionItemId: string) => {
        await http.httpPost('/item/delete', {CollectionId: CollectionId, CollectionItemId: CollectionSubItemId ? CollectionSubItemId : CollectionItemId}, true)
        dispatch(collectionActions.collectionChanged())
        dispatch(listActions.collectionListChanged())
        dispatch(panelActions.closeCollectionItemPanel())
    }

    const addChild = (event: any) => {
        if (viewItem) {
            dispatch(panelActions.addChildCollectionItem(CollectionId, viewItem.CollectionItemId))
        }
    }
    const addEmptyChild = async () => {
        await http.httpPost('/item/addemtychild', {CollectionId: CollectionId, CollectionItemId: CollectionSubItemId ? CollectionSubItemId : CollectionItemId}, true)
        dispatch(collectionActions.collectionChanged())
    }
    // const MyDoc = (
    //     <Item 
    //         item={viewSubItem ? viewSubItem : viewItem} 
    //         catalogSet={cll.viewSet.catalogSet}
    //         titleSet={cll.viewSet.titleSet}
    //         statusSet={cll.viewSet.pictureSet}
    //         labelSet={cll.viewSet.labelSet}
    //         gridSet={cll.viewSet.gridSet}
    //         collectionFields={cll.fields}
    //         showProvenance={showProvenance && !viewSubItem && viewItem && viewItem.source}
    //         // imageRatioWide={cll.imageRatioWide || 2}
    //         // imageRatio={cll.imageRatio || 1}
    //     />
    // )

    // const createPdf = async () => {
    //     const blob = await pdf(MyDoc).toBlob()
    //     let fileName = viewSubItem
    //         ?  viewSubItem.itemNumber 
    //             ? viewSubItem.itemNumber + '-' + viewSubItem.itemSubNumber 
    //             : viewSubItem.CollectionItemId
    //         : viewItem?.itemNumber
    //             ? viewItem?.itemNumber
    //             : viewItem?.CollectionItemId
         

    //     fileDownload(blob, fileName + '.pdf')
    // }

    return (
        !viewItem
        ? <Loader />
        : <>
            {/* <Box sx={{
                padding: '1rem',
                paddingTop: '1.5rem'
            }}> */}
            <div className="item-view-panel">
                <ToolbarView 
                    app={app}
                    goBack={goBack} 
                    cll={cll} 
                    goEdit={goEdit} 
                    goCopy={goCopy}
                />            
                {app.mobileMode ? null : <LineLoader active={collection.loading} />}
                
                <div className="item-view">
                    <ItemGallary 
                        viewItem={viewItem}
                        mobileMode={app.mobileMode}
                        startImageId={props.startImageId}
                        backgroundColor={cll.backgroundColor}
                    /> 
                    <div>
                        <ViewCatalog
                            set={cll.viewSet.catalogSet}
                            item={viewSubItem ? viewSubItem : viewItem}
                            collectionFields={cll.fields}
                            filterString=""
                        />
                        <ViewTitle
                            set={cll.viewSet.titleSet}
                            item={viewSubItem ? viewSubItem : viewItem}
                            collectionFields={cll.fields}
                        />

                        <ViewSubTitle
                            item={viewSubItem ? viewSubItem : viewItem}
                        />

                        <ViewChip
                            item={viewSubItem ? viewSubItem : viewItem}
                            provenance={showProvenance}
                            cardClass='view'
                        />
                        <ViewStatus
                            set={cll.viewSet.pictureSet}
                            item={viewSubItem ? viewSubItem : viewItem}
                            collectionFields={cll.fields}
                        />
                        <ViewLabel
                            set={cll.viewSet.labelSet}
                            item={viewSubItem ? viewSubItem : viewItem}
                            collectionFields={cll.fields}
                        />
                        <ViewGrid
                            set={cll.viewSet.gridSet}
                            item={viewSubItem ? viewSubItem : viewItem}
                            collectionFields={cll.fields}
                        />
                        <ViewItemText
                            item={viewSubItem ? viewSubItem : viewItem}
                            fullscreen
                        />                    
                        <ViewUrl
                            item={viewSubItem ? viewSubItem : viewItem}
                        />

                        {!viewItem.itemToFind && !viewSubItem && ['owner', 'fullAccess', 'fullView'].includes(cll.role)
                        &&
                        <FormControlLabel
                            sx={{padding: '1rem', gap: '.5rem'}}
                            control={
                                <IOSSwitch
                                    checked={showProvenance}
                                    onChange={event => setShowProvenance(!showProvenance)}
                                    size="small"
                                    name="shared"
                                    color="primary"
                                />
                            }
                            labelPlacement="end"
                            label={locale('itemView.provenance')}
                        />                    
                        }
                        {showProvenance && !viewSubItem && viewItem.source
                        && 
                        <ViewSource
                            showInfo={true}
                            showCaption={false}
                            item={viewItem}
                            complect={cll.complect}
                            cardClass='view'
                        />                    
                        }
                        {/* {!app.mobileMode                    
                        && <div>
                            <Button 
                                sx={{marginTop: 2}}
                                size="medium"
                                onClick={event => createPdf()}
                                type="submit"
                                startIcon={<PictureAsPdfIcon />}                    
                            >
                                {locale('pdf.prepare')}
                            </Button>    
                        </div>            
                        } */}

                    </div>
                {/* </Box> */}
                </div>
                <div>
                    {viewItem.items.length > 0 && !viewSubItem
                    && 
                    <>
                        <h3>{locale('itemView.childItems')}</h3>
                        <SubItemsDetail
                            inView={true}
                            viewItem={viewItem} 
                            cll={cll}
                            rootItem={viewItem}
                            onItemClick={onItemClick}
                        />
                    </>
                    }
                    {!viewSubItem && !cll.viewProps.ignoreChildItems && cll.useChildItems && I.isEditAccess(cll.role) && !app.mobileMode
                    &&
                    <Box sx={{
                        // padding: 4, 
                        // display: 'grid',
                        // justifyContent: 'space-around'
                    }}>
                    <Button 
                        sx={{marginTop: 2}}
                        id="save" 
                        size="medium"
                        onClick={addEmptyChild}
                        // onClick={addChild}
                        type="submit"
                        startIcon={<AddIcon />}                    
                    >
                        {locale('itemView.addChild')}
                    </Button>
                    {/* <Button 
                        sx={{marginTop: 2}}
                        id="save" 
                        size="medium"
                        onClick={addEmptyChild}
                        type="submit"
                        startIcon={<SegmentIcon />}                    
                    >
                        {locale('itemView.addEmptyChild')}
                    </Button> */}
                    </Box>
                    }
                    
                    {viewSubItem
                    && <>
                        <h3>{locale('itemView.include')}</h3>
                        <Card
                            item={viewItem}
                            cll={cll}
                            imageWidth='small'
                            subItemsMode={true}
                            onItemClick={onItemClick}
                            cardClass={app.mobileMode ? 'cards' : 'lines'}
                        />
                        
                        </>
                    }
                </div>

            {['owner', 'fullAccess'].includes(cll.role) && !app.mobileMode
            &&
            <Box sx={{
                padding: 4, 
                display: 'grid',
                justifyContent: 'space-around'
            }}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => deleteClick(CollectionItemId)}
                >
                {locale('itemView.deleteItem')}
            </Button>
            </Box>
            }
        </div>               
    </>
    )
}


