import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import ViewCatalog from './view-controls/ViewCatalog';
import ViewChip from './view-controls/ViewChip';
import ViewGrid from './view-controls/ViewGrid';
import ViewImages from './view-controls/ViewImages';
import ViewLabel from './view-controls/ViewLabel';
import ViewStatus from './view-controls/ViewStatus';
import ViewSubTitle from './view-controls/ViewSubTitle';
import ViewTitle from './view-controls/ViewTitle';
import SharedButton from './view-controls/SharedButton';
import { ViewSource } from './view-controls/ViewSource';
import { ViewUrl } from './view-controls/ViewURL';
import ViewItemText from './view-controls/ViewItemText';
import { SubItems } from './SubItems';

// const styleLines = {
//     display: 'grid',
//     gridTemplateColumns: 'min-content auto min-content',
//     gridAutoRows: '',
//     gridTemplateRows: '',
//     position: 'relative',
//     transition: 'all .2s ease-in-out',
//     border: '1px solid',
//     borderRadius: 2,
//     borderColor: 'divider',
//     gap: 0,
//     gridGap: 0   
// }

// const styleCards = {
//     display: 'grid',
//     gridTemplateColumns: '',
//     gridTemplateRows: 'min-content auto min-content',
//     gridAutoRows: '',
//     position: 'relative',
//     transition: 'all .2s ease-in-out',
//     border: '1px solid',
//     borderRadius: 2,
//     borderColor: 'divider',
//     gap: 0,
//     gridGap: 0  
// }

// const styleScale = {
//     display: 'grid',
//     gridTemplateColumns: 'min-content auto',
//     gridAutoRows: 'min-content',
//     gridTemplateRows: '',
//     position: 'relative',
//     transition: 'all .2s ease-in-out',
//     border: '1px solid',
//     borderRadius: 2,
//     borderColor: 'divider',
// }

export default function Card(p: any)  {
    const [ref, inView] = useInView({
        threshold: 0,
    });
    
    const [loaded, setLoaded] = useState(false);

    if (inView && !loaded) {
        setLoaded(true)
    }
    
    // const style = useMemo(() => {
    //     return p.cardClass === 'lines' 
    //         ? styleLines
    //         : p.cardClass === 'cards' 
    //         ? styleCards
    //         : styleScale        
    // }, [p.cardClass]);

    return ( 
        // <Box ref={ref} sx={style}>
        <div ref={ref} className={"card-" + p.cardClass}>
                <ViewImages 
                    CollectionId={p.cll.CollectionId}
                    backgroundColor={p.cll.backgroundColor}
                    inView={inView || loaded}
                    item={p.item}
                    rootItem={p.rootItem}
                    imageProps={p.cll.imageProps}
                    width={p.imageWidth} //'large'//'medium' //'small' 'large'
                    cardClass={p.cardClass}
                />
                <ViewChip
                    item = {p.item} 
                    provenance={p.provenance}
                    cardClass={p.cardClass}
                />
                <ViewStatus 
                    set={p.cll.viewSet.pictureSet}
                    item={p.item}
                    collectionFields={p.cll.fields}
                    cardClass={p.cardClass}
                />
                {/* <Box sx={{
                    padding: p.cardClass==='cards' ? '.5rem 1rem' : '1rem', 
                    position: 'relative'
                }}> */}
                <div className="card-body">
                    <ViewCatalog
                        set={p.cll.viewSet.catalogSet}
                        item={p.item}
                        collectionFields={p.cll.fields}
                        filterString={p.cll.viewProps.filterString}
                        cardClass={p.cardClass}
                    />
                    <ViewTitle
                        set={p.cll.viewSet.titleSet}
                        item={p.item}
                        collectionFields={p.cll.fields}
                        filterString={p.cll.viewProps.filterString}
                        cardClass={p.cardClass}
                    />
                    <ViewSubTitle
                        item={p.item}
                        filterString={p.cll.viewProps.filterString}
                        cardClass={p.cardClass}
                    />
                    <ViewLabel
                        set={p.cll.viewSet.labelSet}
                        item={p.item}
                        collectionFields={p.cll.fields}
                        cardClass={p.cardClass}
                    />
                    <ViewGrid
                        set={p.cll.viewSet.gridSet}
                        item={p.item}
                        collectionFields={p.cll.fields}
                        cardClass={p.cardClass}
                    />

                    {p.viewURL
                    ?<ViewUrl
                        item={p.item}
                        cardClass={p.cardClass}
                    />
                    : null
                    }
                    {['owner', 'fullAccess'].includes(p.cll.role) && p.cll.shared && !p.subItemsMode && !p.item.itemSubNumber
                    ?<SharedButton
                        item={p.item}
                        CollectionId={p.cll.CollectionId}
                        provenance = {p.provenance}
                        cardClass={p.cardClass}
                    />
                    : null
                    }

                </div>
        

                {p.provenance 
                ?<ViewSource
                    showInfo={false}
                    showCaption={true}
                    item={p.item}
                    complect={p.cll.complect}
                    cardClass={p.cardClass}
                />
                : <div></div>
                }
                {p.viewText
                ? <ViewItemText
                    item={p.item}
                />
                : null
                }
                {p.item.items && p.item.items.length > 0 && p.viewSubItems && p.cardClass !== 'cards'
                ? <SubItems
                    CollectionId={p.cll.CollectionId}
                    backgroundColor={p.cll.backgroundColor}
                    viewProps={p.cll.viewProps}
                    inView={inView}
                    viewItem = {p.item} 
                    onItemClick={p.onItemClick}
                    viewSet = {p.viewSet}
                    collectionFields={p.cll.fields}
                    setCatalog={p.cll.viewSet.catalogSet}
                    setTitle={p.cll.viewSet.titleSet}
                    filterString={p.cll.viewProps.filterString}
                    cardClass={p.cardClass}
                />
                : null
                }

        </div>

    )
}

