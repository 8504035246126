import React from 'react'
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { locale } from '../../../ui/locale';
import { Box, Stack } from '@mui/material';
import { gridStyle } from '../../../style/styles';

export default function EditURL(p: any) {
    return(
    <>
        <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
            <h5>{locale('itemEdit.urlTitle')}</h5>
            <Button 
                id="addURL" 
                size="small"
                startIcon={<AddIcon />}
                color="primary"
                onClick={p.onAddUrl}
            >
                {/* {locale('itemEdit.addURL')} */}
                {locale('common.add')}
            </Button>
        </Stack>
        <Box>
        {p.url.map((url: string, index: number) => (
            <Box key={index} sx={{...gridStyle, minHeight: '3rem',}}>
                <TextField
                    key={index}
                    value={url}
                    sx={{gridColumnStart: 'span 11'}}
                    variant="outlined"
                    size={'small'}                    
                    onChange={event => p.onChangeUrl(event, index)}
                />
                <IconButton
                    onClick={event => p.onDeleteURL(index)}
                >
                    <DeleteOutlineIcon />
                </IconButton>

            </Box>
        ))}
        {p.url.length === 0
        &&
        <Box sx={{
            color: 'text.disabled',
            textAlign: 'center',
            minHeight: '3rem'
        }}>
            {locale('itemEdit.noURL')}
        </Box>
        }
        </Box>
    </>
    )
}