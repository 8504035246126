import React from 'react'
import * as I from '../../store/interfaces'
import Card from './Card'

export const ViewCards = (p: any) => {

    // const style = useMemo(() => viewCardStyle(p.cardClass), [p.cardClass]);

    return (        
        // <Box sx={style}>
        <div className={"frame-" + p.cardClass}>
            {    
            p.viewItems.map((item: I.CollectionItem, index: number) => ( 
                <Card
                    key={index}
                    item={item}
                    index={index}
                    cll={p.cll}
                    provenance={p.cll.viewProps.viewProvenance}
                    viewText={p.cll.viewProps.view === 2 && p.cardClass !=='cards'}
                    viewURL={p.cll.viewProps.view === 2 && p.cardClass !=='cards'}
                    viewSubItems={[0, 2].includes(p.cll.viewProps.view) }
                    imageWidth={(p.cll.viewProps.view === 2 && p.cardClass !=='cards') ? 'medium' : 'small'}
                    onItemClick={p.onItemClick}
                    cardClass={p.cardClass}
                />
            ))}
        </div>
    )    
}