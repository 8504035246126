import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import Button from '@mui/material/Button'
import { locale } from '../../../ui/locale'
import TextField from '@mui/material/TextField'
import EditField from '../../../ui/editors/editField'
import * as I from '../../../store/interfaces'
import CollectionList from './CollectionList'
import * as http from '../../../modules/http'
import * as listActions from '../../../store/list/listActions'
import { useNavigate } from 'react-router-dom'

const fields = () => {
    return {
        name: '',
        type: '',
        details: '',
        copyfrom: '',
        userName: ''
    }
}

export default function Create(p: any) {
    const dispatch = useDispatch()
    const auth = useSelector( (state: any) => state.auth )
    const list = useSelector( (state: any) => state.list )
    const navigate = useNavigate();

    const [formState, setFormState] = useState({edited: false, loading: false, error: null});
    const [toEdit, setToEdit] = useState( fields()  )


    if (auth.auth) {
        if (!auth.roles.includes('admin')) {
            navigate('/')
        }
    }

    function onChangeField(fieldName: string, value: string) {
        setToEdit({...toEdit, [fieldName]: value})
        setFormState({...formState, edited: true})
    }

    const createCollection = async () => {
        setFormState((prev) => ({...prev, loading: true}))
        const res = await http.httpPost('/cll/create', toEdit, true)
        if (res.success) {
            setFormState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(listActions.collectionListChanged())
            navigate('/cll')
        } else {
            setFormState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }

    return (
        !auth.auth || !auth.roles.includes('admin')
        ? <Loader />
        : <>
        <section>
            <div className="cm-header">
                <div className="cm-toolbar">
                    <span>
                        <h1>{locale('create.title')}</h1>
                    </span>
                    <span className="cm-right">
                        <Button 
                            id="save" 
                            size="medium"
                            // variant={formState.loading || !formState.edited ? "outlined" : "contained"}
                            variant={"contained"}
                            onClick={createCollection}
                            color="primary"
                            type="submit"
                            // startIcon={<DoneIcon />}
                            disabled={formState.loading || !toEdit.name || !toEdit.userName || !toEdit.type}
                        >
                            {locale('create.create')}
                        </Button>
                    </span>
                </div>
            </div>
        </section>
        <section>
        <div className="cm-content">
            <div className="cm-items settings-collection">
                <div>
                    <form noValidate onSubmit={(event) => createCollection()} >
                        {formState.error ? <p className="form-error">{locale(formState.error)}</p> : null}
                        <TextField
                            disabled={formState.loading}
                            variant="outlined"
                            margin="dense" 
                            required
                            name="name"
                            label={locale('collections.props.name')}
                            value={toEdit.name}
                            onChange={event => onChangeField(event.target.name, event.target.value)}
                        />
                        <TextField
                            disabled={formState.loading}
                            variant="outlined"
                            margin="dense" 
                            multiline
                            rows={2}
                            name="details"
                            label={locale('collections.props.details')} 
                            value={toEdit.details}
                            onChange={event => onChangeField(event.target.name, event.target.value)}
                        />
                        <EditField
                            key="type"
                            freeSolo={true}
                            value={toEdit.type}
                            fieldName="type"
                            onChangeField={onChangeField}
                            list={I.typeArray}
                            label={locale('collections.props.type')}
                            disabled={formState.loading}
                        />
                        <TextField
                            disabled={formState.loading}
                            variant="outlined"
                            margin="dense" 
                            name="userName"
                            label={locale('collections.props.userName')} 
                            value={toEdit.userName}
                            onChange={event => onChangeField(event.target.name, event.target.value)}
                        />
                    </form>
                </div>
                <div>
                    <CollectionList 
                        list = {list.items}
                    />
                </div>
            </div>
        </div>
        </section>
        </>
    )
}
