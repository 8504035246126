import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import Paginator from '../../ui/Paginator'
import * as collectionActions from '../../store/collection/collectionActions'
import { ScrollContext } from '../../modules/context';

export default function BottomPanel(p: any)  {
    const dispatch = useDispatch();

    const scrollContext = useContext(ScrollContext)

    const onPaginatorChange = (num: number) => {
        dispatch(collectionActions.changeItemsOnPage(num))
        scrollContext.current?.scrollTo(0,0)
    }

    const onPageDown = () => {
        dispatch(collectionActions.changeStartItem(-1))
        scrollContext.current?.scrollTo(0,0)
    }

    const onPageUp = () => {
        dispatch(collectionActions.changeStartItem(1))
        scrollContext.current?.scrollTo(0,0)
    }

    return (        

        <div className="bottom-panel">
            <Paginator
                paginatorItemsOnPage={p.paginatorItemsOnPage}
                
                totalItems={p.totalItems}
                itemsOnPage={p.itemsOnPage}    
                startItem={p.startItem}

                onPageDown={onPageDown}
                onPageUp={onPageUp}
                onPaginatorChange={onPaginatorChange}
                mobileMode={p.mobileMode}
            />
        </div>
    )
}

