import { Box } from '@mui/material'
import React from 'react'

export const Loader:React.FC = () => {
    return (
        <Box sx={{
            minHeight: '20rem',
            textAlign: 'center',
            display: 'grid',
            alignItems: 'center',
            gridColumnStart: 'span 2'
          
        }} >
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </Box>
    )
}