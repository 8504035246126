import { LOGIN_SUCCESS } from '../auth/authReducer'
import * as I from '../interfaces'

export const LOAD_LIST_START = 'CLL/LOAD_LIST_START'
export const LOAD_LIST_FAIL = 'CLL/LOAD_LIST_FAIL'
export const LOAD_LIST_SUCCESS = 'CLL/LOAD_LIST_SUCCESS'
export const LOAD_LIST_CHANGED = 'CLL/LOAD_LIST_CHANGED'
export const LIST_CHANGE_FILTER_STR = 'CLL/LIST_CHANGE_FILTER_STR'
export const LIST_CHANGE_FILTER_INDEX = 'CLL/LIST_CHANGE_FILTER_INDEX'

const initialStateList: I.CollectionList = {
    loading: false,
    valid: false,
    filterIndex: 0,
    typeList: [],
    filterString: '',
    items: []
}

export const list = (state = initialStateList, action: any): I.CollectionList => {

    switch (action.type){
        case LOGIN_SUCCESS: {
            return {...state, valid: false}
        }
        case LOAD_LIST_START:{
            return {...state, loading: true}
        }
        case LOAD_LIST_FAIL:{
            return {...state, loading: false, valid: true}
        }
        case LOAD_LIST_SUCCESS:{
            return {...state, 
                loading: false,
                valid: true,
                items: action.data
            }
        }
        case LOAD_LIST_CHANGED:{
            return {...state, valid: false}
        }
        case LIST_CHANGE_FILTER_STR:{
            return {...state, 
                filterString: action.filterString
            }
        }
        case LIST_CHANGE_FILTER_INDEX:{
            return {...state, 
                filterIndex: action.filterIndex
            }
        }
        default: return state
    }
}