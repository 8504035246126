export const MOBILEMODE = 760
// export const DRAWERMODE = 1024

export const OFFLINE_MODE_ON = 'APP/OFFLINE_MODE_ON'
export const OFFLINE_MODE_OFF = 'APP/OFFLINE_MODE_OFF'

export const DRAWER_PAGE_ON = 'APP/DRAWER_PAGE_ON'
export const DRAWER_PAGE_OFF = 'APP/DRAWER_PAGE_OFF'
export const DRAWER_TOGGLE = 'APP/DRAWER_TOGGLE'
export const DRAWER_MODE_ON = 'APP/DRAWER_MODE_ON'
export const DRAWER_MODE_OFF = 'APP/DRAWER_MODE_OFF'
export const MOBILE_MODE_ON = 'APP/MOBILE_MODE_ON'
export const MOBILE_MODE_OFF = 'APP/MOBILE_MODE_OFF'

export const SHOW_ALERT = 'APP/SHOW_ALERT'
export const HIDE_ALERT = 'APP/HIDE_ALERT'
export const SHOW_WARNING = 'APP/SHOW_WARNING'
export const HIDE_WARNING = 'APP/HIDE_WARNING'
export const SHOW_MESSAGE = 'APP/SHOW_MESSAGE'
export const HIDE_MESSAGE = 'APP/HIDE_MESSAGE'
export const SET_MAIN_REF = 'APP/SET_MAIN_REF'
export const SET_LOGIN_OPEN_ON = 'APP/SET_LOGIN_OPEN_ON'
export const SET_LOGIN_OPEN_OFF = 'APP/SET_LOGIN_OPEN_OFF'


const initialStateApp = {
    offlineMode: false,
    loading: false,
    menuVisible: true,

    drawerPage: false,
    drawerOpen: false,
    // drawerMode: window.innerWidth <= DRAWERMODE,
    mobileMode: window.innerWidth <= MOBILEMODE,
    loginOpen: false,
    alert: null,
    warning: null,
    message: null,
    mainRef: null
}

export const app = (state = initialStateApp, action: any) => {

    switch (action.type){

        case SET_LOGIN_OPEN_ON:{
            return {...state, loginOpen: true}
        }
        case SET_LOGIN_OPEN_OFF:{
            return {...state, loginOpen: false}
        }
        case DRAWER_PAGE_ON:{
            return {...state, drawerPage: true}
        }
        case DRAWER_PAGE_OFF:{
            return {...state, drawerPage: false}
        }
        case DRAWER_MODE_ON:{
            return {...state, drawerMode: true}
        }
        case DRAWER_MODE_OFF:{
            return {...state, drawerMode: false}
        }
        case MOBILE_MODE_ON:{
            return {...state, mobileMode: true}
        }
        case MOBILE_MODE_OFF:{
            return {...state, mobileMode: false}
        }

        case DRAWER_TOGGLE:{
            return {...state, drawerOpen: !state.drawerOpen}
        }

        case SHOW_ALERT: return {...state, alert: action.alert, warning: null, message: null}
        case HIDE_ALERT: return {...state, alert: null}

        case SHOW_WARNING: return {...state, warning: action.warning, alert: null, message: null}
        case HIDE_WARNING: return {...state, warning: null}
        
        case SHOW_MESSAGE: return {...state, message: action.message, warning: null, alert: null,}
        case HIDE_MESSAGE: return {...state, message: null}
        case SET_MAIN_REF: return {...state, mainRef: action.ref}
        default: return state
    }
}