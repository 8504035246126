import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';
import EditField from '../../../../ui/editors/editField'
import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditFieldList from './EditFieldList';
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LineLoader } from '../../../../modules/line-loader';
import { Box } from '@mui/material';
import EditFrame from '../editFrame';
import { bottomToolbar, topToolbar } from '../../../../style/styles';


export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState( p.form )
    const [toEditListIndex, setToEditListIndex] = useState( -1 )

    useEffect( () => {
        setToEdit(p.form) 
    }, [p.form])

    const fieldList = p.collectionFields.filter((field: any) => {
        return p.fields.findIndex((f: any) => f.field === field.name) <0 || field.name === (toEdit ? toEdit.field : null)
    })

    function onChangeField(fieldName: string, newValue: any) {
        if (newValue === toEdit[fieldName]) return
        setToEdit((prev: any) => ({...prev, [fieldName]: newValue}))
        p.setEdited()
    }
    const addListItem = (event: any) => {
        setToEditListIndex(toEdit.fields.length)
        p.setEdited()
        setToEdit({...toEdit, fields: [...toEdit.fields, {name: '', length: 6}]}) 
    }
    const onEditListItem = (index: number) => {
        setToEditListIndex(index)
    }
    const onCancelEditListItem = () => {
        setToEditListIndex(-1)
        if (toEdit.fields.filter((value: string) => value).length > 0){
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: string) => value)
            }) 
        }
    }
    const onSaveListItemName = (value: string) => {
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, toEditListIndex), 
                    {...toEdit.fields[toEditListIndex], name: value}, 
                    ...toEdit.fields.slice(toEditListIndex + 1)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: any) => value.name)
            }) 
        }
    }
    const onSaveListItemLength = (value: string) => {
        console.log(value)
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, toEditListIndex), 
                    {...toEdit.fields[toEditListIndex], length: value}, 
                    ...toEdit.fields.slice(toEditListIndex + 1)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: toEdit.fields.filter((value: any) => value.name)
            }) 
        }
    }
    const onDeleteListItem = (delIndex: number) => {
        p.setEdited()
        setToEditListIndex(-1)
        setToEdit({
            ...toEdit, 
            fields: toEdit.fields.filter((value: string, index: number) => index !== delIndex)
        }) 
    }
    const onMoveItem = (index: number, up: boolean) => {
        p.setEdited()

        if (up){
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, index), 
                    toEdit.fields[index + 1], 
                    toEdit.fields[index], 
                    ...toEdit.fields.slice(index + 2)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                fields: [
                    ...toEdit.fields.slice(0, index - 1), 
                    toEdit.fields[index], 
                    toEdit.fields[index - 1], 
                    ...toEdit.fields.slice(index + 1)]
            }) 
        }
        setToEditListIndex(up ? index + 1 : index - 1)

    } 
    const onCancelClick = () => {
        p.onCancelClick()
        setToEdit(p.form) 
        setToEditListIndex(-1)
    }
    const onSaveClick = (toSave: any) => {
        p.onSaveClick(toSave)
        setToEditListIndex(-1)
    }
    const onOutsideClick = (toSave: any) => {
        setToEditListIndex(-1)
    }

    
    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'sticky', top: '5rem'}}>
            <Box sx={topToolbar}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit }
                >
                    {locale('common.cancel')}
                </Button>

                <Button 
                    id="save" 
                    size="medium"
                    variant={p.loading || !p.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={event => onSaveClick(toEdit)}
                    startIcon={<DoneIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit || (!toEdit.field && !toEdit.caption)}
                >
                    {locale('common.save')}
                </Button>
        </Box>
        <LineLoader active={p.loading && p.edited} />
            {!toEdit
            ? null
            : <Box sx={{backgroundColor: 'action.hover', padding: 2}}>
            { toEdit.isLabel
            ? <EditField
                key="caption"
                freeSolo={true}
                value={toEdit.caption}
                fieldName="caption"
                onChangeField={onChangeField}
                list={[]}
                label={locale('collections.form.caption')}
            />
            : toEdit.isGroup
            ? <EditField
                key="caption"
                freeSolo={true}
                value={toEdit.caption}
                fieldName="caption"
                onChangeField={onChangeField}
                list={[]}
                label={locale('collections.form.group')}
            />
            : <Box sx={{marginTop: 2, marginBottom: 2}}>
                <FormControl size="small" variant='outlined' fullWidth>
                    <InputLabel id="label-name">{locale('collections.form.fieldName')}</InputLabel>
                    <Select            
                        labelId="label-name"
                        id="field-select"
                        value={toEdit.field}            
                        label={locale('collections.form.fieldName')}
                        onChange={event => onChangeField('field', event.target.value)}
                    >
                    {fieldList.map((field: any, index: number) => (
                        <MenuItem key={index} value={field.name}>{field.name}</MenuItem>
                    ))}

                    </Select>
                </FormControl>
            </Box>
            }
            {toEdit.isGroup
            ? <>
                <h5>{locale('collections.form.fieldList')}</h5>
                <EditFieldList
                    list={toEdit.fields}
                    collectionFields={p.collectionFields}
                    toEditListIndex={toEditListIndex}
                    addListItem={addListItem}
                    onEditListItem={onEditListItem}
                    onCancelEditListItem={onCancelEditListItem}
                    onSaveListItemName={onSaveListItemName}
                    onSaveListItemLength={onSaveListItemLength}
                    onDeleteListItem={onDeleteListItem}
                    onMoveItem={onMoveItem}
                    onOutsideClick={onOutsideClick}
                />

            </>
            : null
            }

            <Box sx={bottomToolbar}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => p.onDeleteClick()}
                    disabled={p.loading}
                >
                    {locale('collections.form.delete')}
                </Button>
            </Box>

            </Box>
            }
        </Box> 
        </Box> 
    )
}