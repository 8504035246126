import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { locale } from '../../../ui/locale';
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as http from '../../../modules/http'

export default function Menu (p: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onClearClick = async (CollectionId: string) => {
        setAnchorEl(null)
        p.setLoading(true)
        await http.httpPost(
            '/admin/collections/clear', 
            {CollectionId: p.CollectionId},
            true
        )
        p.reloadList()
        p.setLoading(false)
    }

    const onDeleteClick = async (CollectionId: string) => {
        setAnchorEl(null)
        p.setLoading(true)
        await http.httpPost(
            '/admin/collections/delete', 
            {CollectionId: p.CollectionId},
            true
        )
        p.reloadList()
        p.setLoading(false)
    }

    return (
        <div>
            <Button
                onClick={event => handleMenu(event)} 
                color="inherit"
                endIcon={<ExpandMoreIcon />}   
                disabled={p.loading}
            >
                <span>{locale('admin.collections.menu.title')}</span>
            </Button>

                <Popover
                    id="menu-user"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={event => onClearClick(p.CollectionId)}>{locale('admin.collections.menu.clear')}</MenuItem>
                    <MenuItem onClick={event => onDeleteClick(p.CollectionId)}>{locale('admin.collections.menu.delete')}</MenuItem>
                </Popover> 
        </div>
    )
}