import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface CatalogOptionType {
    inputValue?: string;
    description: string;
}

const filter = createFilterOptions<CatalogOptionType>();

export default function EditDropDownText(p: any) {
    return (
        <Autocomplete
            value={p.value}
            className={p.className}
            sx={{gridColumnStart: p.span ? 'span ' + p.span : undefined}}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    p.onChangeField(p.fieldName, newValue)
                } else if (newValue && newValue.inputValue) {
                    p.onChangeField(p.fieldName, newValue.inputValue)
                } else {
                    p.onChangeField(p.fieldName, newValue?.description)
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
    
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        description: `Добавить "${params.inputValue}"`,
                });
                }
    
                return filtered;
            }}
            handleHomeEndKeys
            clearText="Очистить"
            clearOnEscape
            id={p.fieldName}
            options={p.options
                ? p.options.map((item: any) => ({description: item.description}))
                : p.list.map((item: any) => ({description: item}))
            }
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option
                }            
                if (option.inputValue) {
                    return option.inputValue
                }
                return option.description
            }}
            // renderOption={(option) => option.description}
            renderOption={(props, option, state) => <li {...props} >{option.description}</li>}
            freeSolo
            size="small"
            renderInput={(params) => (
                <TextField {...params} margin="normal" label={p.label} variant="outlined" disabled={p.disabled} />
            )}
        />
      )

}