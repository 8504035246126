import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import { locale } from '../../../ui/locale';
import { Box, Stack } from '@mui/material';
import { gridStyle } from '../../../style/styles';

export default function EditChipsAndValue(p: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const addItemClick = (item: string) => {
        p.onChangeField(p.field.name, item, '')
        setAnchorEl(null)
    }


    return(
    <div>
        <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
            <h5>{p.field.name}</h5>
            {p.field.list.length > p.toEdit.valueList.length
            ?<>
                <Button 
                    size="small"
                    startIcon={<AddIcon />}
                    color="primary"
                    onClick={handleClick}
                >
                    {/* {locale('itemEdit.addField')} */}
                    {locale('common.add')}
                </Button>
                <Menu
                    id="add-tag"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                {p.field.list
                    .filter((filter: string) =>  p.toEdit.valueList.findIndex((value: any) => value.name === filter) < 0)
                    .map((item: any, index: number) => (
                        <MenuItem 
                            key={index} 
                            onClick={event => addItemClick(item)}
                            value={index}
                        >
                            {item}
                        </MenuItem>
                ))}
                </Menu>
            </>
            : null
            }
        </Stack>
        {p.toEdit.valueList.length === 0
        ? <Box sx={{
            color: 'text.disabled',
            textAlign: 'center',
            minHeight: '3rem'
        }}>
            {locale('itemEdit.fieldListEmpty')}
        </Box>
        : <Box>
            {p.toEdit.valueList.map((item: any, index: number) => (
                <Box key={index} sx={{...gridStyle, minHeight: '3rem'}}>
                    <TextField 
                        // className="cm-readonly cm-edit-width-4"
                        margin="none" 
                        sx={{gridColumnStart: 'span 6'}} 
                        fullWidth
                        disabled
                        value={item.name}
                        variant="outlined" 
                        size="small" 
                    />
                    <TextField 
                        sx={{gridColumnStart: 'span 2'}} 
                        margin="none" 
                        value={item.value ? item.value : ''}
                        onChange={event => p.onChangeField(p.field.name, item.name, event.target.value)}
                        variant="outlined" 
                        disabled={p.disabled}
                        size="small" 
                    />
                    <IconButton
                        size='small'
                        onClick={event => p.onDeleteField(p.field.name, item.name)}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
            ))
            }
        </Box>
        }
        </div>
    )
    

}