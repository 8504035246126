import React, { useState, useEffect, useMemo } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import Edit from './Edit'
import Items from './Items'
import { locale } from '../../../../ui/locale'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import { twoPanelsStyle } from '../../../../style/styles'

export default function Form(p: any) {
    const dispatch = useDispatch();
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

    const [pageState, setPageState] = useState({
        loading: false, 
        editIndex: -1, 
        edited: false,
        error: null
    })
    const [toEdit, setToEdit] = useState(p.collection.form)
    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(p.collection.form) 
    }, [p.collection])

    const collectionFields = useMemo(() => {
        return (
            p.collection.fields.map((field: any) => ({
                name: field.name, 
                complex: (field.type === 'values' || field.type === 'chips' || field.type === 'chipsAndValue')
            }))        
            )
    }, [p.collection])

    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }

    const addField = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {isGroup: false, isLabel: false, field: ''}])
        setPageState({...pageState, editIndex: index, edited: true})
        closeAddMenu()
    }
    const addGroup = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {isGroup: true, isLabel: false, caption: '', fields: []}])
        setPageState({...pageState, editIndex: index, edited: true})
        closeAddMenu()
    }
    const addLabel = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {isGroup: false, isLabel: true, caption: ''}])
        setPageState({...pageState, editIndex: index, edited: true})
        closeAddMenu()
    }
    const openAddMenu = (event: any) => {
        setMenuAnchor(event.currentTarget)
    }
    const closeAddMenu = () => {
        setMenuAnchor(null)
    }
    const onEditClick = (index: number) => {
        if (!pageState.edited) {
            setPageState({...pageState, editIndex: index})
        }
    }

    const saveForm = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        formData.form = formData.form.filter((form: any) => form.field || form.caption)
        setToEdit(formData.form)
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = async (itemData: any) => {
        const formData = {
            CollectionId: CollectionId,
            form: [...toEdit.slice(0, pageState.editIndex), itemData, ...toEdit.slice(pageState.editIndex + 1)]
        }
        // console.log(formData)
        saveForm(formData)
    }
    const onDeleteClick = () => {
        const formData = {
            CollectionId: CollectionId,
            form: [...toEdit.slice(0, pageState.editIndex), ...toEdit.slice(pageState.editIndex + 1)]
        }        
        saveForm(formData)
        setPageState({...pageState, editIndex: -1, edited: false})
    }
    const onCancelClick = () => {
        setToEdit([...toEdit.filter((form: any) => form.field || form.caption) ])
        setPageState({...pageState, edited: false})
    }
    const onMoveField = (event: any, index: number, up: boolean) => {
        const formData = {
            CollectionId: CollectionId,
            form: up
                ?[
                    ...toEdit.slice(0, index), 
                    toEdit[index + 1], 
                    toEdit[index], 
                    ...toEdit.slice(index + 2)]
                :[
                    ...toEdit.slice(0, index - 1), 
                    toEdit[index], 
                    toEdit[index - 1], 
                    ...toEdit.slice(index + 1)]
        }        
        saveForm(formData)
        setPageState((prev) => ({...prev, editIndex: up ? index + 1 : index - 1 }))
    }

    return(
        <Box sx={twoPanelsStyle}>
        <div>
            <div>
                <Button 
                    id="add" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={openAddMenu}
                    endIcon={<ExpandMoreIcon />}                    
                    disabled={pageState.loading || pageState.edited}
                >
                    {locale('collections.form.addMenu') }
                </Button>
                <Popover
                    id="menu-user"
                    anchorEl={menuAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={closeAddMenu}
                >

                    <MenuItem onClick={addField}>{locale('collections.form.addField')}</MenuItem>
                    <MenuItem onClick={addGroup}>{locale('collections.form.addGroup')}</MenuItem>
                    <MenuItem onClick={addLabel}>{locale('collections.form.addLabel')}</MenuItem>
                </Popover>
                {/* </Menu> */}
            </div>
            <Items 
                form={toEdit}
                onEditClick={onEditClick}
                editIndex={pageState.editIndex}
                edited={pageState.edited}
                loading={pageState.loading}
                onMoveField={onMoveField}
            />
        </div>   
            <Edit 
                form={pageState.editIndex >=0 ? toEdit[pageState.editIndex] : null}
                collectionFields={collectionFields}
                fields={toEdit}
                onSaveClick={onSaveClick}
                onDeleteClick={onDeleteClick}
                onCancelClick={onCancelClick}
                setEdited={setEdited}
                edited={pageState.edited}
                loading={pageState.loading}
            />
    </Box>
    )
}