
import React from 'react'
import { Box, Stack } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import { locale } from '../../../ui/locale';



export default function EditChips(p: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
  
    const handleClose = () => {
        setAnchorEl(null)
    }

    const addItemClick = (item: string) => {
        p.onChangeField(p.field.name, item, '')
        setAnchorEl(null)
    }

    return(
        <div>
            <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
                <h5>{p.field.name}</h5>
                <div>
                    <Button 
                        size="small"
                        startIcon={<AddIcon />}
                        color="primary"
                        disabled={p.field.list.length <= p.toEdit.valueList.length}
                        onClick={handleClick}
                    >
                        {/* {locale('itemEdit.addField')} */}
                        {locale('common.add')}
                    </Button>
                    <Menu
                        id="add-tag"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                    {p.field.list
                        .filter((filter: string) =>  p.toEdit.valueList.findIndex((value: any) => value.name === filter) < 0)
                        .map((item: any, index: number) => (
                            <MenuItem 
                                key={index} 
                                onClick={event => addItemClick(item)}
                                value={index}
                            >
                                {item}
                            </MenuItem>
                    ))}
                    </Menu>
                </div>
            </Stack>
            <Box sx={{marginTop: '0.5rem', minHeight: '2.5rem'}}>
                {p.toEdit.valueList.map((item: any, index: number) => (
                <Chip
                    key={index}
                    sx={{marginRight: '0.5rem'}}
                    label={item.name}
                    onDelete={event => p.onDeleteField(p.field.name, item.name)}
                    variant="outlined"
                />
                ))}   
                {p.toEdit.valueList.length === 0
                && 
                <Box sx={{
                    color: 'text.disabled',
                    paddingTop: 0,
                    textAlign: 'center'
                }}>
                    {locale('itemEdit.fieldListEmpty')}
                </Box>
                // <TextField
                //     // sx={{gridColumnStart: 'span 6'}}
                //     value={locale('itemEdit.fieldListEmpty')}
                //     disabled
                //     // className="cm-edit-width-4"
                //     size='small'
                // />
                }  
            </Box>           
            {/* {p.field.list.length > p.toEdit.valueList.length
            ?<>
            <Button 
                size="small"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleClick}
            >
                {locale('itemEdit.addField')}
            </Button>
            <Menu
                id="add-tag"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            {p.field.list
                .filter((filter: string) =>  p.toEdit.valueList.findIndex((value: any) => value.name === filter) < 0)
                .map((item: any, index: number) => (
                    <MenuItem 
                        key={index} 
                        onClick={event => addItemClick(item)}
                        value={index}
                    >
                        {item}
                    </MenuItem>
            ))}
            </Menu>
            </>
            : null
            } */}
        </div>
    )
}