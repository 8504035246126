import { Toolbar, Typography } from '@mui/material';
import React from 'react'
import { locale } from '../../ui/locale';




function HeaderMy(p: any){
    return (
        <Toolbar disableGutters> 
            <Typography variant='h1'>{locale('collections.titleMy')}</Typography>
            <div className='grow'></div>
            {/* {p.mobileMode
            ? null
            : <span className="cm-right">
                <Button 
                    id="add" 
                    disabled={true}
                    size="medium"
                    variant="outlined"
                    color="inherit"
                    startIcon={<AddIcon />}
                >
                    {locale('collections.addButton')}
                </Button>
            </span>
            } */}
        </Toolbar>
    )
}

export default HeaderMy