import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import * as http from '../../modules/http'

import { Box, Button } from '@mui/material';
import { locale } from '../../ui/locale'
import { FormError } from '../../modules/form-error';


export default function Reset(p: any) {

    const [newPassword, setNewPassword] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [formState, setFormState] = useState({loading: false, ready: false, error: null})

    async function resetClick(event: any) {
        event.preventDefault()
        if (!formState.loading && ! formState.ready) {
            setFormState((prev) =>  ({...prev, loading: true, error: null}))
            const res = await http.httpPost('/auth/change', {newPassword: newPassword, password: password}, true)
            if (res.success) {
                setFormState((prev) =>  ({...prev, loading: false, error: null, ready: true}))
            } else {
                setFormState((prev) => ({...prev, loading: false, error: res.data.message}))
            }
        }
    }

    return (
        <Box sx={{width: '100%', maxWidth: '25rem', minWidth: '15rem'}}>
            <h4>
                {locale('auth.changePassword')}
            </h4>
            <p>Изменить пароль на вход</p>
            <form noValidate onSubmit={(event) => resetClick(event)}>
            {formState.ready
            ? <p>{locale('auth.passwordChanged')}</p>
            :<Box sx={{marginTop: 2}}>
            <FormError message={formState.error}/>
            <TextField
                sx={{marginTop: 2}}
                disabled={formState.loading}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                size="small"
                label={locale('auth.password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                autoComplete="current-password"
                InputProps={
                    { endAdornment: (
                        <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    )}
                }
            />          
            <TextField
                disabled={formState.loading}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                name="newPassword"
                size="small"
                label={locale('auth.newPassword')}
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
                autoComplete="new-password"
                InputProps={
                    {endAdornment: (
                        <IconButton size="small" onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    )}
                }
            />                       

            <Button
                sx={{marginTop: 4}}
                type="submit"
                disabled={formState.loading || password==='' }
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => resetClick(event)}
            >
                {locale('auth.changePassword')}
            </Button>
            </Box>
            }
          </form>
        </Box>
    )
}
