import React from 'react'
import { locale } from '../../ui/locale'

export const MENU_CLL = 0
export const MENU_USERS = 1
export const MENU_MESSAGES = 2
export const MENU_EXPORT = 3


function Menu(p: any){
    return (
        <div className="cm-submenu">
            <span>
                <ul className="cm-tab four">
                    <li 
                        className={p.tabIndex === MENU_CLL ? "active" : ""} 
                        onClick={event=> p.changeTabIndex(event, MENU_CLL)}
                    >{locale('admin.menu.collections')}</li>
                    <li 
                        className={p.tabIndex === MENU_USERS ? "active" : ""} 
                        onClick={event=> p.changeTabIndex(event, MENU_USERS)}
                    >{locale('admin.menu.users')}</li>
                    <li 
                        className={p.tabIndex === MENU_MESSAGES ? "active" : ""} 
                        onClick={event=> p.changeTabIndex(event, MENU_MESSAGES)}
                    >{locale('admin.menu.messages')}</li>
                    <li 
                        className={p.tabIndex === MENU_EXPORT ? "active" : ""} 
                        onClick={event=> p.changeTabIndex(event, MENU_EXPORT)}
                    >{locale('admin.menu.export')}</li>
                    
                </ul>

            </span>
        </div>
    )
}

export default Menu