import { AnyAction } from 'redux'

export const ITEM_PANEL_VIEW = 'PANEL/ITEM_PANEL_VIEW'
export const ITEM_PANEL_EDIT = 'PANEL/ITEM_PANEL_EDIT'
export const ITEM_PANEL_CLOSE = 'PANEL/ITEM_PANEL_CLOSE'
export const ITEM_PANEL_CLOSE_EDIT = 'PANEL/ITEM_PANEL_CLOSE_EDIT'
export const ITEM_PANEL_ADD = 'PANEL/ITEM_PANEL_ADD'
export const ITEM_PANEL_CLOSE_ADD = 'PANEL/ITEM_PANEL_CLOSE_ADD'
export const ITEM_PANEL_ADD_CHILD = 'PANEL/ITEM_PANEL_ADD_CHILD'
export const ITEM_PANEL_ADD_COPY = 'PANEL/ITEM_PANEL_ADD_COPY'

const initialStateItemForm = {
    CollectionId: '',
    CollectionItemId: '',
    CollectionSubItemId: '',
    startImageId: -1,
    show: false,
    editMode: false,
    add: false,
    copy: false,
    subItem: false,
}

export function itemPanel(state: any | undefined = initialStateItemForm, action: AnyAction): any {
    switch (action.type) {

        case ITEM_PANEL_CLOSE_EDIT:{
            return {
                ...state,
                editMode: false,
                add: false,
                copy: false,
            }
        }
        case ITEM_PANEL_CLOSE:{
            // console.log('ITEM_PANEL_CLOSE')
            // if (state.CollectionId) {
            //     window.history.pushState(null, '', '/collection/' + state.CollectionId)
            // }
            return {
                ...state,
                show: false
            }
        }
        case ITEM_PANEL_VIEW:{
            // window.history.pushState(null, '', 
            //     '/collection/' + action.CollectionId + 
            //     '/view/' + action.CollectionItemId +
            //     (action.CollectionSubItemId ? '/' + action.CollectionSubItemId : '') + 
            //     (action.startImageId ? '/' + action.startImageId : '') 
            // )
            return {
                ...state,
                CollectionId: action.CollectionId,
                CollectionItemId: action.CollectionItemId,
                CollectionSubItemId: action.CollectionSubItemId,
                startImageId: action.startImageId,
                show: true,
                editMode: false,
                add: false,
                copy: false,
                subItem: action.CollectionSubItemId ? true : false
            }
        }
        case ITEM_PANEL_EDIT:{
            return {
                ...state,
                CollectionId: action.CollectionId,
                CollectionItemId: action.CollectionItemId,
                CollectionSubItemId: action.CollectionSubItemId,
                show: true,
                editMode: true,
                add: false,
                copy: false,
            }
        }
        case ITEM_PANEL_ADD:{
            return {
                ...state,
                CollectionId: action.CollectionId,
                CollectionItemId: null,
                CollectionSubItemId: null,
                show: true,
                editMode: true,
                add: true,
                copy: false,
                subItem: action.subItem
            }
        }
        case ITEM_PANEL_ADD_COPY:{
            return {
                ...state,
                CollectionId: action.CollectionId,
                CollectionItemId: action.CollectionItemId,
                CollectionSubItemId: action.CollectionSubItemId,
                show: true,
                editMode: true,
                add: true,
                copy: true,
                subItem: action.subItem
            }
        }

        case ITEM_PANEL_CLOSE_ADD:{
            // window.history.pushState(null, '', 
            //     '/collection/' + action.CollectionId + 
            //     '/view/' + action.CollectionItemId +
            //     (action.CollectionSubItemId ? '/' + action.CollectionSubItemId : '') + 
            //     (action.startImageId ? '/' + action.startImageId : '') 
            // )
            return {
                ...state,
                editMode: false,
                add: false,
                copy: false,
                show: !(state.subItem || state.copy),
                CollectionItemId: action.CollectionItemId,
                CollectionSubItemId: action.CollectionSubItemId,
            }
        }
        case ITEM_PANEL_ADD_CHILD:{
            return {
                ...state,
                CollectionId: action.CollectionId,
                CollectionItemId: action.CollectionItemId,
                CollectionSubItemId: null,
                show: true,
                editMode: true,
                add: true,
                copy: false,
                subItem: true
            }
        }
    }
    return state
}