import React, { useEffect } from 'react'
import {useSelector} from 'react-redux'
import { Loader } from '../../modules/Loader'
import Email from './Email'
import Change from './Change'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'


export default function Settings(p: any) {
    const auth = useSelector( (state: any) => state.auth );
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth) {
            navigate('/')
        }
    }, [auth, navigate]);
    
    return (
        !auth.auth
        ? <Loader />
        : <Box sx={{
            // gridTemplateColumns: {sm: 'auto', md: '1fr 1fr'},
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '6rem',
            // gridGap: '6rem',
            marginTop: '3rem',
            marginBottom: '2rem',
            position: 'relative',
            // marginTop: 5                
        }}>
            <Email />
            <Change />
        </Box>
    )

    
}