import { Box } from '@mui/material'
import React from 'react'
import { locale } from '../ui/locale'

export const FormError = (p: any) => {
    if (!p.message) return null
    return (
        <Box sx={{
            position: 'relative',
            backgroundColor: 'rgba(255, 58, 32, 0.15)',
            padding: '0.5rem 0.75rem',
            color: '#FF3A20',
            borderRadius: '0.25rem',
            marginBottom: '1rem',
            textAlign: 'center'                 
        }} >
            {locale(p.message)}
        </Box>
    )
}