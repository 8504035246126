import clsx from 'clsx';
import React from 'react'
import { fieldValue } from '../../../store/interfaces'

const Chip = (props: any) => (
    !props.value ? null :
    <div className={clsx(
        "flag", 
        {"flag-to-find": props.field.name === '_itemToFind'},
        {"flag-dropped": props.field.name === '_itemDropped'}
    )} >
        {props.field?.prefix ? props.field.prefix : ''} 
        {props.value}
        {props.field?.suffix ? ' ' + props.field.suffix : ''}
    </div>
);

export default function ViewStatus(p: any) {
    return (
        <div className="flags">
            {p.set.map( (field:any, index: number) => (
                <Chip
                    key={index}
                    value={fieldValue(p.item, p.collectionFields, field)}
                    field={field}
                />
            ))
            } 
        </div>
    );
};
