import { locale } from '../../../../ui/locale'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { SettingsTableHeadCell } from '../../../../modules/settingsTableHeadCell'
import { SettingsTableRow } from '../../../../modules/settingsTableRow'
import { SettingsTableCell } from '../../../../modules/settingsTableCell'

export default function Items(p: any) {

    return(
        p.fields.length === 0
        ? null
        :<TableContainer>
            {/* <Table className={clsx("cm-table", {'active' : !p.edited})}> */}
            <Table sx={{opacity: p.loading || p.edited ? '0.5' : undefined }}>
            <TableHead>
                <TableRow>
                    <SettingsTableHeadCell>Имя</SettingsTableHeadCell>
                    <SettingsTableHeadCell>Тип</SettingsTableHeadCell>
                    <SettingsTableHeadCell></SettingsTableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>

            {p.fields
            .filter((field: any) => field.name)
            .map((field: any, index: number) => (
            <SettingsTableRow 
                key={index}                 
                selected={p.editIndex === index }
            >
                <SettingsTableCell 
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)} 
                    width="40%"
                >
                    {field.name}
                </SettingsTableCell>
                <SettingsTableCell 
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)} 
                >
                    <small>
                    {locale('collections.fields.types.' + field.type)}
                    </small>
                </SettingsTableCell>
                
                {p.editIndex === index && !p.edited
                ?<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                    width="70px"
                    selected={p.editIndex === index}
                >                
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={index === 0 || p.edited}
                        onClick={event => p.onMoveField(event, index, false)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="primary"
                        disabled={index === p.fields.length - 1 || p.edited}
                        onClick={event => p.onMoveField(event, index, true)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </SettingsTableCell>
                :<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                    width="70px"
                    selected={p.editIndex === index}
                    onClick={() => p.onEditClick(index)}></SettingsTableCell>
                }
            </SettingsTableRow>
            ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}