import * as I from '../../interfaces'
import { locale } from '../../../ui/locale'

export function filterAccess(data: I.Collection) {

    const index = data.filterGroups.length
    const items = data.preparedItems
    data.filterGroups.push({
        description: locale('filters.access.title'),
        isOpen: false,
        hidden: false,
        items: [],        
    })
    data.filterGroups[index].items.push({
        description: locale('filters.access.notShared'),
        isChecked: false,
        itemsList: items.filter(item => !item.sharedItem).map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.access.shared'),
        isChecked: false,
        itemsList: items.filter(item => item.sharedItem).map(item => (item.CollectionItemId))
    })

}