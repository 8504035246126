import * as I from '../../interfaces'


export function listItems(items: I.CollectionItem[], list: string[], listSorting: boolean, fieldName: string, titleNone: string, titleOther: string): I.FilterItem[] {
    if (listSorting) {
        list.sort()
    }
    let result: I.FilterItem[] = []

    result.push({description: titleNone, isChecked: false, itemsList: 
        items
        .filter(item => {
            const idx = item.fields.findIndex(f => f.name === fieldName)
            if (idx >= 0 && !item.fields[idx].value) return true
            return false
        })
        .map(item => (item.CollectionItemId))
    })

    result = result.concat(list.map((el: string, index: number) => ({       
        description: el,
        isChecked: false,
        itemsList: items
            .filter(item => {
                const idx = item.fields.findIndex(f => f.name === fieldName)
                if (idx >= 0 && item.fields[idx].value === el) return true
                return false
            })
            .map(item => (item.CollectionItemId))
    })))

    result.push({description: titleOther, isChecked: false, itemsList: 
        items
        .filter(item => {
            const idx = item.fields.findIndex(f => f.name === fieldName)
            if (idx >= 0 && item.fields[idx].value) {
                if (list.indexOf(item.fields[idx].value) < 0) return true
                return false
            }
            return false
        })
        .map(item => (item.CollectionItemId))
    })

    return result.filter(el => el.itemsList.length > 0)
}