import {useDispatch} from 'react-redux'

import Image from 'material-ui-image'
import { BACKENDROOT } from '../../../index'
import * as panelActions from '../../../store/panel/panelActions'
import * as I from '../../../store/interfaces'

export default function ViewImages(p: any) {
    const dispatch = useDispatch();

    // const style = {width: 
    //     p.width === 'extra-small' 
    //     ? '4rem'
    //     : p.width === 'small'
    //     ? '16rem'
    //     : p.width === 'medium' 
    //     ? '32rem' 
    //     : '64rem'
    // }
    
    // const {imageWidth, imageHeight} = useMemo(() => (
    //     p.width === 'extra-small' 
    //     ? {imageWidth: '4rem', imageHeight: '2rem'}
    //     : p.width === 'small'
    //     ? {imageWidth: '16rem', imageHeight: '8rem'}
    //     : p.width === 'medium' 
    //     ? {imageWidth: '32rem', imageHeight: '16rem'}
    //     : {imageWidth: '64rem', imageHeight: '32rem'}
    // ), [p.width]);

    // const sizeClassName = useMemo(() => (
    //     p.width === 'extra-small' 
    //     ? 'extra-small'
    //     : p.width === 'small'
    //     ? 'small'
    //     : p.width === 'medium' 
    //     ? 'medium'
    //     : 'large'
    // ), [p.width]);

    const onItemClick = (CollectionId: string, CollectionItemId: string, startImageId: number, CollectionSubItemId: string) => {
        dispatch(panelActions.viewCollectionItem(CollectionId, CollectionItemId, startImageId, CollectionSubItemId))
    }

    const onClick = (startImageId: number) => {
        if (p.rootItem) {
            onItemClick(p.CollectionId, p.rootItem.CollectionItemId, startImageId, p.item.CollectionItemId)
        } else {
            onItemClick(p.CollectionId, p.item.CollectionItemId, startImageId, '')
        }
    }

    const url = BACKENDROOT + '/image/' + p.width + '/'
    return (
        <div className={"image-box " + p.width}>
            {!p.inView
            ? null
            :p.item.images && p.item.images.length > 0
            ? p.item.oneImageOnCover || p.width === 'extra-small' || p.item.images.length === 1
                ? 
                <div className="image-border">
                    <Image     
                        aspectRatio={p.width === 'extra-small' ? I.imageRatio : I.imageRatioWide}
                        src={url + p.item.images[0] + '/' + 
                        (p.width === 'extra-small' ? I.imageRatio : I.imageRatioWide) + 
                        (p.backgroundColor ? '/' + p.backgroundColor : '')}
                        onClick={() => onClick(p.item.images[0])}
                    />
                </div>
                :<>
                <div className="image-border-right">
                    <Image     
                        aspectRatio={I.imageRatio}
                        src={url + p.item.images[0] + '/' + I.imageRatio + (p.backgroundColor ? '/' + p.backgroundColor : '')}
                        onClick={() => onClick(p.item.images[0])}
                    />
                </div>
                {p.item.images.length > 1
                ?
                <div className="image-border-left">
                    <Image                
                        // width='50%'
                        aspectRatio={I.imageRatio}
                        src={url + p.item.images[1] + '/' + I.imageRatio + (p.backgroundColor ? '/' + p.backgroundColor : '')}
                        onClick={() => onClick(p.item.images[1])}
                    />
                </div>
                :null
                }</>
            : 
            <div className="image-border">
                <Image     
                    aspectRatio={p.width === 'extra-small' ? I.imageRatio : I.imageRatioWide}
                    src={p.width === 'extra-small' ? '/images/noimage(1x1).jpg': '/images/noimage.jpg'}
                    onClick={() => onClick(0)}
                />
            </div>
            }
        </div>
    )

}
