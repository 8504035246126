import * as I from '../../interfaces'

export function valuesItems(items: I.CollectionItem[], list: string[], fieldName: string, fieldListName: string, titleNone: string): I.FilterItem[] {
    let f: string[] = []
    titleNone = fieldListName === '-' ? ' (' + titleNone + ')' : ' ' + titleNone
    list.forEach(listValue => {
        if (fieldListName === listValue || !fieldListName || fieldListName === '-') {
            f = f.concat(Array.from(new Set(
                    [...items.map((item) => {
                        const idx = item.fields.findIndex(f => f.name === fieldName)
                        if (idx >=0 ) {
                            const listIdx = item.fields[idx].valueList.findIndex(f => f.name === listValue)
                            if (listIdx >= 0) {
                                return (
                                    item.fields[idx].valueList[listIdx].name + 
                                    ' ' + 
                                    item.fields[idx].valueList[listIdx].value
                                )
                            }
                        }
                        return fieldListName === '-' ? listValue + titleNone : titleNone
                    })]
            )))
        }
    })
    f.sort()
    // const noneLength = (' (' + titleNone + ')').length
    return f.map((el: string, index: number) => ({       
        description: el,
        isChecked: false,
        itemsList: 
            items
            .filter(item => {
                const idx = item.fields.findIndex(f => f.name === fieldName)
                if (idx >=0 ) {
                    if (item.fields[idx].valueList
                        .filter(value => value.name + ' ' + value.value === el).length > 0
                    ) return true

                    if (el.includes(titleNone)) {
                        const name = fieldListName === '-' 
                            ? el.slice(0, el.length - titleNone.length)
                            : fieldListName
                        if (item.fields[idx].valueList
                            .filter(value => value.name === name).length === 0
                        ) return true
    
                    }

                    return false
                }
                return false
            })
            .map(item => (item.CollectionItemId))
    }))

}