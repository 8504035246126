import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import { locale } from '../../../../ui/locale'
import AddIcon from '@mui/icons-material/Add'
import Edit from './Edit'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import AddUser from './AddUser'
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function Users(p: any) {

    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        loading: false, 
        addMode: false,
        error: null
    })
    const [toEdit, setToEdit] = useState(p.collection.users)
    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(p.collection.users) 
    }, [p.collection])

    const saveUsers = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        formData.users = formData.users.filter((user: any) => user.userName)
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }

    const onChangeAccess = (index: number, role: string) => {
        const formData = {
            CollectionId: CollectionId,
            users: [
                ...toEdit.slice(0, index), 
                {...toEdit[index], role: role}, 
                ...toEdit.slice(index + 1)]
        }
        saveUsers(formData)
    }
    const onAddUser = (userName: string) => {
        const formData = {
            CollectionId: CollectionId,
            users: [
                ...toEdit, 
                {userName: userName, role: 'na'}
            ]
        }
        setPageState({...pageState, addMode: false})
        saveUsers(formData)
    }
    const onAddUserCancel = () => {
        setPageState({...pageState, addMode: false})
    }
    const onDeleteUser = (index: number) => {
        const formData = {
            CollectionId: CollectionId,
            users: [
                ...toEdit.slice(0, index),
                ...toEdit.slice(index + 1),
            ]
        }
        saveUsers(formData)
    }
    return (
        <Box sx={{maxWidth:'40rem'}}>
        <TableContainer>
            <Table sx={{ minWidth: 350, marginTop: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell width={'50%'}><strong>{locale('collections.users.th.user')}</strong></TableCell>
                        <TableCell align='center'><strong>{locale('collections.users.th.access')}</strong></TableCell>
                        <TableCell align='center'><strong>{locale('collections.users.th.delete')}</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {toEdit.map((user: any, index: number) => (
                    <TableRow 
                        key={index} 
                        hover
                    >
                        <TableCell>{user.userName}</TableCell>
                        <TableCell align='center'>
                            <Edit
                                onChangeAccess={onChangeAccess}
                                index={index}
                                loading={pageState.loading}
                                role={user.role}
                            />
                        </TableCell>
                        <TableCell align='center'>
                            <IconButton 
                                size="small" 
                                disabled={pageState.loading}
                                color="secondary"
                                onClick={event => onDeleteUser(index)}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>

                ))}
                {pageState.addMode
                ?<TableRow>
                    <TableCell>
                        <AddUser
                            onAddUser={onAddUser}
                            onAddUserCancel={onAddUserCancel}
                            list={toEdit}
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                {/* <td width='50%' ></td> */}
                </TableRow>
            : null

            }
                </TableBody>
        </Table>
        </TableContainer>
        {pageState.addMode
        ? null
        : <Button 
            id="addUser" 
            size="medium"
            startIcon={<AddIcon />}
            color="primary"
            onClick={event => setPageState({...pageState, addMode: true})}
            disabled={pageState.loading}
        >
            {locale('collections.users.addUser')}
        </Button>
        }
        </Box>
    )

}