import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done';
import EditField from '../../../../ui/editors/editField'
import EditDropDown from '../../../../ui/editors/editDropDown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { locale } from '../../../../ui/locale'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditFieldList from './EditFieldList';
import CloseIcon from '@mui/icons-material/Close'
import { LineLoader } from '../../../../modules/line-loader';
import { Box } from '@mui/material';
import { bottomToolbar, topToolbar } from '../../../../style/styles';
import EditFrame from '../editFrame';


export default function Edit(p: any) {
    const [toEdit, setToEdit] = useState( p.field )
    const [toEditListIndex, setToEditListIndex] = useState( -1 )

    useEffect( () => {
        setToEdit(p.field) 
    }, [p.field])

    function onChangeField(fieldName: string, newValue: any) {
        if (newValue === toEdit[fieldName]) return
        setToEdit((prev: any) => ({...prev, [fieldName]: newValue}))
        p.setEdited()
    }
    const addListItem = (event: any) => {
        setToEditListIndex(toEdit.list.length)
        p.setEdited()
        setToEdit({...toEdit, list: [...toEdit.list, '']}) 
    }
    const onEditListItem = (index: number) => {
        setToEditListIndex(index)
    }
    const onCancelEditListItem = () => {
        setToEditListIndex(-1)
        if (toEdit.list.filter((value: string) => value).length > 0){
            setToEdit({
                ...toEdit, 
                list: toEdit.list.filter((value: string) => value)
            }) 
        }
    }
    const onSaveListItem = (value: string) => {
        p.setEdited()
        if (value){
            setToEdit({
                ...toEdit, 
                list: [...toEdit.list.slice(0, toEditListIndex), value, ...toEdit.list.slice(toEditListIndex + 1)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                list: toEdit.list.filter((value: string) => value)
            }) 
        }
        setToEditListIndex(-1)
    }
    const onDeleteListItem = (delIndex: number) => {
        p.setEdited()
        setToEditListIndex(-1)
        setToEdit({
            ...toEdit, 
            list: toEdit.list.filter((value: string, index: number) => index !== delIndex)
        }) 
    }
    const onMoveItem = (index: number, up: boolean) => {
        p.setEdited()

        if (up){
            setToEdit({
                ...toEdit, 
                list: [
                    ...toEdit.list.slice(0, index), 
                    toEdit.list[index + 1], 
                    toEdit.list[index], 
                    ...toEdit.list.slice(index + 2)]
            }) 
        } else {
            setToEdit({
                ...toEdit, 
                list: [
                    ...toEdit.list.slice(0, index - 1), 
                    toEdit.list[index], 
                    toEdit.list[index - 1], 
                    ...toEdit.list.slice(index + 1)]
            }) 
        }
        setToEditListIndex(up ? index + 1 : index - 1)

    }
    const onCancelClick = () => {
        p.onCancelClick()
        setToEdit(p.field) 
    }
    // console.log('Edit', toEdit)
    return(
        !toEdit
        ? <EditFrame />
        :
        <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'sticky', top: '5rem'}}>
            <Box sx={topToolbar}>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={event => onCancelClick()}
                    startIcon={<CloseIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit }
                >
                    {locale('common.cancel')}
                </Button>
                <Button 
                    id="save" 
                    size="medium"
                    variant={p.loading || !p.edited ? "outlined" : "contained"}
                    color="primary"
                    onClick={event => p.onSaveClick(toEdit)}
                    startIcon={<DoneIcon />}                    
                    disabled={p.loading || !p.edited || !toEdit ||!toEdit.name || !toEdit.type}
                >
                    {locale('common.save')}
                </Button>
            </Box>
            <LineLoader active={p.loading && p.edited} />
            {!toEdit
            ? null
            : <Box sx={{backgroundColor: 'action.hover', padding: 2}}>
                <div>
                    <EditField
                        freeSolo={true}
                        value={toEdit.name}
                        fieldName="name"
                        onChangeField={onChangeField}
                        list={[]}
                        label={locale('collections.fields.name')}
                    />
                    <EditDropDown
                        value={toEdit.type}
                        fieldName="type"
                        onChangeField={onChangeField}
                        label={locale('collections.fields.type')}
                        options={[
                            {value: 'text', description: locale('collections.fields.types.text')},
                            {value: 'autocomplete', description: locale('collections.fields.types.autocomplete')},
                            {value: 'dropDownText', description: locale('collections.fields.types.dropDownText')},
                            {value: 'dropDown', description: locale('collections.fields.types.dropDown')},
                            {value: 'values', description: locale('collections.fields.types.values')},
                            {value: 'chips', description: locale('collections.fields.types.chips')},
                            {value: 'chipsAndValue', description: locale('collections.fields.types.chipsAndValue')},
                        ]}
                    />
                {toEdit.type !== 'text' && toEdit.type !== 'autocomplete' && toEdit.type !== ''
                ?<div>
                    <FormControl component="fieldset" margin="dense" disabled={p.loading} >
                    <FormLabel component="legend">{locale('collections.fields.sortList')}</FormLabel>
                        <RadioGroup 
                            row
                            value={toEdit.listSorting}
                            onChange={event => onChangeField('listSorting', event.target.value==="true")}
                        >
                            <FormControlLabel 
                                color="primary" 
                                value={true}
                                control={<Radio color="primary"/>} 
                                label={locale('collections.fields.sortAuto')}
                            />
                            <FormControlLabel 
                                color="primary" 
                                value={false}
                                control={<Radio color="primary"/>} 
                                label={locale('collections.fields.sortManualy')}
                            />
                        </RadioGroup>
                    </FormControl>
                    <h5>{locale('collections.fields.valuesList')}</h5>
                    <EditFieldList
                        list={toEdit.list}
                        sorting={toEdit.listSorting}
                        toEditListIndex={toEditListIndex}
                        addListItem={addListItem}
                        onEditListItem={onEditListItem}
                        onCancelEditListItem={onCancelEditListItem}
                        onSaveListItem={onSaveListItem}
                        onDeleteListItem={onDeleteListItem}
                        onMoveItem={onMoveItem}
                    />

                </div>
                : null
                }

            </div>
            <Box sx={bottomToolbar}>
                <Button 
                    id="delete" 
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={event => p.onDeleteClick()}
                    disabled={p.loading}
                >
                    {locale('collections.fields.delete')}
                </Button>
            </Box>

            </Box>
            }
        </Box> 
        </Box> 
    )
}