import { Box } from "@mui/material"

export const EmptyList = (p: any) => {


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '5rem'
        }}>
            {p.auth ? "У вас еще нет коллекций.": "Нет доступных коллекций :("}
        </Box>
    )
}

