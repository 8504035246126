import * as I from '../interfaces'

// function fieldOrderValue(cll: any, fieldName: string, fieldValue: string): number {
//     if (['details1', 'details2', 'details3', 'details4', 'details5', 'details6'].indexOf(fieldName) >=0 ) {
//         let val = cll.fieldValues[fieldName].indexOf(fieldValue)
//         return val >= 0?val:9999
//     }
//     return 9999
// }
// function tagValue(tagGroupId: number, itemTagValues: any[]): string {
//     let idx = itemTagValues.findIndex((el: any) => el.id === tagGroupId)
//     if (idx >= 0) {
//         let val =  itemTagValues[idx].description    
//         itemTagValues[idx].items.forEach((item: any) => {
//             val = val + ' ' + item.description + (item.value?' ' + item.value:'')
//         })  
//         return val
//     }
//     return '}}}'
// }
function coinWeigth(description: string): number {
    const nominal = parseInt(description.replace(" ", ""))
    let Weigth = 1
    if (description.indexOf('рубл') >= 0 ||
        description.indexOf('мар') >= 0 ||
        description.indexOf('злот') >= 0 ||
        description.indexOf('фори') >= 0 ||
        description.indexOf('долл') >= 0 ||
        description.indexOf('гульд') >= 0 ||
        description.indexOf('крон') >= 0 ||
        description.indexOf('фунт') >= 0 ||
        description.indexOf('лир') >= 0 ||
        description.indexOf('реал') >= 0 ||
        description.indexOf('эскуд') >= 0 ||
        description.indexOf('динар') >= 0 
    ) {
        Weigth = 100
    }

    return nominal ? nominal * Weigth : 10 * Weigth

}

export function reorderCollection(cll: I.Collection, sorting: number, sortingReverce: boolean) {
    function listValue(field: I.FieldSet, value: string){
        return field.list.findIndex(v => v === value)
    }

    const items = cll.preparedItems
    const sortingIndex = sorting < cll.sort.length ? sorting : -1
    const sortingSetFields = sortingIndex >= 0 ? cll.sort[sortingIndex].fields : []

    if (sortingSetFields.length > 0 && !cll.sort[sortingIndex].addReverce) {
        sortingReverce = false
    }

    items.sort((a: I.CollectionItem, b:I.CollectionItem) => {
        for (let field of sortingSetFields) {
            let index = 0

            var result: number = 0
            
            if (field.name === '_catalog') {
                if (a.catalogOrderNr < b.catalogOrderNr) result = -1
                if (a.catalogOrderNr > b.catalogOrderNr) result = 1
            } else 
            if (field.name === '_description') {
                if (a.description < b.description) result = -1
                if (a.description > b.description) result = 1
            } else 
            if (field.name === '_subDescription') {
                if (a.subDescription < b.subDescription) result = -1
                if (a.subDescription > b.subDescription) result = 1
            } else 
            if (field.name === '_itemToFind') {
                if (a.itemToFind < b.itemToFind) result = -1
                if (a.itemToFind > b.itemToFind) result = 1
            } else 
            if (field.name === '_number') {
                const aValue = a.itemNumber + (a.itemSubNumber ? a.itemSubNumber : '')
                const bValue = b.itemNumber + (b.itemSubNumber ? b.itemSubNumber : '')
                if (aValue < bValue) result = -1
                if (aValue > bValue) result = 1
                // if (a.itemNumber < b.itemNumber) result = -1
                // if (a.itemNumber > b.itemNumber) result = 1
            } else 
            if (field.name === '_coinWeigth') {
                if (coinWeigth(a.description) < coinWeigth(b.description)) result = -1
                if (coinWeigth(a.description) > coinWeigth(b.description)) result = 1
            } else {
                const fieldIndex = cll.fields.findIndex(f => f.name === field.name)
                const fieldIndexA = a.fields.findIndex(f => f.name === field.name)
                const fieldIndexB = b.fields.findIndex(f => f.name === field.name)
                if (fieldIndex >=0 && fieldIndexA >= 0 && fieldIndexB >= 0) {
                    if (cll.fields[fieldIndex].type === I.FIELD_TEXT || 
                        cll.fields[fieldIndex].type === I.FIELD_AUTOCOMPLETE) {
                        if (a.fields[fieldIndexA].value < b.fields[fieldIndexB].value) result = -1
                        if (a.fields[fieldIndexA].value > b.fields[fieldIndexB].value) result = 1
                    } else if (cll.fields[fieldIndex].type === I.FIELD_DROPDOWNTEXT || 
                        cll.fields[fieldIndex].type === I.FIELD_DROPDOWN) {
                        let aVal = listValue(cll.fields[fieldIndex], a.fields[fieldIndexA].value)
                        let bVal = listValue(cll.fields[fieldIndex], b.fields[fieldIndexB].value)
                        if (aVal < bVal) result = -1
                        if (aVal > bVal) result = 1
                    } else {
                        let aVal = I.fieldValue(a, cll.fields, a.fields[fieldIndexA])
                        let bVal = I.fieldValue(b, cll.fields, b.fields[fieldIndexB])
                        if (aVal < bVal) result = -1
                        if (aVal > bVal) result = 1
                    }
                }

            }

            if (result !== 0 && index === 0 && sortingReverce) {
                return field.desc ? result : -result
            } else if (result !== 0) {
                return field.desc ? -result : result
            }
            index++
        }
        return 0
    })
}