import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Filter from './filter/Filter'
import {ItemsList} from './ItemsList'
import { Loader } from '../../modules/Loader'
import * as collectionActions from '../../store/collection/collectionActions'

import * as I from '../../store/interfaces'
import {Header} from './Header'
import {Menu} from './Menu'
import LinearProgress from '@mui/material/LinearProgress';
import Settings from './settings/Settings'

import {MENU_ITEMS, MENU_SETTINGS, MENU_EXPORT_IMPORT}  from './Menu'
import ExportImport from './exportImport/ExportImport'
import * as panelActions from '../../store/panel/panelActions'
import { Box, Stack } from '@mui/material'
import { LeftDrawer } from '../left-drawer'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Collection(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const app  = useSelector( (state: any) => state.app )
    const collection  = useSelector( (state: any) => state.collection )
    const itemPanel  = useSelector( (state: any) => state.itemPanel )
    const [filterOpen, setFilterOpen] = useState(false);
    const location = useLocation();

    const pathArray = location.pathname.split('/')
    // console.log(pathArray)
    let CollectionId: string | null = null

    if ( pathArray.length >= 2 ) {
        CollectionId = pathArray[2]
    } else {
        navigate('/404')
    }

    if (CollectionId === collection.CollectionId && collection.notFound){
        navigate('/404')
    }

    const cll: I.Collection = collection.collection && CollectionId === collection.collection.CollectionId
        ? collection.collection
        : null


    // const path = window.location.href.split('/')
    // const isItemPanelView = useMemo(()=> pathArray.length >= 4 &&  pathArray[3] === 'view', [pathArray]);

    // useEffect(() => {
    //     if (isItemPanelView && !itemPanel.show) {
    //         dispatch(panelActions.viewCollectionItem(cll.CollectionId, pathArray[4], 0, '')) 
    //     } else if (!isItemPanelView && itemPanel.show) {
    //         dispatch(panelActions.closeCollectionItemPanel()) 
    //     }
    // }, [isItemPanelView, itemPanel.show])

    if ( cll && !itemPanel.show && pathArray.length >= 4 &&  pathArray[3] === 'view') {
        dispatch(panelActions.viewCollectionItem(cll.CollectionId, pathArray[4], 0, ''))   
    } 

    
    useEffect( () => {
        dispatch<any>(collectionActions.loadCollection(CollectionId ? CollectionId : '' ))
    }) 

    const [tabIndex, setTabIndex] = React.useState(0);
    const changeTabIndex = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    // let viewItems: I.CollectionItem[] = []
    // let totalItems: null|number = null
    // let itemsOnPage: null|number = null
    // let preparedItems: I.CollectionItem[] = []

    const {viewItems, totalItems, itemsOnPage, preparedItems} = useMemo(() => {

        let viewItems: I.CollectionItem[] = []
        let totalItems: null|number = null
        let itemsOnPage: null|number = null
        let preparedItems: I.CollectionItem[] = []
        
        if (!cll) {
            return {viewItems, totalItems, itemsOnPage, preparedItems}
        }

        preparedItems = cll.preparedItems

        if (cll.viewProps.filterString.length > 0) {
            viewItems = preparedItems.filter((item: I.CollectionItem)=> (
                cll.viewProps.filterStringItems.indexOf(item.CollectionItemId) >=0
            ))
        } else {
            viewItems = preparedItems.filter((item: I.CollectionItem)=> (
                (
                    !cll.viewProps.catalogOpen ||
                    cll.viewProps.catalogItems.length === 0 ||
                    cll.viewProps.catalogItems.indexOf(item.CollectionItemId) >=0
                ) 
                &&
                (
                    cll.viewProps.filterItems.length === 0 ||
                    cll.viewProps.filterItems.indexOf(item.CollectionItemId) >=0
                )
            ))
        }

        totalItems = viewItems.length 
        itemsOnPage = 
            cll.viewProps.itemsOnPage === -1
            ? totalItems
            : totalItems - cll.viewProps.startItem < cll.viewProps.itemsOnPage * 1.3
                ? totalItems - cll.viewProps.startItem
                : cll.viewProps.itemsOnPage

        viewItems = viewItems.slice(cll.viewProps.startItem, cll.viewProps.startItem + itemsOnPage)
        if (itemsOnPage + cll.viewProps.startItem < viewItems.length) {
            itemsOnPage = totalItems - cll.viewProps.startItem
        }        

        return {viewItems, totalItems, itemsOnPage, preparedItems}

    }, [cll])

    const addItemClick = () => {
        if (cll) {
            dispatch(panelActions.addCollectionItem(cll.CollectionId))
        }
    }

    // console.log('Collections', viewItems)

    useEffect(() => {
        // console.log('save', cll)
        if (CollectionId && cll && cll.CollectionId === CollectionId) {
            sessionStorage.setItem('cll.' + CollectionId, JSON.stringify(({
                catalogCheckedList: cll.viewProps.catalogCheckedList, 
                catalogOpen: cll.viewProps.catalogOpen, 
                filterString: cll.viewProps.filterString, 
                filterStringItems: cll.viewProps.filterStringItems,
                itemsOnPage: cll.viewProps.itemsOnPage, 
                sorting: cll.viewProps.sorting, 
                sortingReverce: cll.viewProps.sortingReverce, 
                startItem: cll.viewProps.startItem, 
                view: cll.viewProps.view, 
                viewDropped: cll.viewProps.viewDropped, 
                viewNoImages: cll.viewProps.viewNoImages, 
                viewOnlyDropped: cll.viewProps.viewOnlyDropped, 
                viewOnlyToFind: cll.viewProps.viewOnlyToFind, 
                viewProvenance: cll.viewProps.viewProvenance, 
                viewToFind: cll.viewProps.viewToFind, 
                viewZeroItems: cll.viewProps.viewZeroItems, 
                catalogItems: cll.viewProps.catalogItems, 
                filterItems: cll.viewProps.filterItems, 
                catalog: cll.catalog,
                filterGroups: cll.filterGroups,
                ignoreChildItems: cll.viewProps.ignoreChildItems,
            })))
        }

    }, [cll, CollectionId])

    const onFilterClick = useCallback(() => {
        setFilterOpen(true);
    }, []);

    return (

        !cll
        ?<Loader />
        :<>
            <Header
                mobileMode={app.mobileMode}
                cll={cll}
                totalItems={totalItems}
                preparedItems={preparedItems}
                addItemClick={addItemClick}
            />
            {app.mobileMode || cll.role !== 'owner'
            ? <Box sx={{ width: '100%', marginTop: 3 }}>
                <Box sx={{borderTop: 1, borderColor: 'divider'}}></Box>
            </Box>
            : <Menu tabIndex={tabIndex} changeTabIndex={changeTabIndex} />
            }
            <Box sx={{
                height: '0.125rem',
                // marginTop: '0.5rem'
                marginTop: '-4px'
            }}>
                {collection.loading ? <LinearProgress /> : null}
            </Box>
            {tabIndex === MENU_ITEMS
            ? <>
            {/* <SideMenuButton caption='Фильтр' onClick={onFilterClick} /> */}
            <Stack direction='row' gap={4} sx={{
                position: 'relative'
            }}>
                <Box
                    sx={{
                        paddingTop: 2,
                        width: '18em', 
                        minWidth: '18em', 
                        display: {xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                        position: 'relative',
                    }}
                >
                    <Filter cll={cll} />
                </Box>
                {/* <div className="cm-content"> */}
                <ItemsList 
                    mobileMode={app.mobileMode}
                    cll={cll}
                    viewItems={viewItems}
                    itemsOnPage={itemsOnPage}
                    totalItems={totalItems}
                    onFilterClick={onFilterClick}
                />
                {/* </div> */}
                </Stack>
                <LeftDrawer 
                    open={filterOpen}
                    onClose={() => setFilterOpen(false)}       
                    caption='Фильтр'
                    subCaption={
                        totalItems !== preparedItems.length
                        ? totalItems + ' из ' + preparedItems.length
                        : preparedItems.length
                        } 
                    component={Filter}
                />

            </>
            : tabIndex === MENU_SETTINGS ?<Settings cll={cll} />
            : tabIndex === MENU_EXPORT_IMPORT ?<ExportImport collection={cll} />
            : null
            }

        
        </>
    )
}



