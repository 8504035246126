import * as I from '../../interfaces'


export function customItems(items: I.CollectionItem[], valuesMap: I.ValuesMap[], listSorting: boolean, fieldName: string, titleNone: string, titleOther: string): I.FilterItem[] {
    if (listSorting) {
        valuesMap.sort((a, b) => {
            if (a.filter.toLowerCase() < b.filter.toLowerCase()) {
                return -1
            } 
            return 1
        })
    }
    let result: I.FilterItem[] = []

    result.push({description: titleNone, isChecked: false, itemsList: 
        items
        .filter(item => {
            const idx = item.fields.findIndex(f => f.name === fieldName)
            if (idx >= 0 && !item.fields[idx].value) return true
            return false
        })
        .map(item => (item.CollectionItemId))
    })

    result = result.concat(valuesMap.map((el, index: number) => ({       
        description: el.filter,
        isChecked: false,
        itemsList: items
            .filter(item => {
                const idx = item.fields.findIndex(f => f.name === fieldName)
                if (idx >= 0 && el.value.split('\n').includes(item.fields[idx].value) ) return true
                return false
            })
            .map(item => (item.CollectionItemId))
    })))

    let arrValues: string[] = []
    result.forEach(res => { arrValues = arrValues.concat(res.itemsList) })

    result.push({description: titleOther, isChecked: false, itemsList: 
        items
        .filter(item => {
            const idx = item.fields.findIndex(f => f.name === fieldName)
            if (idx >= 0 && item.fields[idx].value) {
                if (arrValues.indexOf(item.CollectionItemId) < 0) return true
                return false
            }
            return false
        })
        .map(item => (item.CollectionItemId))
    })

    return result.filter(el => el.itemsList.length > 0)
}