import { useState } from 'react'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import { locale } from '../../../ui/locale'
import { Button, MenuItem, Popover, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { menuItems } from './Menu'

export const MENU_COVER = 0
export const MENU_PROPS = 1
export const MENU_CATALOG = 2
export const MENU_FIELDS = 3
export const MENU_VIEW = 4
export const MENU_FILTERS = 5
export const MENU_SORT = 6
export const MENU_FORM = 7
export const MENU_LIST = 8

export default function FloatMenu(p: any){
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <div>
            <Button
                onClick={event => setAnchorEl(event.currentTarget)} 
                color="inherit"
                startIcon={menuItems[p.editTabIndex].icon}   
                endIcon={<ExpandMoreIcon />}   
                disabled={p.loading}
            >
                <Typography noWrap sx={{fontSize: '1.25rem'}}>{locale(menuItems[p.editTabIndex].name)}</Typography>
            </Button>

                <Popover
                    id="menu-user"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {menuItems.map((item, index: number) => (
                        <MenuItem 
                            key={index}
                            // autoFocus={index === p.editTabIndex}
                            selected={index === p.editTabIndex}
                            // autoFocus={true}
                            onClick={event => {
                                setAnchorEl(null)
                                p.onMenuClick(item.index)
                            }}
                        >
                            <ListItemIcon>
                                {menuItems[index].icon}
                            </ListItemIcon>
                            <ListItemText>
                                {locale(item.name)}
                            </ListItemText>
                        </MenuItem>  
                    ))
                    }
                </Popover> 
        </div>
    )

}

