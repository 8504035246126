import { Box } from '@mui/material'
import React from 'react'
import EditDropDownText from '../../../ui/editors/editDropDownText'
import EditField from '../../../ui/editors/editField'
import EditMemo from '../../../ui/editors/editMemo'
import { locale } from '../../../ui/locale'
import { gridStyle } from '../../../style/styles'

// export default function EditSource(p: any) {
function EditSource(p: any) {

    const disabled = !p.toEdit.source || !!p.toEdit.source.complect

    return(
        <>
            
            {/* <div className="cm-edit-prov-name"> */}
                <EditDropDownText
                    value={p.toEdit.source.complect || ''}
                    fieldName="complect"
                    onChangeField={p.onChangeField}
                    list={p.valuesMap.get('_source.complect')}
                    label={locale('itemEdit.complect')}
                />
                <EditField
                    key="source"
                    disabled={disabled}
                    freeSolo={true}
                    value={p.toEdit.source.description || ''}
                    fieldName="description"
                    onChangeField={p.onChangeField}
                    list={p.valuesMap.get('_source.description')}
                    label={locale('itemEdit.source')}
                />
                <EditField
                    key="sourceDetails"
                    disabled={disabled}
                    freeSolo={true}
                    value={p.toEdit.source.details || ''}
                    fieldName="details"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.details')}
                />
            {/* </div> */}
            <Box sx={gridStyle}>
                <EditField
                    key="sourceDate"
                    disabled={disabled}
                    span={4}
                    freeSolo={true}
                    value={p.toEdit.source.date || ''}
                    fieldName="date"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.date')}
                />
                <EditField
                    key="sourceCurrency"
                    disabled={disabled}
                    span={4}
                    freeSolo={true}
                    value={p.toEdit.source.currency || ''}
                    fieldName="currency"
                    onChangeField={p.onChangeField}
                    list={p.valuesMap.get('_source.currency')}
                    label={locale('itemEdit.currency')}
                />
                <EditField
                    key="sourcePrice"
                    span={4}
                    freeSolo={true}
                    value={p.toEdit.source.price || ''}
                    fieldName="price"
                    onChangeField={p.onChangeField}
                    list={[]}
                    label={locale('itemEdit.price')}
                />
            </Box>             
            <EditMemo
                key="otherDetails"
                disabled={disabled}
                value={p.toEdit.source.info || ''}
                fieldName="info"
                onChangeField={p.onChangeField}
                label={locale('itemEdit.info')}
            />
            <EditField
                key="storageInfo"
                freeSolo={true}
                value={p.toEdit.source.storage || ''}
                fieldName="storage"
                onChangeField={p.onChangeField}
                list={p.valuesMap.get('_source.storage')}
                label={locale('itemEdit.storage')}
            />
        </>

    )
}

export default React.memo(EditSource)