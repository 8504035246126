import React, { useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import * as http from '../../../../modules/http'
import * as collectionActions from '../../../../store/collection/collectionActions'
import AddIcon from '@mui/icons-material/Add'
import Edit from './Edit';
import Items from './Items'
import { locale } from '../../../../ui/locale'
import { Box } from '@mui/material'
import { twoPanelsStyle } from '../../../../style/styles'

export default function Complect(p: any) {
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        loading: false, 
        editIndex: -1, 
        edited: false,
        error: null
    });
    const [toEdit, setToEdit] = useState(p.collection.complect)
    const CollectionId = p.collection.CollectionId

    useEffect( () => {
        setToEdit(p.collection.complect) 
    }, [p.collection])

    const setEdited = () => {
        setPageState({...pageState, edited: true})
    }

    const addComplect = () => {
        const index = toEdit.length
        setToEdit([...toEdit, {
            name: '',
            description: '',
            details: '',
            currency: '',
            date: '',
            price: '',
            info: '',
        }])
        setPageState({...pageState, editIndex: index, edited: true})
    }
    const onEditClick = (index: number) => {
        if (!pageState.edited) {
            setPageState({...pageState, editIndex: index})
        }
    }

    const saveComplect = async (formData: any) => {
        setPageState((prev) => ({...prev, loading: true}))
        formData.complect = formData.complect.filter((complect: any) => complect.name)
        setToEdit(formData.complect)
        const res = await http.httpPost('/cll/save', formData, true)
        if (res.success) {
            setPageState((prev) => ({...prev, loading: false, edited: false}))
            dispatch(collectionActions.collectionChangeProps(res.data.props))
        } else {
            setPageState((prev) => ({...prev, loading: false, error: res.data}))
        }
    }
    const onSaveClick = async (itemData: any) => {
        const formData = {
            CollectionId: CollectionId,
            complect: [...toEdit.slice(0, pageState.editIndex), itemData, ...toEdit.slice(pageState.editIndex + 1)]
        }
        saveComplect(formData)
    }
    const onDeleteClick = () => {
        const formData = {
            CollectionId: CollectionId,
            complect: [...toEdit.slice(0, pageState.editIndex), ...toEdit.slice(pageState.editIndex + 1)]
        }        
        saveComplect(formData)
        setPageState({...pageState, editIndex: -1, edited: false})
    }
    const onCancelClick = () => {
        setToEdit([...toEdit.filter((complect: any) => complect.name) ])
        setPageState({...pageState, edited: false})
    }

    return (
            
        <Box sx={twoPanelsStyle}>
            <div>
            <Button                 
                size="medium"
                variant="outlined"
                color="primary"
                onClick={event => addComplect()}
                startIcon={<AddIcon />}                    
                disabled={pageState.loading || pageState.edited}
            >
                {locale('collections.complect.addComplect') }
            </Button>
            <Items 
                complect={toEdit}
                onEditClick={onEditClick}
                editIndex={pageState.editIndex}
                edited={pageState.edited}
                loading={pageState.loading}
            />
            </div>
            <Edit 
                complect={pageState.editIndex >=0 ? toEdit[pageState.editIndex] : null}
                onSaveClick={onSaveClick}
                onDeleteClick={onDeleteClick}
                onCancelClick={onCancelClick}
                setEdited={setEdited}
                edited={pageState.edited}
                loading={pageState.loading}
            />
        </Box>
    )
    
}