import { ArrowBackIos } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { locale } from '../../ui/locale'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import * as I from '../../store/interfaces'

export const ToolbarView = ({app, goBack, cll, goEdit, goCopy}: any) => {
    return (
        !app.mobileMode
            ? <Box sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gap: 0,
                gridGap: 0
            }}>
                <span>
                    <Button
                        id="back"
                        className="btn link"
                        size="medium"
                        startIcon={<ArrowBackIos />}
                        variant="text"
                        color='primary'
                        onClick={goBack}
                    >
                        {locale('itemView.goToCollection')}
                    </Button>
                </span>
                {I.isEditAccess(cll.role) && !app.mobileMode
                    ? <Box sx={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gridGap: '0.5rem',
                        gap: '0.5rem',
                        justifySelf: 'end'
                    }}>
                        <Button
                            id="save"
                            size="medium"
                            variant="outlined"
                            onClick={goEdit}
                            type="submit"
                            startIcon={<EditIcon />}
                        >
                            {locale('common.edit')}
                        </Button>
                        <Button
                            id="save"
                            sx={{
                                borderColor: 'divider',
                                '&:hover': { borderColor: 'rgba(0, 0, 0, 0.5)' }
                            }}
                            size="medium"
                            variant="outlined"
                            onClick={goCopy}
                            type="submit"
                            startIcon={<FileCopyIcon />}
                        >
                            Копировать{/* {locale('common.edit')} */}
                        </Button>

                    </Box>
                    : null}
            </Box>
            : null

    )
}