import React from 'react'
import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import * as I from '../../../store/interfaces'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FilterCheckBox } from '../../../modules/filter-check-box';

export default function Catalog (p: any) {
    const dispatch = useDispatch();

    const onCheckClick = (id: string) => {
        dispatch(collectionActions.catalogCheck(id))
    }

    const onOpenClick = (id: string) => {
        dispatch(collectionActions.catalogOpen(id))
    }
    // console.log(p.items)
    return (
        <List dense disablePadding>
        {
            p.items
            .filter((CatalogItem: I.CatalogItem) => CatalogItem.itemsListGroup.length > 0)
            .map((CatalogItem: I.CatalogItem, index: number)=>{
            return(
            <React.Fragment key={CatalogItem._id}>
            <ListItem 
                // key = {CatalogItem._id} 
                sx={{
                    cursor: 'pointer',
                    paddingLeft: p.level*2 + 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontSize: '.875rem',
                    fontWeight: 600,
                    alignItems: 'flex-start',
                    color: CatalogItem.isChecked ? 'text.primary' : 'action.active',
                    '&:hover': {color: 'text.primary'}
                }}
                // button 
                onClick={(event => onCheckClick(CatalogItem._id))}
            >
                <FilterCheckBox checked={CatalogItem.isChecked} />

                <div style={{width:  (180 - 24 * p.level) + 'px'}}>
                    {CatalogItem.description}
                </div>
                <div className="filter-catalog-item">
                    {CatalogItem.isOpen
                    ? CatalogItem.itemsList.length > 0 ? CatalogItem.itemsList.length : null
                    : CatalogItem.itemsListGroup.length > 0 ? CatalogItem.itemsListGroup.length : null
                    }
                </div>
                {!CatalogItem.items || CatalogItem.items.filter(item => item.itemsListGroup.length > 0 ).length === 0
                ? null
                :<ListItemSecondaryAction >
                    <IconButton edge="end" size="small"
                        onClick={(event => onOpenClick(CatalogItem._id))}
                    >
                        {CatalogItem.isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ListItemSecondaryAction>
                }
            
            </ListItem> 
            {CatalogItem.items
            ? <Collapse key={'x' + CatalogItem._id} in={CatalogItem.isOpen} timeout="auto" unmountOnExit>
                <Catalog 
                    items={CatalogItem.items.filter(item => item.itemsListGroup.length > 0 )}
                    level={p.level + 1}
                    onToggle={p.onToggle}
                    onToggleCloseOpen={p.onToggleCloseOpen}
                />
             </Collapse>
            : null
            }
            </React.Fragment>
            )})
        }
        </List>
    )
}