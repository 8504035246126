import React from 'react'
import EditMemo from '../../../ui/editors/editMemo'
import { locale } from '../../../ui/locale'
import FormControlLabel from '@mui/material/FormControlLabel'
import { IOSSwitch } from '../../../modules/ios-switch'

export default function EditDropped(p: any) {

    return(
        <div className="cm-edit-dropped">
            <div className="form-row form-switch">
                <FormControlLabel
                    sx={{paddingLeft: '1rem', gap: '.5rem'}}
                    control={
                        <IOSSwitch
                            disabled={p.disabled}
                            checked={p.toEdit.itemDropped}
                            value={p.toEdit.itemDropped}
                            onChange={() => p.onChangeDropped()}
                            size="small"
                            name="framed"
                            color="primary"
                        />  
                    } 
                    labelPlacement="end"
                    label={locale('itemEdit.itemDropped')}
                />
            </div>
            {p.toEdit.itemDropped
            ? <EditMemo
                key="droppedInfo"
                disabled={p.disabled}
                value={p.toEdit.droppedInfo || ''}
                fieldName="droppedInfo"
                onChangeField={p.onChangeField}
                label={locale('itemEdit.droppedInfo')}
            />
            : null
            }
        </div>

    )
}