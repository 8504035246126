import { Box } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles';

export const FilterCheckBox = (props: any) => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'block',
            position: 'relative',
            width: '1.125rem',
            height: '1.125rem',
            borderRadius: '0.25rem',
            border: '1px solid rgba(0,0,0,.3)',
            backgroundColor: props.checked ? theme.palette.primary.main :'transparent',
            borderColor: props.checked ? theme.palette.primary.main : undefined,
            transition: 'all .2s ease-in-out',
            content: '""',
            marginRight: '0.5rem',
            flexShrink: 0,
        }} >
            {props.checked
            ? <Box sx={{
                position: 'absolute',
                top: '0.2rem',
                left: '0.4rem',
                width: '0.25rem',
                height: '0.5rem',
                borderLeftColor: '#fff',
                borderBottom: '0.125rem solid #fff',
                borderRight: '0.125rem solid #fff',
                borderTopColor: '#fff',
                backgroundColor: 'transparent',
                transform: 'rotate(52deg)'           
            }} />
            : null
            }

        </Box>

    )
}