
import * as React from 'react';
import { Box, FormControlLabel } from '@mui/material';
import { locale } from '../ui/locale';
import { IOSSwitch } from './ios-switch';

export const FilterSwitch = (props: any) => (

    <FormControlLabel 
        label={locale(props.label)} 
        labelPlacement='start'
        sx={{
            width: '100%',
            margin: '6px 0px',
            justifyContent: 'space-between',
            color: props.checked ? 'text.primary' : 'action.active'
        }}
        control={
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                {props.qty}
                <IOSSwitch
                    size="small"
                    checked={props.checked}
                    onChange={props.onChange}
                    name={props.name}
                    color="primary"
                />
            </Box>}
    /> 
);