import * as I from '../interfaces'
import { fieldValue } from '../interfaces'

function fieldSetValue(fields: I.FieldSet[], set: I.ViewSetFields[], item: I.CollectionItem): string {
    let result = ""
    set.forEach((field, index) => {
        const value = fieldValue(item, fields, field)
        result = result +         
        (
            value
            ? 
            (index > 0 ? ', ' : '') +
            (field.prefix ? field.prefix + ' ': '') + 
            value + 
            (field.suffix ? ' ' + field.suffix: '')
            : ""
        )
    })
    return result
}

function setTitle(fields: I.FieldSet[], set: I.ViewSetFields[], item: I.CollectionItem) {
    item.title = fieldSetValue(fields, set, item)
    // let title = ""
    // set.forEach((field, index) => {
    //     const value = fieldValue(item, fields, field)
    //     title = title +         
    //     (
    //         value
    //         ? 
    //         (index > 0 ? ', ' : '') +
    //         (field.prefix ? field.prefix + ' ': '') + 
    //         value + 
    //         (field.suffix ? ' ' + field.suffix: '')
    //         : ""
    //     )
    // })
    // item.title = title
}

function setCatalogName(catalog: I.CatalogItem[], fields: I.FieldSet[], set: I.ViewSetFields[], item: I.CollectionItem) {
    const index1 = catalog.findIndex(cat => cat._id === item.catalogLevel1)
    if (index1 >= 0) {
        item.catalog = catalog[index1].description
        item.catalogOrderNr = ('000' + index1).slice(-3)

        const index2 = catalog[index1].items.findIndex(cat => cat._id === item.catalogLevel2)
        if (index2 >= 0) {
            item.catalog = item.catalog + ', ' + catalog[index1].items[index2].description
            item.catalogOrderNr = item.catalogOrderNr + ('000' + index2).slice(-3)

            const index3 = catalog[index1].items[index2].items.findIndex(cat => cat._id === item.catalogLevel3)
            if (index3 >= 0) {
                item.catalog = item.catalog + ', ' + catalog[index1].items[index2].items[index3].description
                item.catalogOrderNr = item.catalogOrderNr + ('000' + index3).slice(-3)
            }
        }
    }
    item.catalog = fieldSetValue(fields, set, item)
}

export function prepareItems (data: I.Collection, viewProps: I.ViewProps) {

    data.preparedItems = data.items
    .filter((item: I.CollectionItem) =>  
        (
            (viewProps.viewToFind || !item.itemToFind)
            &&
            (viewProps.viewDropped || !item.itemDropped)
            &&
            (viewProps.viewNoImages || item.images.length > 0 || !data.noPhotoFilter)
            &&
            (!viewProps.viewToFind || !viewProps.viewOnlyToFind || item.itemToFind)
            &&
            (!viewProps.viewDropped || !viewProps.viewOnlyDropped || item.itemDropped)
            &&
            (!data.useQuantity || viewProps.viewZeroItems || item.qty > 0)
        )
    )
    //Посчитаем количество для свитчей
    data.viewItemsQty = {
        toFind: data.items.filter(item => item.itemToFind).length,
        zeroQty: data.items.filter(item => item.qty === 0).length,
        dropped: data.items.filter(item => item.itemDropped).length,
        noImage: data.items.filter(item => 
            (viewProps.viewToFind || !item.itemToFind )&& 
            (viewProps.viewDropped || !item.itemDropped) &&
            (!item.images || item.images.length === 0)
        ).length,
    }
    
}

export function prepareProvenance(data: I.Collection) {
    data.items.forEach(item => {
        if (item.source && item.source.complect) {
            const index = data.complect.findIndex(complect => complect.name === item.source.complect)
            if (index >= 0) {
                item.source.info = data.complect[index].info
                item.source.description = data.complect[index].description
                item.source.details = data.complect[index].details
                item.source.date = data.complect[index].date
                item.source.currency = data.complect[index].currency
                item.source.price = data.complect[index].price + (item.source.price ? ' / ' + item.source.price : '')
            }
        }
    })

    if (data.preparedItems) {
        data.preparedItems.forEach(item => {
            if (item.source && item.source.complect) {
                const index = data.complect.findIndex(complect => complect.name === item.source.complect)
                if (index >= 0) {
                    item.source.info = data.complect[index].info
                    item.source.description = data.complect[index].description
                    item.source.details = data.complect[index].details
                    item.source.date = data.complect[index].date
                    item.source.currency = data.complect[index].currency
                    item.source.price = data.complect[index].price + item.source.price ? ' / ' + item.source.price : ''
                }
            }
        })
    }
}

function catalogArr(item: I.CollectionItem) {
    const arr = [item.catalogLevel1]
    if (item.catalogLevel2) {
        arr.push(item.catalogLevel2)
        if (item.catalogLevel3) {
            arr.push(item.catalogLevel3)
        }
    }
    return arr
}
function catalogId(item: I.CollectionItem) {
    return item.catalogLevel3
        ? item.catalogLevel3
        : item.catalogLevel2
        ? item.catalogLevel2
        : item.catalogLevel1
}

function prepareCatalogSet(items: I.CollectionItem[]){
    items.forEach(item => {
        const list: string[] = [catalogId(item)]
        let listGroup: string[] = catalogArr(item)
        item.items.forEach(child => {
            list.push(catalogId(child))
            listGroup = listGroup.concat(catalogArr(child))
        })
        item.catalogList = Array.from(new Set(list))
        item.catalogListGroup = Array.from(new Set(listGroup))
    })
}

export function prepareData(data: I.Collection) {

    const viewProps = data.viewProps 

    //подготовим сеты для просмотра
    const catalogIndex = data.view.findIndex((el: any) => el.setName === 'catalog')
    const titleIndex =  data.view.findIndex((el: any) => el.setName === 'title')
    const pictureIndex =  data.view.findIndex((el: any) => el.setName === 'picture')
    const labelIndex = data.view.findIndex((el: any) => el.setName === 'label')
    const gridIndex = data.view.findIndex((el: any) => el.setName === 'grid')

    data.viewSet={catalogSet:[], titleSet:[], pictureSet:[], labelSet:[], gridSet:[]}

    data.viewSet.catalogSet = catalogIndex >=0 ? data.view[catalogIndex].fields : []
    data.viewSet.titleSet = titleIndex >=0 ? data.view[titleIndex].fields : []
    data.viewSet.pictureSet = pictureIndex >=0 ? data.view[pictureIndex].fields : []
    data.viewSet.labelSet = labelIndex >=0 ? data.view[labelIndex].fields : []
    data.viewSet.gridSet = gridIndex >=0 ? data.view[gridIndex].fields : []
    
    try {
        prepareProvenance(data)

        let catalog = data.catalog

        data.items.forEach(item => {
            //установим название каталога и строку для сортировки
            setCatalogName(catalog, data.fields, data.viewSet.catalogSet, item)
            setTitle(data.fields, data.viewSet.titleSet, item)
            //перенесем подчиненных к родителям
            if (!item.itemSubNumber && !viewProps.ignoreChildItems) {
                    item.items = data.items
                        .filter(el => el.itemNumber === item.itemNumber && el.itemSubNumber)
                        .sort((a, b) => a.itemSubNumber < b.itemSubNumber ? -1 : 1)
            } else {
                item.items = []
            }
        })
        //очистим массив от подчиненных
        // if (data.useChildItems && !viewProps.ignoreChildItems) {
        // if (data.useChildItems && !true) {

        if (!viewProps.ignoreChildItems) {
            data.items = data.items.filter( el => !el.itemSubNumber)
            // data.items = data.items.filter( el => true)
        } 

        //Каталог для коллекций с вложениями
        if (data.useChildItems && !viewProps.ignoreChildItems) {
            prepareCatalogSet(data.items)
        }

        //Отфильруем видимое согласно флагов свитчей
        prepareItems(data, viewProps)

    

    } catch(err) {
        console.error(err)
    }
}