import React from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material'
import { SettingsTableRow } from '../../../../modules/settingsTableRow'
import { SettingsTableCell } from '../../../../modules/settingsTableCell'
import { SettingsTableHeadCell } from '../../../../modules/settingsTableHeadCell'


export default function Items(p: any) {

    return(
        p.sort.length === 0
        ? null
        :<TableContainer>
        <Table sx={{opacity: p.loading || p.edited ? '0.5' : undefined }}>
            <TableHead>
                <TableRow>
                    <SettingsTableHeadCell>Наименование</SettingsTableHeadCell>
                    <SettingsTableHeadCell></SettingsTableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {p.sort
                .filter((sort: any) => sort.setName)
                .map((sort: any, index: number) => (
                <SettingsTableRow 
                    key={index} 
                    selected={p.editIndex === index }
                >
                    <SettingsTableCell 
                        selected={p.editIndex === index}
                        onClick={() => p.onEditClick(index)}
                    >
                        {sort.setName}
                    </SettingsTableCell>
                
                    {p.editIndex === index && !p.edited
                    ?<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                        width="70px"
                        selected={p.editIndex === index}
                    >
                        <IconButton 
                            size="small" 
                            color="primary"
                            disabled={index === 0 || p.edited}
                            onClick={event => p.onMoveField(event, index, false)}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton 
                            size="small" 
                            color="primary"
                            disabled={index === p.sort.length - 1 || p.edited}
                            onClick={event => p.onMoveField(event, index, true)}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </SettingsTableCell>
                    :<SettingsTableCell sx={{padding: "0rem 0.2rem"}} 
                        width="70px"
                        selected={p.editIndex === index}
                        onClick={() => p.onEditClick(index)}>                            
                    </SettingsTableCell>  
                    }
                </SettingsTableRow>         
                ))}  
                   
            </TableBody>
        </Table>
        </TableContainer>
    )
}