import React, { useState, useEffect }  from 'react'
import {useSelector} from 'react-redux'
import { Loader } from '../../../modules/Loader'
import { locale } from '../../../ui/locale'
import * as http from '../../../modules/http'
import * as I from '../../../store/interfaces'
import Menu from './Menu'
import { LineLoader } from '../../../modules/line-loader'
import { useNavigate } from 'react-router-dom'


export default function Users(p: any) {
    const auth = useSelector( (state: any) => state.auth )
    const navigate = useNavigate();
    
    const [pageState, setPageState] = useState({loaded: false, loading: false, error: null});
    const [list, setList] = useState([])

    if (auth.auth) {
        if (!auth.roles.includes('admin')) {
            navigate('/')
        }
    }

    useEffect( () => {
        if (!pageState.loaded) {
            (async () => {
                setPageState(prev => ({...prev, loading: true}))
                const res = await http.httpGet('/admin/users', {}, true)
                if (res.success) {
                    setList(res.data)
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                } else {
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                }    
            })()
        }    
    }, [pageState.loaded])

    const reloadList = () => {
        setPageState({...pageState, loaded: false})
    }

    const setLoading = (loading: boolean) => {
        setPageState({...pageState, loading: loading})
    }

    return (
        !auth.auth || !auth.roles.includes('admin')
        ? <Loader />
        : <>
        <div className="cm-content">
        {/* <div className="cm-toolbar">
            <span>
                <Button 
                    id="save" 
                    size="medium"
                    variant="outlined"
                    onClick={event => createCollection()}
                    color="primary"
                    type="submit"
                >
                    {locale('create.create')}
                </Button>
            </span>
        </div> */}
        
        <div style={{maxWidth:'60rem'}}>
        <LineLoader active={pageState.loading} />
        <table className="cm-table users">
            <tbody>
            {list.map((user: any, index: number) => (
            <tr key={index}>
                <td>
                {user.disabled
                    ? <><h4 style={{color:'gray'}}>{user.userName}</h4><span>{locale('admin.users.disabled')}</span></>
                    : <h4>{user.userName}</h4>
                    }
                    
                </td>
                <td>
                    {!user.emailVerified
                    ? <span style={{color:'red'}}>{user.email}</span>
                    : user.email
                    }
                </td>
                <td>
                    {user.roles.join(', ')}
                </td>
                <td>
                    {I.timeString(new Date(user.createdAt))}
                </td>
                <td style={{textAlign:'center'}}>
                    <Menu
                        // userId={user._id}
                        user={user}
                        reloadList={reloadList}
                        loading={pageState.loading}
                        setLoading={setLoading}
                    />
                </td>
            </tr>
            ))}
            </tbody>
        </table>        
        </div>
        {/* </div> */}

        </div>
        </>
    )
}
