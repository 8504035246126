import { Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const SideMenuButton = (props: any) => {
    // const [open, setOpen] = useState(false);

    return (        
        <Box
            onClick={props.onClick}
            // onMouseEnter={() => setOpen(true)}
            // onMouseLeave={() => setOpen(false)}

            sx={{
                height: 26,
                cursor: 'pointer',
                position: 'fixed',
                left: 0,
                padding: 0.5,
                paddingTop: 1,
                paddingBottom: 1,
                borderRight: '2px solid',
                borderTop: '2px solid',
                borderBottom: '2px solid',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                borderColor: 'primary.light',
                boxShadow: '0 -0.125rem 0.25rem rgb(0 0 0 / 8%)',
                display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' },
                alignItems: 'center',
                backgroundColor: 'background.paper',
                color: 'primary.main',
                zIndex: 10,
            }}
        >
            {/* {open ? props.caption : null} */}
            <ArrowForwardIosIcon color="primary"/>
        </Box>
    )
}