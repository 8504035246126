import { Box } from '@mui/material';


export default function EditFrame() {

    return(        
        <Box sx={{
            // width: '100%', 
            // height: '100%', 
            border: '1px dashed', 
            borderColor: 'divider', 
            padding: '2rem', 
            color: 'text.disabled',
            textAlign: 'center'
        }}>
            Выберите объект для редактирования из списка
        </Box>
    )
}