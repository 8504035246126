import React, { useMemo } from 'react'
import { locale } from '../../../ui/locale';


export const ViewSource: React.FC<any> = (p) => {

    const visible = useMemo(() => (
        (p.item.source && (p.item.source.description || p.item.source.details || p.item.source.price || p.item.source.date) )
        ||
        (p.item.itemDropped && p.item.droppedInfo)
    )
    , [p.item.source, p.item.itemDropped, p.item.droppedInfo]);

    return(

        <div className="source-box">
            {p.showCaption ? <h5>{locale('itemView.provenance')}</h5> : null}
            {visible
            ?
            <>
                {p.item.source.complect
                ?<dl>
                    <dt>
                        {locale('itemView.fromComplect')}
                    </dt>
                    <dd>
                        {p.item.source.complect}
                    </dd>
                </dl>
                :null
                }
                
                {p.item.source.description || p.item.source.description
                ?<dl>
                    <dt>
                        {locale('itemView.sourceDescription')}
                    </dt>
                    <dd>
                        {p.item.source.description ? <span>{p.item.source.description}</span> : null }
                        <span> {p.item.source.details}</span>
                    </dd>
                </dl>
                : null
                }
                {p.item.source.date
                ? <dl>
                    <dt>
                        {locale('itemView.sourceDate')}
                    </dt>
                    <dd>
                        <span>{p.item.source.date}</span>
                    </dd>
                </dl>
                : null
                }
                {p.item.source.currency || p.item.source.price
                ? <dl>
                    <dt>
                        {locale('itemView.sourcePrice')}
                    </dt>
                    <dd>
                        {p.item.source.currency ? <span>{p.item.source.currency}</span> : null}
                        &nbsp;
                        <span>{p.item.source.price}</span>
                    </dd>
                </dl>
                : null
                }
                {p.showInfo && p.item.source.info
                ? <dl>
                    <dt>
                        {locale('itemView.sourceInfo')}
                    </dt>
                    <dd>
                        <span>{p.item.source.info.replace(/<br>/gi, String.fromCharCode(10))}</span>
                    </dd>
                </dl>
                : null
                }
                {p.item.itemDropped && p.item.droppedInfo
                ? <dl>
                    <dt>
                        {locale('itemView.droppedInfo')}
                    </dt>
                    <dd>
                        <span>{p.item.droppedInfo.replace(/<br>/gi, String.fromCharCode(10))}</span>
                    </dd>
                </dl>
                : null
                }
            </>
            : <div className="nosource">
                Сведения не указаны
            </div>
            }
        </div>     
        // : p.cardClass!=='lines' ? null : <div className="source-box" />    
        
    )

}