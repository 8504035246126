import { combineReducers } from 'redux'
import { app } from './app/appReducer'
import { auth } from './auth/authReducer'
import { itemPanel } from './panel/panelReducer'
import { list } from './list/listReducer'
import { collection } from './collection/collectionReducer'

export const rootReducer = combineReducers({ 
    app,
    auth,
    list,
    collection,
    itemPanel
})

export type RootState = ReturnType<typeof rootReducer>;
