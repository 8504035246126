import { Box } from '@mui/material'
import React from 'react'
import ViewCatalog from './view-controls/ViewCatalog'
import ViewImages from './view-controls/ViewImages'
import ViewSubTitle from './view-controls/ViewSubTitle'
import ViewTitle from './view-controls/ViewTitle'

export const SubItems: React.FC<any> = (p) => {


    return(
        // <Box sx={{
        //     paddingTop: '0.5rem',
        //     borderTop: '1px solid',
        //     borderColor: 'divider',
        //     gridColumnStart: p.cardClass==='lines' ? 'span 3' : 'span 2',
        //     display: 'grid',
        //     gridTemplateColumns: 'repeat(3,1fr)',
        //     gridGap: '1rem',
        //     // padding: '1rem'
        //     margin: '0 0.5rem 1.25rem'
        // }}>
        <div className="subitems-box">
            {
            p.viewItem.items.map((item: any, index: number) => (
                <div key={index} 
                    className={"subitem" + 
                    (p.viewProps.catalogCheckedList.length === 0 
                    || !p.viewProps.catalogOpen
                    || p.viewProps.catalogCheckedList.includes(item.catalogLevel1)
                    || p.viewProps.catalogCheckedList.includes(item.catalogLevel2)
                    || p.viewProps.catalogCheckedList.includes(item.catalogLevel3)
                        ? ''
                        : ' disabled'
                    ) + 
                    (p.viewProps.filterString && !p.viewProps.filterStringItems.includes(item.CollectionItemId)
                        ? ' disabled'
                        : ''
                    )
                    }
                >                
                    <ViewImages
                        CollectionId={p.CollectionId}
                        backgroundColor={p.backgroundColor}
                        inView={p.inView}
                        item={item}
                        rootItem={p.viewItem}
                        coverClassName={'w1h1'}
                        width={'extra-small'} 
                    />
                    <Box sx={{
                        padding: '0.5rem'
                    }}>
                        <ViewCatalog
                            set={p.setCatalog}
                            item={item}
                            collectionFields={p.collectionFields}
                            filterString={p.filterString}
                        />
                        <ViewTitle
                            set={p.setTitle}
                            item={item}
                            collectionFields={p.collectionFields}
                            filterString={p.filterString}
                            subItem
                        />
                        <ViewSubTitle
                            item={item}
                            filterString={p.filterString}
                            subItem
                        />
                        <div className="number">
                            {item.itemNumber + (item.itemSubNumber ? '-' + item.itemSubNumber : '')}
                        </div>
                        {/* <ViewChip
                            item = {item} 
                            provenance={false}
                            subItem={true}
                        /> */}
                    </Box>
                {/* // </div> */}
                </div>

            ))
            }
        </div>
    )
}