import { PaletteMode } from '@mui/material'

const theme = {
    palette: {
        type: 'light' as PaletteMode, 
        // primary: {
        //     main: '#1A73E8',
        // },
        // secondary: {
        //     main: '#E57C04',
        // },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1030,
        //   md: 10,
          lg: 1366,
          xl: 1450,
        },
    },
    toolbarControlsHeight: 36,
    typography: {
        // htmlFontSize: 16,
        fontFamily: [
            'MuseoSansRegular',
            'MuseoSansLight',
            'MuseoSansBold',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 14,    
        h1: { fontSize: '2rem', marginTop: '2.5rem', marginBottom: '1rem' },
        h2: { fontSize: '2rem' },
        h3: { fontSize: '2rem' },
        h4: { fontSize: '1.5rem' },
        h5: { fontSize: '1.25rem' },
        h6: { fontSize: '1rem' },
        
        subtitle1: { fontSize: '1rem', color: 'black' },
        // subtitle1: { fontSize: '0.95rem' },
        subtitle2: { fontSize: '0.875rem' },

        body1: { fontSize: '1rem' },
        body2: { fontSize: '1rem' },

        // caption: { fontSize: '0.875rem' },
        caption: { fontSize: '0.875rem' },
        overline: { fontSize: '0.75rem' },

        button: {
            textTransform: 'none' as 'none',
            fontWeight: 500,
            // fontSize: 14,
            // fontSize: '0.875rem' 
            fontSize: '1rem' 
        },
    },
    overrides: {
        MuiTab: {
            root: {
                minWidth: 0,
                '@media (min-width: 0px)': {
                    minWidth: '120px'
                },
            },
        }
    }
};

export default theme;
