import React from 'react'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import { locale } from '../../ui/locale';
import { Box, Typography } from '@mui/material';

export const Header = (p: any) => {
    return (
        <>
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                // alignItems: 'flex-end'
                alignItems: 'center'
            }}
        >
            <Typography variant='h1' 
                sx={{marginBottom: 0}}
            >
                {p.cll.name}
            </Typography>
            <Typography variant='h1' 
                sx={{color: 'action.active', fontSize: '1rem'}}
            >
                {p.totalItems !== p.preparedItems.length
                ? p.totalItems + ' из '
                : null
                } 
                {p.preparedItems.length}
            </Typography>
            <div className='grow'></div>
            
            {p.cll.role === 'owner' && !p.mobileMode
            ?
            <Button 
                id="add" 
                size="medium"
                // variant="outlined"
                color="primary"
                onClick={event => p.addItemClick()}
                startIcon={<AddIcon />}
                sx={{marginTop: '2.5rem'}}
            >
                {locale('collections.addItem')}
            </Button>
            : <Chip 
                sx={{display: {xs: 'none', sm: 'inline-flex'}}}
                label={p.cll.owner} 
                variant="outlined"  
                color="primary"/>                
            }            

        </Box>
        <Typography sx={{color: 'action.active', lineHeight: '100%'}} >
            {p.cll.details}
        </Typography>
        </>
    )
}

