import React, {useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paginator from '../../ui/Paginator'
import * as collectionActions from '../../store/collection/collectionActions'
import { locale } from '../../ui/locale'
import { ListItemText, Menu, Stack, Typography } from '@mui/material'
import { ViewButtons } from './view-buttons'
import { SideMenuButton } from '../../modules/side-menu-button'

export default function TopPanel(p: any)  {
    const dispatch = useDispatch()

    const [anchorSort, setAnchorSort] = useState<null | HTMLElement>(null);

    const handleSort = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorSort(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorSort(null);
    }

    const onViewChange = (view: number) => {
        dispatch(collectionActions.changeView(view))
    }

    const onSortingChange = (sorting: number, sortingReverce: boolean | undefined) => {
        setAnchorSort(null)
        dispatch(collectionActions.changeSorting(sorting, !!sortingReverce))
    }

    const onPaginatorChange = (num: number) => {
        dispatch(collectionActions.changeItemsOnPage(num))
    }

    const onPageDown = () => {
        dispatch(collectionActions.changeStartItem(-1))
    }
    const onPageUp = () => {
        dispatch(collectionActions.changeStartItem(1))
    }
    // console.log(p.sortSet)

    const menuItems = useMemo(() => {
        const result = []
        for(let i = 0; i<p.sortSet.length; i++ ) {
            result.push({
                setName: p.sortSet[i].setName, 
                sortingReverse: p.sortSet[i].addReverce ? false : undefined,
                sorting: i
            })
            if (p.sortSet[i].addReverce) {
                result.push({
                    setName: p.sortSet[i].setName, 
                    sortingReverse: true,
                    sorting: i
                })
            }
        }
        return result;
    }, [p.sortSet]);

    const {sortingIndex, sortingSymbol} = useMemo(() => {
            const index = menuItems.findIndex((item) => 
                item.sorting === p.sorting && 
                (item.sortingReverse === p.sortingReverce || item.sortingReverse === undefined) )

            if (index === -1) return {sortingIndex: -1, sortingSymbol: ''}
            return {
                sortingIndex: index,
                sortingSymbol: menuItems[index].sortingReverse === undefined ? undefined :
                    p.sortingReverce? '↑' : '↓'
            }       
    }, [menuItems, p.sorting, p.sortingReverce])

    // console.log({menuItems, sortSet: p.sortSet, sortingIndex, sortingSymbol})

    return (        

        <div className="top-panel">
        
        <Stack direction='row' spacing={2}>
            <SideMenuButton caption='Фильтр' onClick={p.onFilterClick} />

            {!p.mobileMode && !p.hideViewType 
                ? <ViewButtons view={p.view} onViewChange={onViewChange}/> 
                : null 
            }

            {p.sortSet.length <= 1 || p.mobileMode
            ? null
            : 
            <Stack 
                direction='row'
                spacing={1}
                alignItems='center'
                sx={{                
                    fontSize: '0.875rem',
                    color: 'text.secondary',
                }}
            >

                <span>{locale('toolbar.sorting')}:</span>
                <Stack 
                    direction='row'
                    alignItems='center'
                    onClick={handleSort}                    
                    sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                        fontWeight: 700,
                        marginRight: 1,
                    }}
                >             
                    {menuItems[sortingIndex].setName} {sortingSymbol}&nbsp;&nbsp;
                    <ExpandMoreIcon />
                </Stack>
            </Stack>
            }
            {/* {p.sortSet.length <= 1
            ? null
            : 
            <Stack direction='row' spacing={1} alignItems='center' sx={{                
                fontSize: '0.875rem',
                color: 'text.secondary'
            }}>

                {!p.mobileMode ? <span>{locale('toolbar.sorting')}:</span> : null}
                <Stack 
                    direction='row'
                    alignItems='center'
                    onClick={handleSort}                    
                    sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                        fontWeight: 700,
                        marginRight: 1,
                    }}
                >             
                    {menuItems[sortingIndex].setName} {sortingSymbol}&nbsp;&nbsp;
                    <ExpandMoreIcon />
                </Stack>
            </Stack>
            } */}
            
        </Stack>
        <Paginator
            paginatorItemsOnPage={p.paginatorItemsOnPage}
            
            totalItems={p.totalItems}
            itemsOnPage={p.itemsOnPage}    
            startItem={p.startItem}

            onPageDown={onPageDown}
            onPageUp={onPageUp}
            onPaginatorChange={onPaginatorChange}
            mobileMode={p.mobileMode}
        />

        <Menu
            id="menu-sort"
            sx={{width: '420px'}}
            anchorEl={anchorSort}
            open={Boolean(anchorSort)}
            // open={true}
            onClose={handleClose}
        >
        {
        menuItems.map((item, index: number) => (
            <MenuItem 
                key={index} 
                value={item.setName}
                selected={index === sortingIndex}
                onClick={(event) => onSortingChange(item.sorting, item.sortingReverse)}
            >
                <ListItemText>
                    {item.setName}
                </ListItemText>
                {item.sortingReverse === undefined ? null :
                <Typography align="right" variant="body2" color="text.secondary" sx={{width: '50px'}}>
                    {/* {sortingSymbol} */}
                    {item.sortingReverse ? '↑' : '↓'}
                </Typography>
                }
            </MenuItem>
        ))
        }
        </Menu>
    </div>
    )
}



// {p.mobileMode
        // ? null
        // :<Stack direction='row' spacing={2}>

            

        //     {!p.mobileMode && !p.hideViewType 
        //         ? <ViewButtons view={p.view} onViewChange={onViewChange}/> 
        //         : null 
        //     }

        //     {
        //     p.sortSet.length <= 1 || p.mobileMode
        //     ? null
        //     : 
        //     <Stack direction='row' spacing={1} alignItems='center' sx={{                
        //         fontSize: '0.875rem',
        //         color: 'text.secondary'
        //     }}>

        //         <span>{locale('toolbar.sorting')}:</span>
        //         <Stack 
        //             direction='row'
        //             alignItems='center'
        //             onClick={handleSort}                    
        //             sx={{
        //                 cursor: 'pointer',
        //                 color: 'primary.main',
        //                 fontWeight: 700
        //             }}
        //         >
        //             {p.sortSet[p.sorting].setName}
        //             <ExpandMoreIcon />
        //         </Stack>
        //         {p.sortSet.length > p.sorting && p.sortSet[p.sorting].addReverce
        //         ? <Box 
        //             onClick={(event) => onSortingChange(p.sorting, !p.sortingReverce)}
        //             sx={{cursor: 'pointer'}}
        //         >{p.sortingReverce ? <ArrowUpwardIcon />  : <ArrowDownwardIcon />}
        //         </Box>
        //         : null                
        //         }  
                
        //         <Menu
        //             id="menu-sort"
        //             anchorEl={anchorSort}
        //             open={Boolean(anchorSort)}
        //             onClose={handleClose}
        //         >
        //             {
        //             p.sortSet.map((item: any, index: number) => (
        //                 <MenuItem 
        //                     key={index} 
        //                     value={item.setName}
        //                     selected={index === p.sorting}
        //                     onClick={(event) => onSortingChange(index, p.sortingReverce)}
        //                 >
        //                     <ListItemText>
        //                         {item.setName}
        //                     </ListItemText>
        //                 </MenuItem>
        //             ))
        //             }
        //         </Menu>
        //     </Stack>
        //     }
        
        // </Stack>
        // }
        // <Paginator
        //     paginatorItemsOnPage={p.paginatorItemsOnPage}
            
        //     totalItems={p.totalItems}
        //     itemsOnPage={p.itemsOnPage}    
        //     startItem={p.startItem}

        //     onPageDown={onPageDown}
        //     onPageUp={onPageUp}
        //     onPaginatorChange={onPaginatorChange}
        //     mobileMode={p.mobileMode}
        // />
        // </Box>