import React, {useState, useRef} from 'react'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import useOutsideClick from '../../hooks/useOutsideClick'
import TextField from '@mui/material/TextField';

export default function EditSingleText(p: any) {
    const [toEdit, setToEdit] = useState(p.value)

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, p.onCancelClick)

    const handleClickSave = (event: any) => {
        p.onSaveClick(toEdit)
    }

    const onKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleClickSave(event)
        }
        if (event.key === 'Escape') {
            p.onCancelClick()
        }
    }

    return(
        <div ref={p.useOutsideClick ? wrapperRef : null}>
        <TextField
            label={p.label}
            fullWidth={p.fullWidth}
            error={p.error}
            size='small'
            autoFocus={true}
        //   id=""
            onChange={event => setToEdit(event.target.value)}
            onKeyDown={onKeyDown}
            value={toEdit}
            helperText={p.helperText}
            className={p.className}
            InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                    <IconButton
                        size="small"
                        onClick={handleClickSave}
                    >
                        <DoneIcon />
                    </IconButton>
                </InputAdornment>,
            }}
          variant="outlined"
        />
        </div>
    )
}