import React from 'react'
import { locale } from '../../../../ui/locale'

import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Table, TableBody, TableContainer } from '@mui/material'
import { SettingsTableRow } from '../../../../modules/settingsTableRow'
import { SettingsTableCell } from '../../../../modules/settingsTableCell'


export default function Items(p: any) {

    return(
        p.fields.length === 0
        ? null
        : <TableContainer>
            <Table sx={{opacity: p.loading || p.edited ? '0.5' : undefined }}>
        <TableBody>
        
        {p.fields
        .filter((item: any) => item.name)
        .map((item: any, index: number) => (
        <SettingsTableRow 
            selected={p.toEditListIndex === index }
            key={index} 
            // className={clsx({"item-onclick" : !p.edited}, {'active' : p.toEditListIndex === index})}
        >
            <SettingsTableCell selected={p.toEditListIndex === index} onClick={() => p.onEditClick(p.setIndex, index)} >
                {p.collectionFields.findIndex((el:any) => el.value === item.name) >= 0
                ? p.collectionFields[p.collectionFields.findIndex((el:any) => el.value === item.name)].description
                : locale('common.notSpecified')
                } 
            </SettingsTableCell>
            <SettingsTableCell selected={p.toEditListIndex === index} onClick={() => p.onEditClick(p.setIndex, index)}>
                {item.listName !== '-' ? item.listName : null} 
            </SettingsTableCell>
            
            {p.toEditListIndex === index && !p.edited
            ?<SettingsTableCell selected={p.toEditListIndex === index} width='70px' sx={{padding: 0}}>
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={index === 0}
                    onClick={() => p.onMoveField(p.setIndex, index, false)}
                >
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton 
                    size="small" 
                    color="primary"
                    disabled={index === p.fields.length - 1}
                    onClick={() => p.onMoveField(p.setIndex, index, true)}
                >
                    <ArrowDownwardIcon />
                </IconButton>
            </SettingsTableCell>
            : <SettingsTableCell selected={p.toEditListIndex === index} width='70px' onClick={() => p.onEditClick(p.setIndex, index)}>

            </SettingsTableCell>
            }
        
        </SettingsTableRow>
        ))}
        </TableBody>
        </Table>
        </TableContainer>
    )
}