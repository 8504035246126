import React from 'react'
import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { FilterCheckBox } from '../../../modules/filter-check-box'
import { Box } from '@mui/material'

export default function FilterItem(p: any) { 
    const dispatch = useDispatch();

    const onCheckClick = (index: number) => {
        dispatch(collectionActions.filterCheck(p.groupIndex, index))
    }

    return(
        <List dense disablePadding >
            {
            p.items.map((item: any, index: number)=>(
                true
                ?
                <ListItem 
                    key = {index} 
                    // button 
                    disabled={item.itemsList.length === 0}
                    sx={{
                        cursor: 'pointer',
                        paddingTop: 1,
                        paddingBottom: 1,
                        fontSize: '.875rem',
                        fontWeight: 600,
                        alignItems: 'flex-start',
                        color: item.isChecked ? 'text.primary' : 'action.active',
                        '&:hover': {color: 'text.primary'}
                    }}                    
                    onClick={() => onCheckClick(index)}
                    >
                        <FilterCheckBox checked={item.isChecked} />
                        {item.description} 
                        <Box sx={{
                            fontSize: '0.875rem',
                            color: 'action.disabled',
                            position: 'absolute',
                            right: '3rem'
                        }}>
                            {item.itemsList.length}
                    </Box>
                </ListItem>
                :null
            )
            )}
        </List>
    )
}    
