import * as I from '../../interfaces'
import { locale } from '../../../ui/locale'

export function filterRecomend(data: I.Collection) {

    const index = data.filterGroups.length
    const items = data.preparedItems
    const year: number = new Date().getFullYear()
    data.filterGroups.push({
        description: locale('filters.recomend.title'),
        isOpen: false,
        hidden: false,
        items: [],        
    })

    data.filterGroups[index].items.push({
        description: locale('filters.recomend.keep'),
        isChecked: false,
        itemsList: items.filter(item => 
            item.yearFrom 
            ? year < item.yearFrom 
            : false
        )
        .map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.recomend.drink'),
        isChecked: false,
        itemsList: items.filter(item => 
            item.yearFrom && item.yearTo 
            ? year >= item.yearFrom && year <= item.yearTo 
            : false
        )
        .map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.recomend.expired'),
        isChecked: false,
        itemsList: items.filter(item => 
            item.yearTo 
            ? year > item.yearTo
            : false
        )
        .map(item => (item.CollectionItemId))
    })

    data.filterGroups[index].items.push({
        description: locale('filters.recomend.notDefined'),
        isChecked: false,
        itemsList: items.filter(item => 
            (!item.yearTo && !item.yearTo) ||
            (!item.yearTo && !!item.yearFrom && year > item.yearFrom) ||
            (!item.yearFrom && !!item.yearTo && year < item.yearTo) 
        )
        .map(item => (item.CollectionItemId))
    })


}