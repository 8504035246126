import * as I from '../interfaces'

export function setCatalogProps (prevCatalog: I.CatalogItem[], newCatalog: I.CatalogItem[]) {
    newCatalog.forEach( cat1 => {
        const prevCatIndex1 = prevCatalog.findIndex( cat => cat._id === cat1._id)
        if (prevCatIndex1 >=0) {
            cat1.isOpen = prevCatalog[prevCatIndex1].isOpen
            cat1.isChecked = prevCatalog[prevCatIndex1].isChecked
            cat1.items.forEach(cat2 => {
                const prevCatIndex2 = prevCatalog[prevCatIndex1].items.findIndex( cat => cat._id === cat2._id)
                if (prevCatIndex2 >= 0) {
                    cat2.isOpen = prevCatalog[prevCatIndex1].items[prevCatIndex2].isOpen
                    cat2.isChecked = prevCatalog[prevCatIndex1].items[prevCatIndex2].isChecked
                    cat2.items.forEach(cat3 => {
                        const prevCatIndex3 = prevCatalog[prevCatIndex1].items[prevCatIndex2].items.findIndex( cat => cat._id === cat3._id)
                        if (prevCatIndex3 >= 0) {
                            cat3.isChecked = prevCatalog[prevCatIndex1].items[prevCatIndex2].items[prevCatIndex3].isChecked
                        }
                    })
                }
            })
        } 
    })
}

export function sortCatalog(data: I.Collection){

    function sort(catalog: I.CatalogItem[]){
        catalog.sort((a, b) => {
            if (a.description === '') return 1
            if (b.description === '') return -1
            if (a.description.toLowerCase() < b.description.toLowerCase()) {
                return -1
            } 
            return 1    
        })
        catalog.forEach(item => {
    
            if (item.items && item.items.length > 0) {
                sort(item.items)
            }
        })
    }

    if (data.catalogAutoSort) {
        sort(data.catalog)
    }
}

export function onChangeCatalog(
    catalog: I.CatalogItem[], 
    openId: string, 
    checkId: string
):  {catalogItems: string[], catalogCheckedList: string[]} 
{
    function setChild(items: I.CatalogItem[], check: boolean){
        items.forEach(cat2 => {
            cat2.isChecked = check
            if (cat2.items) {
                cat2.items.forEach(cat3 => {cat3.isChecked = check})
            }
        })
    }

    let arrResult: string[] = []
    let arrCatalog: string[] = []

    catalog.forEach(cat1 => {
        cat1.isOpen = cat1._id === openId ? !cat1.isOpen : cat1.isOpen
        cat1.isChecked = cat1._id === checkId ? !cat1.isChecked : cat1.isChecked
        if (cat1.isChecked) {
            arrResult = arrResult.concat(cat1.isOpen ? cat1.itemsList : cat1.itemsListGroup)
            arrCatalog.push(cat1._id)
        }
        if (cat1._id === checkId && !cat1.isOpen) {
            setChild(cat1.items, cat1.isChecked)
        }

        if (cat1.isOpen) {
            cat1.items.forEach(cat2 => {
                cat2.isOpen = cat2._id === openId ? !cat2.isOpen : cat2.isOpen
                cat2.isChecked = cat2._id === checkId ? !cat2.isChecked : cat2.isChecked
                if (cat2.isChecked) {
                    arrResult = arrResult.concat(cat2.isOpen ? cat2.itemsList : cat2.itemsListGroup)
                    arrCatalog.push(cat2._id)
                }
                if (cat2._id === checkId && !cat2.isOpen) {
                    setChild(cat2.items, cat2.isChecked)
                }
        
                if (cat2.isOpen) {
                    cat2.items.forEach(cat3 => {
                        cat3.isOpen = cat3._id === openId ? !cat3.isOpen : cat3.isOpen
                        cat3.isChecked = cat3._id === checkId ? !cat3.isChecked : cat3.isChecked
                        if (cat3.isChecked) {
                            arrResult = arrResult.concat(cat3.itemsList)
                            arrCatalog.push(cat3._id)
                        }
                    })
                }
            })
        }
    }) 

    return {catalogItems: arrResult, catalogCheckedList: arrCatalog}
}

export function setCatalogItems(data: I.Collection) { 

    const catalog = data.catalog
    const items = data.preparedItems
    if (data.useChildItems && !data.viewProps.ignoreChildItems) {

        catalog.forEach(cat1 => {
            cat1.itemsListGroup = items
                .filter( item => item.catalogListGroup?.includes(cat1._id))
                .map(item => item.CollectionItemId)
            cat1.itemsList = items
                .filter( item => item.catalogList?.includes(cat1._id))
                .map(item => item.CollectionItemId)
            cat1.items.forEach(cat2 => {
                cat2.itemsListGroup = items
                    .filter( item => item.catalogListGroup?.includes(cat2._id))
                    .map(item => item.CollectionItemId)
                cat2.itemsList = items
                    .filter( item => item.catalogListGroup?.includes(cat2._id))
                    .map(item => item.CollectionItemId)
    
                    cat2.items.forEach(cat3 => {
                    cat3.itemsListGroup = items
                        .filter( item => item.catalogListGroup?.includes(cat3._id))
                        .map(item => item.CollectionItemId)
                    cat3.itemsList = cat3.itemsListGroup
    
                })
            })
        }) 

    } else {

        catalog.forEach(cat1 => {
            cat1.itemsListGroup = items
                .filter( item => item.catalogLevel1 === cat1._id)
                .map(item => item.CollectionItemId)
            cat1.itemsList = items
                .filter( item => item.catalogLevel1 === cat1._id && !item.catalogLevel2)
                .map(item => item.CollectionItemId)
            cat1.items.forEach(cat2 => {
                cat2.itemsListGroup = items
                    .filter( item => item.catalogLevel2 === cat2._id)
                    .map(item => item.CollectionItemId)
                cat2.itemsList = items
                    .filter( item => item.catalogLevel2 === cat2._id && !item.catalogLevel3)
                    .map(item => item.CollectionItemId)
    
                    cat2.items.forEach(cat3 => {
                    cat3.itemsListGroup = items
                        .filter( item => item.catalogLevel3 === cat3._id)
                        .map(item => item.CollectionItemId)
                    cat3.itemsList = cat3.itemsListGroup
    
                })
            })
        }) 
    }
}


