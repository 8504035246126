import React from 'react'
import {useDispatch} from 'react-redux'
import * as collectionActions from '../../../store/collection/collectionActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Collapse from '@mui/material/Collapse'
import * as I from '../../../store/interfaces'
import IconButton from '@mui/material/IconButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import FilterItem from './FilterItem'
import AccessAll from './AccessAll'

export default function FilterGroup(p: any) { 
    const dispatch = useDispatch();

    const onOpenClick = (index: number) => {
        dispatch(collectionActions.filterOpen(index))
    }

    return (
        p.items
        .map((header: I.FilterGroup, index: number)=>(
        header.hidden
        ? null
        :
        <div key={index} className={"filter-list" + (p.disabled ? " disabled" : "")}>
            <List disablePadding>
            <ListItem 
                button
                sx={{
                    paddingLeft: p.level*2 + 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontSize: '.875rem',
                    fontWeight: 600,
                    alignItems: 'flex-start',
                    // color: CatalogItem.isChecked ? 'text.primary' : 'action.active',
                    // '&:hover': {color: 'text.primary'}
                }}
                // className={header.isOpen ? 'ct-filter-open' : 'ct-filter-closed'} 
                onClick={() => onOpenClick(index)}
            >
            <ListItemText  primary={header.description} />
            <IconButton edge="end" size="small">
                {header.isOpen && header.items.length > 0 ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            </ListItem> 
            <Collapse key={'x' + index} in={header.isOpen && header.items.length > 0} timeout="auto" unmountOnExit>
                
                <FilterItem
                    groupIndex={index}
                    items={header.items} 
                />
                {p.showAccess && index === p.items.length - 1
                ?<AccessAll
                    CollectionId={p.CollectionId}
                />
                :null
                }
            </Collapse>
            </List>
        </div>
        ))
    )
}