import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import Image from 'material-ui-image'
import { BACKENDROOT } from '../../index'
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'


export const CardOwn = (props: any) => {

    const qty = useMemo(() => props.collection.itemsQty ?
    {
        qtyItems: props.collection.itemsQty.total - props.collection.itemsQty.toFind,
        qtyItemsToFind: props.collection.itemsQty.toFind,
        qtySharedItems: props.collection.itemsQty.sharedTotal,
    } : {}, [props.collection])

    const htmlColor = 'rgb(255 255 255 / 80%)';

    const textSx = useMemo(() => ({
        color: htmlColor,
        fontSize: '0.85rem'
    }), []);

    return (
    // <Grid item xl={3} lg={4} md={4} sm={6} xs={12} >
    <Grid item lg={3} md={4} sm={6} xs={12} >
        <Box 
            sx={{cursor: 'pointer'}} 
            onClick={() => props.onCardClick(props.collection.CollectionId)}
        >
            <Box sx={{position: 'relative'}} >
                {props.collection.imageId
                ?<Image
                    aspectRatio={2/1}
                    src={BACKENDROOT + '/image/cover-picture/' + (props.collection.imageId ? props.collection.imageId : '')} 
                />
                :<Image
                    aspectRatio={2/1}
                    src='/images/noimage.jpg'
                />
                }
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        bottom: 0,
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent)'
                    }}>
                    <Stack 
                        direction='row' 
                        spacing={1} 
                        sx={{paddingTop: 2, paddingLeft: 2, paddingRight: 2, paddingBottom: 0.5}}
                    >
                        <Typography sx={textSx}>{qty.qtyItems}</Typography>
                        {qty.qtyItemsToFind > 0
                        ? <>
                            <Divider color='white' orientation="vertical" flexItem />
                            <Typography sx={textSx}>{qty.qtyItemsToFind} ищу</Typography>
                        </>
                        : null
                        }
                        <div className='grow'></div>
                        {props.collection.shared
                        ? <>
                            <VisibilityOutlinedIcon fontSize='small' htmlColor={htmlColor}/>
                            <Typography sx={textSx}>{qty.qtySharedItems}</Typography>
                        </>
                        :<LockIcon fontSize='small' htmlColor={htmlColor} />
                        }
                    </Stack>
                </Box>
            </Box>

        <Stack direction='column'>
            {/* <Typography variant='h5'>{props.collection.name}</Typography> */}
            <h3>{props.collection.name}</h3>
            <Typography sx={{color: 'action.active', lineHeight: '100%'}} >{props.collection.details}</Typography>
        </Stack>
        
        </Box>
    </Grid>
)
}