import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { locale } from '../../../ui/locale';

export default function EditToFind(p: any) {

    return(
        <>
        <RadioGroup 
            row
            className="cm-edit-width-8"
            value={p.itemToFind ? 0 : 1}
            onChange={p.onChangeToFind}
            sx={{gridColumnStart: 'span 8'}}
        >
            <FormControlLabel 
                color="primary" 
                value={1}
                control={<Radio color="primary" disabled={p.disabled} />} 
                label={locale('itemEdit.toFind.item')}
            />
            <FormControlLabel 
                color="primary" 
                value={0}
                control={<Radio color="primary" disabled={p.disabled} />} 
                label={locale('itemEdit.toFind.toFind')}
            />
        </RadioGroup>
    </>
    )
}