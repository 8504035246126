import { Box, Input, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as http from '../../modules/http'
import { Loader } from '../../modules/Loader';
import { LineLoader } from '../../modules/line-loader';
import Button from '@mui/material/Button'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const VinoList = () => {
    const auth = useSelector( (state: any) => state.auth )
    const navigate = useNavigate();
    
    const [pageState, setPageState] = useState({loaded: false, loading: false, error: null});
    const [list, setList] = useState([])

    if (auth.auth) {
        if (!auth.roles.includes('vino')) {
            navigate('/')
        }
    }

    useEffect( () => {
        if (!pageState.loaded) {
            (async () => {
                setPageState(prev => ({...prev, loading: true}))
                const res = await http.httpGet('/vino/list', {}, true)
                if (res.success) {
                    setList(res.data)
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                } else {
                    setPageState(prev => ({...prev, loading: false, loaded: true}))
                }    
            })()
        }    
    }, [pageState.loaded])

    const reloadList = () => {
        setPageState({...pageState, loaded: false})
    }

    const setLoading = (loading: boolean) => {
        setPageState({...pageState, loading: loading})
    }

    const openPdf = (fileName: string) => {
        window.open('pdf/' + fileName, '_blank');
    }

    const deletePdf = async (fileName: string) => {
        setLoading(true);
        await http.httpPost(
            '/vino/delete', 
            {fileName},
            true
        )
        reloadList();
    }

    async function onFileChange(e: any) {
        setLoading(true);
        var formData = new FormData();
        formData.append("file", e.target.files[0]);

        await http.httpPost('/vino/upload', formData, true)
        reloadList();
    }

    // console.log(!auth.auth || !auth.roles.includes('vno'))

    return (
        !auth.auth || !auth.roles.includes('vino')
        ? <Loader />
        : <>

        <LineLoader active={pageState.loading} />
        <Paper variant='outlined' sx={{
                padding: '1rem 1rem 4.25rem',
                position: {sm: 'relative', md: undefined},
        }}> 
            <Typography variant='h4'>Загруженные файлы</Typography>
            <TableContainer sx={{marginTop: 2}}>
            <Table >
                <TableBody>
                {list.map((fileName: string, index: number) => (
                <TableRow key={index} hover>
                    <TableCell onClick={event => openPdf(fileName)} sx={{cursor: 'pointer'}}>
                        {fileName} 
                    </TableCell>
                    <TableCell>
                        <Button 
                            id="delete" 
                            size="small"
                            // variant="outlined"
                            component="span"
                            disabled={pageState.loading}
                            startIcon={<DeleteForeverIcon />} 
                            onClick={() => deletePdf(fileName)}  
                        >
                            Удалить
                        </Button> 
                    </TableCell>
                    
                </TableRow>
                ))}
                </TableBody>
            </Table>  
            </TableContainer>

            <Box sx={{marginTop: 2}}>
                <input
                    accept="application/pdf"
                    style={{display: 'none'}} 
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label htmlFor="contained-button-file">
                    <Button 
                        id="add" 
                        size="medium"
                        variant="outlined"
                        component="span"
                        disabled={pageState.loading}
                        startIcon={<PictureAsPdfIcon />}   
                    >
                        Загрузить Файл
                    </Button>
                </label>
            </Box> 
        </Paper>
        </>
    )
} 
