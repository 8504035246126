import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { viewCardStyle } from '../../style/cardStyle';
import Card from '../collection/Card'

export const SubItemsDetail: React.FC<any> = (p) => {

    const {style, cardClass} = useSelector((state: RootState) => state.app.mobileMode
        ? {style: viewCardStyle('cards'), cardClass: 'cards'}
        : {style: viewCardStyle('lines'), cardClass: 'lines'}
    )

    return(
        <Box sx={style}>
        {p.viewItem.items.map((item: any, index: number) => (
            <Card
                key={index}
                item={item}
                index={index}
                cll={p.cll}
                imageWidth='small'
                subItemsMode={true}
                onItemClick={p.onItemClick}
                rootItem={p.rootItem}
                cardClass={cardClass}
            />
        ))
        }
        </Box>
    )
}