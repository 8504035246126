import * as I from '../../interfaces'
import { locale } from '../../../ui/locale'
import _get from 'lodash/get'

export function filterField(data: I.Collection, fieldName: string) {
    function value(item: any): string {
        const val =  _get(item, fieldName)
        return val ? val : ''
    }

    const index = data.filterGroups.length
    const items = data.preparedItems
    data.filterGroups.push({
        description: locale('filters.' + fieldName),
        isOpen: false,
        hidden: false,
        items: [],        
    })
    const f: any[] = Array.from(new Set([...items.map((item) => value(item) )]))
    f.sort()

    data.filterGroups[index].items = 
        f.map((el: string) => ({       
            description: !el ? locale('filters.none') : el,
            isChecked: false,
            itemsList: items
                .filter(item => value(item) === el)
                .map(item => (item.CollectionItemId))
        }))

}