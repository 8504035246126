import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { locale } from '../../ui/locale'

export const MENU_ITEMS = 0
export const MENU_SETTINGS = 1
export const MENU_EXPORT_IMPORT = 2


export const Menu = (p: any) => {

    // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //     setValue(newValue);
    // };
    
    return (
        <Box sx={{ width: '100%', marginTop: 3 }}>
            <Box sx={{ borderBottom: 1, borderTop: 1,  borderColor: 'divider'}}>
                <Tabs value={p.tabIndex} onChange={p.changeTabIndex} >
                    <Tab label={locale('collections.menu.items')} sx={{fontSize: '.85rem'}} />
                    {/* <Tab label={locale('collections.menu.complect')} sx={{fontSize: '.85rem'}} /> */}
                    <Tab label={locale('collections.menu.settings')} sx={{fontSize: '.85rem'}} />
                    {/* <Tab label={locale('collections.menu.access')} sx={{fontSize: '.85rem'}} /> */}
                    <Tab label={locale('collections.menu.exportImport')} sx={{fontSize: '.85rem'}} />
                </Tabs>
            </Box>
        </Box>

        // <div className="cm-submenu">
        //     <span>
        //         <ul className="cm-tab four">
        //             <li 
        //                 className={p.tabIndex === MENU_ITEMS ? "active" : ""} 
        //                 onClick={event=> p.changeTabIndex(event, MENU_ITEMS)}
        //             >{locale('collections.menu.items')}</li>
        //             <li 
        //                 className={p.tabIndex === MENU_COMPLECT ? "active" : ""} 
        //                 onClick={event=> p.changeTabIndex(event, MENU_COMPLECT)}
        //             >{locale('collections.menu.complect')}</li>
        //             <li 
        //                 className={p.tabIndex === MENU_ACCESS ? "active" : ""} 
        //                 onClick={event=> p.changeTabIndex(event, MENU_ACCESS)}
        //             >{locale('collections.menu.access')}</li>
        //             <li 
        //                 className={p.tabIndex === MENU_SETTINGS ? "active" : ""} 
        //                 onClick={event=> p.changeTabIndex(event, MENU_SETTINGS)}
        //             >{locale('collections.menu.settings')}</li>
        //             <li 
        //                 className={p.tabIndex === MENU_EXPORT_IMPORT ? "active" : ""} 
        //                 onClick={event=> p.changeTabIndex(event, MENU_EXPORT_IMPORT)}
        //             >{locale('collections.menu.exportImport')}</li>
                    
        //         </ul>

        //     </span>
        // </div>
    )
}
