import React from 'react'
import LinkIcon from '@mui/icons-material/Link';

export const ViewUrl: React.FC<any> = (p) => {
    return(
        p.item.url.length > 0
        ? <div className="url-box">
            {p.item.url.map( (url: string, index: number) => (
                <div key={index} className="url">
                    <LinkIcon color="primary"/>
                    <a href={url} target="_blank" rel="noopener noreferrer" >
                        {/* {item.UrlDescription} */}
                        {url.slice(0, 30)}{url.length > 30 ? '...' : ''}
                        {/* {url} */}
                    </a>  
                </div>
            ))}
        </div>
        : null
    )
}