import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { locale } from '../../../ui/locale';
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as http from '../../../modules/http'

export default function Menu (p: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onDeleteClick = async (userId: string) => {
        setAnchorEl(null)
        p.setLoading(true)
        await http.httpPost(
            '/users/enable', 
            {CollectionId: p.CollectionId},
            true
        )
        p.reloadList()
        p.setLoading(false)
    }

    const onDisableClick = async (userId: string) => {
        setAnchorEl(null)
        p.setLoading(true)
        await http.httpPost(
            '/users/disable', 
            {CollectionId: p.CollectionId},
            true
        )
        p.reloadList()
        p.setLoading(false)
    }

    const onEnableClick = async (userId: string) => {
        setAnchorEl(null)
        p.setLoading(true)
        await http.httpPost(
            '/users/delete', 
            {CollectionId: p.CollectionId},
            true
        )
        p.reloadList()
        p.setLoading(false)
    }

    return (
        <div>
            <Button
                onClick={event => handleMenu(event)} 
                color="inherit"
                endIcon={<ExpandMoreIcon />}   
                disabled={p.loading}
            >
                <span>{locale('admin.collections.menu.title')}</span>
            </Button>

                <Popover
                    id="menu-user"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {!p.msg.read
                    ? <MenuItem onClick={event => onEnableClick(p.CollectionId)}>{locale('admin.feedback.menu.read')}</MenuItem>
                    : <MenuItem onClick={event => onDisableClick(p.CollectionId)}>{locale('admin.feedback.menu.unread')}</MenuItem> 
                    }
                    <MenuItem onClick={event => onDeleteClick(p.CollectionId)}>{locale('admin.feedback.menu.delete')}</MenuItem>
                </Popover> 
        </div>
    )
}